<!-- Your HTML code here -->
<template>   
    <t-audio-recorder
        :device-id="deviceId"
        @device-enumerate="onDeviceEnumerate"
        @set-device-id="onSetDeviceId"
        @stand-by="onStandBy"
        @start="onRecordStart"
        @pause="onRecordPause"
        @resume="onRecordResume"
        @stop="onRecordStop"
        @clear="onClear"
    >
        <template v-slot="{ recording, start, stop, pause, resume, clear }">
            <v-card width="500" class="mx-auto my-8">
                <v-btn v-if="!recording" @click="start" block>
                    <v-icon color="red" x-large>
                        mdi-record-rec
                    </v-icon>
                </v-btn>
                <v-btn v-if="recording && !pausing" @click="pause" block>
                    <v-icon dark x-large>
                        mdi-pause-circle
                    </v-icon>
                </v-btn>
                <v-btn v-if="recording && !pausing" @click="stop" block>
                    <v-icon dark x-large>
                        mdi-stop-circle
                    </v-icon>
                </v-btn>
                <v-btn v-if="recording && pausing" @click="resume" block>
                    <v-icon dark x-large>
                        mdi-record-rec
                    </v-icon>
                </v-btn>
        
                <v-select
                    label="Select your audio device:"
                    v-model="deviceId"
                    :items="deviceItems"
                ></v-select>
        
                <v-divider class="mx-4"></v-divider>
        
                <div v-if="media.url">
                    <audio
                        id="player"
                        ref="player"
                        :src="media.url"
                        :type="media.metadata.mimeType"
                        controls
                    ></audio>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th class="text-center">    
                                    key
                                </th>
                                <th class="text-center">
                                    value
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(value, key) in media.metadata"
                                :key="key"
                            >
                                <td>{{ key }}</td>
                                <td>{{ value }}</td>
                            </tr>
                            <tr
                                v-for="(value, key) in media.input"
                                :key="key"
                            >
                                <td>{{ key }}</td>
                                <td>
                                    <v-text-field
                                        :ref=key
                                        type="text"
                                        v-model="media.input[key]"
                                    ></v-text-field>
                              </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    <v-btn 
                        text
                        block
                        :href="media.url"
                        :download="media.input.filename"
                    >
                        <v-icon>
                            mdi-tray-arrow-down
                        </v-icon>
                        Download 
                    </v-btn>
                </div>

                <v-card-text>
                    <div class="d-flex">
                        <v-btn @click="clear" v-bind:disabled="!media.url">Clear</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="uploadAndSubmit()"
                            v-bind:disabled="!media.url"
                            v-bind:loading="submitting"
                        >Submit</v-btn>
                    </div>
                </v-card-text>    
            </v-card>
        </template>
    </t-audio-recorder>
</template>

<!-- Your JavaScript code here -->
<script>
import nanoMixIn from "@/mixins/nano";
import TAudioRecorder from '@/components/template-ui/TAudioRecorder.vue'
    
export default {
    mixins: [nanoMixIn],
    components: { TAudioRecorder },
    data: () => ({
        defaultNanoProps: {
        },
        defaultNanoAnswers: {
            metadata: {},
        },
        media: {},
        deviceId: null,
        devices: [],
        mediaData: [],
        pausing: false,
        submitting: false,
    }),
    computed: {
        deviceItems() {
            return this.devices.map((dev) => ({
                text: dev.label ? dev.label : dev.deviceId,
                value: dev.deviceId,
            }));
        }
    },
    methods: {
        async uploadAndSubmit() {
            let suffix = "";
            if (!this.media.input.filename.includes(".")) {
                let match = this.media.metadata.mimeType.match(/(.*)\/([^;]*)[;]?(.*)/);
                if (match && match.length > 1)
                    suffix = '.'+match[2];
            }
            await this.addBufferToFileUploadQueue(
                await this.media.buf,
                this.media.input.dir,
                this.media.input.filename+suffix
            );
            this.nano.ans.metadata = this.media.metadata;
            this.nano.ans.input = this.media.input;
            this.submitting = true;
            this.submit();
        },
        onDeviceEnumerate(devices) {
            this.devices = devices;
            if(this.devices.length > 0) {
                this.$nextTick(() => {
                    this.deviceId = this.deviceItems[0].value;
                });
            } else {
                alert('No audio input device found. Please check your audio input device and try again.');
            }
        },
        onSetDeviceId(deviceId) {
            console.log('onSetDeviceId', deviceId);
        },
        onStandBy(deviceId) {
            console.log('standby', deviceId);
        },
        onRecordStart(evt) {
            console.log('start', evt);
        },
        onRecordPause(evt) {
            this.pausing = true;
            console.log('pause', evt);
        },
        onRecordResume(evt) {
            this.pausing = false;
            console.log('resume', evt);
        },
        onRecordStop(media, evt) {
            this.pausing = false;
            this.media = media;
            console.log('stop', evt);
        },
        onClear(media) {
            this.pausing = false;
            this.media = media;
            console.log('clear');
        }
    },
    async mounted() {
        this.addFileUploadStatusChangeHandler(() => {
            if(this.allFileUploadComplete) this.submitting = false;
        });
    },

};
</script>

<!-- Your CSS here -->
<style scoped>   
.card {
    width: 600px;
    margin: 100px auto;
    border: 1px solid black;
    padding: 20px;
    box-sizing: border-box;
}
div.block {
    padding: 15px 0;
}
input[type=text] {
    width: 100%;
    border: 1px solid grey;
    font-size: 1.2em;
    padding: 3px 10px;
    border-radius: 3px;
    box-sizing: border-box;
}
div.next-btn-wrapper {
    padding: 10px 0;
    text-align: right;
}
div.next-btn-wrapper>button {
    width: 100px;
    padding: 10px;
    background-color: #eee;
    border-radius: 5px;
}
div.next-btn-wrapper>button:disabled {
    color: grey;
}
audio {
    width: 100%;
}
</style>

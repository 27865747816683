var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "pa-10": "" } },
    [
      _c(
        "v-card",
        { staticClass: "mx-auto my-6 pa-6", attrs: { width: "600" } },
        [
          _c("header", { staticClass: "pt-4 pb-6" }, [
            _c("b", [_vm._v("Pre-survey.")]),
            _vm._v(" Please answer the following questions first. "),
          ]),
          _c("header", [
            _c("b", [_vm._v("Q1.")]),
            _vm._v(" Which is the fruit you like the most? "),
          ]),
          _c(
            "div",
            { staticClass: "question" },
            [
              _c("v-select", {
                attrs: {
                  "hide-details": "",
                  items: _vm.fruitItems,
                  label: "Select one",
                },
                model: {
                  value: _vm.nano.ans.fruit,
                  callback: function ($$v) {
                    _vm.$set(_vm.nano.ans, "fruit", $$v)
                  },
                  expression: "nano.ans.fruit",
                },
              }),
            ],
            1
          ),
          _c("header", [
            _c("b", [_vm._v("Q2.")]),
            _vm._v(" Select all your hobbies that apply. "),
          ]),
          _c(
            "div",
            { staticClass: "question" },
            [
              _c("v-checkbox", {
                staticClass: "pt-0",
                attrs: { "hide-details": "", value: "Sport", label: "Sport" },
                model: {
                  value: _vm.nano.ans.hobbies,
                  callback: function ($$v) {
                    _vm.$set(_vm.nano.ans, "hobbies", $$v)
                  },
                  expression: "nano.ans.hobbies",
                },
              }),
              _c("v-checkbox", {
                staticClass: "pt-0",
                attrs: {
                  "hide-details": "",
                  value: "Bookreading",
                  label: "Bookreading",
                },
                model: {
                  value: _vm.nano.ans.hobbies,
                  callback: function ($$v) {
                    _vm.$set(_vm.nano.ans, "hobbies", $$v)
                  },
                  expression: "nano.ans.hobbies",
                },
              }),
              _c("v-checkbox", {
                staticClass: "pt-0",
                attrs: {
                  "hide-details": "",
                  value: "Traveling",
                  label: "Traveling",
                },
                model: {
                  value: _vm.nano.ans.hobbies,
                  callback: function ($$v) {
                    _vm.$set(_vm.nano.ans, "hobbies", $$v)
                  },
                  expression: "nano.ans.hobbies",
                },
              }),
              _c("v-checkbox", {
                staticClass: "pt-0",
                attrs: { "hide-details": "", value: "Music", label: "Music" },
                model: {
                  value: _vm.nano.ans.hobbies,
                  callback: function ($$v) {
                    _vm.$set(_vm.nano.ans, "hobbies", $$v)
                  },
                  expression: "nano.ans.hobbies",
                },
              }),
            ],
            1
          ),
          _c("header", [
            _c("b", [_vm._v("Q3.")]),
            _vm._v(" Select one that describes your personality the best. "),
          ]),
          _c(
            "div",
            { staticClass: "question" },
            [
              _c(
                "v-radio-group",
                {
                  attrs: { row: "" },
                  model: {
                    value: _vm.nano.ans.personality,
                    callback: function ($$v) {
                      _vm.$set(_vm.nano.ans, "personality", $$v)
                    },
                    expression: "nano.ans.personality",
                  },
                },
                [
                  _c("v-radio", {
                    attrs: { label: "Inspector", value: "Inspector" },
                  }),
                  _c("v-radio", {
                    attrs: { label: "Performer", value: "Performer" },
                  }),
                  _c("v-radio", {
                    attrs: { label: "Counselor", value: "Counselor" },
                  }),
                  _c("v-radio", { attrs: { label: "Giver", value: "Giver" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-end" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3 mb-3",
                  attrs: { disabled: _vm.submitDisabled },
                  on: {
                    click: function ($event) {
                      return _vm.submit()
                    },
                  },
                },
                [_vm._v("next")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
class AudioPlaybackLoopDefinition {
  constructor(beginTimeSec, endTimeSec) {
    this.beginTimeSec = beginTimeSec;
    this.endTimeSec = endTimeSec;
  }

  get loopDurationSec() {
    return this.endTimeSec - this.beginTimeSec;
  }
}

export default AudioPlaybackLoopDefinition;

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "offset-y": "",
        "offset-overflow": "",
        value: _vm.$data.$_menuVisibility,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ attrs }) {
            return [
              _c(
                "v-card",
                _vm._b(
                  {
                    staticClass: "timeline-title-card v-btn",
                    attrs: {
                      tile: "",
                      text: "",
                      outlined: "",
                      title: _vm.timelineTitle,
                      color: _vm.$_timelineTitleContainerColor,
                      height: _vm.timelineHeightPx,
                    },
                    on: {
                      blur: _vm.$_closeMenu,
                      keydown: [
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "up", 38, $event.key, [
                              "Up",
                              "ArrowUp",
                            ])
                          )
                            return null
                          $event.stopPropagation()
                          return _vm.$_decrementSelectedIdx.apply(
                            null,
                            arguments
                          )
                        },
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "down", 40, $event.key, [
                              "Down",
                              "ArrowDown",
                            ])
                          )
                            return null
                          $event.stopPropagation()
                          return _vm.$_incrementSelectedIdx.apply(
                            null,
                            arguments
                          )
                        },
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "escape",
                              undefined,
                              $event.key,
                              undefined
                            )
                          )
                            return null
                          $event.stopPropagation()
                          return _vm.$_blurMenu.apply(null, arguments)
                        },
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "space", 32, $event.key, [
                              " ",
                              "Spacebar",
                            ])
                          )
                            return null
                          if (
                            $event.ctrlKey ||
                            $event.shiftKey ||
                            $event.altKey ||
                            $event.metaKey
                          )
                            return null
                          $event.stopPropagation()
                          return _vm.$_openMenuOrSelectMenuItem.apply(
                            null,
                            arguments
                          )
                        },
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          if (
                            $event.ctrlKey ||
                            $event.shiftKey ||
                            $event.altKey ||
                            $event.metaKey
                          )
                            return null
                          $event.stopPropagation()
                          return _vm.$_openMenuOrSelectMenuItem.apply(
                            null,
                            arguments
                          )
                        },
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "space", 32, $event.key, [
                              " ",
                              "Spacebar",
                            ])
                          )
                            return null
                          if (!$event.shiftKey) return null
                          if ($event.ctrlKey || $event.altKey || $event.metaKey)
                            return null
                          $event.stopPropagation()
                          return _vm.$_emitSelectAllVisibleSegments.apply(
                            null,
                            arguments
                          )
                        },
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          if (!$event.shiftKey) return null
                          if ($event.ctrlKey || $event.altKey || $event.metaKey)
                            return null
                          $event.stopPropagation()
                          return _vm.$_emitSelectAllVisibleSegments.apply(
                            null,
                            arguments
                          )
                        },
                      ],
                      click: [
                        function ($event) {
                          if (
                            $event.ctrlKey ||
                            $event.shiftKey ||
                            $event.altKey ||
                            $event.metaKey
                          )
                            return null
                          $event.stopPropagation()
                          return _vm.$_toggleMenuOpenAndBlur.apply(
                            null,
                            arguments
                          )
                        },
                        function ($event) {
                          if (!$event.shiftKey) return null
                          if ($event.ctrlKey || $event.altKey || $event.metaKey)
                            return null
                          $event.stopPropagation()
                          return _vm.$_emitSelectAllVisibleSegments.apply(
                            null,
                            arguments
                          )
                        },
                      ],
                      contextmenu: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  "v-card",
                  attrs,
                  false
                ),
                [
                  _c(
                    "div",
                    { staticClass: "timeline-title-container" },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "timeline-data-id-indicator" },
                        [_vm._v(_vm._s(_vm.visibleTimelineDataId))]
                      ),
                      _c(
                        "v-card-text",
                        {
                          staticClass:
                            "timeline-title-text-container text-truncate",
                        },
                        [_vm._v(_vm._s(_vm.timelineTitle))]
                      ),
                      _c(
                        "div",
                        { staticClass: "timeline-title-button-container" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: "",
                                outlined: "",
                                "x-small": "",
                                disabled: _vm.timelineReadonly,
                              },
                              on: {
                                keydown: [
                                  function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    )
                                      return null
                                    $event.stopPropagation()
                                  },
                                  function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.stopPropagation()
                                  },
                                ],
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.$_toggleLockOrUnlockTimeline(
                                    _vm.visibleTimelineDataId
                                  )
                                },
                              },
                            },
                            [
                              _vm.timelineLocked || _vm.timelineReadonly
                                ? _c("v-icon", [_vm._v("mdi-lock")])
                                : _c("v-icon", [_vm._v("mdi-lock-open")]),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: "",
                                outlined: "",
                                "x-small": "",
                                disabled:
                                  _vm.timelineLocked || _vm.timelineReadonly,
                              },
                              on: {
                                keydown: [
                                  function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    )
                                      return null
                                    $event.stopPropagation()
                                  },
                                  function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.stopPropagation()
                                  },
                                ],
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.$_emitToggleSetOrUnsetRealtimeSegmentCreationTarget(
                                    _vm.visibleTimelineDataId
                                  )
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("mdi-record")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        { attrs: { dense: "" } },
        _vm._l(_vm.menuContents, function (menuContent, menuContentIdx) {
          return _c(
            "v-list-item",
            {
              key: menuContentIdx,
              attrs: {
                disabled: menuContent.disabled,
                "input-value": _vm.$_isSelected[menuContentIdx],
              },
              on: {
                mousedown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "left", 37, $event.key, [
                      "Left",
                      "ArrowLeft",
                    ])
                  )
                    return null
                  if ("button" in $event && $event.button !== 0) return null
                  $event.stopPropagation()
                  return _vm.$_select(menuContent)
                },
                contextmenu: function ($event) {
                  $event.preventDefault()
                },
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("v-list-item-title", {
                domProps: { textContent: _vm._s(menuContent.name) },
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
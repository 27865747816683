<template>
  <v-container
    fluid pa-0
    v-bind:style="{ height: timelineHeightPx + 'px' }"
  >
    <v-menu offset-y offset-overflow>
      <template v-slot:activator="{ on, attrs }">
        <v-card
          tile text outlined
          class="timeline-title-container v-btn"
          title="Generate New Timeline"
          v-bind="attrs"
          v-on="on"
        >
          <v-container fill-height fluid pa-0>
            <v-row justify-center no-gutters>
              <v-col>
                <v-icon class="generate-new-timeline-button-icon">mdi-plus</v-icon>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>
      <v-list dense>
        <v-list-item
          v-for="(menuContent, menuContentIdx) in menuContents"
          v-bind:key="menuContentIdx"
          v-bind:disabled="menuContent.disabled"
          v-on:click="menuContent.callback()"
        >
          <v-list-item-title v-text="menuContent.name" />
        </v-list-item>
      </v-list>
    </v-menu>
  </v-container>
</template>

<style scoped>
.timeline-title-container {
  width: 100%;
  height: 100% !important;
  padding: 0 10px;
  background-color: #f3f3f3;
}

.generate-new-timeline-button-icon {
  display: inline-block;
  vertical-align: baseline;
  text-transform: none;
}
</style>

<script>
export default {
  props: {
    timelineHeightPx: { type: Number },
    menuContents: { type: Array },
  },
};
</script>

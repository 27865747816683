<template>
    <v-container pa-10 style="max-width: 1000px;">
        <v-card class="my-10">
            <v-system-bar window dark color="indigo">
                <h3>Your task</h3>
            </v-system-bar>
            <div class="pa-4 text-body-1 font-weight-medium">
                Select a genre of the suggested music.
            </div>
        </v-card>
        <v-card max-width="1000" class="mx-auto my-6 pa-6">
            <v-card-text>
                <v-row align="center">
                    <v-col>
                        <t-audio
                            :src="nano.props.audioPath"
                            @emit-current-time="getCurrentTime"
                        />
                    </v-col >
                </v-row>
                <v-row>
                    <v-col>
                        <div>Radio buttons are activated after the audio is played beyond 0:05.</div>
                    </v-col>
                </v-row>
                <v-row class="mt-0 pt-0">
                    <v-col>
                        <v-radio-group v-model="nano.ans.choice">
                            <v-radio 
                                v-for="option in optionChoice"
                                :key="option.val"
                                :label="option.text"
                                :value="option.val"
                                :disabled="radioDisabled"
                            />
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn
                            :disabled="nano.ans.choice === null"
                            color="primary" 
                            @click="submit()"
                        >Submit
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import nanoMixIn from '@/mixins/nano';
import TAudio from '@/components/template-ui/TAudio'
export default{
    mixins: [nanoMixIn],
    components: { TAudio },
    data: () => ({
        currentTime: 0,
        optionChoice: [
            { text: 'Jazz', val: 'jazz' },
            { text: 'Classical Music', val:'classical' },
            { text: 'EDM', val:'edm' },
            { text: 'Pop Music', val:'pop' },
        ],
        defaultNanoProps: {
            audioPath: 'https://storage.googleapis.com/iflab-public/tutti.works/audios/pops-music-01.mp3',
        },
        defaultNanoAnswers: {
            choice: null,
        }
    }),
    computed:{
        radioDisabled(){
            if(this.currentTime > 5) return false;
            else return true;
        }
    },
    methods:{
        getCurrentTime(val){
            this.currentTime = val;
        }
    }
}
</script>

<template>
  <v-menu
    offset-y offset-overflow
    v-bind:value="$data.$_menuVisibility"
  >
    <template v-slot:activator="{ attrs }">
      <v-card
        class="timeline-title-card v-btn"
        tile text outlined
        v-bind="attrs"
        v-bind:title="timelineTitle"
        v-bind:color="$_timelineTitleContainerColor"
        v-bind:height="timelineHeightPx"
        v-on:blur="$_closeMenu"
        v-on:keydown.up.stop="$_decrementSelectedIdx"
        v-on:keydown.down.stop="$_incrementSelectedIdx"
        v-on:keydown.escape.stop="$_blurMenu"
        v-on:click.exact.stop="$_toggleMenuOpenAndBlur"
        v-on:keydown.space.exact.stop="$_openMenuOrSelectMenuItem"
        v-on:keydown.enter.exact.stop="$_openMenuOrSelectMenuItem"
        v-on:click.shift.exact.stop="$_emitSelectAllVisibleSegments"
        v-on:keydown.space.shift.exact.stop="$_emitSelectAllVisibleSegments"
        v-on:keydown.enter.shift.exact.stop="$_emitSelectAllVisibleSegments"
        v-on:click.right.prevent
      >
        <div class="timeline-title-container">
          <v-card-text class="timeline-data-id-indicator">{{ visibleTimelineDataId }}</v-card-text>
          <v-card-text class="timeline-title-text-container text-truncate">{{ timelineTitle }}</v-card-text>
          <div class="timeline-title-button-container">
            <v-btn
              icon outlined x-small
              v-on:keydown.space.stop
              v-on:keydown.enter.stop
              v-on:click.stop="$_toggleLockOrUnlockTimeline(visibleTimelineDataId)"
              v-bind:disabled="timelineReadonly"
            >
              <v-icon v-if="timelineLocked || timelineReadonly">mdi-lock</v-icon>
              <v-icon v-else>mdi-lock-open</v-icon>
            </v-btn>
            <v-btn
              icon outlined x-small
              v-bind:disabled="timelineLocked || timelineReadonly"
              v-on:keydown.space.stop
              v-on:keydown.enter.stop
              v-on:click.stop="$_emitToggleSetOrUnsetRealtimeSegmentCreationTarget(visibleTimelineDataId)"
            >
              <v-icon>mdi-record</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card>
    </template>
    <v-list dense>
      <v-list-item
        v-for="(menuContent, menuContentIdx) in menuContents"
        v-bind:key="menuContentIdx"
        v-bind:disabled="menuContent.disabled"
        v-bind:input-value="$_isSelected[menuContentIdx]"
        v-on:mousedown.left.stop="$_select(menuContent)"
        v-on:click.right.prevent
        v-on:click.stop
      >
        <v-list-item-title v-text="menuContent.name" />
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<style scoped>
.timeline-title-card {
  width: 100%;
}

.timeline-title-container {
  display: flex;
  height: 100%;
  padding-left: 20px;
  padding-right: 10px;
  align-items: center;
}

.timeline-title-container>* {
  vertical-align: baseline;
  text-transform: none;
}

.timeline-title-text-container {
  padding: 0;
  flex-grow: 1;
}

.timeline-title-button-container {
  display: inline-flex;
}

.timeline-title-button-container>* {
  margin-left: 5px;
}

.timeline-data-id-indicator:before {
  content: '[';
}

.timeline-data-id-indicator {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  font-size: 9pt;
}

.timeline-data-id-indicator:after {
  content: ']';
}
</style>

<script>
export default {
  props: {
    visibleTimelineDataId: { type: String, default: '' },
    visibleTimelineDataIdx: { type: Number },
    timelineTitle: { type: String, default: '' },
    timelineHeightPx: { type: Number },
    timelineReadonly: { type: Boolean, default: false },
    timelineLocked: { type: Boolean, default: false },
    isRealtimeSegmentCreationTarget: { type: Boolean },
    menuContents: { type: Array },
  },

  data() {
    return {
      $_menuVisibility: false,
      $_selectedIdx: 0,
    };
  },

  computed: {
    $_numMenuContents() { return this.menuContents.length; },
    $_isSelected() {
      return this.menuContents.map((menuContent, menuContentIdx) => {
        if (menuContent.disabled) {
          return null;
        } else {
          return (this.$data.$_selectedIdx === menuContentIdx)? true : null;
        }
      });
    },
    $_timelineTitleContainerColor() {
      if (this.isRealtimeSegmentCreationTarget) {
        return '#ff9494';
      } else {
        return '#f3f3f3';
      }
    },
  },

  inject: [
    'lockTimeline',
    'unlockTimeline',
  ],

  methods: {
    $_incrementSelectedIdx() {
      let currentSelectedIdx = this.$data.$_selectedIdx;
      let newSelectedIdx = currentSelectedIdx;
      do {
        ++newSelectedIdx;
        if (newSelectedIdx === this.$_numMenuContents) newSelectedIdx = 0;
        if (!this.menuContents[newSelectedIdx].disabled) break;
      }
      while (newSelectedIdx !== currentSelectedIdx);
      this.$data.$_selectedIdx = newSelectedIdx;
    },

    $_decrementSelectedIdx() {
      let currentSelectedIdx = this.$data.$_selectedIdx;
      let newSelectedIdx = currentSelectedIdx;
      do {
        --newSelectedIdx;
        if (newSelectedIdx < 0) newSelectedIdx = (this.$_numMenuContents - 1);
        if (!this.menuContents[newSelectedIdx].disabled) break;
      }
      while (newSelectedIdx !== currentSelectedIdx);
      this.$data.$_selectedIdx = newSelectedIdx;
    },

    $_openMenuOrSelectMenuItem() {
      if (!this.$data.$_menuVisibility) {
        this.$_openMenu();
      } else {
        this.$_select(this.menuContents[this.$data.$_selectedIdx]);
      }
    },

    async $_select(menuContent) {
      await menuContent.callback(this.visibleTimelineDataIdx);
      this.$_blurMenu();
    },

    $_blurMenu() {
      document.activeElement.blur();
    },

    $_closeMenu() {
      this.$data.$_menuVisibility = false;
    },

    $_openMenu() {
      this.$data.$_selectedIdx = 0;
      this.$data.$_menuVisibility = true;
    },

    $_toggleMenuOpenAndBlur() {
      (this.$data.$_menuVisibility)? this.$_blurMenu() : this.$_openMenu();
    },

    $_toggleLockOrUnlockTimeline(timelineDataId) {
      if (this.timelineLocked) {
        this.unlockTimeline(timelineDataId);
      } else {
        this.lockTimeline(timelineDataId);
      }
    },

    $_emitSelectAllVisibleSegments() {
      this.$emit('select-all-visible-segments', this.visibleTimelineDataIdx);
    },

    $_emitToggleSetOrUnsetRealtimeSegmentCreationTarget(visibleTimelineDataId) {
      this.$emit('toggle-set-or-unset-realtime-segment-creation-target', visibleTimelineDataId);
    },
  }
};
</script>

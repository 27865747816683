<template>
  <div 
    id="editable-text"
    v-on:focusin="$_onFocusin"
    v-on:focusout="$_onFocusout"
  >
    <input
      ref="inputElement"
      id="text-input"
      class="flex-grow-1"
      autocomplete="off"
      v-bind:disabled="readonly"
      v-bind:readonly="$_apparentlyReadonly"
      v-bind:list="$_datalistId"
      v-on:compositionstart="$_onCompositionstart"
      v-on:compositionend="$_onCompositionend"
      v-on:click.stop
      v-on:keydown="$_onKeydownMayStopPropagation"
      v-on:dblclick="$_onDblclick"
      v-on:input="$_onInput"
      v-on:change="$_onChange"
    />
    <datalist
      v-if="$_hasOptions"
      v-bind:id="$_datalistId"
    >
      <option
        v-for="option in options"
        v-bind:key="option"
        v-bind:value="option"
      >
      </option>
    </datalist>
    <template v-if="!simple">
      <template v-if="$data.$_isEditing">
        <v-btn
          icon x-small
          class="flex-grow-0"
          v-on:click="$_finishEditing"
          v-on:keydown="$_onKeydownOnButtonMayStopPropagation($event, $_finishEditing)"
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>
        <v-btn
          icon x-small
          class="flex-grow-0"
          v-on:click="$_cancelEditing"
          v-on:keydown="$_onKeydownOnButtonMayStopPropagation($event, $_cancelEditing)"
        >
          <v-icon>mdi-cancel</v-icon>
        </v-btn>
      </template>

      <template v-else>
        <v-btn
          icon x-small
          v-if="!readonly"
          v-on:click="$_startEditing"
          v-on:keydown="$_onKeydownOnButtonMayStopPropagation($event, $_startEditing)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
    </template>
  </div>
</template>

<style scoped>
div#editable-text {
  display: flex;
  align-items: center;
  width: 100%;
}

div#editable-text.simple {
  border-bottom: none !important;
  min-width: max-content;
}

div#editable-text.editing {
  border-bottom: 1px solid #000000;
}

div#editable-text {
  border-bottom: 1px solid #dddddd;
}

input#text-input {
  width: 100%;
  text-overflow: ellipsis;
}
</style>

<script>
import Utils from './modules/Utils.js';

export default {
  model: {
    prop: 'value',
    event: 'update',
  },

  watch: {
    value(value) {
      this.$_updateElement(value);
    },

    '$data.$_isEditing'() {
      this.$_updateElement(this.value);
    },

    simple() {
      this.$_updateElement(this.value);
    },
  },

  mounted() {
    this.$_updateElement(this.value);
  },

  props: {
    value: {},
    readonly: { type: Boolean, default: false },
    prohibitEmpty: { type: Boolean, default: false },
    validator: { type: Function, default: null },
    options: { type: Array, default: null },
    simple: { type: Boolean, default: false },
  },

  data() {
    return {
      $_isEditing: false,
      $_isValid: true,
      $_isCompositionStarted: false,
    };
  },

  computed: {
    $_apparentlyReadonly() {
      if (this.simple) {
        return this.readonly;
      } else {
        return this.readonly || (!this.$data.$_isEditing);
      }
    },

    $_inputElement() {
      return this.$refs.inputElement;
    },

    $_isValueNumber() {
      return Utils.isNumber(this.value);
    },

    $_hasOptions() {
      return (this.options !== null);
    },

    $_datalistId() {
      if (this.$_hasOptions && this.$data.$_isEditing) {
        return 'datalist-id';
      } else {
        return undefined;
      }
    },
  },

  methods: {
    $_updateElement(value) {
      this.$_inputElement.value = String(value);
      this.$_validate();
      let valueString = String(value);
      this.$_inputElement.size = (valueString.length < 1)? 1 : valueString.length;
      if (this.simple) {
        this.$el.classList.add('simple');
      } else {
        this.$el.classList.remove('simple')
      }
      if (this.$data.$_isEditing) {
        this.$el.classList.add('editing');
      } else {
        this.$el.classList.remove('editing')
      }
    },

    $_updateValue() {
      let newValue = getNewValue(this);
      this.$_updateElement(newValue);
      if (newValue === this.value) return false;
      this.$emit('update', newValue);
      return true;

      function getNewValue(self) {
        if (self.$data.$_isValid) {
          return self.$_getInputValue();
        } else {
          return self.value;
        }
      }
    },

    $_getInputValue() {
      if (this.$_isValueNumber) {
        return Number(this.$_inputElement.value);
      } else {
        return this.$_inputElement.value;
      }
    },

    $_onFocusin() {
      if (this.simple) {
        this.$_startEditing();
      }
    },

    $_onCompositionstart() {
      this.$data.$_isCompositionStarted = true;
    },

    $_onCompositionend() {
      this.$data.$_isCompositionStarted = false;
    },

    $_onDblclick() {
      if (!this.simple && !this.$data.$_isEditing) {
        this.$_startEditing();
      }
    },

    $_onKeydownMayStopPropagation(keyboardEvent) {
      if (this.$data.$_isEditing) {
        keyboardEvent.stopPropagation();
        if (onKeydownWhileEditing(this, keyboardEvent)) {
          keyboardEvent.preventDefault();
        }
      } else {
        if (onKeydownUnlessEditing(this, keyboardEvent)) {
          keyboardEvent.stopPropagation();
          keyboardEvent.preventDefault();
        }
      }

      function onKeydownWhileEditing(self, keyboardEvent) {
        if (self.$data.$_isCompositionStarted) return true;
        switch (keyboardEvent.code) {
          case 'Enter':
            if (self.simple) {
              return updateValue(self);
            } else {
              return finishEditing(self);
            }
          case 'ArrowUp':
            if (self.$_isValueNumber) {
              return incrementInputValue(self);
            }
            return false;
          case 'ArrowDown':
            if (self.$_isValueNumber) {
              return decrementInputValue(self);
            }
            return false;
          case 'Escape':
            return cancelEditing(self);
          default:
            return false;
        }
      }

      function onKeydownUnlessEditing(self, keyboardEvent) {
        switch (keyboardEvent.code) {
          case 'Enter':
            if (!self.simple) {
              return startEditing(self);
            }
            return false;
          default:
            return false;
        }
      }

      function updateValue(self) {
        self.$_updateValue();
        return true;
      }

      function finishEditing(self) {
        self.$_finishEditing();
        return true;
      }

      function startEditing(self) {
        self.$_startEditing();
        return true;
      }

      function cancelEditing(self) {
        self.$_cancelEditing();
        return true;
      }

      function incrementInputValue(self) {
        self.$_updateElement(Math.floor(self.$_getInputValue()) + 1);
        return true;
      }

      function decrementInputValue(self) {
        self.$_updateElement(Math.floor(self.$_getInputValue()) - 1);
        return true;
      }
    },

    $_onFocusout(focusEvent) {
      if (!this.$data.$_isEditing) return;
      if (focusEvent.relatedTarget !== null) {
        for (let childNode of this.$el.childNodes) {
          if (focusEvent.relatedTarget.isSameNode(childNode)) return;
        }
      }
      this.$_cancelEditing();
    },

    $_onKeydownOnButtonMayStopPropagation(keyboardEvent, callback) {
      if (onKeydownOnButton(this, keyboardEvent, callback)) {
        keyboardEvent.stopPropagation();
        keyboardEvent.preventDefault();
      }

      function onKeydownOnButton(self, keyboardEvent, callback) {
        switch (keyboardEvent.code) {
          case 'Enter':
            callback();
            return true;
          case 'Space':
            callback();
            return true;
          case 'Escape':
            return false;
          case 'Tab':
            return false;
          default:
            return self.$data.$_isEditing;
        }
      }
    },

    $_startEditing() {
      if (this.readonly) return;
      this.$_inputElement.focus();
      this.$data.$_isEditing = true;
    },

    $_finishEditing() {
      this.$_updateValue();
      this.$data.$_isEditing = false;
    },

    $_cancelEditing() {
      this.$_updateElement(this.value);
      document.activeElement.blur();
      this.$data.$_isEditing = false;
    },

    $_onChange(event) {
      if (this.simple) {
        this.$_updateElement(event.target.value);
        this.$_updateValue();
      }
    },

    $_onInput() {
      this.$_validate();
    },

    $_validate() {
      if (this.validator !== null) {
        this.$data.$_isValid = this.validator(this.$_getInputValue());
      } else {
        this.$data.$_isValid = true;
      }
    },
  },
}
</script>
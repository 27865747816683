var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("dl", { staticClass: "insepctor-definition-list" }, [
    _c("dt", [_vm._v(_vm._s(_vm.title))]),
    _c(
      "dd",
      [
        _vm.$_isObject(_vm.definition)
          ? _vm._l(
              _vm.definition,
              function (definition_in_definition, title_in_definition) {
                return _c("InspectorDefinition", {
                  key: title_in_definition,
                  attrs: {
                    title: title_in_definition,
                    definition: definition_in_definition,
                  },
                })
              }
            )
          : [_vm._v(_vm._s(_vm.definition))],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("dialog-base", {
    attrs: {
      shows: _vm.shows,
      "initialize-callback": _vm.$_onInitialize,
      "ok-callback": _vm.$_okCallback,
      "finalize-callback": _vm.finalizeCallback,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("v-card-title", [_vm._v("Timeline Duration")]),
            _c(
              "v-card-text",
              [
                _vm.$_isVideoDurationSet
                  ? _c("v-checkbox", {
                      attrs: { label: _vm.$_usesVideoDurationCheckboxLabel },
                      model: {
                        value: _vm.$data.$_usesVideoDuration,
                        callback: function ($$v) {
                          _vm.$set(_vm.$data, "$_usesVideoDuration", $$v)
                        },
                        expression: "$data.$_usesVideoDuration",
                      },
                    })
                  : _vm._e(),
                _c("v-text-field", {
                  attrs: {
                    dense: "",
                    autofocus: "",
                    suffix: "msec",
                    disabled: _vm.$data.$_usesVideoDuration,
                  },
                  model: {
                    value: _vm.$_durationMsec,
                    callback: function ($$v) {
                      _vm.$_durationMsec = _vm._n($$v)
                    },
                    expression: "$_durationMsec",
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div id="footer" class="border-common">
    <div
      id="footer-message"
      class="text-truncate"
      v-on:click="showFooterMessageLogs"
    >
      {{ $_latestMessage }}
    </div>
    <div id="footer-menu">
      <a v-on:click="showHelp">Help(h)</a>
      <TextDivider content="-" />
      <a v-on:click="showVersion">{{ toolTitle }}({{ version }})</a>
    </div>
  </div>
</template>

<style scoped>
#footer {
  display: flex;
  line-height: 24px;
  padding: 0 10px; 
  border-top-width: 1px;
  border-top-style: solid;
  font-size: 0.8em;
}

#footer-message {
  position: relative;
  flex: auto;
  padding-right: 20px;
}

#footer-menu {
  position: relative;
  text-align: right;
  width: auto;
}

#footer-menu > a {
  color: inherit;
}
</style>

<style src="../styles/common.css" scoped />

<script>
import TextDivider from './Parts/TextDivider.vue'

class FooterMessages {
  constructor(...args) {
    this.data = new Array(...args);
  }

  push(message) {
    this.data.push(new FooterMessages.Message(message, new Date()));
  }

  get latestMessage() {
    if (this.data.length === 0) return '';
    return this.data[this.data.length - 1];
  }

  get logMessages() {
    return this.data.map(message => message.toLogString());
  }
}

Object.defineProperty(
  FooterMessages,
  'Message',
  {
    value: class {
      constructor(data, time) {
        this.data = data;
        this.time = time;
      }

      toString() {
        return this.data;
      }

      toLogString() {
        return '[' + this.time.toLocaleString() + ']: ' + this.data;
      }
    },
    writable: false,
  },
);

export { FooterMessages };

export default {
  components: {
    TextDivider,
  },

  props: {
    toolTitle: { type: String },
    messages: { type: Object },
    version: { type: String },
  },

  computed: {
    $_latestMessage() {
      return this.messages.latestMessage;
    },
  },

  inject: [
    'showHelp',
    'showVersion',
    'showFooterMessageLogs',
  ],
};
</script>

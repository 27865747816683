<template>
  <canvas>
  </canvas>
</template>

<script>
import CanvasBase from './mixins/CanvasBase.js';
import Color from '../../modules/Color.js';

const playTimeBarColor = new Color(255, 0, 0);

export default {
  mixins: [
    CanvasBase,
  ],

  watch: {
    playTimeSec()    { this.$_setDirty(true); },
    canvasBeginSec() { this.$_setDirty(true); },
    canvasEndSec()   { this.$_setDirty(true); },
  },

  props: {
    playTimeSec:       { type: Number },
    canvasBeginSec:    { type: Number },
    canvasEndSec:      { type: Number },
    canvasDurationSec: { type: Number },
  },

  computed: {
    $_playTimeOffsetPx() {
      let timeResolution = this.canvasDurationSec / this.$_canvasWidthPx;
      let playTimeOffsetSec = this.playTimeSec - this.canvasBeginSec;
      return playTimeOffsetSec / timeResolution;
    },
  },

  methods: {
    draw(canvasElement) {
      if (this.$_playTimeOffsetPx < 0) return;
      if (this.$_playTimeOffsetPx > this.$_canvasWidthPx) return;
      let canvasContext = canvasElement.getContext('2d');
      this.$_playTimeBar(canvasContext);
    },

    $_playTimeBar(canvasContext) {
      canvasContext.setLineDash([]);
      canvasContext.lineWidth = 1;
      canvasContext.strokeStyle = playTimeBarColor.styleString;
      canvasContext.moveTo(...this.$_dotByDotOffsetCoordArgs(this.$_playTimeOffsetPx, 0));
      canvasContext.lineTo(...this.$_dotByDotOffsetCoordArgs(this.$_playTimeOffsetPx, this.$_canvasHeightPx));
      canvasContext.stroke();
    },
  },
}
</script>
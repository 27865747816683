<template>
  <div id="time-indicator">
    <span>{{ $_hours }}</span>
    <span>{{ $_minutes }}</span>
    <span>{{ $_seconds }}</span>
  </div>
</template>

<style scoped>
#time-indicator {
  font-size: 11px;
}

#time-indicator > span:not(:last-child):after {
  content: ':';
  padding: 0 2px;
}
</style>

<script>
import utils from '../../modules/utils.js'
const oneSecondMsec = 1000;
const oneMinuteMsec = 60 * oneSecondMsec;
const oneHourMsec = 60 * oneMinuteMsec;

class TimeComponent {
  constructor(hours, minutes, seconds, milliseconds) {
    this.hours = hours;
    this.minutes = minutes;
    this.seconds = seconds;
    this.milliseconds = milliseconds;
  }

  static fromMilliseconds(remainings) {
    let hours = String(Math.floor(remainings / oneHourMsec));
    remainings %= oneHourMsec;
    let minutes = utils.padZero(Math.floor(remainings / oneMinuteMsec), 2);
    remainings %= oneMinuteMsec;
    let seconds = utils.padZero(Math.floor(remainings / oneSecondMsec), 2);
    remainings %= oneMinuteMsec;
    let milliseconds = utils.padZero(remainings, 3);
    return new TimeComponent(hours, minutes, seconds, milliseconds);
  }
}

export default {
  props: {
    milliseconds: { type: Number },
  },

  computed: {
    $_timeComponent() {
      return TimeComponent.fromMilliseconds(this.milliseconds)
    },

    $_hours() {
      return this.$_timeComponent.hours;
    },

    $_minutes() {
      return this.$_timeComponent.minutes;
    },

    $_seconds() {
      return this.$_timeComponent.seconds;
    },
  },
}
</script>
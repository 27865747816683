var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-tooltip", {
    attrs: { bottom: "" },
    scopedSlots: _vm._u(
      [
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function () {
                              return [_vm._t("default")]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    "v-btn",
                    _vm.$_mergeAttributes(attrs),
                    false
                  ),
                  _vm.$_mergeListeners(on)
                )
              ),
            ]
          },
        },
        {
          key: "default",
          fn: function () {
            return [_vm._t("tooltip")]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "time-indicator" } }, [
    _c("span", [_vm._v(_vm._s(_vm.$_hours))]),
    _c("span", [_vm._v(_vm._s(_vm.$_minutes))]),
    _c("span", [_vm._v(_vm._s(_vm.$_seconds))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import AudioSegmentSequence from './modules/AudioSegmentSequence.js'
import { AudioSegmentTimeUnit } from './modules/AudioSegmentSequence.js'

export default {
  data() {
    return {
      $_audioSegmentSequenceInternal: undefined,
    };
  },

  watch: {
    data: {
      handler(data) {
        let timeUnit = AudioSegmentTimeUnit.getByLabel[data.timeUnit];
        let audioSegmentSequence = AudioSegmentSequence.generate(data.audioSegments, timeUnit, data.samplingRate);
        if (this.$data.$_audioSegmentSequenceInternal === undefined) {
          this.$data.$_audioSegmentSequenceInternal = audioSegmentSequence;
        } else if (!this.$data.$_audioSegmentSequenceInternal.isEqualTo(audioSegmentSequence)) {
          this.$data.$_audioSegmentSequenceInternal = audioSegmentSequence;
        }
      },
      immediate: true,
    },
  },

  computed: {
    $_audioSegmentSequence: {
      get() { return this.$data.$_audioSegmentSequenceInternal },
      set(audioSegmentSequence) {
        if (!this.$data.$_audioSegmentSequenceInternal.isEqualTo(audioSegmentSequence)) {
          this.$emit('update', Object.assign(this.data, audioSegmentSequence.exportAsObject()))
        }
        this.$data.$_audioSegmentSequenceInternal = audioSegmentSequence;
      },
    },

    $_labelSequence: {
      get() { return this.data.labelSequence },
      set(labelSequence) { this.$emit('update', Object.assign(this.data, { labelSequence })) },
    },
  },
}

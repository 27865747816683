var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: { "close-on-content-click": false, "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { attrs: { icon: "", small: "", elevation: "0" } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_c("v-icon", [_vm._v(_vm._s(_vm.volumeIcon))])],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c("v-slider", {
        staticClass: "overflow-hidden",
        attrs: {
          value: _vm.volume,
          "hide-details": "",
          color: "#616161",
          "track-color": "#616161",
          "background-color": "#FFFFFF",
          vertical: "",
          step: "0.01",
          min: "0",
          max: "1",
        },
        on: { input: _vm.changeVolume },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
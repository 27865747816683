var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "timeline-list-container" } }, [
    _c(
      "div",
      {
        staticClass: "border-common",
        style: _vm.$_timelineTitleAndFooterContainerStyle,
        attrs: { id: "timeline-title-and-footer-container" },
      },
      [
        _c(
          "div",
          {
            style: _vm.$_timelineTitleContainerStyle,
            attrs: { id: "timeline-title-container" },
          },
          [
            _vm._l(
              _vm.visibleTimelineDataIdxToId,
              function (visibleTimelineDataId, visibleTimelineDataIdx) {
                return _c("TimelineTitle", {
                  key: visibleTimelineDataIdx,
                  staticClass: "timeline-title",
                  attrs: {
                    "visible-timeline-data-id": visibleTimelineDataId,
                    "visible-timeline-data-idx": visibleTimelineDataIdx,
                    "timeline-title":
                      _vm.visibleTimelineDataSet[visibleTimelineDataId].name,
                    "timeline-height-px": _vm.$_timelineHeightPx,
                    "timeline-readonly":
                      _vm.visibleTimelineDataSet[visibleTimelineDataId]
                        .readonly,
                    "timeline-locked":
                      _vm.visibleTimelineDataSet[visibleTimelineDataId].locked,
                    "is-realtime-segment-creation-target":
                      _vm.$data.$_isRealtimeSegmentCreationTarget[
                        visibleTimelineDataId
                      ],
                    "menu-contents": _vm.$_getMenuContentsTimelineTitle(
                      visibleTimelineDataIdx
                    ),
                  },
                  on: {
                    "select-all-visible-segments":
                      _vm.$_selectAllVisibleSegments,
                    "toggle-set-or-unset-realtime-segment-creation-target":
                      _vm.$_toggleSetOrUnsetRealtimeSegmentCreationTarget,
                  },
                })
              }
            ),
            _c("GenerateNewTimelineDataButton", {
              staticClass: "timeline-title",
              attrs: {
                "timeline-height-px": _vm.$_timelineHeightPx,
                "menu-contents": _vm.$_menuContentsGenerateNewTimelineData,
              },
            }),
          ],
          2
        ),
        _c("TimelineTitleFooter", {
          attrs: {
            "is-mouse-mode": _vm.isMouseMode,
            "is-snap-enabled": _vm.isSnapEnabled,
          },
          on: {
            "toggle-mouse-mode": _vm.$_toggleMouseMode,
            "toggle-snap": _vm.$_toggleSnap,
          },
        }),
      ],
      1
    ),
    _c("div", {
      ref: "timelineSeparator",
      staticClass: "border-common",
      attrs: { id: "timeline-separator" },
    }),
    _c(
      "div",
      { attrs: { id: "timeline-container" } },
      [
        _c(
          "div",
          {
            ref: "timelineCanvasContainer",
            class: _vm.$data.$_timelineContainerClass,
            attrs: { id: "timeline-canvas-container" },
          },
          [
            _c("TimelineBackgroundCanvas", {
              ref: "timelineBackgroundCanvas",
              attrs: {
                id: "timeline-background-canvas",
                "ruler-vertical-offset-px": _vm.$_rulerVerticalOffsetPx,
                "num-visible-timelines": _vm.$_numVisibleTimelines,
                "vertical-offset-px": _vm.$data.$_verticalOffsetPx,
                "timeline-height-px": _vm.$_timelineHeightPx,
                "time-resolution": _vm.$data.$_timeResolution,
                "waveform-digest": _vm.waveformDigest,
                "timeline-view-begin-msec": _vm.$data.$_timelineViewBeginMsec,
                "is-loop-enabled": _vm.isLoopEnabled,
                "loop-definition": _vm.loopDefinition,
              },
            }),
            _c("TimelineSegmentCanvas", {
              ref: "timelineSegmentCanvas",
              attrs: {
                id: "timeline-segment-canvas",
                "ruler-vertical-offset-px": _vm.$_rulerVerticalOffsetPx,
                "duration-msec": _vm.durationMsec,
                "vertical-offset-px": _vm.$data.$_verticalOffsetPx,
                "timeline-height-px": _vm.$_timelineHeightPx,
                "time-resolution": _vm.$data.$_timeResolution,
                "timeline-view-begin-msec": _vm.$data.$_timelineViewBeginMsec,
                "timeline-segment-dom-rects": _vm.$_timelineSegmentDomRects,
                "selected-timeline-segment-ids": _vm.selectedTimelineSegmentIds,
                "visible-timeline-data-set": _vm.visibleTimelineDataSet,
                "visible-timeline-data-idx-to-id":
                  _vm.visibleTimelineDataIdxToId,
              },
            }),
            _c("TimelineForegroundCanvas", {
              ref: "timelineForegroundCanvas",
              attrs: {
                id: "timeline-foreground-canvas",
                "time-resolution": _vm.$data.$_timeResolution,
                "timeline-view-begin-msec": _vm.$data.$_timelineViewBeginMsec,
                "timeline-marker": _vm.timelineMarker,
              },
            }),
            _c("TimelinePlayTimeBarCanvas", {
              ref: "timelinePlayTimeBarCanvas",
              attrs: {
                id: "timeline-play-time-bar-canvas",
                "ruler-vertical-offset-px": _vm.$_rulerVerticalOffsetPx,
                "play-time-msec": _vm.playTimeMsec,
                "is-play-time-in-view": _vm.$_isPlayTimeInView,
                "follow-play-bar-flag": _vm.$data.$_followPlayBarFlag,
                "time-resolution": _vm.$data.$_timeResolution,
                "timeline-view-begin-msec": _vm.$data.$_timelineViewBeginMsec,
              },
            }),
            _c("TimelineOverlayCanvas", {
              ref: "timelineOverlayCanvas",
              attrs: {
                id: "timeline-overlay-canvas",
                "timeline-canvas-local-coords-at-mousedown":
                  _vm.$_timelineCanvasLocalCoordsAtMousedownOrMarkerBegin,
                "timeline-canvas-local-coords-at-mousemove":
                  _vm.$_timelineCanvasLocalCoordsAtMousemoveOrMarkerEnd,
                "is-mousedown-on-ruler-area":
                  _vm.$data.$_isMousedownOnRulerArea,
                "timeline-segment-dom-rects-to-drag-with-info":
                  _vm.$_timelineSegmentDomRectsToDragWithInfo,
                "segment-drag-offset-time-px": _vm.$_segmentDragOffsetTimePx,
                "num-visible-timelines": _vm.$_numVisibleTimelines,
                "view-mode": _vm.$data.$_currentViewMode,
                "vertical-offset-px": _vm.$data.$_verticalOffsetPx,
                "timeline-height-px": _vm.$_timelineHeightPx,
                "minimum-segment-duration-px": _vm.$_minimumSegmentDurationPx,
                "time-resolution": _vm.$data.$_timeResolution,
                "timeline-view-begin-msec": _vm.$data.$_timelineViewBeginMsec,
              },
            }),
            _c("TimeScrollBarCanvas", {
              ref: "timeScrollBarCanvas",
              attrs: {
                id: "time-scroll-bar-canvas",
                "time-scroll-bar-canvas-height-px":
                  _vm.$data.$_timelineScrollBarWidthPx,
                "time-scroll-bar-dom-rect": _vm.$_timeScrollBarDomRect,
              },
            }),
            _c("TimelineListMenu", {
              attrs: {
                "menu-contents": _vm.$_menuContentsTimelineList,
                "height-px": _vm.$_timelineCanvasHeightPx,
                "width-px": _vm.$_timelineCanvasWidthPx,
              },
            }),
          ],
          1
        ),
        _c("TimelineInfoBar", {
          attrs: {
            "duration-msec": _vm.durationMsec,
            "play-time-msec": _vm.playTimeMsec,
            "visible-timeline-data-set": _vm.visibleTimelineDataSet,
            "selected-timeline-segment-ids": _vm.selectedTimelineSegmentIds,
            "view-mode": _vm.$data.$_currentViewMode,
            "time-resolution": _vm.$data.$_timeResolution,
            "time-resolution-min": _vm.$data.$_timeResolutionMin,
            "time-resolution-max": _vm.$_timeResolutionMax,
            "timeline-position-resolution":
              _vm.$data.$_timelinePositionResolution,
            "timeline-position-resolution-min":
              _vm.$_timelinePositionResolutionMin,
            "timeline-position-resolution-max":
              _vm.$_timelinePositionResolutionMax,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
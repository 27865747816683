var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    _vm._g(
      _vm._b(
        {
          attrs: { small: "", text: "" },
          scopedSlots: _vm._u(
            [
              {
                key: "default",
                fn: function () {
                  return [_vm._t("default")]
                },
                proxy: true,
              },
              _vm._l(_vm.$scopedSlots, function (_, name) {
                return {
                  key: name,
                  fn: function () {
                    return [_vm._t(name)]
                  },
                  proxy: true,
                }
              }),
            ],
            null,
            true
          ),
        },
        "v-btn",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
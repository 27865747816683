<template>
  <div
    id="video-player-container"
    v-on:mouseenter="$_showController"
    v-on:mouseleave="$_hideController"
  >
    <div
      id="video-player"
      class="d-flex justify-center"
      v-if="audioOnly"
      v-on:click="$_togglePlayAndPause"
    >
      <v-icon dark x-large>mdi-volume-low</v-icon>
    </div>
    <video
      id="video-player"
      ref="videoPlayer"
      v-else
      v-on:click.right.prevent
      v-on:click="$_togglePlayAndPause"
    />
    <VideoPlayerController
      id="video-player-controller"
      v-bind:shows="$data.$_isControllerShown"
      v-bind:duration-msec="$_durationMsec"
      v-bind:play-time-msec="$_playTimeMsec"
      v-bind:is-playing="isVideoPlaying"
      v-bind:is-loop-enabled="isLoopEnabled"
      v-bind:loop-definition="loopDefinition"
      v-bind:timeline-marker="timelineMarker"
      v-on:seek-in-msec="$_seekInMsec"
      v-on:play="$_play"
      v-on:pause="$_pause"
      v-on:enable-loop="$_enableLoop"
      v-on:disable-loop="$_disableLoop"
    />
  </div>
</template>

<style scoped>
#video-player-container {
  position: relative;
  background-color: #000000;
  width: 100%;
  height: 100%;
}

#video-player {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

#video-player-controller {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
}
</style>

<script>
import VideoPlayerController from './VideoPlayer/VideoPlayerController.vue';
import TimelineMarker from '../modules/TimelineMarker.js';
import LoopDefinition from '../modules/LoopDefinition.js'

class VideoMetaData {
  constructor(durationMsec) {
    this.durationMsec = durationMsec;
  }
}

class VideoPlayInfo {
  constructor() {
    this.playTimeMsec = 0;
  }
}

export default {
  components: {
    VideoPlayerController,
  },

  watch: {
    videoSource(newVideoSource) {
      if (newVideoSource) this.$_loadVideoSource(newVideoSource);
    },

    '$data.$_playInfo': {
      handler() { this.$emit('play-info-updated', this.$data.$_playInfo); },
      deep: true,
    },

    seekTimeMsec(seekTimeMsec) {
      if (this.videoSource === null) return;
      if (seekTimeMsec === null) {
        if (this.isVideoPlaying) {
          this.$_beginPlayback(seekTimeMsec);
          this.$data.$_player.play();
        }
      } else {
        if (this.isVideoPlaying) {
          this.$data.$_player.pause();
        }
        this.$_seekInMsec(seekTimeMsec);
      }
    },

    isVideoPlaying(isVideoPlaying) {
      if (this.videoSource === null) return;
      if (isVideoPlaying) {
        if (this.$data.$_player.ended) this.$_seekInMsec(0);
        this.$_beginPlayback(this.$_getPlayTimeMsec());
        this.$data.$_player.play();
      } else {
        this.$data.$_player.pause();
      }
    },
  },

  props: {
    videoSource: { type: Uint8Array },
    seekTimeMsec: { type: Number },
    isVideoPlaying: { type: Boolean },
    isLoopEnabled: { type: Boolean },
    loopDefinition: { type: LoopDefinition },
    timelineMarker: { type: TimelineMarker },
    audioOnly: { type: Boolean },
  },

  data() {
    return {
      $_player: null,
      $_videoMetaData: null,
      $_playInfo: new VideoPlayInfo(),
      $_playbackBeginTimeMsec: null,
      $_isControllerShown: false,
    }
  },

  mounted() {
    if (this.audioOnly) {
      this.$data.$_player = document.createElement('audio');
    } else {
      this.$data.$_player = this.$refs.videoPlayer;
    }
    this.$nextTick(() => {
      if (this.$data.$_player === null) return;

      this.$data.$_playbackBeginTimeMsec = null;

      this.$data.$_player.onloadedmetadata = () => {
        this.$data.$_videoMetaData = new VideoMetaData(Math.trunc(this.$data.$_player.duration * 1000));
        this.$emit('meta-data-loaded', this.$data.$_videoMetaData);

        let previousTimeMsec = this.$_getPlayTimeMsec();
        let self = this;
        window.requestAnimationFrame(function recursiveFunction() {
          let currentTimeMsec = self.$_getPlayTimeMsec();
          if (previousTimeMsec !== currentTimeMsec) {
            previousTimeMsec = currentTimeMsec;
            self.$data.$_player.dispatchEvent(new CustomEvent('animationframeupdate'));
          }
          window.requestAnimationFrame(recursiveFunction);
        });
      };

      this.$data.$_player.addEventListener('animationframeupdate', this.$_update);
      this.$data.$_player.addEventListener('timeupdate', this.$_update);

      this.$data.$_player.addEventListener('playing', () => {
        if (!this.$_isPlaybackBegun) {
          this.$_beginPlayback(this.$_getPlayTimeMsec());
        }
      });

      this.$data.$_player.addEventListener('ended', () => {
        if (this.isLoopEnabled && this.isVideoPlaying) {
          this.$_seekInMsec(this.loopDefinition.beginTimeMsec);
          this.$data.$_player.play();
        } else {
          this.$_pause();
        }
      });

      this.$data.$_player.addEventListener('pause', () => {
        this.$_fixPlayback(this.$_getPlayTimeMsec());
      });

      if (this.videoSource) this.$_loadVideoSource(this.videoSource);
    });
  },

  computed: {
    /* private */
    $_playTimeMsec() {
      if (this.$data.$_playInfo === null) return null;
      return this.$data.$_playInfo.playTimeMsec;
    },
    $_durationMsec() {
      if (this.$data.$_videoMetaData === null) return null;
      return this.$data.$_videoMetaData.durationMsec;
    },
    $_isPlaybackBegun() {
      return this.$data.$_playbackBeginTimeMsec !== null;
    },
  },

  methods: {
    /* private */
    $_seekInMsec(timeMsec) {
      if (this.isVideoPlaying) this.$_fixPlayback(this.$_getPlayTimeMsec());
      this.$data.$_player.currentTime = timeMsec / 1000;
      if (this.isVideoPlaying) this.$_beginPlayback(timeMsec);
    },

    $_getPlayTimeMsec() {
      return Math.trunc(this.$data.$_player.currentTime * 1000);
    },

    $_loadVideoSource(videoSource) {
      this.$data.$_player.src = URL.createObjectURL(new Blob([ videoSource.buffer ]));
      this.$_disableLoop();
      this.$_pause();
    },

    $_togglePlayAndPause() {
      (this.isVideoPlaying)? this.$_pause() : this.$_play();
    },

    $_play() {
      this.$emit('play');
    },

    $_pause() {
      this.$emit('pause');
    },

    $_enableLoop() {
      this.$emit('enable-loop');
    },

    $_disableLoop() {
      this.$emit('disable-loop');
    },

    $_showController() {
      this.$data.$_isControllerShown = true;
    },

    $_hideController() {
      this.$data.$_isControllerShown = false;
    },

    $_update() {
      let playTimeMsec = this.$_getPlayTimeMsec();
      if (this.isLoopEnabled) {
        let isPlayTimeBeforeLoopBegin = playTimeMsec < this.loopDefinition.beginTimeMsec;
        let isPlayTimeAfterLoopEnd = playTimeMsec >= this.loopDefinition.endTimeMsec;
        if (isPlayTimeBeforeLoopBegin || isPlayTimeAfterLoopEnd) {
          this.$_seekInMsec(this.loopDefinition.beginTimeMsec);
          playTimeMsec = this.loopDefinition.beginTimeMsec;
        }
      }
      this.$data.$_playInfo.playTimeMsec = playTimeMsec;
    },

    $_beginPlayback(playbackBeginTimeMsec) {
      this.$data.$_playbackBeginTimeMsec = playbackBeginTimeMsec;
    },

    $_fixPlayback(playbackEndTimeMsec) {
      if (this.$_isPlaybackBegun) {
        this.$emit('playback-fixed', this.$data.$_playbackBeginTimeMsec, playbackEndTimeMsec);
        this.$data.$_playbackBeginTimeMsec = null;
      }
    },
  },
};
</script>

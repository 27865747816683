var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-select", {
    attrs: {
      dense: "",
      "hide-details": "",
      label: "Time Unit",
      items: _vm.$_allTimeUnitItems,
    },
    model: {
      value: _vm.$_timeUnit,
      callback: function ($$v) {
        _vm.$_timeUnit = $$v
      },
      expression: "$_timeUnit",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div id="wrapper" :style="wrapperStyle">
        <div id="camera-preview">
            <video
                v-show="videoShown"
                ref="video"
                id="video"
                autoplay
                playsinline
            ></video>
            <slot name="camera-preview" v-bind="{ media }">
            </slot>
        </div>
        <div id="div-overlay">
            <slot
                name="overlay"
                v-bind="{
                    videoShown,
                    isReady,
                    recorderState,
                    media,
                    standBy,
                    start,
                    stop,
                    pause,
                    resume,
                    clear
                }"
            ></slot>
        </div>
    </div>
</template>
<script>
const getUserMediaConstraint = (audioDeviceId, videoDeviceId) => {
    return { 
        audio: { deviceId: { exact: audioDeviceId }},
        video: { deviceId: { exact: videoDeviceId }},
    };
};

const defaultMedia = {
    metadata: {
        mimeType: '',
        size: 0,
        audioBitsPerSecond: 0,
    },
    input: {
        dir: 'dev/audio',
        filename: '',
        memo: '',
    },
    url: null,
    buf: null,
};  

export default {
    data: () => ({
        recorder: null,
        recorderState: null,
        mediaData: [],
        media: null,
        isAudioReady: false,
        isVideoReady: false,
        standByInProgress: false,
    }),
    props: {
        audioDeviceId: { type: String, default: null },
        videoDeviceId: { type: String, default: null },
        alwaysShowCamera: { type: Boolean, default: false },
        width: { type: [Number,String], default: '100%' },
        maxWidth: { type: [Number,String], default: '100%' },
        mediaRecorderOptions: { type: Object, default: () => ({
            audioBitsPerSecond: 128000,
            videoBitsPerSecond: 2500000,
            mimeType: "video/webm;codecs=h264",
        }) },
    },
    computed: {
        deviceIdsSet() {
            return this.audioDeviceId !== null && this.videoDeviceId !== null;
        },
        wrapperStyle() {
            return {
                width: this.width,
                maxWidth: this.maxWidth,
                margin: '0 auto',
                height: '100%'
            }
        },
        isReady() {
            return this.isAudioReady && this.isVideoReady;
        },
        //recorderState() {
        //    return this.recorder !== null ? this.recorder.state : null;  // not working (not reactive)
        //},
        videoShown() {
            return this.isReady && ((this.recorderState !== 'inactive' || this.media === null) || this.alwaysShowCamera);
        }
    },
    methods: {
        async standBy() {
            if (this.recorderState === 'recording' || this.recorderState === 'paused') return false;

            this.standByInProgress = true;
            if (this.video.srcObject) {
                this.video.srcObject.getTracks().forEach( (track) => {
                    if (track.readyState == 'live') {
                        track.stop();
                    }
                });
            }

            const constraint = getUserMediaConstraint(this.audioDeviceId, this.videoDeviceId);
            const userMedia = await navigator.mediaDevices.getUserMedia(constraint);

            this.video.srcObject = await navigator.mediaDevices.getUserMedia(constraint);
            this.video.onloadedmetadata = () => {
                this.video.play();
                this.isVideoReady = true;
                if(this.isReady && !this.standByInProgress) {
                    this.recorderState = 'inactive';
                    this.$emit('stand-by', { audio: this.audioDeviceId, video: this.videoDeviceId });
                }
            };

            this.recorder = new MediaRecorder(userMedia, this.mediaRecorderOptions);

            this.recorder.addEventListener('start', (evt) => {
                this.mediaData.length = 0;
                this.recorderState = 'recording';
                this.$emit('start', evt);
            });
            this.recorder.addEventListener('pause', (evt) => {
                this.recorderState = 'paused';
                this.$emit('pause', evt);
            });
            this.recorder.addEventListener('resume', (evt) => {
                this.recorderState = 'recording';
                this.$emit('resume', evt);
            });
            this.recorder.addEventListener('dataavailable', (evt) => {
                this.mediaData.push(evt.data);
            });
            this.recorder.addEventListener('stop', async (evt) => {
                this.media = { ...defaultMedia };
                //this.media.metadata.mimeType = evt.srcElement.mimeType;
                //this.media.metadata.audioBitsPerSecond = evt.srcElement.audioBitsPerSecond;
                //this.media.metadata.videoBitsPerSecond = evt.srcElement.videoBitsPerSecond;
                this.media.metadata.mimeType = this.mediaRecorderOptions.mimeType;
                this.media.metadata.audioBitsPerSecond = this.mediaRecorderOptions.audioBitsPerSecond;
                this.media.metadata.videoBitsPerSecond = this.mediaRecorderOptions.videoBitsPerSecond;
                const blob = new Blob(this.mediaData, {type: this.media.metadata.mimeType});
                this.media.metadata.size = blob.size;
                this.media.url = URL.createObjectURL(blob);
                this.media.buf = await blob.arrayBuffer();
                this.media.input.filename = Math.floor(Date.now() / 1000).toString();
                this.recorderState = 'inactive';
                this.$emit('stop', this.media, evt);
            });
            this.isAudioReady = true;
            if(this.isReady && !this.standByInProgress) {
                this.recorderState = 'inactive';
                this.$emit('stand-by', { audio: this.audioDeviceId, video: this.videoDeviceId });
            }
            this.standByInProgress = false;
        },
        async start() {
            this.recorder.start();
        },
        pause() {
            this.recorder.pause();
        },
        resume() {
            this.recorder.resume();
        },
        stop() {
            if (this.recorderState === 'recording' || this.recorderState === 'paused') {
                this.recorder.stop();
            }
        },
        clear() {
            this.media = null;
            this.$emit('clear');
            this.standBy();
        }
    },
    watch: {
        audioDeviceId(id) {
            this.isAudioReady = false;
            this.isVideoReady = false;
            if(id !== null) {
                this.audioDeviceId = id;
                this.$emit('set-audio-device-id', id);
            }
            if(this.audioDeviceId !== null && this.videoDeviceId !== null) {
                this.standBy();
            }
        },
        videoDeviceId(id) {
            this.isAudioReady = false;
            this.isVideoReady = false;
            if(id !== null) {
                this.videoDeviceId = id;
                this.$emit('set-video-device-id', id);
            }
            if(this.audioDeviceId !== null && this.videoDeviceId !== null) {
                this.standBy();
            }
        }
    },
    async mounted() {
        this.video = this.$refs.video;
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            const allDevices = await navigator.mediaDevices.enumerateDevices();
            const audioDevices = allDevices.filter((dev) => dev.kind === 'audioinput');
            const videoDevices = allDevices.filter((dev) => dev.kind === 'videoinput');
            this.$emit('device-enumerate', { audio: audioDevices, video: videoDevices });
        }
        console.log(this.$scopedSlots, this.$slots)
    }
}
</script>
<style scoped>
#wrapper {
    width: 100%;    
    position: relative;
}
#camera-preview {
    height: 100%;
    display: flex;
    align-items: center;
}
#camera-preview > * {
    display: block;
}
#video {
    width: 100%;
    object-fit: contain;
    display: block;
}
#div-overlay {
    width: 100%;    
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
</style>
<template>
  <dialog-base
    v-bind:shows="shows"
    v-bind:finalize-callback="finalizeCallback"
  >
    <template v-slot:body>
      <v-card-title>Help</v-card-title>
      <v-card-text>
        <v-card-subtitle>Common</v-card-subtitle>
        <v-simple-table dense>
          <tbody>
            <tr><th>h</th><td>show help</td></tr>
            <tr><th>f</th><td>toggle follow-play-bar mode</td></tr>
            <tr><th>s</th><td>select mode</td></tr>
            <tr><th>c</th><td>create segment mode</td></tr>
            <tr><th>d</th><td>divide segment mode</td></tr>
            <tr><th>m</th><td>merge segment mode</td></tr>
            <tr><th>p</th><td>position edit mode</td></tr>
            <tr><th>l</th><td>toggle loop enalbled/disabled</td></tr>
            <tr><th>v</th><td>show a view filter dialog</td></tr>
            <tr><th>Shift + l</th><td>set loop begin/end by selected timeline segments or a marker and enable loop</td></tr>
            <tr><th>Space</th><td>toggle play/pause</td></tr>
            <tr><th>Esc</th><td>normal mode, unset marker, clear selected timeline segments, clear realtime segment creation target</td></tr>
            <tr><th>Click(timeline segment)</th><td>select a segment</td></tr>
            <tr><th>Click(ruler)</th><td>seek media</td></tr>
            <tr><th>Drag(ruler)</th><td>set marker</td></tr>
            <tr><th>Ctrl + l</th><td>lock selected timeline segments</td></tr>
            <tr><th>Ctrl + Shift + l</th><td>unlock selected timeline segments</td></tr>
            <tr><th>Ctrl + s</th><td>save result</td></tr>
            <tr><th>Ctrl + z</th><td>undo</td></tr>
            <tr><th>Ctrl + Shift + z</th><td>redo</td></tr>
          </tbody>
        </v-simple-table>

        <v-card-subtitle>Common (Mouse Control Mode)</v-card-subtitle>
        <v-simple-table dense>
          <tbody>
            <tr><th>Wheel-Up</th><td>scroll-up</td></tr>
            <tr><th>Wheel-Down</th><td>scroll-down</td></tr>
            <tr><th>Shift + Wheel-Up</th><td>scroll-left</td></tr>
            <tr><th>Shift + Wheel-Down</th><td>scroll-right</td></tr>
            <tr><th>Ctrl + Wheel-Up</th><td>zoom-in(horizontal, cursor fixed)</td></tr>
            <tr><th>Ctrl + Wheel-Down</th><td>zoom-out(horizontal, cursor fixed)</td></tr>
            <tr><th>Ctrl + Shift + Wheel-Up</th><td>zoom-in(vertical, cursor fixed)</td></tr>
            <tr><th>Ctrl + Shift + Wheel-Down</th><td>zoom-out(vertical, cursor fixed)</td></tr>
          </tbody>
        </v-simple-table>

        <v-card-subtitle>Common (Trackpad Control Mode)</v-card-subtitle>
        <v-simple-table dense>
          <tbody>
            <tr><th>Swipe-Up</th><td>scroll-up</td></tr>
            <tr><th>Swipe-Down</th><td>scroll-down</td></tr>
            <tr><th>Swipe-Left</th><td>scroll-left</td></tr>
            <tr><th>Swipe-Right</th><td>scroll-right</td></tr>
            <tr><th>Ctrl + Swipe-Right</th><td>zoom-in(horizontal, cursor fixed)</td></tr>
            <tr><th>Ctrl + Swipe-Left</th><td>zoom-out(horizontal, cursor fixed)</td></tr>
            <tr><th>Ctrl + Shift + Swipe-Up</th><td>zoom-in(vertical, cursor fixed)</td></tr>
            <tr><th>Ctrl + Shift + Swipe-Down</th><td>zoom-out(vertical, cursor fixed)</td></tr>
          </tbody>
        </v-simple-table>

        <v-card-subtitle>Normal Mode</v-card-subtitle>
        <v-simple-table dense>
          <tbody>
            <tr><th>n</th><td>add note and attach it to the selected segment</td></tr>
            <tr><th>Shift + Click(timeline segment)</th><td>select a segment(add)</td></tr>
            <tr><th>Click(timeline)</th><td>seek media(normal mode)</td></tr>
            <tr><th>Drag(timeline)</th><td>select timeline segments in drag area</td></tr>
            <tr><th>Shift + Drag(timeline)</th><td>select timeline segments in drag area(add)</td></tr>
            <tr><th>Enter(when realtime timeline segment creation target is set)</th><td>set begin/end of a new timeline segment</td></tr>
            <tr><th>Right</th><td>scroll right</td></tr>
            <tr><th>Left</th><td>scroll left</td></tr>
            <tr><th>Up</th><td>scroll up</td></tr>
            <tr><th>Down</th><td>scroll down</td></tr>
            <tr><th>Home</th><td>scroll to the beginning</td></tr>
            <tr><th>End</th><td>scroll to the end</td></tr>
            <tr><th>PageUp</th><td>scroll right(page)</td></tr>
            <tr><th>PageDown</th><td>scroll left(page)</td></tr>
            <tr><th>Shift + Right</th><td>zoom-in(horizontal, view left fixed)</td></tr>
            <tr><th>Shift + Left</th><td>zoom-out(horizontal, view left fixed)</td></tr>
            <tr><th>Shift + Up</th><td>zoom-in(vertical, view top fixed)</td></tr>
            <tr><th>Shift + Down</th><td>zoom-out(vertical, view top fixed)</td></tr>
          </tbody>
        </v-simple-table>

        <v-card-subtitle>Select Mode</v-card-subtitle>
        <v-simple-table dense>
          <tbody>
            <tr><th>Drag(timeline)</th><td>select timeline segments in drag area</td></tr>
            <tr><th>Shift + Drag(timeline)</th><td>select timeline segments in drag area(add)</td></tr>
            <tr><th>Drag(selected timeline timeline segment)</th><td>move timeline segment</td></tr>
            <tr><th>Shift + Click(timeline timeline segment)</th><td>select a timeline segment(add)</td></tr>
            <tr><th>Shift + Drag(timeline)</th><td>select timeline segments in drag area(add)</td></tr>
            <tr><th>Shift + Enter</th><td>settle timeline segment(default: seek to the timeline segment)</td></tr>
            <tr><th>Enter(single selected timeline segment)</th><td>show a timeline segment editor dialog</td></tr>
            <tr><th>Click(single selected timeline segment)</th><td>show a timeline segment editor dialog</td></tr>
            <tr><th>Ctrl + c</th><td>copy timeline segment data</td></tr>
            <tr><th>Ctrl + v</th><td>paste timeline segment data</td></tr>
            <tr><th>Delete / Backspace</th><td>clear selected timeline segments</td></tr>
            <tr><th>Shift + Delete / Shift + Backspace</th><td>delete selected timeline segments</td></tr>
            <tr><th>Right</th><td>select next timeline segment</td></tr>
            <tr><th>Left</th><td>select previous timeline segment</td></tr>
            <tr><th>Up</th><td>select previous timeline segment</td></tr>
            <tr><th>Down</th><td>select next timeline segment</td></tr>
            <tr><th>Home</th><td>select first timeline segment</td></tr>
            <tr><th>End</th><td>select last timeline segment</td></tr>
            <tr><th>Shift + Right</th><td>zoom-in(horizontal, last selected timeline segment's left fixed)</td></tr>
            <tr><th>Shift + Left</th><td>zoom-out(horizontal, last selected timeline segment's left fixed)</td></tr>
            <tr><th>Shift + Up</th><td>zoom-in(vertical, last selected timeline segment's top fixed)</td></tr>
            <tr><th>Shift + Down</th><td>zoom-out(vertical, last selected timeline segment's top fixed)</td></tr>
          </tbody>
        </v-simple-table>

        <v-card-subtitle>Create Segment Mode</v-card-subtitle>
        <v-simple-table dense>
          <tbody>
            <tr><th>Drag(timeline)</th><td>create timeline segment</td></tr>
          </tbody>
        </v-simple-table>

        <v-card-subtitle>Merge Segment Mode</v-card-subtitle>
        <v-simple-table dense>
          <tbody>
            <tr><th>Click(selected timeline segments)</th><td>merge selected segments</td></tr>
            <tr><th>Shift + Click(timeline segment)</th><td>select a timeline segment(add)</td></tr>
            <tr><th>Drag(timeline)</th><td>select timeline segments in drag area</td></tr>
            <tr><th>Shift + Drag(timeline)</th><td>select timeline segments in drag area(add)</td></tr>
            <tr><th>Shift + Right</th><td>zoom-in(horizontal, last selected timeline segment's left fixed)</td></tr>
            <tr><th>Shift + Left</th><td>zoom-out(horizontal, last selected timeline segment's left fixed)</td></tr>
            <tr><th>Shift + Up</th><td>zoom-in(vertical, last selected timeline segment's top fixed)</td></tr>
            <tr><th>Shift + Down</th><td>zoom-out(vertical, last selected timeline segment's top fixed)</td></tr>
          </tbody>
        </v-simple-table>

        <v-card-subtitle>Divide Segment Mode</v-card-subtitle>
        <v-simple-table dense>
          <tbody>
            <tr><th>Click(selected timeline segments)</th><td>divide selected segments</td></tr>
            <tr><th>Shift + Click(timeline segment)</th><td>select a timeline segment(add)</td></tr>
            <tr><th>Drag(timeline)</th><td>select timeline segments in drag area</td></tr>
            <tr><th>Shift + Drag(timeline)</th><td>select timeline segments in drag area(add)</td></tr>
            <tr><th>Shift + Right</th><td>zoom-in(horizontal, last selected timeline segment's left fixed)</td></tr>
            <tr><th>Shift + Left</th><td>zoom-out(horizontal, last selected timeline segment's left fixed)</td></tr>
            <tr><th>Shift + Up</th><td>zoom-in(vertical, last selected timeline segment's top fixed)</td></tr>
            <tr><th>Shift + Down</th><td>zoom-out(vertical, last selected timeline segment's top fixed)</td></tr>
          </tbody>
        </v-simple-table>

        <v-card-subtitle>Position Edit Mode</v-card-subtitle>
        <v-simple-table dense>
          <tbody>
            <tr><th>Right</th><td>offset end time</td></tr>
            <tr><th>Left</th><td>offset end time</td></tr>
            <tr><th>Shift + Right</th><td>offset start time</td></tr>
            <tr><th>Shift + Left</th><td>offset start time</td></tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </template>

    <template v-slot:buttons="{ on, attrs }">
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        v-bind="attrs"
        v-on:click="on.okClicked"
      >
        OK
      </v-btn>
    </template>
  </dialog-base>
</template>

<script>
import DialogBase from './DialogBase.vue';

export default {
  components: {
    DialogBase,
  },

  props: {
    shows: { type: Boolean, default: false },
    finalizeCallback: { type: Function, default: null },
  },
}
</script>
<template>
  <v-form ref="timelineSegmentEditorForm">
    <dialog-base
      v-bind:shows="shows"
      v-bind:initialize-callback="$_onInitialize"
      v-bind:ok-callback="$_onOkClicked"
      v-bind:finalize-callback="$_onFinalize"
      v-bind:ok-disabled="$data.$_hasError"
    >
      <template v-slot:body>
        <v-card-title>Edit Timeline Segment</v-card-title>

        <v-card-text class="ma-0 pa-0">
          <v-container fluid>
            <v-row justify="center" align-content="center" no-gutters>
              <v-col cols="1">
                <TimelineSegmentEditorSideButton
                  v-bind:disabled="!reopenPreviousSegmentEnabled"
                  v-on:click="$_reopenPreviousTimelineSegment"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </TimelineSegmentEditorSideButton>
              </v-col>

              <v-col cols="10">
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="Label"
                        dense autofocus
                        v-if="$_isLabelSegment"
                        v-bind:disabled="isImmutable"
                        v-model="$data.$_segmentTemp.data.label"
                      />

                      <v-text-field
                        label="Note"
                        dense autofocus
                        v-if="$_isNoteSegment"
                        v-bind:disabled="isImmutable"
                        v-model="$data.$_segmentTemp.data.text"
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        label="Begin time in millisecond"
                        dense type="number"
                        v-bind:disabled="isImmutable"
                        v-bind:rules="$_rules.begin"
                        v-model.number="$data.$_segmentTemp.begin"
                        v-on:input="$_validateInput"
                      />
                    </v-col>

                    <v-col cols="6">
                      <v-text-field
                        label="End time in millisecond"
                        dense type="number"
                        v-bind:disabled="isImmutable"
                        v-bind:rules="$_rules.end"
                        v-model.number="$data.$_segmentTemp.end"
                        v-on:input="$_validateInput"
                      />
                    </v-col>
                  </v-row>
                </v-container>

                <v-expansion-panels flat>
                  <v-expansion-panel>
                    <v-expansion-panel-header>Notes</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="timeline-segment-editor-add-note-container">
                        <v-btn
                          outlined text x-small
                          v-on:click="$_addNote"
                        >
                          Add Note
                        </v-btn>
                      </div>
                      <TimelineSegmentEditorNote
                        v-for="(noteWithInfoReferingToTargetSegment, noteWithInfoReferingToTargetSegmentIdx) of $data.$_noteWithInfoReferingToTargetSegmentArray"
                        v-bind:key="noteWithInfoReferingToTargetSegmentIdx"
                        v-bind:note-timeline-segment-id="noteWithInfoReferingToTargetSegment.noteTimelineSegmentId"
                        v-bind:note="noteWithInfoReferingToTargetSegment.note"
                        v-on:save-note="$_saveNote"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>

              <v-col cols="1">
                <TimelineSegmentEditorSideButton
                  v-bind:disabled="!reopenNextSegmentEnabled"
                  v-on:click="$_reopenNextTimelineSegment"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </TimelineSegmentEditorSideButton>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </template>
    </dialog-base>
  </v-form>
</template>

<style scoped>
div.timeline-segment-editor-add-note-container {
  text-align: right;
}
</style>

<script>
import { TimelineDataSet, TimelineSegmentDataBase, TimelineSegmentId } from '../../../external/modules/ScattData.js';
import DialogBase from './DialogBase.vue';
import TimelineSegmentEditorNote from './TimelineSegmentEditor/TimelineSegmentEditorNote.vue'
import TimelineSegmentEditorSideButton from './TimelineSegmentEditor/TimelineSegmentEditorSideButton.vue'
import LabelSegmentData from '../../../external/modules/segmentDataTypes/LabelSegmentData.js'
import NoteSegmentData from '../../../external/modules/segmentDataTypes/NoteSegmentData.js'
import referencePool from '../../../external/modules/referencePool.js'

function findNoteTimelineSegmentIdsRefferingTo(targetTimelineSegmentId) {
  let noteTimelineSegmentIds = new Array();
  for (let reference of Object.values(referencePool.getAllReferences())) {
    if (reference.referTo.isSame(targetTimelineSegmentId) && (reference.refClass === 'Note')) {
      noteTimelineSegmentIds.push(reference.referFrom);
    }
  }
  return noteTimelineSegmentIds;
}

class NoteReferingToTargetSegmentWithInfo {
  constructor(note, noteTimelineSegmentId) {
    this.note = note;
    this.noteTimelineSegmentId = noteTimelineSegmentId;
  }
}

export default {
  components: {
    DialogBase,
    TimelineSegmentEditorNote,
    TimelineSegmentEditorSideButton,
  },

  props: {
    shows: { type: Boolean, default: false },
    cancelledValue: { default: null },
    okCallback: { type: Function, default: null },
    finalizeCallback: { type: Function, default: null },
    timelineDataSet: { type: TimelineDataSet, default: null },
    minimumSegmentDurationMsec: { type: Number, default: 0 },
    targetSegment: { type: TimelineSegmentDataBase, default: null },
    targetTimelineSegmentId: { type: TimelineSegmentId, default: null },
    isImmutable: { type: Boolean, default: false },
    reopenPreviousSegmentEnabled: { type: Boolean, default: false },
    reopenNextSegmentEnabled: { type: Boolean, default: false },
  },

  data() {
    return {
      $_segmentTemp: null,
      $_finalizeCallbackReturnValue: null,
      $_hasError: false,
      $_noteWithInfoReferingToTargetSegmentArray: null,
    };
  },

  computed: {
    $_segmentDataType() { return (this.$data.$_segmentTemp)? this.$data.$_segmentTemp.constructor : null; },
    $_isLabelSegment()  { return this.$_segmentDataType === LabelSegmentData; },
    $_isNoteSegment()   { return this.$_segmentDataType === NoteSegmentData; },

    $_rules() {
      let mustBeZeroOrAboveWhenNotEmpty = (numberOrStringTime) => {
        let stringValue = String(numberOrStringTime);
        if (stringValue.length > 0) {
          return (Number(stringValue) >= 0) || 'Must be 0 or above.';
        } else {
          return true;
        }
      };

      let eitherBeginTimeOrEndTimeEmpty = (beginTime, endTime) => {
        return (beginTime === '') || (endTime === '');
      }

      let beginTimeMustBeEqualToOrLessThanEndTimeMinusMinimumDuration = (beginTime, endTime) => {
        return (beginTime <= (endTime - this.minimumSegmentDurationMsec));
      };

      let beginTimeMustBeLessThanEndTime = (numberOrStringBeginTime) => {
        let stringBeginTime = String(numberOrStringBeginTime);
        if (eitherBeginTimeOrEndTimeEmpty(stringBeginTime, this.$data.$_segmentTemp.end)) return true;
        return (Number(stringBeginTime) < this.$data.$_segmentTemp.end) || 'Begin time must be less than end time.';
      };

      let beginTimeMustBeLessThanEndTimeMinusMinimumDuration = (numberOrStringBeginTime) => {
        let stringBeginTime = String(numberOrStringBeginTime);
        if (eitherBeginTimeOrEndTimeEmpty(stringBeginTime, this.$data.$_segmentTemp.end)) return true;
        return beginTimeMustBeEqualToOrLessThanEndTimeMinusMinimumDuration(Number(stringBeginTime), this.$data.$_segmentTemp.end) || 'Segment duration is too short(min: ' + this.minimumSegmentDurationMsec + ' milliseconds)';
      };

      let endTimeMustBeMoreThanBeginTime = (numberOrStringEndTime) => {
        let stringEndTime = String(numberOrStringEndTime);
        if (eitherBeginTimeOrEndTimeEmpty(this.$data.$_segmentTemp.begin, stringEndTime)) return true;
        return (this.$data.$_segmentTemp.begin < Number(stringEndTime));
      };

      let endTimeMustBeMoreThanBeginTimePlusMinimumDuration = (numberOrStringEndTime) => {
        let stringEndTime = String(numberOrStringEndTime);
        if (eitherBeginTimeOrEndTimeEmpty(this.$data.$_segmentTemp.begin, stringEndTime)) return true;
        return beginTimeMustBeEqualToOrLessThanEndTimeMinusMinimumDuration(this.$data.$_segmentTemp.begin, Number(stringEndTime));
      };

      return {
        begin: [
          mustBeZeroOrAboveWhenNotEmpty,
          beginTimeMustBeLessThanEndTime,
          beginTimeMustBeLessThanEndTimeMinusMinimumDuration,
        ],
        end: [
          mustBeZeroOrAboveWhenNotEmpty,
          endTimeMustBeMoreThanBeginTime,
          endTimeMustBeMoreThanBeginTimePlusMinimumDuration,
        ],
      };
    },
  },

  watch: {
    timelineDataSet: {
      handler() { 
        this.$_updateNoteWithInfoReferingToTargetSegmentArray();
      },
      deep: true,
      immediate: true,
    },

    targetTimelineSegmentId: {
      handler() { 
        this.$_updateNoteWithInfoReferingToTargetSegmentArray();
      },
      deep: true,
      immediate: true,
    },

    targetSegment: {
      handler() {
        this.$_onInitialize();
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    this.$nextTick(this.$_validateInput);
  },

  inject: [
    'setSegment',
    'generateAndAttachEmptyNote',
    'selectPreviousTimelineSegment',
    'selectNextTimelineSegment',
    'invokeActionOnSelectedTimelineSegments',
  ],

  methods: {
    /* private */
    $_onInitialize() {
      let segmentTemp = this.targetSegment.clone(true);
      if (segmentTemp.begin === null) segmentTemp.begin = '';
      if (segmentTemp.end === null) segmentTemp.end = '';
      this.$data.$_segmentTemp = segmentTemp;
      this.$data.$_finalizeCallbackReturnValue = this.cancelledValue;
    },

    $_onOkClicked() {
      this.$data.$_finalizeCallbackReturnValue = this.targetTimelineSegmentId;
      if (this.$data.$_segmentTemp.begin === '') this.$data.$_segmentTemp.begin = null;
      if (this.$data.$_segmentTemp.end === '') this.$data.$_segmentTemp.end = null;
      this.okCallback(this.$data.$_segmentTemp);
    },

    $_onFinalize() {
      this.finalizeCallback(this.$data.$_finalizeCallbackReturnValue);
    },

    async $_reopenPreviousTimelineSegment() {
      this.$_onOkClicked();
      this.selectPreviousTimelineSegment();
      await this.invokeActionOnSelectedTimelineSegments('editSegment');
    },

    async $_reopenNextTimelineSegment() {
      this.$_onOkClicked();
      this.selectNextTimelineSegment();
      await this.invokeActionOnSelectedTimelineSegments('editSegment');
    },

    $_addNote() {
      let begin = (this.$data.$_segmentTemp.begin === '')? null : this.$data.$_segmentTemp.begin;
      let end = (this.$data.$_segmentTemp.end === '')? null : this.$data.$_segmentTemp.end;
      this.generateAndAttachEmptyNote(this.targetTimelineSegmentId, begin, end);
    },

    $_updateNoteWithInfoReferingToTargetSegmentArray() {
      let noteWithInfoReferingToTargetSegmentArray = new Array();
      if (this.targetTimelineSegmentId) {
        let noteTimelineSegmentIdsReferingToTargetSegment = findNoteTimelineSegmentIdsRefferingTo(this.targetTimelineSegmentId);
        for (let noteTimelineSegmentId of noteTimelineSegmentIdsReferingToTargetSegment) {
          let noteTimelineDataId = noteTimelineSegmentId.timelineDataId;
          let noteSegmentId = noteTimelineSegmentId.segmentId;
          let noteSegment = this.timelineDataSet[noteTimelineDataId].segments[noteSegmentId];
          let note = noteSegment.data.text;
          noteWithInfoReferingToTargetSegmentArray.push(new NoteReferingToTargetSegmentWithInfo(note, noteTimelineSegmentId));
        }
      }
      this.$data.$_noteWithInfoReferingToTargetSegmentArray = noteWithInfoReferingToTargetSegmentArray;
    },

    $_saveNote(noteDataTimelineSegmentId, description) {
      let noteDataTimelineDataId = noteDataTimelineSegmentId.timelineDataId;
      let noteDataSegmentId = noteDataTimelineSegmentId.segmentId;
      let newNoteData = new NoteSegmentData.contentData(description, new Date());
      let noteDataSegment = this.timelineDataSet[noteDataTimelineDataId].segments[noteDataSegmentId];
      let newNoteDataSegment = noteDataSegment.clone();
      newNoteDataSegment.data = newNoteData;
      this.setSegment(noteDataTimelineSegmentId, NoteSegmentData, newNoteDataSegment, 'Note set.', 'setting note');
    },

    $_validateInput() {
      this.$data.$_hasError = !this.$refs.timelineSegmentEditorForm.validate();
    },
  },
}
</script>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "audio-player-controller" } },
    [
      _c(
        "button-with-tooltip",
        {
          attrs: { icon: "" },
          on: {
            mousedown: function ($event) {
              $event.stopPropagation()
              return _vm.$_seekToHead.apply(null, arguments)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "tooltip",
              fn: function () {
                return [_vm._v("Seek To Head")]
              },
              proxy: true,
            },
          ]),
        },
        [_c("v-icon", [_vm._v("mdi-skip-previous")])],
        1
      ),
      _c(
        "button-with-tooltip",
        {
          attrs: { icon: "" },
          on: {
            mousedown: function ($event) {
              $event.stopPropagation()
              return _vm.$_seekBackwardStart.apply(null, arguments)
            },
            mouseup: function ($event) {
              $event.stopPropagation()
              return _vm.$_seekBackwardEnd.apply(null, arguments)
            },
            mouseout: function ($event) {
              $event.stopPropagation()
              return _vm.$_seekBackwardEnd.apply(null, arguments)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "tooltip",
              fn: function () {
                return [_vm._v("Seek Backward")]
              },
              proxy: true,
            },
          ]),
        },
        [_c("v-icon", [_vm._v("mdi-rewind")])],
        1
      ),
      _vm.isPlaying
        ? _c(
            "button-with-tooltip",
            {
              attrs: { icon: "" },
              on: {
                mousedown: function ($event) {
                  $event.stopPropagation()
                  return _vm.$_pause.apply(null, arguments)
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "tooltip",
                    fn: function () {
                      return [_vm._v("Pause")]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                697248442
              ),
            },
            [_c("v-icon", [_vm._v("mdi-pause")])],
            1
          )
        : _c(
            "button-with-tooltip",
            {
              attrs: { icon: "" },
              on: {
                mousedown: function ($event) {
                  $event.stopPropagation()
                  return _vm.$_play.apply(null, arguments)
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "tooltip",
                  fn: function () {
                    return [_vm._v("Play")]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("v-icon", [_vm._v("mdi-play")])],
            1
          ),
      _c(
        "button-with-tooltip",
        {
          attrs: { icon: "" },
          on: {
            mousedown: function ($event) {
              $event.stopPropagation()
              return _vm.$_seekForwardStart.apply(null, arguments)
            },
            mouseup: function ($event) {
              $event.stopPropagation()
              return _vm.$_seekForwardEnd.apply(null, arguments)
            },
            mouseout: function ($event) {
              $event.stopPropagation()
              return _vm.$_seekForwardEnd.apply(null, arguments)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "tooltip",
              fn: function () {
                return [_vm._v("Seek Forward")]
              },
              proxy: true,
            },
          ]),
        },
        [_c("v-icon", [_vm._v("mdi-fast-forward")])],
        1
      ),
      _c(
        "button-with-tooltip",
        {
          attrs: { icon: "" },
          on: {
            mousedown: function ($event) {
              $event.stopPropagation()
              return _vm.$_seekToTail.apply(null, arguments)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "tooltip",
              fn: function () {
                return [_vm._v("Seek To Tail")]
              },
              proxy: true,
            },
          ]),
        },
        [_c("v-icon", [_vm._v("mdi-skip-next")])],
        1
      ),
      _c("audio-player-seek-bar", {
        ref: "audioPlayerSeekBar",
        attrs: {
          "current-time-sec": _vm.playTimeSec,
          "duration-sec": _vm.durationSec,
          "is-loop-enabled": _vm.isLoopEnabled,
          "is-seeking": _vm.isSeeking,
          "loop-definition": _vm.loopDefinition,
        },
        on: {
          seek: _vm.$_seekBySlider,
          "seek-start": _vm.$_seekBySliderStart,
          "seek-end": _vm.$_seekBySliderEnd,
        },
      }),
      _vm.isLoopEnabled
        ? _c(
            "button-with-tooltip",
            {
              staticClass: "loop-enabled",
              attrs: { icon: "" },
              on: {
                mousedown: function ($event) {
                  $event.stopPropagation()
                  return _vm.$_disableLoop.apply(null, arguments)
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "tooltip",
                    fn: function () {
                      return [_vm._v("Disable Loop")]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1953923296
              ),
            },
            [_c("v-icon", [_vm._v("mdi-restart")])],
            1
          )
        : _c(
            "button-with-tooltip",
            {
              attrs: { icon: "" },
              on: {
                mousedown: function ($event) {
                  $event.stopPropagation()
                  return _vm.$_enableLoop.apply(null, arguments)
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "tooltip",
                  fn: function () {
                    return [_vm._v("Enable Loop")]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("v-icon", [_vm._v("mdi-restart")])],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-overlay",
        {
          attrs: {
            absolute: "",
            value: _vm.loading,
            "z-index": "2",
            color: "#FFFFFF",
            opacity: "0.75",
          },
        },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "primary" },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-center py-1 px-5",
              attrs: { cols: "12" },
            },
            [
              _c("left-icons", {
                attrs: {
                  "display-flags": _vm.leftIconsDisplayFlags,
                  duration: _vm.duration,
                  "current-time": _vm.currentTime,
                  "is-playing": _vm.isPlaying,
                },
                on: {
                  play: _vm.play,
                  pause: _vm.pause,
                  "start-rewind": _vm.startRewind,
                  "stop-rewind": _vm.stopRewind,
                  "start-fast-forward": _vm.startFastForward,
                  "stop-fast-forward": _vm.stopFastForward,
                  "skip-backward": _vm.skipBackward,
                  "skip-forward": _vm.skipForward,
                },
                scopedSlots: _vm._u(
                  [
                    _vm._l(_vm.leftIconsSlots, function (slot) {
                      return {
                        key: `${slot}`,
                        fn: function () {
                          return [
                            _vm._t(slot, null, {
                              methods: {
                                play: _vm.play,
                                pause: _vm.pause,
                                rewindOnce: _vm.rewindOnce,
                                startRewind: _vm.startRewind,
                                stopRewind: _vm.stopRewind,
                                fastForwardOnce: _vm.fastForwardOnce,
                                startFastForward: _vm.startFastForward,
                                stopFastForward: _vm.stopFastForward,
                                skipBackward: _vm.skipBackward,
                                skipForward: _vm.skipForward,
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      }
                    }),
                  ],
                  null,
                  true
                ),
              }),
              _vm._t(
                "seek-bar",
                function () {
                  return [
                    _vm.displayFlagsEdited.seekBar
                      ? _c("seek-bar", {
                          attrs: {
                            duration: _vm.duration,
                            "current-time": _vm.currentTime,
                          },
                          on: {
                            seeked: _vm.changeCurrentTime,
                            "change-mouse-condition": _vm.updateSeekBarManually,
                          },
                        })
                      : _vm._e(),
                  ]
                },
                {
                  currentTime: _vm.currentTime,
                  duration: _vm.duration,
                  methods: { changeCurrentTime: _vm.changeCurrentTime },
                }
              ),
              _vm._t(
                "time-display",
                function () {
                  return [
                    _vm.displayFlagsEdited.timeDisplay
                      ? _c("time-display", {
                          attrs: {
                            duration: _vm.duration,
                            "current-time": _vm.currentTime,
                          },
                        })
                      : _vm._e(),
                  ]
                },
                { currentTime: _vm.currentTime, duration: _vm.duration }
              ),
              _vm._t("right-icons-prepend", null, {
                volume: _vm.volume,
                audioSrc: _vm.src,
                methods: _vm.allMethods,
              }),
              _vm.displayFlagsEdited.volumeIcon
                ? _c("volume-icon", {
                    attrs: { volume: _vm.volume },
                    on: {
                      "update:volume": function ($event) {
                        _vm.volume = $event
                      },
                    },
                  })
                : _vm._e(),
              _vm.displayFlagsEdited.playbackSpeedIcon
                ? _c("playback-speed-icon", {
                    on: { "change-playback-speed": _vm.changePlaybackSpeed },
                  })
                : _vm._e(),
              _vm.displayFlagsEdited.downloadIcon
                ? _c("download-icon", { attrs: { src: _vm.src } })
                : _vm._e(),
              _vm._t("right-icons-append", null, {
                volume: _vm.volume,
                audioSrc: _vm.src,
                methods: _vm.allMethods,
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
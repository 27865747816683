var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "note-editor-container" } },
    [
      _c(
        "v-container",
        { attrs: { id: "note-editor-card-container" } },
        [
          _vm.$_hasNoEntry
            ? _c(
                "v-card",
                { staticClass: "mb-3" },
                [_c("v-card-text", [_vm._v("(No entries)")])],
                1
              )
            : _vm._e(),
          _vm._l(
            _vm.$_filteredNotesWithInfo,
            function (noteWithInfo, timelineSegmentMergedId) {
              return _c(
                "v-card",
                {
                  key: timelineSegmentMergedId,
                  staticClass: "mb-3",
                  attrs: { outlined: "" },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-subheader", [_vm._v("Text")]),
                          _c(
                            "v-card-text",
                            [
                              _c("v-textarea", {
                                attrs: {
                                  dense: "",
                                  disabled: noteWithInfo.isLocked,
                                  rows: _vm.$_numRows(noteWithInfo.text),
                                },
                                on: {
                                  keydown: function ($event) {
                                    $event.stopPropagation()
                                  },
                                  blur: function ($event) {
                                    return _vm.$_saveNote(
                                      timelineSegmentMergedId
                                    )
                                  },
                                },
                                model: {
                                  value: noteWithInfo.text,
                                  callback: function ($$v) {
                                    _vm.$set(noteWithInfo, "text", $$v)
                                  },
                                  expression: "noteWithInfo.text",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-subheader", [_vm._v("Author Name(ID)")]),
                          _c("v-card-text", [
                            _vm._v(
                              _vm._s(noteWithInfo.authorName) +
                                "(" +
                                _vm._s(noteWithInfo.authorId) +
                                ")"
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-subheader", [_vm._v("Date")]),
                          _c("v-card-text", [
                            _vm._v(_vm._s(noteWithInfo.date.toLocaleString())),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c(
                            "v-subheader",
                            { staticStyle: { position: "relative" } },
                            [
                              _c("span", [_vm._v("Refer to")]),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    outlined: "",
                                    text: "",
                                    small: "",
                                    right: "",
                                    disabled: noteWithInfo.isLocked,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.$_toggleSelectSegmentsCallbackRegistoration(
                                        noteWithInfo.timelineSegmentId,
                                        $event
                                      )
                                    },
                                    keydown: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                [_vm._v(" Edit ")]
                              ),
                            ],
                            1
                          ),
                          _vm._l(
                            _vm.$data.$_timelineSegmentIdsReferringTo[
                              timelineSegmentMergedId
                            ],
                            function (
                              timelineSegmentIdReferringTo,
                              referenceId
                            ) {
                              return _c(
                                "v-btn",
                                {
                                  key: referenceId,
                                  attrs: { text: "", small: "", tile: "" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.selectTimelineSegmentAndSeek(
                                        timelineSegmentIdReferringTo
                                      )
                                    },
                                    keydown: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        timelineSegmentIdReferringTo.mergedId
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }
          ),
        ],
        2
      ),
      _c(
        "div",
        { attrs: { id: "note-editor-filter-container" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                id: "note-editor-filter-button",
                tile: "",
                "x-small": "",
                text: "",
                outlined: "",
                color: _vm.$_noteEditorFilterButtonColor,
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$_openFilterMenu.apply(null, arguments)
                },
                keydown: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-filter")])],
            1
          ),
          _c("NoteEditorFilterMenu", {
            ref: "noteEditorFilterMenu",
            attrs: { id: "note-editor-filter-menu" },
            on: { "apply-filter": _vm.$_applyFilter },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
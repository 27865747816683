<template>
  <div class="d-flex flex-column">
    <waveform-view-toolbar
      ref="waveformViewToolbar"
      v-model="$data.$_controlMode"
      v-bind:shift-key-pressed="$data.$_shiftKeyPressed"
      v-bind:is-single-audio-segment-selected="$_isSingleAudioSegmentSelected"
      v-bind:is-any-audio-segment-selected="$_isAnyAudioSegmentSelected"
      v-bind:is-audio-segment-label-copied="$_isAudioSegmentLabelCopied"
      v-bind:is-auto-follow-loop-definition-enabled="$data.$_isAutoFollowLoopDefinitionEnabled"
      v-bind:is-undo-disabled="isUndoDisabled"
      v-bind:is-redo-disabled="isRedoDisabled"
      v-on:copy-audio-segment-label="$_copyAudioSegmentLabel"
      v-on:paste-audio-segment-label="$_pasteAudioSegmentLabel"
      v-on:enable-auto-follow-loop-definition="$_enableAutoFollowLoopDefinition"
      v-on:disable-auto-follow-loop-definition="$_disableAutoFollowLoopDefinition"
    >
    </waveform-view-toolbar>
    <waveform-canvas-container
      id="waveform-canvas-container"
      ref="waveformCanvasContainer"
      v-model="$_audioSegmentSequence"
      v-bind:label-sequence="labelSequence"
      v-bind:selected-audio-segment-idcs="selectedAudioSegmentIdcs"
      v-bind:audio-buffer="audioBuffer"
      v-bind:waveform-digest="waveformDigest"
      v-bind:canvas-sample-offset-min="$_sampleOffsetMin"
      v-bind:canvas-sample-offset-max="$_sampleOffsetMax"
      v-bind:play-time-sec="playTimeSec"
      v-bind:control-mode="$data.$_controlMode"
      v-bind:shift-key-pressed="$data.$_shiftKeyPressed"
    >
    </waveform-canvas-container>
    <selected-audio-segment-editor-pane
      id="selected-waveform-view"
      ref="selectedAudioSegmentEditorPane"
      v-model="$_audioSegmentSequence"
      v-bind:label-sequence="labelSequence"
      v-bind:label-options="labelOptions"
      v-bind:selected-audio-segment-idcs="selectedAudioSegmentIdcs"
      v-bind:time-unit="timeUnit"
      v-bind:waveform-digest="waveformDigest"
      v-bind:is-auto-follow-loop-definition-enabled="$data.$_isAutoFollowLoopDefinitionEnabled"
      v-bind:control-mode="$data.$_controlMode"
      v-bind:shift-key-pressed="$data.$_shiftKeyPressed"
      v-on:update-label-sequence="$_onUpdateLabelSequence"
    >
    </selected-audio-segment-editor-pane>
  </div>
</template>

<style scoped>
#selected-waveform-view {
  height: 240px;
}

div#waveform-canvas-container {
  height: 60px;
}
</style>

<script>
import WaveformViewToolbar from './WaveformView/WaveformViewToolbar.vue';
import WaveformCanvasContainer from './WaveformView/WaveformCanvasContainer.vue';
import WaveformDigest from './modules/WaveformDigest.js';
import AudioSegmentSequence, { AudioSegmentTimeUnit } from './modules/AudioSegmentSequence.js';
import SelectedAudioSegmentEditorPane from './WaveformView/SelectedAudioSegmentEditorPane.vue';
import ControlMode from './modules/ControlMode.js';
import Utils from './modules/Utils.js';

const sampleOffsetMin = 0

export default {
  components: {
    WaveformViewToolbar,
    WaveformCanvasContainer,
    SelectedAudioSegmentEditorPane,
  },

  model: {
    prop: 'audioSegmentSequence',
    event: 'update',
  },

  watch: {
    '$data.$_isAutoFollowLoopDefinitionEnabled'(isAutoFollowLoopDefinitionEnabled) {
      if (!isAutoFollowLoopDefinitionEnabled) this.resetAudioPlaybackLoopDefinition();
    },
  },

  props: {
    audioSegmentSequence:     { type: AudioSegmentSequence },
    timeUnit:                 { type: AudioSegmentTimeUnit },
    labelSequence:            { type: Array },
    labelOptions:             { type: Array },
    selectedAudioSegmentIdcs: { type: Array },
    audioBuffer:              { type: AudioBuffer },
    waveformDigest:           { type: WaveformDigest },
    playTimeSec:              { type: Number },
    isUndoDisabled:           { type: Boolean },
    isRedoDisabled:           { type: Boolean },
  },

  data() {
    return {
      $_controlMode: ControlMode.normal,
      $_shiftKeyPressed: false,
      $_copiedAudioSegmentLabel: null,
      $_isAutoFollowLoopDefinitionEnabled: false,
    };
  },

  computed: {
    $_sampleOffsetMin() { return sampleOffsetMin },

    $_sampleOffsetMax() { return this.audioBuffer.length; },

    $_audioSegmentSequence: {
      get()                     { return this.audioSegmentSequence },
      set(audioSegmentSequence) { this.$emit('update', audioSegmentSequence) },
    },

    $_isAnyAudioSegmentSelected() { return (this.selectedAudioSegmentIdcs.length > 0) },

    $_isSingleAudioSegmentSelected() { return (this.selectedAudioSegmentIdcs.length === 1) },

    $_singlySelectedAudioSegmentIdx() { return ((this.$_isSingleAudioSegmentSelected)? this.selectedAudioSegmentIdcs[0] : null) },

    $_isAudioSegmentLabelCopied() { return (this.$data.$_copiedAudioSegmentLabel !== null) },
  },

  mounted() {
    this.$emit('register-component-instance', this);
  },

  beforeDestroy() {
    this.$emit('unregister-component-instance');
  },

  inject: [
    'resetAudioPlaybackLoopDefinition',
  ],

  methods: {
    onMouseup(mouseEvent) {
      this.$refs.selectedAudioSegmentEditorPane.onMouseup(mouseEvent);
      this.$refs.waveformCanvasContainer.onMouseup(mouseEvent);
    },

    onMousemove(mouseEvent) {
      this.$refs.selectedAudioSegmentEditorPane.onMousemove(mouseEvent);
      this.$refs.waveformCanvasContainer.onMousemove(mouseEvent);
    },

    onKeydown(keyboardEvent) {
      this.$data.$_shiftKeyPressed = keyboardEvent.shiftKey;
      if (this.$refs.waveformViewToolbar.onKeydown(keyboardEvent)) return true;
      return false;
    },

    onKeyup(keyboardEvent) {
      this.$data.$_shiftKeyPressed = keyboardEvent.shiftKey;
    },

    $_onUpdateLabelSequence(labelSequence) {
      this.$emit('update-label-sequence', labelSequence);
    },

    $_copyAudioSegmentLabel() {
      this.$data.$_copiedAudioSegmentLabel = this.labelSequence[this.$_singlySelectedAudioSegmentIdx];
    },

    $_pasteAudioSegmentLabel() {
      let newLabelSequence = Utils.cloneArray(this.labelSequence);
      for (let audioSegmentIdx of this.selectedAudioSegmentIdcs) {
        newLabelSequence[audioSegmentIdx] = this.$data.$_copiedAudioSegmentLabel;
      }
      this.$emit('update-label-sequence', newLabelSequence);
    },

    $_enableAutoFollowLoopDefinition() {
      this.$data.$_isAutoFollowLoopDefinitionEnabled = true;
    },

    $_disableAutoFollowLoopDefinition() {
      this.$data.$_isAutoFollowLoopDefinitionEnabled = false;
    },
  },
}
</script>

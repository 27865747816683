<template>
    <v-navigation-drawer ref="drawer" color="grey lighten-5" app right clipped hide-overlay :width="navigation.width" v-model="navigation.shown"> 
        <div class="pa-4" style="height:50%;box-sizing: border-box;display: flex;flex-direction: column; user-select: none;">
            <div class="pt-2 d-flex">
                <h3 class="mr-2">Nano Props ({{ $t('console.templates.responseNavigationDrawer.input') }})</h3>
                <copy-to-clipboard-btn :text="JSON.stringify(nanoProps, null, 4)"></copy-to-clipboard-btn>
            </div>
            <vue-json-pretty :data="nanoProps" class="nano-object"></vue-json-pretty>
        </div>
        <div class="pa-4" style="height:50%;box-sizing: border-box;display: flex;flex-direction: column; user-select: none;">
            <div class="pt-2 d-flex">
                <h3 class="mr-2">Nano Answers ({{ $t('console.templates.responseNavigationDrawer.output') }})</h3>
                <copy-to-clipboard-btn :text="JSON.stringify(nanoAnswers, null, 4)"></copy-to-clipboard-btn>
            </div>
            <vue-json-pretty :data="nanoAnswers" class="nano-object"></vue-json-pretty>
        </div>
    </v-navigation-drawer>
</template>
<script>
import 'vue-json-pretty/lib/styles.css'
import VueJsonPretty from 'vue-json-pretty/lib/vue-json-pretty'
import CopyToClipboardBtn from '@/components/ui/CopyToClipboardBtn.vue'

export default {
    components: { VueJsonPretty, CopyToClipboardBtn },
    data: () => {
        return {
            navigation: {
                shown: true,
                width: 300,
                borderSize: 3
            }
        };
    },
    props: ['nanoProps', 'nanoAnswers'],
    computed: {
        direction() {
            return this.navigation.shown === false ? "Open" : "Closed";
        }
    },
    methods: {
        setBorderWidth() {
            let i = this.$refs.drawer.$el.querySelector(
                ".v-navigation-drawer__border"
            );
            i.style.width = this.navigation.borderSize + "px";
            i.style.cursor = "ew-resize";
            i.style.backgroundColor = "#ccc";
        },
        setEvents() {
            const minSize = this.navigation.borderSize;
            const el = this.$refs.drawer.$el;
            const drawerBorder = el.querySelector(".v-navigation-drawer__border");
            const direction = el.classList.contains("v-navigation-drawer--right") ?
                "right" :
                "left";

            function resize(e) {
                document.body.style.cursor = "ew-resize";
                let f =
                    direction === "right" ?
                    document.body.scrollWidth - e.clientX :
                    e.clientX;
                el.style.width = f + "px";
            }

            drawerBorder.addEventListener(
                "mousedown",
                (e) => {
                    if (e.offsetX < minSize) {
                        el.style.transition = "initial";
                        document.addEventListener("mousemove", resize, false);
                    }
                },
                false
            );

            document.addEventListener(
                "mouseup",
                () => {
                    el.style.transition = "";
                    this.navigation.width = el.style.width;
                    document.body.style.cursor = "";
                    document.removeEventListener("mousemove", resize, false);
                },
                false
            );
        }
    },
    mounted() {
        this.setBorderWidth();
        this.setEvents();
    }
}
</script>
<style scoped>
.nano-object {
    line-height: 1.4em;
    overflow: scroll;
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    margin-top: 10px;
    padding: 10px;
    box-sizing: border-box;
    -ms-overflow-style: none;
    scrollbar-width: none;
    flex: 1;
}
.nano-object::-webkit-scrollbar { 
    display: none;
}
</style>
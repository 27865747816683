<template>
  <canvas>
  </canvas>
</template>

<script>
import CanvasBase from './mixins/CanvasBase.js';
import Color from '../../modules/Color.js';
import CanvasLocalCoords from './modules/CanvasLocalCoords.js';
import ControlMode from '../../modules/ControlMode.js';

const dragTrajectoryColor = Color.black;
const dragTrajectoryLineWidth = 1;

export default {
  mixins: [
    CanvasBase,
  ],

  watch: {
    controlMode()                  { this.$_setDirty(true); },
    canvasLocalCoordsOnMousedown() { this.$_setDirty(true); },
    canvasLocalCoordsOnMousemove() { this.$_setDirty(true); },
  },

  props: {
    controlMode:                  { type: ControlMode },
    canvasLocalCoordsOnMousedown: { type: CanvasLocalCoords },
    canvasLocalCoordsOnMousemove: { type: CanvasLocalCoords },
  },

  methods: {
    draw(canvasElement) {
      if (this.controlMode !== ControlMode.normal) return;
      if (this.canvasLocalCoordsOnMousedown === null) return;
      if (this.canvasLocalCoordsOnMousemove === null) return;
      let canvasContext = canvasElement.getContext('2d');
      this.$_drawDragTrajectory(canvasContext, this.canvasLocalCoordsOnMousedown, this.canvasLocalCoordsOnMousemove);
    },

    $_drawDragTrajectory(canvasContext, canvasLocalCoordsOnMousedown, canvasLocalCoordsOnMousemove) {
      canvasContext.setLineDash([ 3, 3 ]);
      canvasContext.lineWidth = dragTrajectoryLineWidth;
      canvasContext.strokeStyle = dragTrajectoryColor.styleString;
      canvasContext.beginPath();
      canvasContext.rect(...this.$_dotByDotOffsetRectArgs(
        Math.floor(canvasLocalCoordsOnMousedown.x),
        Math.floor(canvasLocalCoordsOnMousedown.y),
        Math.floor(canvasLocalCoordsOnMousemove.x - canvasLocalCoordsOnMousedown.x),
        Math.floor(canvasLocalCoordsOnMousemove.y - canvasLocalCoordsOnMousedown.y),
      ));
      canvasContext.stroke();
    },
  },
}
</script>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "open-on-hover": "",
        top: "",
        "offset-y": "",
        "close-delay": "250",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-icon",
                _vm._g(
                  _vm._b(
                    { staticClass: "px-1", attrs: { small: "" } },
                    "v-icon",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(" mdi-magnify ")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        { attrs: { dense: "", width: "200" } },
        [
          _c(
            "v-list-item",
            {
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("v-icon", { attrs: { "x-small": "" } }, [
                _vm._v("mdi-arrow-expand-vertical"),
              ]),
              _c("v-slider", {
                attrs: {
                  "hide-details": "",
                  dense: "",
                  step: "0.1",
                  min: _vm.timelinePositionResolutionMin,
                  max: _vm.timelinePositionResolutionMax,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
                model: {
                  value: _vm.$_timelinePositionResolution,
                  callback: function ($$v) {
                    _vm.$_timelinePositionResolution = $$v
                  },
                  expression: "$_timelinePositionResolution",
                },
              }),
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("v-icon", { attrs: { "x-small": "" } }, [
                _vm._v("mdi-arrow-expand-horizontal"),
              ]),
              _c("v-slider", {
                attrs: {
                  "hide-details": "",
                  dense: "",
                  step: "0.01",
                  min: _vm.$_timeResolutionLog10Min,
                  max: _vm.$_timeResolutionLog10Max,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
                model: {
                  value: _vm.$_timeResolutionLog10,
                  callback: function ($$v) {
                    _vm.$_timeResolutionLog10 = $$v
                  },
                  expression: "$_timeResolutionLog10",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <dialog-base
    v-bind:shows="shows"
    v-bind:initialize-callback="$_onInitialize"
    v-bind:ok-callback="$_okCallback"
    v-bind:finalize-callback="finalizeCallback"
  >
    <template v-slot:body>
      <v-card-title>Filter View</v-card-title>
      <v-card-text>
        <v-checkbox
          dense
          label="Video and editor view"
          v-model="$data.$_showVideoAndEditorView"
        />
        <v-checkbox
          dense
          label="Timeline view"
          v-model="$data.$_showTimelineView"
        />
      </v-card-text>
    </template>
  </dialog-base>
</template>

<script>
import DialogBase from './DialogBase.vue';

export default {
  components: {
    DialogBase,
  },

  props: {
    shows: { type: Boolean, default: false },
    okCallback: { type: Function, default: null },
    finalizeCallback: { type: Function, default: null },
    showVideoAndEditorView: { type: Boolean, default: false },
    showTimelineView: { type: Boolean, default: false },
  },

  data() {
    return {
      $_showVideoAndEditorView: null,
      $_showTimelineView: null,
    };
  },

  watch: {
    showVideoAndEditorView(newShowVideoAndEditorView) {
      this.$data.$_showVideoAndEditorView = newShowVideoAndEditorView;
    },

    showTimelineView(newShowTimelineView) {
      this.$data.$_showTimelineView = newShowTimelineView;
    },
  },

  methods: {
    $_onInitialize() {
      this.$data.$_showVideoAndEditorView = this.showVideoAndEditorView;
      this.$data.$_showTimelineView = this.showTimelineView;
    },

    $_okCallback() {
      this.okCallback(this.$data.$_showVideoAndEditorView, this.$data.$_showTimelineView);
    },
  },
}
</script>

<template>
  <t-audio-segmentation
    v-bind:audio-url="audioUrl"
    v-bind:audio-segment-sequence.sync="$_audioSegmentSequence"
    v-bind:label-sequence.sync="$_labelSequence"
    v-bind:label-options="labelOptions"
  >
  </t-audio-segmentation>
</template>

<script>
import TAudioSegmentation from '@/components/template-ui/TAudioSegmentation.vue'
import TAudioSegmentationMixIn from '@/components/template-ui/TAudioSegmentation/TAudioSegmentationMixIn.js'

export default {
  components: { TAudioSegmentation },

  mixins: [ TAudioSegmentationMixIn ],

  model: {
    /*
     * props の data を `v-model` プロパティに設定できます。
     * TAudioSegmentation コンポーネント内で data が更新されると、その内容が自動的に反映されます。
     */
    prop: 'data',
    event: 'update',
  },

  props: {
    /**
     * オーディオファイルへの URL
     */
    audioUrl: { type: String, default: null },

    /**
     * オーディオセグメントデータ
     * - オーディオセグメントの時刻情報、時刻の単位、サンプリングレート、ラベル時系列を含む Object を指定します。
     *   ```
     *   {
     *     audioSegments: [ [(開始時刻), (終了時刻)], ... ],
     *     timeUnit: (次のいずれか: 'Second', 'Millisecond', 'Microsecond', 'Sample'),
     *     samplingRate: (timeUnit が 'Sample' のときのみ必須: サンプリングレート),
     *     labelSequence: [ (ラベル文字列), ... ],
     *   }
     *   ```
     * 
     *   - `audioSegments`:
     *     - オーディオセグメントの開始時刻と終了時刻の配列を、開始時刻の昇順に並べた 2 次元配列です。
     *       いずれの時刻も数値型で入力してください。
     *     - このキーを含めない、あるいは値が `undefined` の場合、
     *       オーディオファイル全体を埋めるセグメントが 1 つ挿入されます。
     *     - あるセグメントの終了時刻と、次のセグメントの開始時刻が異なる場合、
     *       それらのセグメントの間を埋めるようなセグメントが自動的に挿入されます。
     *     - 開始時刻が昇順に並んでいない場合や、あるセグメントの終了時刻よりも、
     *       次のセグメントの開始時刻が小さい(セグメントが重なり合っている)場合、例外が発生します。
     * 
     *   - `timeUnit`:
     *     - `audioSegments` の各時刻の単位を文字列で指定します。
     *       有効な値は Second, Millisecond, Microsecond, Sample のいずれかのみです。
     *     - このキーを含めない、あるいは値が `undefined` の場合、Millisecond が与えられたものとして扱います。
     * 
     *   - `sampingRate`:
     *     - `timeUnit` が Sample の場合、サンプリングレートを指定します。
     *       数値型で入力してください。
     * 
     *   - `labelSequence`:
     *     - `audioSegments` で与えた各セグメントに対応するラベル文字列を並べた配列です。
     *     - このキーを含めない、あるいは値が `undefined` の場合、空の配列が与えられたものとして扱います。
     *     - `labelSequence` の要素数が、`audioSegments` の要素数よりも少ない場合、
     *       不足する個数だけ空文字列が末尾に挿入されます。
     */
    data: { type: Object, default: () => new Object() },

    /**
     * 規定のラベル文字列配列
     * - オーディオセグメントのラベルに割り当てる規定のラベル文字列の配列を指定します。
     * - 指定しても、ラベル文字列には任意の値を入力することができます。
     * - この prop を含めない、あるいは値が undefined の場合、
     *   ラベル文字列の入力フォームに選択肢が表示されなくなります。
     */
    labelOptions: { type: Array, default: null },
  },
}
</script>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { tile: "" } },
    [
      _c(
        "v-toolbar",
        { attrs: { id: "waveform-view-toolbar", tile: "", flat: "" } },
        [
          _c(
            "button-with-tooltip",
            {
              staticClass: "my-1 mr-2 py-1",
              attrs: {
                outlined: "",
                small: "",
                depressed: "",
                disabled: _vm.isUndoDisabled,
              },
              on: { click: _vm.undo },
              scopedSlots: _vm._u([
                {
                  key: "tooltip",
                  fn: function () {
                    return [_vm._v("Undo(Ctrl+Z)")]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("v-icon", [_vm._v("mdi-undo")])],
            1
          ),
          _c(
            "button-with-tooltip",
            {
              staticClass: "my-1 mr-2 py-1",
              attrs: {
                outlined: "",
                small: "",
                depressed: "",
                disabled: _vm.isRedoDisabled,
              },
              on: { click: _vm.redo },
              scopedSlots: _vm._u([
                {
                  key: "tooltip",
                  fn: function () {
                    return [_vm._v("Undo(Ctrl+Shift+Z)")]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("v-icon", [_vm._v("mdi-redo")])],
            1
          ),
          _c(
            "button-with-tooltip",
            {
              staticClass: "my-1 mr-2 py-1",
              attrs: {
                outlined: "",
                small: "",
                depressed: "",
                "input-value": _vm.isAutoFollowLoopDefinitionEnabled,
              },
              on: { click: _vm.$_toggleIsAutoFollowLoopDefinitionEnabled },
              scopedSlots: _vm._u([
                {
                  key: "tooltip",
                  fn: function () {
                    return [_vm._v("Loop Selected Segment")]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("v-icon", [_vm._v("mdi-restart")])],
            1
          ),
          _c(
            "button-with-tooltip",
            {
              staticClass: "my-1 mr-2 py-1",
              attrs: {
                outlined: "",
                small: "",
                depressed: "",
                disabled: _vm.$_isCopyAudioSegmentLabelDisabled,
              },
              on: { click: _vm.$_copyAudioSegmentLabel },
              scopedSlots: _vm._u([
                {
                  key: "tooltip",
                  fn: function () {
                    return [_vm._v("Copy Selected Segment Label(Ctrl+C)")]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("v-icon", [_vm._v("mdi-content-copy")])],
            1
          ),
          _c(
            "button-with-tooltip",
            {
              staticClass: "my-1 mr-2 py-1",
              attrs: {
                outlined: "",
                small: "",
                depressed: "",
                disabled: _vm.$_isPasteAudioSegmentLabelDisabled,
              },
              on: { click: _vm.$_pasteAudioSegmentLabel },
              scopedSlots: _vm._u([
                {
                  key: "tooltip",
                  fn: function () {
                    return [_vm._v("Paste Label On Selected Segments(Ctrl+V)")]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("v-icon", [_vm._v("mdi-content-paste")])],
            1
          ),
          _c(
            "v-btn-toggle",
            {
              staticClass: "my-1 mr-2",
              attrs: { dense: "", mandatory: "" },
              model: {
                value: _vm.$_selectedControlModeIdx,
                callback: function ($$v) {
                  _vm.$_selectedControlModeIdx = $$v
                },
                expression: "$_selectedControlModeIdx",
              },
            },
            _vm._l(_vm.$_allControlModes, function (controlMode, idx) {
              return _c(
                "button-with-tooltip",
                {
                  key: idx,
                  staticClass: "py-1",
                  attrs: { outlined: "", small: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "tooltip",
                        fn: function () {
                          return [
                            _vm.shiftKeyPressed
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        controlMode.alternativeTooltipLabel
                                      ) +
                                      " "
                                  ),
                                ]
                              : [
                                  _vm._v(
                                    " " + _vm._s(controlMode.tooltipLabel) + " "
                                  ),
                                ],
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c(
                    "v-icon",
                    [
                      _vm.shiftKeyPressed
                        ? [
                            _vm._v(
                              " " + _vm._s(controlMode.alternativeIconId) + " "
                            ),
                          ]
                        : [_vm._v(" " + _vm._s(controlMode.iconId) + " ")],
                    ],
                    2
                  ),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "button-with-tooltip",
            {
              staticClass: "my-1 py-1",
              attrs: {
                outlined: "",
                small: "",
                depressed: "",
                "input-value": _vm.$data.$_showsHelp,
              },
              on: { click: _vm.$_toggleShowsHelp },
              scopedSlots: _vm._u([
                {
                  key: "tooltip",
                  fn: function () {
                    return [_vm._v("Show Help")]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("v-icon", [_vm._v("mdi-help")])],
            1
          ),
        ],
        1
      ),
      _vm.$data.$_showsHelp
        ? [
            _c("v-card-title", [_vm._v("Help")]),
            _c(
              "v-card-text",
              [
                _c("v-simple-table", { attrs: { dense: "" } }, [
                  _c("tbody", [
                    _c("tr", { staticStyle: { "background-color": "#eee" } }, [
                      _c("th", [_vm._v("Input")]),
                      _c("th", [_vm._v("Mode")]),
                      _c("th", [_vm._v("Description")]),
                    ]),
                    _c("tr", [
                      _c("th", [
                        _vm._v("Scroll-Up/Swipe-Left on a main waveform"),
                      ]),
                      _c("td"),
                      _c("td", [_vm._v("Scroll a waveform toward left")]),
                    ]),
                    _c("tr", [
                      _c("th", [
                        _vm._v("Scroll-Down/Swipe-Right on a main waveform"),
                      ]),
                      _c("td"),
                      _c("td", [_vm._v("Scroll a waveform toward right")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Right Arrow")]),
                      _c("td"),
                      _c("td", [_vm._v("Select a next segment")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Left Arrow")]),
                      _c("td"),
                      _c("td", [_vm._v("Select a previous segment")]),
                    ]),
                    _c("tr", [
                      _c("th", [
                        _vm._v(
                          "Shift + Scroll Up on a main/begin/end waveform"
                        ),
                      ]),
                      _c("td"),
                      _c("td", [_vm._v("Zoom in")]),
                    ]),
                    _c("tr", [
                      _c("th", [
                        _vm._v(
                          "Shift + Scroll Down on a main/begin/end waveform"
                        ),
                      ]),
                      _c("td"),
                      _c("td", [_vm._v("Zoom out")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Ctrl + Z")]),
                      _c("td"),
                      _c("td", [_vm._v("Undo a change on audio segments")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Ctrl + Shift + Z")]),
                      _c("td"),
                      _c("td", [_vm._v("Redo a change on audio segments")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Ctrl + C")]),
                      _c("td"),
                      _c("td", [_vm._v("Copy a selected audio segment label")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Ctrl + V")]),
                      _c("td"),
                      _c("td", [
                        _vm._v(
                          "Paste a copied audio segment label on selected audio segments"
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [
                        _vm._v(
                          "Scroll-Up/Swipe-Left/Drag-Right on a begin waveform"
                        ),
                      ]),
                      _c("td"),
                      _c("td", [_vm._v("Decrease a begin value")]),
                    ]),
                    _c("tr", [
                      _c("th", [
                        _vm._v(
                          "Scroll-Down/Swipe-Right/Drag-Left on a begin waveform"
                        ),
                      ]),
                      _c("td"),
                      _c("td", [_vm._v("Increase a begin value")]),
                    ]),
                    _c("tr", [
                      _c("th", [
                        _vm._v(
                          "Scroll-Up/Swipe-Left/Drag-Right on an end waveform"
                        ),
                      ]),
                      _c("td"),
                      _c("td", [_vm._v("Decrease an end value")]),
                    ]),
                    _c("tr", [
                      _c("th", [
                        _vm._v(
                          "Scroll-Down/Swipe-Right/Drag-Left on an end waveform"
                        ),
                      ]),
                      _c("td"),
                      _c("td", [_vm._v("Increase an end value")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Click on an audio segment")]),
                      _c("td", [_vm._v("Normal Mode")]),
                      _c("td", [_vm._v("Select an audio segment")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Drag on audio segments")]),
                      _c("td", [_vm._v("Normal Mode")]),
                      _c("td", [_vm._v("Select audio segments")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Drag on a main waveform")]),
                      _c("td", [_vm._v("Move View Mode")]),
                      _c("td", [_vm._v("Scroll a waveform")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Click on an audio segment")]),
                      _c("td", [_vm._v("Divide Segment Mode")]),
                      _c("td", [_vm._v("Divide an audio segment")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Click on an audio segment")]),
                      _c("td", [_vm._v("Remove Segment Mode")]),
                      _c("td", [_vm._v("Remove an audio segment")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Click on a main/begin/end waveform")]),
                      _c("td", [_vm._v("Zoom In Mode")]),
                      _c("td", [_vm._v("Zoom in")]),
                    ]),
                    _c("tr", [
                      _c("th", [
                        _vm._v("Shift + Click on a zoom in mode button"),
                      ]),
                      _c("td", [_vm._v("Zoom In Mode")]),
                      _c("td", [_vm._v("Switch to Zoom out mode")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Click on a main/begin/end waveform")]),
                      _c("td", [_vm._v("Zoom Out Mode")]),
                      _c("td", [_vm._v("Zoom out")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Click on a zoom out mode button")]),
                      _c("td", [_vm._v("Zoom Out Mode")]),
                      _c("td", [_vm._v("Switch to Zoom in mode")]),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
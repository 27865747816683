export const getListItems = (vm) => ([
    {
        name: vm.$t('console.templates.create.presets.basic.title'),
        icon: '',
        presets: [
            {
                name: vm.$t('console.templates.create.presets.basic.items.blank'),
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_Blank/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_Blank',
            },
        ]
    },
    {
        name: vm.$t('console.templates.create.presets.survey.title'),
        icon: 'mdi-pencil',
        presets: [
            {
                name: vm.$t('console.templates.create.presets.survey.items.single'),
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_SimpleSurvey/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_SimpleSurvey',
            },
            {
                name: vm.$t('console.templates.create.presets.survey.items.multi'),
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_Survey/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_Survey',
            },
        ]
    },
    {
        name: vm.$t('console.templates.create.presets.audio.title'),
        icon: 'mdi-headphones',
        presets: [
            {
                name: vm.$t('console.templates.create.presets.audio.items.classification'),
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_AudioClassification/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_AudioClassification',
            },
            {
                name: vm.$t('console.templates.create.presets.audio.items.pairwise'),
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_AudioComparison/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_AudioComparison',
            },
            {
                name: vm.$t('console.templates.create.presets.audio.items.segmentation'),
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_AudioSegmentation/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_AudioSegmentation',
            },
        ]
    },
    {
        name: vm.$t('console.templates.create.presets.image.title'),
        icon: 'mdi-image',
        presets: [
            {
                name: vm.$t('console.templates.create.presets.image.items.classification'),
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_ImageClassification/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_ImageClassification',
            },
            {
                name: vm.$t('console.templates.create.presets.image.items.pairwise'),
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_ImageComparison/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_ImageComparison',
            },
            {
                name: vm.$t('console.templates.create.presets.image.items.ranking'),
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_ImageRanking/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_ImageRanking',
            },
            {
                name: vm.$t('console.templates.create.presets.image.items.boundingBox'),
                //component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_BoundingBox/Main.vue').default,
                //presetGroupName: 'VueComponent',
                //presetName: 'Vuetify_BoundingBox',
            },
        ]
    },
    {
        name: vm.$t('console.templates.create.presets.text.title'),
        icon: 'mdi-format-text',
        presets: [
            {
                name: vm.$t('console.templates.create.presets.text.items.classification'),
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_TextClassification/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_TextClassification',
            },
            //{
            //    name: vm.$t('console.templates.create.presets.text.items.ner'),
            //    component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_TextNamedEntityLabeling/Main.vue').default,
            //    presetGroupName: 'VueComponent',
            //    presetName: 'Vuetify_TextNamedEntityLabeling',
            //},
        ]
    },
    {
        name: vm.$t('console.templates.create.presets.video.title'),
        icon: 'mdi-filmstrip',
        presets: [
            {
                name: vm.$t('console.templates.create.presets.video.items.classification'),
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_VideoClassification/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_VideoClassification',
            },
            {
                name: vm.$t('console.templates.create.presets.video.items.timeline'),
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_VideoTimelineSegmentLabeling/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_VideoTimelineSegmentLabeling',
            }
        ]
    },
    {
        name: vm.$t('console.templates.create.presets.upload.title'),
        icon: 'mdi-filmstrip',
        presets: [
            {
                name: vm.$t('console.templates.create.presets.upload.items.file'),
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_FileUpload/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_FileUpload',
            },
        ]
    },
    {
        name: vm.$t('console.templates.create.presets.capture.title'),
        icon: 'mdi-record-rec',
        presets: [
            {
                name: vm.$t('console.templates.create.presets.capture.items.audio'),
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_CaptureAudio/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_CaptureAudio',
            },
            {
                name: vm.$t('console.templates.create.presets.capture.items.photo'),
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_CapturePhoto/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_CapturePhoto',
            },
            {
                name: vm.$t('console.templates.create.presets.capture.items.video'),
                component: require('@/projects/.defaultproject/templates/.presets/VueComponent/Vuetify_CaptureVideo/Main.vue').default,
                presetGroupName: 'VueComponent',
                presetName: 'Vuetify_CaptureVideo',
            }
        ]
    }
]);
<template>
  <span id="area-divider" v-bind:content="content" />
</template>

<style scoped>
span#area-divider:after {
  content: attr(content);
}
</style>

<script>
export default {
  watch: {
    fontSize(fontSize) { this.$_updateStyle(fontSize, this.marginPx) },
    marginPx(marginPx) { this.$_updateStyle(this.fontSize, marginPx) },
  },

  props: {
    content: { type: String, default: '|' },
    fontSize: { type: Number, default: null },
    marginPx: { type: Number, default: 10 },
  },

  mounted() {
    this.$_updateStyle(this.fontSize, this.marginPx) ;
  },

  methods: {
    $_updateStyle(fontSize, marginPx) {
      if (fontSize === null) {
        delete this.$el.style.fontSize
      } else {
        this.$el.style.fontSize = String(fontSize) + 'px';
      }
      this.$el.style.marginLeft = String(marginPx) + 'px';
      this.$el.style.marginRight = String(marginPx) + 'px';
    },
  },
}
</script>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      style: { height: _vm.timelineHeightPx + "px" },
      attrs: { fluid: "", "pa-0": "" },
    },
    [
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "", "offset-overflow": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-card",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "timeline-title-container v-btn",
                          attrs: {
                            tile: "",
                            text: "",
                            outlined: "",
                            title: "Generate New Timeline",
                          },
                        },
                        "v-card",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c(
                        "v-container",
                        { attrs: { "fill-height": "", fluid: "", "pa-0": "" } },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: { "justify-center": "", "no-gutters": "" },
                            },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass:
                                        "generate-new-timeline-button-icon",
                                    },
                                    [_vm._v("mdi-plus")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-list",
            { attrs: { dense: "" } },
            _vm._l(_vm.menuContents, function (menuContent, menuContentIdx) {
              return _c(
                "v-list-item",
                {
                  key: menuContentIdx,
                  attrs: { disabled: menuContent.disabled },
                  on: {
                    click: function ($event) {
                      return menuContent.callback()
                    },
                  },
                },
                [
                  _c("v-list-item-title", {
                    domProps: { textContent: _vm._s(menuContent.name) },
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
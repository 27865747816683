<template>
  <canvas-container
    ref="canvasContainer"
    v-bind:begin-sample-offset="$_canvasBeginSampleOffsetWithDrag"
    v-bind:end-sample-offset="$_canvasEndSampleOffsetWithDrag"
    v-bind:sampling-rate="audioSegmentSequence.samplingRate"
    v-on:mousedown="$_onMousedown"
    v-on:wheel="$_onWheel"
    v-on:resize="$_onResize"
  >
    <template v-slot:default="{ widthPx, heightPx, canvasLocalRect, beginSec, endSec, durationSec }">
      <waveform-canvas
        v-bind:width-px="widthPx"
        v-bind:height-px="heightPx"
        v-bind:waveform-digest="waveformDigest"
        v-bind:canvas-begin-sample-offset="$_canvasBeginSampleOffsetWithDrag"
        v-bind:canvas-end-sample-offset="$_canvasEndSampleOffsetWithDrag"
        v-bind:canvas-duration-sec="durationSec"
      >
      </waveform-canvas>

      <audio-segment-canvas
        v-bind:width-px="widthPx"
        v-bind:height-px="heightPx"
        v-bind:audio-segment-sequence="audioSegmentSequence"
        v-bind:label-sequence="labelSequence"
        v-bind:selected-segment-idcs="selectedAudioSegmentIdcs"
        v-bind:canvas-begin-sample-offset="$_canvasBeginSampleOffsetWithDrag"
        v-bind:canvas-end-sample-offset="$_canvasEndSampleOffsetWithDrag"
        v-bind:canvas-local-rect="canvasLocalRect"
        v-on:register-component-instance="$_registerAudioSegmentCanvasComponentInstance"
        v-on:unregister-component-instance="$_unregisterAudioSegmentCanvasComponentInstance"
      >
      </audio-segment-canvas>

      <play-time-bar-canvas
        v-if="playTimeSec !== null"
        v-bind:width-px="widthPx"
        v-bind:height-px="heightPx"
        v-bind:play-time-sec="playTimeSec"
        v-bind:canvas-begin-sec="beginSec"
        v-bind:canvas-end-sec="endSec"
        v-bind:canvas-duration-sec="durationSec"
      >
      </play-time-bar-canvas>

      <drag-trajectory-canvas
        v-bind:width-px="widthPx"
        v-bind:height-px="heightPx"
        v-bind:control-mode="controlMode"
        v-bind:canvas-local-coords-on-mousedown="$data.$_canvasLocalCoordsOnMousedown"
        v-bind:canvas-local-coords-on-mousemove="$data.$_canvasLocalCoordsOnMousemove"
      >
      </drag-trajectory-canvas>
    </template>
  </canvas-container>
</template>

<script>
import WaveformCanvas from './canvases/WaveformCanvas.vue';
import AudioSegmentCanvas from './canvases/AudioSegmentCanvas.vue';
import PlayTimeBarCanvas from './canvases/PlayTimeBarCanvas.vue';
import DragTrajectoryCanvas from './canvases/DragTrajectoryCanvas.vue';
import WaveformDigest from '../modules/WaveformDigest.js';
import AudioSegmentSequence from '../modules/AudioSegmentSequence.js';
import CanvasLocalCoords from './canvases/modules/CanvasLocalCoords.js';
import ControlMode from '../modules/ControlMode.js';
import RationalNumber from '../modules/RationalNumber.js';
import CanvasContainer from './canvases/CanvasContainer.vue';
import Utils from '../modules/Utils.js';

const numCanvasSamplesMin = 10;

export default {
  components: {
    CanvasContainer,
    WaveformCanvas,
    AudioSegmentCanvas,
    PlayTimeBarCanvas,
    DragTrajectoryCanvas,
  },

  model: {
    prop: 'audioSegmentSequence',
    event: 'update',
  },

  watch: {
    canvasSampleOffsetMin: {
      handler(canvasSampleOffsetMin) {
        this.$data.$_canvasBeginSampleOffset = canvasSampleOffsetMin;
      },
      immediate: true,
    },

    canvasSampleOffsetMax: {
      handler(canvasSampleOffsetMax) {
        this.$data.$_canvasEndSampleOffset = canvasSampleOffsetMax;
      },
      immediate: true,
    },

    shiftKeyPressed() { this.$_updateCursorStyle() },
  },

  props: {
    audioSegmentSequence:     { type: AudioSegmentSequence },
    labelSequence:            { type: Array },
    selectedAudioSegmentIdcs: { type: Array },
    canvasSampleOffsetMin:    { type: Number },
    canvasSampleOffsetMax:    { type: Number },
    audioBuffer:              { type: AudioBuffer },
    waveformDigest:           { type: WaveformDigest },
    playTimeSec:              { type: Number },
    controlMode:              { type: ControlMode },
    shiftKeyPressed:          { type: Boolean },
  },

  data() {
    return {
      $_boundingClientRect: null,
      $_canvasLocalRect: null,
      $_canvasBeginSampleOffset: 0,
      $_canvasEndSampleOffset: 0,
      $_dragSampleOffset: 0,
      $_audioSegmentIdxOnMousemove: null,
      $_canvasLocalCoordsOnMousemove: null,
      $_canvasLocalCoordsOnMousedown: null,
      $_audioSegmentCanvasComponentInstance: null,
      $_shiftKeyPressed: false,
    };
  },

  computed: {
    $_numCanvasSamplesMax() { return this.canvasSampleOffsetMax - this.canvasSampleOffsetMin },

    $_durationSample() {
      return this.$data.$_canvasEndSampleOffset - this.$data.$_canvasBeginSampleOffset;
    },

    $_dragOffsetPx() {
      if (this.$data.$_canvasLocalCoordsOnMousemove === null) return null;
      if (this.$data.$_canvasLocalCoordsOnMousedown === null) return null;
      return this.$data.$_canvasLocalCoordsOnMousemove.x - this.$data.$_canvasLocalCoordsOnMousedown.x;
    },

    $_isInsideCanvasOnMousemove() {
      return this.$data.$_canvasLocalCoordsOnMousemove.isInside(this.$data.$_canvasLocalRect);
    },

    $_isCursorOnAudioSegmentOnMousemove() {
      return (this.$data.$_audioSegmentIdxOnMousemove !== null);
    },

    $_canvasBeginSampleOffsetWithDrag() {
      return this.$data.$_canvasBeginSampleOffset + this.$data.$_dragSampleOffset;
    },

    $_canvasEndSampleOffsetWithDrag() {
      return this.$data.$_canvasEndSampleOffset + this.$data.$_dragSampleOffset;
    },

    $_dragSampleOffsetMin() {
      return this.canvasSampleOffsetMin - this.$data.$_canvasBeginSampleOffset;
    },

    $_dragSampleOffsetMax() {
      return this.canvasSampleOffsetMax - this.$data.$_canvasEndSampleOffset;
    },

  },

  inject: [
    'selectAudioSegments',
    'clearSelectedAudioSegments',
    'shiftSelectedAudioSegmentIdcs',
  ],

  methods: {
    $_onResize({ boundingClientRect, canvasLocalRect }) {
      this.$data.$_boundingClientRect = boundingClientRect;
      this.$data.$_canvasLocalRect = canvasLocalRect;
    },

    onMousemove(mouseEvent) {
      let canvasLocalCoordsOnMousemove = CanvasLocalCoords.generateFromMouseEvent(mouseEvent, this.$data.$_boundingClientRect);
      this.$data.$_audioSegmentIdxOnMousemove = this.$data.$_audioSegmentCanvasComponentInstance.getSegmentIdxAt(
        canvasLocalCoordsOnMousemove,
      );
      this.$data.$_canvasLocalCoordsOnMousemove = canvasLocalCoordsOnMousemove;
      this.$_updateCursorStyle();
      switch (this.controlMode) {
        case ControlMode.move:
          return drag(this);
        default:
          return false;
      }

      function drag(self) {
        if (self.$data.$_canvasLocalCoordsOnMousedown === null) return false;
        let dragSampleOffset = -(self.$refs.canvasContainer.sampleResolution * self.$_dragOffsetPx);
        let newDragSampleOffset = Utils.clamp(dragSampleOffset, self.$_dragSampleOffsetMin, self.$_dragSampleOffsetMax);
        if (self.$data.$_dragSampleOffset === newDragSampleOffset) return false;
        self.$data.$_dragSampleOffset = newDragSampleOffset;
        return true;
      }
    },

    $_onMousedown(mouseEvent) {
      this.$data.$_canvasLocalCoordsOnMousedown = CanvasLocalCoords.generateFromMouseEvent(mouseEvent, this.$data.$_boundingClientRect);
      this.$_updateCursorStyle();
      let offsetPxOnMousedown = mouseEvent.clientX - this.$data.$_boundingClientRect.x;
      switch (this.controlMode) {
        case ControlMode.zoom:
          return zoom(this, offsetPxOnMousedown, ((mouseEvent.shiftKey)? 0.5 : 2));
        case ControlMode.divide:
          return divideAudioSegment(this);
        case ControlMode.remove:
          return removeAudioSegment(this);
        default:
          return false;
      }

      function zoom(self, offsetPx, scale) {
        self.$_setNumCanvasSamples(offsetPx, self.$_durationSample / scale);
        return true;
      }

      function divideAudioSegment(self) {
        let segmentIdx = self.$data.$_audioSegmentIdxOnMousemove;
        if (segmentIdx === null) return false;
        let isAudioSegmentOnMousemoveSelected = self.selectedAudioSegmentIdcs.includes(segmentIdx);
        let sampleOffsetOnMousedown = self.$data.$_canvasBeginSampleOffset + self.$refs.canvasContainer.sampleResolution * self.$data.$_canvasLocalCoordsOnMousedown.x;
        let dividedTime = AudioSegmentSequence.convertTime(
          RationalNumber.generateFrom(Math.round(sampleOffsetOnMousedown)),
          AudioSegmentSequence.TimeUnit.sample,
          self.audioSegmentSequence.timeUnit,
          { samplingRate: self.audioSegmentSequence.samplingRate },
        );
        let newAudioSegmentSequence = self.audioSegmentSequence.clone();
        let targetAudioSegmentFirstHalf = newAudioSegmentSequence.audioSegments[segmentIdx];
        let targetAudioSegmentSecondHalf = targetAudioSegmentFirstHalf.clone();
        targetAudioSegmentFirstHalf.end = dividedTime;
        targetAudioSegmentSecondHalf.begin = dividedTime;
        self.shiftSelectedAudioSegmentIdcs(segmentIdx, 1);
        if (isAudioSegmentOnMousemoveSelected) {
          self.selectAudioSegments([ segmentIdx ]);
        }
        newAudioSegmentSequence.audioSegments.splice(segmentIdx, 1, targetAudioSegmentFirstHalf, targetAudioSegmentSecondHalf);
        self.$emit('update', newAudioSegmentSequence);
        return true;
      }

      function removeAudioSegment(self) {
        let targetSegmentIdx = self.$data.$_audioSegmentIdxOnMousemove;
        if (targetSegmentIdx === null) return false;
        let nextSegmentIdx = targetSegmentIdx + 1;
        if (nextSegmentIdx >= self.audioSegmentSequence.numAudioSegments) return false;
        let newAudioSegmentSequence = self.audioSegmentSequence.clone();
        let targetAudioSegment = newAudioSegmentSequence.audioSegments[targetSegmentIdx];
        let nextAudioSegment = newAudioSegmentSequence.audioSegments[nextSegmentIdx];
        targetAudioSegment.end = nextAudioSegment.end;
        newAudioSegmentSequence.audioSegments.splice(targetSegmentIdx, 2, targetAudioSegment);
        self.$emit('update', newAudioSegmentSequence);
        return true;
      }
    },

    onMouseup(mouseEvent) {
      let isMouseEventHooked = onMouseup(this, mouseEvent);
      this.$data.$_canvasLocalCoordsOnMousedown = null;
      this.$data.$_dragSampleOffset = 0;
      return isMouseEventHooked;

      function onMouseup(self, mouseEvent) {
        let canvasLocalCoordsOnMouseup = CanvasLocalCoords.generateFromMouseEvent(mouseEvent, self.$data.$_boundingClientRect);
        let withShiftKey = mouseEvent.shiftKey;
        if (self.$data.$_canvasLocalCoordsOnMousedown === null) return false;
        switch (self.controlMode) {
          case ControlMode.normal:
            return selectAudioSegments(self, withShiftKey, canvasLocalCoordsOnMouseup);
          case ControlMode.move:
            if (isMouseEventClick(self, canvasLocalCoordsOnMouseup)) {
              return selectAudioSegments(self, withShiftKey, canvasLocalCoordsOnMouseup);
            } else {
              return fixDragOffset(self);
            }
          default:
            return false;
        }
      }

      function isMouseEventClick(self, canvasLocalCoordsOnMouseup) {
        return self.$data.$_canvasLocalCoordsOnMousedown.isEqualTo(canvasLocalCoordsOnMouseup)
      }

      function selectAudioSegments(self, withShiftKey, canvasLocalCoordsOnMouseup) {
        if (!withShiftKey) self.clearSelectedAudioSegments();
        let containingSegmentIdcs = self.$data.$_audioSegmentCanvasComponentInstance.getContainingSegmentIdcs(
          self.$data.$_canvasLocalCoordsOnMousedown,
          canvasLocalCoordsOnMouseup,
        );
        self.selectAudioSegments(containingSegmentIdcs);
        return true;
      }

      function fixDragOffset(self) {
        self.$data.$_canvasBeginSampleOffset += self.$data.$_dragSampleOffset;
        self.$data.$_canvasEndSampleOffset += self.$data.$_dragSampleOffset;
      }
    },

    $_onWheel(wheelEvent) {
      if (onWheel(this, wheelEvent)) {
        wheelEvent.preventDefault();
      }

      function onWheel(self, wheelEvent) {
        let horizontalOffsetPx = Utils.convertWheelEventToHorizontalOffsetPx(wheelEvent);
        let offsetPxOnWheel = wheelEvent.clientX - self.$data.$_boundingClientRect.x;
        if (wheelEvent.shiftKey) {
          return zoom(self, offsetPxOnWheel, (horizontalOffsetPx > 0)? 1.25: 0.75);
        } else {
          return scroll(self, horizontalOffsetPx * 20);
        }
      }

      function zoom(self, offsetPx, scale) {
        return self.$_setNumCanvasSamples(offsetPx, self.$_durationSample * scale);
      }

      function scroll(self, offsetValue) {
        return self.$_offsetCanvasBeginAndEnd(offsetValue);
      }
    },

    $_registerAudioSegmentCanvasComponentInstance(audioSegmentCanvasComponentInstance) {
      this.$data.$_audioSegmentCanvasComponentInstance = audioSegmentCanvasComponentInstance;
    },

    $_unregisterAudioSegmentCanvasComponentInstance() {
      this.$data.$_audioSegmentCanvasComponentInstance = null;
    },

    $_updateCursorStyle() {
      this.$el.style.cursor = getCursorStyle(this);

      function getCursorStyle(self) {
        if (self.$_isInsideCanvasOnMousemove) {
          switch (self.controlMode) {
            case ControlMode.zoom:
              return (self.shiftKeyPressed)? 'zoom-out' : 'zoom-in';
            case ControlMode.divide:
              if (self.$_isCursorOnAudioSegmentOnMousemove) {
                return 'text'; // FIXME
              } else {
                return 'default';
              }
            case ControlMode.move:
              if (self.$data.$_canvasLocalCoordsOnMousedown === null) {
                return 'grab';
              } else {
                return 'grabbing';
              }
            default:
              if (self.$_isCursorOnAudioSegmentOnMousemove) {
                return 'pointer';
              } else {
                return 'default';
              }
          }
        } else {
          return 'default';
        }
      }
    },

    $_offsetCanvasBeginAndEnd(sampleOffset) {
      let actualSampleOffset = 0;
      if (sampleOffset > 0) {
        let newCanvasEndSampleOffset = Utils.clamp(
          this.$data.$_canvasEndSampleOffset + sampleOffset,
          this.canvasSampleOffsetMin,
          this.canvasSampleOffsetMax,
        );
        actualSampleOffset = newCanvasEndSampleOffset - this.$data.$_canvasEndSampleOffset;
      } else {
        let newCanvasBeginSampleOffset = Utils.clamp(
          this.$data.$_canvasBeginSampleOffset + sampleOffset,
          this.canvasSampleOffsetMin,
          this.canvasSampleOffsetMax,
        );
        actualSampleOffset = newCanvasBeginSampleOffset - this.$data.$_canvasBeginSampleOffset;
      }
      if (actualSampleOffset === 0) return false;
      this.$data.$_canvasEndSampleOffset += actualSampleOffset;
      this.$data.$_canvasBeginSampleOffset += actualSampleOffset;
      return true;
    },

    $_setNumCanvasSamples(stableOffsetPx, newNumCanvasSamples) {
      let stableSampleOffset = this.$refs.canvasContainer.sampleResolution * stableOffsetPx;
      let stableSample = this.$data.$_canvasBeginSampleOffset + stableSampleOffset;
      newNumCanvasSamples = Utils.clamp(
        newNumCanvasSamples,
        numCanvasSamplesMin,
        this.$_numCanvasSamplesMax,
      );
      let newSampleResolution = newNumCanvasSamples / this.$refs.canvasContainer.canvasWidthPx;
      let newStableOffsetSample = newSampleResolution * stableOffsetPx;
      let newCanvasBeginSampleOffset = stableSample - newStableOffsetSample;
      let newCanvasEndSampleOffset = newCanvasBeginSampleOffset + newNumCanvasSamples;
      if (newCanvasEndSampleOffset > this.canvasSampleOffsetMax) {
        newCanvasEndSampleOffset = this.canvasSampleOffsetMax;
        newCanvasBeginSampleOffset = newCanvasEndSampleOffset - newNumCanvasSamples;
      } else if (newCanvasBeginSampleOffset < this.canvasSampleOffsetMin) {
        newCanvasBeginSampleOffset = this.canvasSampleOffsetMin;
        newCanvasEndSampleOffset = newCanvasBeginSampleOffset + newNumCanvasSamples;
      }
      if ((newCanvasBeginSampleOffset === this.$data.$_canvasBeginSampleOffset) && (newCanvasEndSampleOffset === this.$data.$_canvasEndSampleOffset)) return false;
      this.$data.$_canvasBeginSampleOffset = newCanvasBeginSampleOffset;
      this.$data.$_canvasEndSampleOffset = newCanvasEndSampleOffset;
      return true;
    },
  },
}
</script>
class TimelineSegmentDomRectsToDragWithInfo {
  constructor(domRects, endTypes, edgeType) {
    this.domRects = domRects;
    this.endTypes = endTypes;
    this.edgeType = edgeType;
  }

  static edgeType = {
    left: 'left',
    right: 'right',
    none: 'none',
  }

  static endType = {
    bothEndFinite: 'bothEndFinite',
    leftEndInfinite: 'leftEndInfinite',
    rightEndInfinite: 'rightEndInfinite',
    bothEndInfinite: 'bothEndInfinite',
  }
}

export default TimelineSegmentDomRectsToDragWithInfo;

<template>
  <v-card tile>
    <v-toolbar id="waveform-view-toolbar" tile flat>
      <button-with-tooltip
        outlined small depressed class="my-1 mr-2 py-1"
        v-bind:disabled="isUndoDisabled"
        v-on:click="undo"
      >
        <v-icon>mdi-undo</v-icon>
        <template v-slot:tooltip>Undo(Ctrl+Z)</template>
      </button-with-tooltip>

      <button-with-tooltip
        outlined small depressed class="my-1 mr-2 py-1"
        v-bind:disabled="isRedoDisabled"
        v-on:click="redo"
      >
        <v-icon>mdi-redo</v-icon>
        <template v-slot:tooltip>Undo(Ctrl+Shift+Z)</template>
      </button-with-tooltip>

      <button-with-tooltip
        outlined small depressed class="my-1 mr-2 py-1"
        v-bind:input-value="isAutoFollowLoopDefinitionEnabled"
        v-on:click="$_toggleIsAutoFollowLoopDefinitionEnabled"
      >
        <v-icon>mdi-restart</v-icon>
        <template v-slot:tooltip>Loop Selected Segment</template>
      </button-with-tooltip>

      <button-with-tooltip
        outlined small depressed class="my-1 mr-2 py-1"
        v-bind:disabled="$_isCopyAudioSegmentLabelDisabled"
        v-on:click="$_copyAudioSegmentLabel"
      >
        <v-icon>mdi-content-copy</v-icon>
        <template v-slot:tooltip>Copy Selected Segment Label(Ctrl+C)</template>
      </button-with-tooltip>

      <button-with-tooltip
        outlined small depressed class="my-1 mr-2 py-1"
        v-bind:disabled="$_isPasteAudioSegmentLabelDisabled"
        v-on:click="$_pasteAudioSegmentLabel"
      >
        <v-icon>mdi-content-paste</v-icon>
        <template v-slot:tooltip>Paste Label On Selected Segments(Ctrl+V)</template>
      </button-with-tooltip>

      <v-btn-toggle
        dense mandatory class="my-1 mr-2"
        v-model="$_selectedControlModeIdx"
      >
        <button-with-tooltip
          outlined small class="py-1"
          v-for="(controlMode, idx) of $_allControlModes"
          v-bind:key="idx"
        >
          <v-icon>
            <template v-if="shiftKeyPressed">
              {{ controlMode.alternativeIconId }}
            </template>
            <template v-else>
              {{ controlMode.iconId }}
            </template>
          </v-icon>
          <template v-slot:tooltip>
            <template v-if="shiftKeyPressed">
              {{ controlMode.alternativeTooltipLabel }}
            </template>
            <template v-else>
              {{ controlMode.tooltipLabel }}
            </template>
          </template>
        </button-with-tooltip>
      </v-btn-toggle>

      <button-with-tooltip
        outlined small depressed class="my-1 py-1"
        v-bind:input-value="$data.$_showsHelp"
        v-on:click="$_toggleShowsHelp"
      >
        <v-icon>mdi-help</v-icon>
        <template v-slot:tooltip>Show Help</template>
      </button-with-tooltip>
    </v-toolbar>

    <template v-if="$data.$_showsHelp">
      <v-card-title>Help</v-card-title>

      <v-card-text>
        <v-simple-table dense>
          <tbody>
            <tr style="background-color:#eee">
              <th>Input</th>
              <th>Mode</th>
              <th>Description</th>
            </tr>
            <tr>
              <th>Scroll-Up/Swipe-Left on a main waveform</th>
              <td></td>
              <td>Scroll a waveform toward left</td>
            </tr>
            <tr>
              <th>Scroll-Down/Swipe-Right on a main waveform</th>
              <td></td>
              <td>Scroll a waveform toward right</td>
            </tr>
            <tr>
              <th>Right Arrow</th>
              <td></td>
              <td>Select a next segment</td>
            </tr>
            <tr>
              <th>Left Arrow</th>
              <td></td>
              <td>Select a previous segment</td>
            </tr>
            <tr>
              <th>Shift + Scroll Up on a main/begin/end waveform</th>
              <td></td>
              <td>Zoom in</td>
            </tr>
            <tr>
              <th>Shift + Scroll Down on a main/begin/end waveform</th>
              <td></td>
              <td>Zoom out</td>
            </tr>
            <tr>
              <th>Ctrl + Z</th>
              <td></td>
              <td>Undo a change on audio segments</td>
            </tr>
            <tr>
              <th>Ctrl + Shift + Z</th>
              <td></td>
              <td>Redo a change on audio segments</td>
            </tr>
            <tr>
              <th>Ctrl + C</th>
              <td></td>
              <td>Copy a selected audio segment label</td>
            </tr>
            <tr>
              <th>Ctrl + V</th>
              <td></td>
              <td>Paste a copied audio segment label on selected audio segments</td>
            </tr>
            <tr>
              <th>Scroll-Up/Swipe-Left/Drag-Right on a begin waveform</th>
              <td></td>
              <td>Decrease a begin value</td>
            </tr>
            <tr>
              <th>Scroll-Down/Swipe-Right/Drag-Left on a begin waveform</th>
              <td></td>
              <td>Increase a begin value</td>
            </tr>
            <tr>
              <th>Scroll-Up/Swipe-Left/Drag-Right on an end waveform</th>
              <td></td>
              <td>Decrease an end value</td>
            </tr>
            <tr>
              <th>Scroll-Down/Swipe-Right/Drag-Left on an end waveform</th>
              <td></td>
              <td>Increase an end value</td>
            </tr>
            <tr>
              <th>Click on an audio segment</th>
              <td>Normal Mode</td>
              <td>Select an audio segment</td>
            </tr>
            <tr>
              <th>Drag on audio segments</th>
              <td>Normal Mode</td>
              <td>Select audio segments</td>
            </tr>
            <tr>
              <th>Drag on a main waveform</th>
              <td>Move View Mode</td>
              <td>Scroll a waveform</td>
            </tr>
            <tr>
              <th>Click on an audio segment</th>
              <td>Divide Segment Mode</td>
              <td>Divide an audio segment</td>
            </tr>
            <tr>
              <th>Click on an audio segment</th>
              <td>Remove Segment Mode</td>
              <td>Remove an audio segment</td>
            </tr>
            <tr>
              <th>Click on a main/begin/end waveform</th>
              <td>Zoom In Mode</td>
              <td>Zoom in</td>
            </tr>
            <tr>
              <th>Shift + Click on a zoom in mode button</th>
              <td>Zoom In Mode</td>
              <td>Switch to Zoom out mode</td>
            </tr>
            <tr>
              <th>Click on a main/begin/end waveform</th>
              <td>Zoom Out Mode</td>
              <td>Zoom out</td>
            </tr>
            <tr>
              <th>Click on a zoom out mode button</th>
              <td>Zoom Out Mode</td>
              <td>Switch to Zoom in mode</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </template>
  </v-card>
</template>

<style scoped>
#waveform-view-toolbar {
  user-select: none;
  height: fit-content !important;
}

#waveform-view-toolbar >>> * {
  height: auto !important;
  flex-wrap: wrap !important;
  white-space: normal !important;
}
</style>

<script>
import ControlMode from '../modules/ControlMode.js';
import ButtonWithTooltip from '../ButtonWithTooltip.vue';

export default {
  components: {
    ButtonWithTooltip,
  },

  model: {
    prop: 'controlMode',
    event: 'update',
  },

  watch: {
    controlMode(controlMode) {
      this.$data.$_selectedControlModeIdx = this.$_allControlModes.findIndex(x => (x === controlMode));
    },
  },

  props: {
    controlMode:                       { type: ControlMode },
    shiftKeyPressed:                   { type: Boolean },
    isSingleAudioSegmentSelected:      { type: Boolean },
    isAnyAudioSegmentSelected:         { type: Boolean },
    isAudioSegmentLabelCopied:         { type: Boolean },
    isAutoFollowLoopDefinitionEnabled: { type: Boolean },
    isUndoDisabled:                    { type: Boolean },
    isRedoDisabled:                    { type: Boolean },
  },

  data() {
    return {
      $_showsHelp: false,
    };
  },

  computed: {
    $_selectedControlModeIdx: {
      get() {
        return this.$_allControlModes.findIndex(x => (x === this.controlMode));
      },

      set(selectedControlModeIdx) {
        this.$emit('update', this.$_allControlModes[selectedControlModeIdx]);
      },
    },

    $_allControlModes() {
      return [
        ControlMode.normal,
        ControlMode.move,
        ControlMode.divide,
        ControlMode.remove,
        ControlMode.zoom,
      ];
    },

    $_isCopyAudioSegmentLabelDisabled() { return !this.isSingleAudioSegmentSelected },

    $_isPasteAudioSegmentLabelDisabled() {
      if (!this.isAudioSegmentLabelCopied) return true;
      if (!this.isAnyAudioSegmentSelected) return true;
      return false;
    },
  },

  inject: [
    'undo',
    'redo',
  ],
  
  methods: {
    onKeydown(keyboardEvent) {
      switch (keyboardEvent.code) {
        case 'Escape':
          return setControlModeNormal(this);
        case 'KeyZ':
          if (keyboardEvent.ctrlKey)  {
            if (keyboardEvent.shiftKey) {
              return this.redo();
            } else {
              return this.undo();
            }
          }
          return false;
        case 'KeyC':
          if (keyboardEvent.ctrlKey)  {
            return this.$_copyAudioSegmentLabel();
          }
          return false;
        case 'KeyV':
          if (keyboardEvent.ctrlKey)  {
            return this.$_pasteAudioSegmentLabel();
          }
          return false;
        default:
          return false;
      }

      function setControlModeNormal(self) {
        if (self.controlMode === ControlMode.normal) return false;
        self.$emit('update', ControlMode.normal);
        return true;
      }
    },

    $_copyAudioSegmentLabel() {
      if (this.$_isCopyAudioSegmentLabelDisabled) return false;
      this.$emit('copy-audio-segment-label');
      return true;
    },

    $_pasteAudioSegmentLabel() {
      if (this.$_isPasteAudioSegmentLabelDisabled) return false;
      this.$emit('paste-audio-segment-label');
      return true;
    },

    $_toggleIsAutoFollowLoopDefinitionEnabled() {
      if (this.isAutoFollowLoopDefinitionEnabled) {
        this.$emit('disable-auto-follow-loop-definition');
      } else {
        this.$emit('enable-auto-follow-loop-definition');
      }
    },

    $_toggleShowsHelp() {
      this.$data.$_showsHelp = !this.$data.$_showsHelp;
    },
  }
}
</script>
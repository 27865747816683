<template>
  <canvas>
  </canvas>
</template>

<script>
import Utils from '../../modules/Utils.js';
import CanvasBase from './mixins/CanvasBase.js';
import WaveformDigest from '../../modules/WaveformDigest.js';
import Color from '../../modules/Color.js';

const waveformColor = new Color(220, 220, 220);

export default {
  mixins: [
    CanvasBase,
  ],

  watch: {
    waveformDigest()          { this.$_setDirty(true); },
    canvasBeginSampleOffset() { this.$_setDirty(true); },
    canvasEndSampleOffset()   { this.$_setDirty(true); },
    canvasDurationSec()       { this.$_setDirty(true); },
  },

  props: {
    waveformDigest:          { type: WaveformDigest },
    canvasBeginSampleOffset: { type: Number },
    canvasEndSampleOffset:   { type: Number },
    canvasDurationSec:       { type: Number },
  },

  computed: {
    $_currentWaveformDigest() {
      if (this.canvasDurationSec === 0) return null;
      let suitableSamplingRate = this.$_canvasWidthPx / this.canvasDurationSec;
      return this.waveformDigest.getDigest(suitableSamplingRate);
    },

    $_drawAreaDomRect() {
      return new DOMRect(0, 0, this.$_canvasWidthPx, this.$_canvasHeightPx);
    },
  },

  methods: {
    draw(canvasElement) {
      let canvasContext = canvasElement.getContext('2d');
      if (!Utils.isNullOrUndefined(this.waveformDigest) && (this.canvasDurationSec > 0)) {
        this.waveformDigest.drawWaveformOnCanvas(
          canvasContext,
          this.$_drawAreaDomRect,
          this.canvasDurationSec,
          this.canvasBeginSampleOffset,
          this.canvasEndSampleOffset,
          { waveformLineWidth: 1, waveformColor: waveformColor.styleString },
        );
      }
    },
  },
}
</script>
export default {
  data() {
    return {
      $_timelineCanvasElement: null,
      $_timelineCanvasContext: null,
      $_timelineCanvasWidthPx: null,
      $_timelineCanvasHeightPx: null,
      $_timelineCanvasResizeObserver: null,
      $_isDirty: false,
      $_isDrawing: false,
      $_isDrawSkipped: false,
    };
  },

  created() {
    this.$data.$_timelineCanvasResizeObserver = new ResizeObserver(this.$_updateTimelineCanvasSize);
  },

  mounted() {
    this.$nextTick(() => {
      this.$data.$_timelineCanvasElement = this.$el;
      this.$data.$_timelineCanvasContext = this.$data.$_timelineCanvasElement.getContext('2d');
      this.$data.$_timelineCanvasResizeObserver.observe(this.$data.$_timelineCanvasElement);
      this.$_updateTimelineCanvasSize();
    });
  },

  destroyed() {
    this.$data.$_timelineCanvasResizeObserver.disconnect();
  },

  methods: {
    $_updateTimelineCanvasSize() {
      let timelineCanvasWidthPx = this.$data.$_timelineCanvasElement.clientWidth;
      let timelineCanvasHeightPx = this.$data.$_timelineCanvasElement.clientHeight;
      this.$data.$_timelineCanvasElement.width = timelineCanvasWidthPx;
      this.$data.$_timelineCanvasElement.height = timelineCanvasHeightPx;
      this.$data.$_timelineCanvasWidthPx = timelineCanvasWidthPx;
      this.$data.$_timelineCanvasHeightPx = timelineCanvasHeightPx;
      this.$_setDirty(true);
    },

    $_setDirty(isDirty) {
      this.$data.$_isDirty = isDirty;
    },

    $_draw(callback) {
      if (this.$data.$_timelineCanvasContext === null) return;
      if (!this.$data.$_isDrawing) {
        this.$data.$_isDrawing = true;
        if (this.$data.$_isDirty || this.$data.$_isDrawSkipped) {
          this.$data.$_timelineCanvasContext.clearRect(
            0,
            0,
            this.$data.$_timelineCanvasWidthPx,
            this.$data.$_timelineCanvasHeightPx,
          );
          callback();
          this.$data.$_isDirty = false;
          this.$data.$_isDrawSkipped = false;
        }
        this.$data.$_isDrawing = false;
      } else {
        this.$data.$_isDrawSkipped = true;
      }
    },
  },
}

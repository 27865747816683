var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pt-1 pr-1", style: _vm.fontSize }, [
    _vm._v(
      " " +
        _vm._s(_vm.currentTimeInFormat) +
        "/" +
        _vm._s(_vm.durationInFormat) +
        " "
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
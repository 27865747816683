var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nano.props
    ? _c(
        "div",
        { attrs: { id: "wrapper" } },
        [
          _c(
            "v-card",
            { staticClass: "my-10" },
            [
              _c(
                "v-system-bar",
                { attrs: { window: "", dark: "", color: "indigo" } },
                [_c("h3", [_vm._v("Your task")])]
              ),
              _c(
                "div",
                {
                  staticClass: "pa-4 text-body-1 font-weight-medium text-left",
                },
                [
                  _vm._v(
                    ' Sort images in the order you think they are "strong". '
                  ),
                ]
              ),
            ],
            1
          ),
          _vm.nano.props
            ? _c(
                "div",
                { key: `ranking${_vm.refreshCnt}` },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center justify-center" },
                    [
                      _c(
                        "div",
                        { staticStyle: { width: "100px" } },
                        [
                          _c(
                            "v-chip",
                            { attrs: { dark: "", color: "indigo" } },
                            [_c("h3", [_vm._v("Rank")])]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "500px" } },
                        [
                          _c(
                            "v-chip",
                            { attrs: { dark: "", color: "indigo" } },
                            [_c("h3", [_vm._v("Image")])]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticStyle: { width: "200px" } }),
                    ]
                  ),
                  _vm._l(_vm.rankingIndex, function (rank, idx) {
                    return _c(
                      "div",
                      { key: `rank${idx}` },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-center justify-center my-4",
                            staticStyle: { "min-height": "150px" },
                          },
                          [
                            _c("div", { staticStyle: { width: "100px" } }, [
                              _c("h2", [_vm._v(_vm._s(idx + 1))]),
                            ]),
                            _c(
                              "div",
                              {
                                staticStyle: { width: "500px", height: "100%" },
                              },
                              [
                                _c("img", {
                                  staticStyle: { "max-height": "200px" },
                                  attrs: { src: _vm.imgPaths[rank] },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              { staticStyle: { width: "200px" } },
                              [
                                idx > 0
                                  ? _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          icon: "",
                                          large: "",
                                          color: "indigo",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.swapRanks(idx, "up")
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", { attrs: { large: "" } }, [
                                          _vm._v("mdi-arrow-up"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("br"),
                                idx < _vm.rankingIndex.length - 1
                                  ? _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          icon: "",
                                          large: "",
                                          color: "indigo",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.swapRanks(idx, "down")
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", { attrs: { large: "" } }, [
                                          _vm._v("mdi-arrow-down"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c("v-divider"),
                      ],
                      1
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
          _c(
            "v-btn",
            {
              attrs: { color: "primary mt-4" },
              on: {
                click: function ($event) {
                  return _vm.submit()
                },
              },
            },
            [
              _c("v-icon", { attrs: { left: "", dark: "" } }, [
                _vm._v("mdi-send-outline"),
              ]),
              _vm._v("submit"),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
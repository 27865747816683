var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shows || _vm.$data.$_isSeeking
    ? _c(
        "v-card",
        _vm._b(
          { attrs: { dark: "", tile: "", id: "video-player-controller" } },
          "v-card",
          _vm.$attrs,
          false
        ),
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "", "data-testid": "cue" },
              on: {
                mousedown: function ($event) {
                  $event.stopPropagation()
                  return _vm.$_seekToHead.apply(null, arguments)
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-skip-previous")])],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { icon: "", "data-testid": "rewind" },
              on: {
                mousedown: function ($event) {
                  $event.stopPropagation()
                  return _vm.$_seekBackwardStart.apply(null, arguments)
                },
                mouseup: function ($event) {
                  $event.stopPropagation()
                  return _vm.$_seekBackwardEnd.apply(null, arguments)
                },
                mouseout: function ($event) {
                  $event.stopPropagation()
                  return _vm.$_seekBackwardEnd.apply(null, arguments)
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-rewind")])],
            1
          ),
          _vm.isPlaying
            ? _c(
                "v-btn",
                {
                  attrs: { icon: "", "data-testid": "pause" },
                  on: {
                    mousedown: function ($event) {
                      $event.stopPropagation()
                      return _vm.$_pause.apply(null, arguments)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-pause")])],
                1
              )
            : _c(
                "v-btn",
                {
                  attrs: { icon: "", "data-testid": "play" },
                  on: {
                    mousedown: function ($event) {
                      $event.stopPropagation()
                      return _vm.$_play.apply(null, arguments)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-play")])],
                1
              ),
          _c(
            "v-btn",
            {
              attrs: { icon: "", "data-testid": "fast-forward" },
              on: {
                mousedown: function ($event) {
                  $event.stopPropagation()
                  return _vm.$_seekForwardStart.apply(null, arguments)
                },
                mouseup: function ($event) {
                  $event.stopPropagation()
                  return _vm.$_seekForwardEnd.apply(null, arguments)
                },
                mouseout: function ($event) {
                  $event.stopPropagation()
                  return _vm.$_seekForwardEnd.apply(null, arguments)
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-fast-forward")])],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { icon: "", "data-testid": "go-to-end" },
              on: {
                mousedown: function ($event) {
                  $event.stopPropagation()
                  return _vm.$_seekToTail.apply(null, arguments)
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-skip-next")])],
            1
          ),
          _c("VideoPlayerSeekBar", {
            staticClass: "mr-2",
            attrs: {
              "current-time-msec": _vm.playTimeMsec,
              "duration-msec": _vm.durationMsec,
              "is-loop-enabled": _vm.isLoopEnabled,
              "loop-definition": _vm.loopDefinition,
              "timeline-marker": _vm.timelineMarker,
            },
            on: {
              seek: _vm.$_seekBySlider,
              "seek-start": _vm.$_seekBySliderStart,
              "seek-end": _vm.$_seekBySliderEnd,
            },
          }),
          _c("VideoPlayerTimeIndicator", {
            attrs: { milliseconds: _vm.playTimeMsec },
          }),
          _c("TextDevider", { attrs: { content: "/", "margin-px": 3 } }),
          _c("VideoPlayerTimeIndicator", {
            attrs: { milliseconds: _vm.durationMsec },
          }),
          _c(
            "v-btn",
            {
              attrs: { icon: "", "data-testid": "loop" },
              on: {
                mousedown: function ($event) {
                  $event.stopPropagation()
                  return _vm.$_toggleEnableLoopFlag.apply(null, arguments)
                },
              },
            },
            [
              _c("v-icon", { class: _vm.$_loopIconClass }, [
                _vm._v("mdi-restore"),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div></div>
</template>
<script>
import nanoMixIn from "@/mixins/nano";
export default {
    mixins: [nanoMixIn],
    data: () => ({
        defaultNanoProps: {
        },
        defaultNanoAnswers: {
        }
    })
};
</script>
<style scoped>
</style>

<template>
  <canvas></canvas>
</template>

<script>
import timelineCanvas from './mixins/timelineCanvas.js'
import { TimelineSegmentId } from '../../../../external/modules/ScattData.js'
import referencePool from '../../../../external/modules/referencePool.js'
import utils from '../../../modules/utils.js'
import canvasUtils from './modules/canvasUtils.js'

const segmentPaddingPx = 2;
const segmentIdWidthPxMinToRenderText = 15;
const segmentIconWidthPxMinToRederText = 15;

export default {
  mixins: [
    timelineCanvas,
  ],

  watch: {
    visibleTimelineDataSet: {
      handler() { this.$_setDirty(true); },
      deep: true,
      immediate: true,
    },
    timelineSegmentDomRects() { this.$_setDirty(true); },
    '$data.$_timelineSegmentReferenceCount'() { this.$_setDirty(true); },
    selectedTimelineSegmentIds() { this.$_setDirty(true); },
  },

  props: {
    rulerVerticalOffsetPx: { type: Number },
    durationMsec: { type: Number },
    verticalOffsetPx: { type: Number },
    timelineHeightPx: { type: Number },
    timeResolution: { type: Number },
    timelineViewBeginMsec: { type: Number },
    selectedTimelineSegmentIds: { type: Array },
    visibleTimelineDataSet: { type: Object },
    visibleTimelineDataIdxToId: { type: Array },
    timelineSegmentDomRects: { type: Object },
  },

  data() {
    return {
      $_timelineSegmentReferenceCount: new Object(),
      $_onReferencePoolRegisterCallbackId: null,
      $_onReferencePoolUnregisterCallbackId: null,
    }
  },

  computed: {
    $_timelineSegmentCanvasDomRect() {
      return new DOMRect(
        0,
        this.rulerVerticalOffsetPx,
        this.$data.$_timelineCanvasWidthPx,
        this.$data.$_timelineCanvasHeightPx - this.rulerVerticalOffsetPx
      );
    },
  },

  created() {
    let self = this;
    {
      incrementRefernceCount(null, Object.values(referencePool.getAllReferences()));
      this.$data.$_onReferencePoolRegisterCallbackId = referencePool.registerOnRegisterCallback(incrementRefernceCount);
      this.$data.$_onReferencePoolUnregisterCallbackId = referencePool.registerOnUnregisterCallback(decrementReferenceCount);
    }

    function incrementRefernceCount(_, registeredReferences) {
      let numRegisteredReferences = registeredReferences.length;
      for (let registeredReferenceIdx = 0; registeredReferenceIdx < numRegisteredReferences; ++registeredReferenceIdx) {
        let registeredReference = registeredReferences[registeredReferenceIdx];
        let timelineDataIdRefered = registeredReference.referTo.timelineDataId;
        let segmentIdRefered = registeredReference.referTo.segmentId;
        if (!Object.keys(self.$data.$_timelineSegmentReferenceCount).includes(timelineDataIdRefered)) {
          self.$set(self.$data.$_timelineSegmentReferenceCount, timelineDataIdRefered, new Object());
        }
        if (!Object.keys(self.$data.$_timelineSegmentReferenceCount[timelineDataIdRefered]).includes(segmentIdRefered)) {
          self.$set(self.$data.$_timelineSegmentReferenceCount[timelineDataIdRefered], segmentIdRefered, 0);
        }
        ++self.$data.$_timelineSegmentReferenceCount[timelineDataIdRefered][segmentIdRefered];
      }
      self.$_setDirty(true);
    }

    function decrementReferenceCount(_, unregisteredReferences) {
      let numUnregisteredReferences = unregisteredReferences.length;
      for (let unregisteredReferenceIdx = 0; unregisteredReferenceIdx < numUnregisteredReferences; ++unregisteredReferenceIdx) {
        let unregisteredReference = unregisteredReferences[unregisteredReferenceIdx];
        let timelineDataIdRefered = unregisteredReference.referTo.timelineDataId;
        let segmentIdRefered = unregisteredReference.referTo.segmentId;
        --self.$data.$_timelineSegmentReferenceCount[timelineDataIdRefered][segmentIdRefered];
        if (self.$data.$_timelineSegmentReferenceCount[timelineDataIdRefered][segmentIdRefered] === 0) {
          self.$delete(self.$data.$_timelineSegmentReferenceCount[timelineDataIdRefered], segmentIdRefered);
        }
        if (Object.keys(self.$data.$_timelineSegmentReferenceCount[timelineDataIdRefered]).length === 0) {
          self.$delete(self.$data.$_timelineSegmentReferenceCount, timelineDataIdRefered);
        }
      }
      self.$_setDirty(true);
    }
  },

  destroyed() {
    referencePool.unregisterOnRegisterCallback(this.$data.$_onReferencePoolRegisterCallbackId);
    referencePool.unregisterOnUnregisterCallback(this.$data.$_onReferencePoolUnregisterCallbackId);
  },

  methods: {
    /* private */
    $_getTimelineSegmentDomRect(timelineDataId, segmentId) {
      if (!Object.keys(this.timelineSegmentDomRects).includes(timelineDataId)) return null;
      if (!Object.keys(this.timelineSegmentDomRects[timelineDataId]).includes(segmentId)) return null;
      return this.timelineSegmentDomRects[timelineDataId][segmentId];
    },

    /* public */
    getOffsetForRectTimelineSegmentToBeInCanvas(timelineSegmentId) {
      let timelineDataId = timelineSegmentId.timelineDataId;
      let segmentId = timelineSegmentId.segmentId;
      let timelineSegmentDomRect = this.$_getTimelineSegmentDomRect(timelineDataId, segmentId);
      if (timelineSegmentDomRect) {
        return utils.getOffsetRectToBeInRect(timelineSegmentDomRect, this.$_timelineSegmentCanvasDomRect);
      } else {
        return null;
      }
    },

    draw() {
      let self = this;
      let timelineSegmentCanvas = this.$data.$_timelineCanvasContext;
      this.$_draw(() => {
        drawSegments();
        clearRulerArea();
      });

      function drawSegments() {
        for(let visibleTimelineDataId of self.visibleTimelineDataIdxToId) {
          let visibleTimelineData = self.visibleTimelineDataSet[visibleTimelineDataId];
          for (let [ segmentId, segment ] of Object.entries(visibleTimelineData.segments)) {
            let isLocked = segment.locked || visibleTimelineData.locked || visibleTimelineData.readonly;
            let timelineSegmentDomRect = self.$_getTimelineSegmentDomRect(visibleTimelineDataId, segmentId);
            if (timelineSegmentDomRect) {
              if (utils.areRectsOverwrapped(self.$_timelineSegmentCanvasDomRect, timelineSegmentDomRect)) {
                drawTimelineSegment(timelineSegmentDomRect,
                  isTimelineSegmentRefered(visibleTimelineDataId, segmentId),
                  isTimelineSegmentSelected(visibleTimelineDataId, segmentId));
                drawContentsOnTimelineSegment(timelineSegmentDomRect, segmentId, segment.data, isLocked);
              }
            }
          }
        }

        function drawTimelineSegment(timelineSegmentDomRect, isRefered, isSelected) {
          {
            timelineSegmentCanvas.beginPath();
            timelineSegmentCanvas.rect(...canvasUtils.dotByDotOffsetRectArgs(
              timelineSegmentDomRect.x,
              timelineSegmentDomRect.y,
              timelineSegmentDomRect.width,
              timelineSegmentDomRect.height,
            ));
            timelineSegmentCanvas.fillStyle = utils.generateCSSRgbaColorString(...getRgbColorOfSegmentBackground(isRefered, isSelected), 0.5);
            timelineSegmentCanvas.fill();
            timelineSegmentCanvas.setLineDash([]);
            timelineSegmentCanvas.lineWidth = 1;
            timelineSegmentCanvas.strokeStyle = utils.generateCSSRgbaColorString(...getRgbColorOfSegmentBorder(isSelected), 0.5);
            timelineSegmentCanvas.stroke();
          }

          function getRgbColorOfSegmentBackground(isRefered, isSelected) {
            if (isRefered) {
              return (isSelected)? [ 155, 106, 106 ] : [ 206, 144, 144 ];
            } else {
              return (isSelected)? [ 134, 192, 215 ] : [ 220, 248, 251 ];
            }
          }

          function getRgbColorOfSegmentBorder(isSelected) {
            if (isRefered) {
              return (isSelected)? [ 110, 68, 68 ] : [ 170, 110, 110 ];
            } else {
              return (isSelected)? [ 100, 160, 200 ] : [ 190, 210, 220 ];
            }
          }
        }

        function drawContentsOnTimelineSegment(timelineSegmentDomRect, segmentId, data, isLocked) {
          {
            let segmentIdFontSizePx = 8;
            let segmentIdLeftX = timelineSegmentDomRect.x + segmentPaddingPx;
            let segmentIdTopY = timelineSegmentDomRect.y + segmentPaddingPx;
            let segmentIdWidthPxMax = timelineSegmentDomRect.x + timelineSegmentDomRect.width - segmentIdLeftX;
            let iconFontSizePx = 8;
            let iconLeftX = timelineSegmentDomRect.x + segmentPaddingPx;
            let iconBottomY = timelineSegmentDomRect.y + timelineSegmentDomRect.height - segmentPaddingPx;
            let iconWidthPxMax = timelineSegmentDomRect.x + timelineSegmentDomRect.width - iconLeftX;
            timelineSegmentCanvas.fillStyle = utils.generateCSSRgbaColorString(0, 0, 0, 1);
            timelineSegmentCanvas.textAlign = 'left';
            timelineSegmentCanvas.textBaseline = 'top';
            if (segmentIdWidthPxMax > segmentIdWidthPxMinToRenderText) {
              timelineSegmentCanvas.font = 'normal ' + String(segmentIdFontSizePx) + 'px sans-serif';
              timelineSegmentCanvas.fillText('[' + segmentId + ']', segmentIdLeftX, segmentIdTopY, segmentIdWidthPxMax);
            }
            timelineSegmentCanvas.textBaseline = 'bottom';
            if (isLocked && (iconWidthPxMax > segmentIconWidthPxMinToRederText)) {
              timelineSegmentCanvas.font = 'normal ' + String(iconFontSizePx) + 'px material-design-icons';
              timelineSegmentCanvas.fillText('lock', iconLeftX, iconBottomY, iconWidthPxMax);
            }
            data.drawOnTimelineSegment({
              canvas2d: timelineSegmentCanvas,
              rect: timelineSegmentDomRect,
              paddingPx: segmentPaddingPx,
            });
          }
        }

        function isTimelineSegmentRefered(timelineDataIdRefered, segmentIdRefered) {
          if (!Object.keys(self.$data.$_timelineSegmentReferenceCount).includes(timelineDataIdRefered)) {
            return false;
          }
          if (!Object.keys(self.$data.$_timelineSegmentReferenceCount[timelineDataIdRefered]).includes(segmentIdRefered)) {
            return false;
          }
          return true;
        }

        function isTimelineSegmentSelected(timelineDataIdRefered, segmentIdRefered) {
          let timelineSegmentId = new TimelineSegmentId(timelineDataIdRefered, segmentIdRefered);
          for (let selectedTimelineSegmentId of self.selectedTimelineSegmentIds) {
            if (selectedTimelineSegmentId.isSame(timelineSegmentId)) {
              return true;
            }
          }
          return false;
        }
      }

      function clearRulerArea() {
        timelineSegmentCanvas.clearRect(
          0,
          0,
          self.$data.$_timelineCanvasWidthPx,
          self.rulerVerticalOffsetPx,
        );
      }
    },
  }
};
</script>

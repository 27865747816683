var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.audioDeviceId && _vm.videoDeviceId,
          expression: "audioDeviceId && videoDeviceId",
        },
      ],
      attrs: { id: "wrapper" },
    },
    [
      _c("t-video-recorder", {
        attrs: {
          "audio-device-id": _vm.audioDeviceId,
          "video-device-id": _vm.videoDeviceId,
          "always-show-camera": false,
          "mime-type": _vm.mimeType,
          "max-width": "500px",
        },
        on: {
          "device-enumerate": _vm.onDeviceEnumerate,
          "set-audio-device-id": _vm.onSetAudioDeviceId,
          "set-video-device-id": _vm.onSetVideoDeviceId,
          "stand-by": _vm.onStandBy,
          start: _vm.onRecordStart,
          pause: _vm.onRecordPause,
          resume: _vm.onRecordResume,
          stop: _vm.onRecordStop,
          clear: _vm.onClear,
        },
        scopedSlots: _vm._u([
          {
            key: "camera-preview",
            fn: function ({ media }) {
              return [
                _c(
                  "div",
                  {
                    staticStyle: { position: "relative" },
                    style: { zIndex: _vm.isPlayMode ? 10 : "auto" },
                  },
                  [
                    media
                      ? _c("video", {
                          attrs: {
                            id: "player",
                            src: media.url,
                            type: media.metadata.mimeType,
                            controls: _vm.isPlayMode,
                            playsinline: "",
                          },
                        })
                      : _vm._e(),
                    _vm.isPlayMode
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              top: "0",
                              left: "0",
                              width: "100%",
                            },
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "ma-2",
                                attrs: { large: "", dark: "", icon: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.isPlayMode = false
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            },
          },
          {
            key: "overlay",
            fn: function ({
              isReady,
              recorderState,
              media,
              start,
              stop,
              pause,
              resume,
              clear,
            }) {
              return [
                _c(
                  "v-overlay",
                  {
                    attrs: {
                      value: _vm.shownDevicesMenu !== null,
                      absolute: "",
                      dark: false,
                    },
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-list",
                          {
                            staticClass: "overflow-y-auto",
                            attrs: { dense: "", "max-height": "200" },
                          },
                          _vm._l(
                            _vm.deviceItems[_vm.shownDevicesMenu],
                            function (item, i) {
                              return _c(
                                "v-list-item",
                                {
                                  key: `device-item-${i}`,
                                  on: {
                                    click: () => {
                                      if (_vm.shownDevicesMenu === "audio")
                                        _vm.audioDeviceId = item.value
                                      else if (_vm.shownDevicesMenu === "video")
                                        _vm.videoDeviceId = item.value
                                      _vm.shownDevicesMenu = null
                                    },
                                  },
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(item.text)),
                                  ]),
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      (_vm.shownDevicesMenu === "audio" &&
                                        item.value === _vm.audioDeviceId) ||
                                      (_vm.shownDevicesMenu === "video" &&
                                        item.value === _vm.videoDeviceId)
                                        ? _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("mdi-check")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                isReady && !_vm.isPlayMode
                  ? _c("div", { staticClass: "camera-overlay" }, [
                      _c("div", { staticClass: "flex-grow-1" }),
                      _c(
                        "div",
                        { staticClass: "camera-action-buttons" },
                        [
                          recorderState === "inactive" && media === null
                            ? [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          small: "",
                                          fab: "",
                                          outlined: "",
                                          dark: "",
                                          color: "white",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.shownDevicesMenu = "video"
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-camera-flip-outline"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          fab: "",
                                          dark: "",
                                          color: "red",
                                        },
                                        on: { click: start },
                                      },
                                      [_c("v-icon", [_vm._v("mdi-circle")])],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          small: "",
                                          fab: "",
                                          outlined: "",
                                          dark: "",
                                          color: "white",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.shownDevicesMenu = "audio"
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-microphone-settings"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : recorderState === "recording"
                            ? [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          small: "",
                                          fab: "",
                                          dark: "",
                                          outlined: "",
                                        },
                                        on: { click: pause },
                                      },
                                      [_c("v-icon", [_vm._v("mdi-pause")])],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          fab: "",
                                          dark: "",
                                          color: "red",
                                        },
                                        on: { click: stop },
                                      },
                                      [_c("v-icon", [_vm._v("mdi-stop")])],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("div"),
                              ]
                            : recorderState === "paused"
                            ? [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          small: "",
                                          fab: "",
                                          outlined: "",
                                          color: "white",
                                        },
                                        on: { click: resume },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "red" } },
                                          [_vm._v("mdi-circle")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          small: "",
                                          fab: "",
                                          dark: "",
                                          color: "red",
                                        },
                                        on: { click: stop },
                                      },
                                      [_c("v-icon", [_vm._v("mdi-stop")])],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("div"),
                              ]
                            : recorderState === "inactive" && media !== null
                            ? [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          small: "",
                                          fab: "",
                                          outlined: "",
                                          color: "white",
                                        },
                                        on: { click: clear },
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-arrow-u-left-top"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          fab: "",
                                          dark: "",
                                          color: "red",
                                          loading: _vm.submitting,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.uploadAndSubmit(media)
                                          },
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("mdi-check")])],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          fab: "",
                                          outlined: "",
                                          dark: "",
                                          small: "",
                                          color: "white",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.isPlayMode = true
                                          },
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("mdi-play")])],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
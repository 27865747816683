<template>
  <div
    id="app-main-container"
    v-on:mousedown="onMousedown"
    v-on:mousemove="onMousemove"
    v-on:mouseup="onMouseup"
  >
    <v-overlay absolute v-bind:value="isMediaLoading">
      <v-progress-circular indeterminate />
    </v-overlay>

    <AppBar v-bind="externalProps">
      <template v-slot:default>
        <slot name="app-bar" v-bind="externalProps" />
      </template>
    </AppBar>

    <div
      id="video-and-editor-view-container"
      ref="videoAndEditorViewContainer"
      v-show="loadedConfig.showVideoAndEditorView"
      v-bind:style="videoAndEditorViewContainerStyle"
    >
      <div
        id="video-player-container"
        v-bind:style="videoPlayerContainerStyle"
      >
        <VideoPlayer
          v-bind:video-source="videoSource"
          v-bind:seek-time-msec="seekTimeMsec"
          v-bind:is-video-playing="isVideoPlaying"
          v-bind:is-loop-enabled="isLoopEnabled"
          v-bind:loop-definition="loopDefinition"
          v-bind:timeline-marker="timelineMarker"
          v-bind:audio-only="audioOnly"
          v-on:meta-data-loaded="onMetaDataLoaded"
          v-on:play-info-updated="onPlayInfoUpdated"
          v-on:play="playVideo"
          v-on:pause="pauseVideo"
          v-on:enable-loop="enableLoopFlag"
          v-on:disable-loop="disableLoopFlag"
          v-on:playback-fixed="generatePlaybackHistory"
        />
      </div>
      <div id="editor-view-container">
        <EditorView
          v-bind:timeline-data-set="scattData.timelineDataSet"
          v-bind:selected-timeline-segment-ids="selectedTimelineSegmentIds"
          v-bind:annotator-id="annotatorId"
          v-bind:annotator-name="annotatorName"
          v-bind:editor-definitions="editorDefinitions"
        />
      </div>
    </div>
    <div
      v-show="loadedConfig.showTimelineView && loadedConfig.showVideoAndEditorView"
      id="horizontal-view-separator"
      ref="horizontalViewSeparator"
      class="border-common"
    />
    <TimelineListView
      ref="timelineListView"
      v-show="loadedConfig.showTimelineView"
      v-bind:duration-msec="durationMsec"
      v-bind:play-time-msec="playTimeMsec"
      v-bind:is-video-playing="isVideoPlaying"
      v-bind:visible-timeline-data-set="visibleTimelineDataSet"
      v-bind:visible-timeline-data-idx-to-id="visibleTimelineDataIdxToId"
      v-bind:sorted-visible-segment-idx-to-id-maps="sortedVisibleSegmentIdxToIdMaps"
      v-bind:visible-segment-id-to-sorted-idx-maps="visibleSegmentIdToSortedIdxMaps"
      v-bind:waveform-digest="waveformDigest"
      v-bind:minimum-segment-duration-msec="minimumSegmentDurationMsec"
      v-bind:selected-timeline-segment-ids="selectedTimelineSegmentIds"
      v-bind:is-any-selected-timeline-segment-immutable="isAnySelectedTimelineSegmentImmutable"
      v-bind:is-loop-enabled="isLoopEnabled"
      v-bind:is-mouse-mode="loadedConfig.isMouseMode"
      v-bind:is-snap-enabled="loadedConfig.isSnapEnabled"
      v-bind:loop-definition="loopDefinition"
      v-bind:timeline-marker="timelineMarker"
      v-bind:segment-data-types="mergedSegmentDataTypes"
      v-bind:timeline-title-width-px="loadedConfig.timelineTitleWidthPx"
      v-on:update-timeline-title-width-px="updateTimelineTitleWidthPx"
      v-on:update-is-mouse-mode="updateIsMouseMode"
      v-on:update-is-snap-enabled="updateIsSnapEnabled"
    />
    <Footer
      v-bind:tool-title="toolTitle"
      v-bind:messages="footerMessages"
      v-bind:version="toolSemanticVersionString"
    />
    <component
      v-bind="dialogProps"
      v-bind:shows="showsDialog"
      v-bind:is="dialogComponentName"
    >
    </component>
  </div>
</template>

<style>
#app-main-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#video-and-editor-view-container {
  display: flex;
  flex: auto;
  flex-direction: row;
}

#editor-view-container,
#video-player-container {
  height: 100%;
}

#editor-view-container {
  flex: auto;
}

#horizontal-view-separator {
  border-top-width: 5px;
  border-top-style: solid;
  width: 100%;
  height: 5px;
  cursor: ns-resize;
  user-select: none;
}
</style>

<style src="./Scatt/internal/styles/common.css" scoped />

<script>
import AppBar from './Scatt/internal/components/AppBar.vue';
import EditorView from './Scatt/internal/components/EditorView.vue';
import TimelineListView from './Scatt/internal/components/TimelineListView.vue';
import VideoPlayer from './Scatt/internal/components/VideoPlayer.vue';
import Footer from './Scatt/internal/components/Footer.vue';
import { FooterMessages } from './Scatt/internal/components/Footer.vue';
import GeneralTextFieldEditor from './Scatt/internal/components/Dialogs/GeneralTextFieldEditor.vue';
import TimelineSegmentEditor from './Scatt/internal/components/Dialogs/TimelineSegmentEditor.vue';
import TimelineDataFilter from './Scatt/internal/components/Dialogs/TimelineDataFilter.vue';
import TimelineDataSelector from './Scatt/internal/components/Dialogs/TimelineDataSelector.vue';
import ViewFilter from './Scatt/internal/components/Dialogs/ViewFilter.vue';
import HelpMessage from './Scatt/internal/components/Dialogs/HelpMessage.vue';
import GeneralConfirmation from './Scatt/internal/components/Dialogs/GeneralConfirmation.vue';
import GeneralMessage from './Scatt/internal/components/Dialogs/GeneralMessage.vue';
import TimelineDurationEditor from './Scatt/internal/components/Dialogs/TimelineDurationEditor.vue';
import referencePool from './Scatt/external/modules/referencePool.js';
import { Reference } from './Scatt/external/modules/referencePool.js';
import changeHistory from './Scatt/internal/modules/changeHistory.js';
import { ScattData, TimelineDataSet, TimelineData, TimelineSegmentId } from './Scatt/external/modules/ScattData.js';
import LabelSegmentData from './Scatt/external/modules/segmentDataTypes/LabelSegmentData.js';
import NoteSegmentData from './Scatt/external/modules/segmentDataTypes/NoteSegmentData.js';
import PlaybackHistorySegmentData from './Scatt/external/modules/segmentDataTypes/PlaybackHistorySegmentData.js';
import utils from './Scatt/internal/modules/utils.js';
import TimelineMarker from './Scatt/internal/modules/TimelineMarker.js'
import LoopDefinition from './Scatt/internal/modules/LoopDefinition.js'
import providedFunctions from './Scatt/external/modules/providedFunctions.js'

import TimelineLabelEnumeratorDefinition from './Scatt/external/components/Editors/TimelineLabelEnumeratorDefinition.js'
import NoteEditorDefinition from './Scatt/external/components/Editors/NoteEditorDefinition.js'
import InspectorDefinition from './Scatt/external/components/Editors/InspectorDefinition.js'
import WaveformDigest from './Scatt/internal/modules/WaveformDigest';

const toolTitle = 'Scatt'

const toolSemanticVersionMajor = 0;
const toolSemanticVersionMinor = 17;
const toolSemanticVersionPatch = 8;
const toolSemanticVersionString = [ toolSemanticVersionMajor, toolSemanticVersionMinor, toolSemanticVersionPatch ].map(x => String(x)).join('.');

const scattConfigKey = 'scattConfig';

const minimumSegmentDurationMsec = 10;
const videoAndEditorViewHeightPxMin = 100;
const videoAndEditorViewBottomMarginPxMin = 100;
const defaultVideoAndEditorViewHeightPx = 420;
const timelineTitleDefaultWidthPx = 200;

const mediaLoadingStatus = {
  notLoaded: 'notLoaded',
  loading: 'loading',
  loaded: 'loaded',
};

const defaultEditorDefinitions = [
  TimelineLabelEnumeratorDefinition,
  NoteEditorDefinition,
  InspectorDefinition,
];

class ScattConfig {
  constructor(
    showVideoAndEditorView,
    showTimelineView,
    videoAndEditorViewHeightPx,
    videoPlayerWidthPercentage,
    timelineTitleWidthPx,
    isMouseMode,
    isSnapEnabled,
  ) {
    this.showVideoAndEditorView = showVideoAndEditorView;
    this.showTimelineView = showTimelineView;
    this.videoAndEditorViewHeightPx = videoAndEditorViewHeightPx;
    this.videoPlayerWidthPercentage = videoPlayerWidthPercentage;
    this.timelineTitleWidthPx = timelineTitleWidthPx;
    this.isMouseMode = isMouseMode;
    this.isSnapEnabled = isSnapEnabled;
  }

  static get default() {
    return new ScattConfig(
      true,
      true,
      defaultVideoAndEditorViewHeightPx,
      50,
      timelineTitleDefaultWidthPx,
      false,
      true,
    );
  }
}

export {
  toolSemanticVersionString,
  ScattConfig,
};

export default {
  name: 'App',

  components: {
    AppBar,
    TimelineListView,
    VideoPlayer,
    EditorView,
    Footer,
    GeneralTextFieldEditor,
    TimelineSegmentEditor,
    TimelineDataFilter,
    TimelineDataSelector,
    ViewFilter,
    HelpMessage,
    GeneralConfirmation,
    GeneralMessage,
    TimelineDurationEditor,
  },

  created() {
    window.addEventListener('beforeunload', this.confirmClosingWindow);
    this.appMainContainerReserveObserver = new ResizeObserver(this.onResize);
    changeHistory.initialize(this.scattData);
  },

  mounted() {
    this.enableInputEventListener();
    this.appMainContainerReserveObserver.observe(this.$el);
    window.onresize = this.onResize;
  },

  destroyed() {
    window.onresize = null;
    this.disableInputEventListener();
    this.appMainContainerReserveObserver.disconnect();
    changeHistory.finalize();
    window.removeEventListener('beforeunload', this.confirmClosingWindow);
  },

  props: {
    annotatorId: { type: String, default: 'scatt' },
    annotatorName: { type: String, default: 'Scatt' },
    editorDefinitions: { type: Array, default: () => defaultEditorDefinitions },
    segmentDataTypes: { type: Array, default: () => new Array() },
    onKeydownCallback: { type: Function, default: null },
    config: { type: ScattConfig, default: null },
    webAssemblyFileLocation: { type: String, default: '/resources/web-assembly' },
    audioOnly: { type: Boolean, default: false },
  },

  data() {
    return {
      durationMsec: 10000,
      videoDurationMsec: null,
      playTimeMsec: 0,
      footerMessages: new FooterMessages(),
      appMainContainerReserveObserver: null,
      videoAndEditorViewHeightPxAtMousedownOnSeparator: null,
      selectSegmentsCallback: null,
      scattData: ScattData.generateEmpty(),
      selectedTimelineSegmentIds: new Array(),
      timelineSegmentIdCopySource: null,
      videoSource: null,
      waveformDigest: null,
      isCurrentScattDataSameAsLastSaved: true,

      loadedConfig: null,

      clientYAtMousedownOnSeparator: null,

      isUndoTargetEmpty: true,
      isRedoTargetEmpty: true,

      isVideoPlaying: false,
      isLoopEnabled: false,
      loopDefinition: new LoopDefinition(0, 0),
      loopBeginTimeMsec: 0,
      loopEndTimeMsec: 0,
      seekTimeMsec: null,

      timelineMarker: null,

      mediaLoadingStatus: mediaLoadingStatus.notLoaded,

      showsDialog: false,
      dialogComponentName: null,
      dialogProps: null,
    };
  },

  watch: {
    scattData: {
      handler() {
        this.normalizeSelectedTimelineSegmentIds();
      },
      deep: true,
    },

    config: {
      handler(newConfig) {
        if (newConfig === null) {
          let configInLocalStorage = JSON.parse(localStorage.getItem(scattConfigKey));
          if (configInLocalStorage === null) {
            this.loadedConfig = ScattConfig.default;
          } else {
            newConfig = new Object();
            let validConfigKeys = Object.keys(ScattConfig.default);
            let configKeysInLocalStorage = Object.keys(configInLocalStorage);
            for (let validConfigKey of validConfigKeys) {
              if (configKeysInLocalStorage.includes(validConfigKey)) {
                newConfig[validConfigKey] = configInLocalStorage[validConfigKey];
              } else {
                newConfig[validConfigKey] = ScattConfig.default[validConfigKey];
              }
            }
            this.loadedConfig = newConfig;
          }
        } else {
          this.loadedConfig = newConfig;
        }
      },
      deep: true,
      immediate: true,
    },

    loadedConfig: {
      handler(newLoadedConfig) {
        localStorage.setItem(scattConfigKey, JSON.stringify(newLoadedConfig));
      },
      deep: true,
    },
  },

  computed: {
    toolTitle() { return toolTitle; },

    isMediaLoading() { return (this.mediaLoadingStatus === mediaLoadingStatus.loading) },

    externalProps() {
      return {
        callback: {
          undoChange: this.undoChange,
          redoChange: this.redoChange,
          showViewFilter: this.showViewFilter,
          showHelp: this.showHelp,
          registerFooterMessage: this.registerFooterMessage,
          loadMediaFile: this.loadMediaFile,
          loadMediaData: this.loadMediaData,
          loadScattFile: this.loadScattFile,
          loadScattData: this.loadScattData,
          saveScattData: this.saveScattData,
          getCurrentScattData: this.getCurrentScattData,
          initializeDialog: this.initializeDialog,
          finalizeDialog: this.finalizeDialog,
        },
        state: {
          isCurrentScattDataSameAsLastSaved: this.isCurrentScattDataSameAsLastSaved,
          isUndoTargetEmpty: this.isUndoTargetEmpty,
          isRedoTargetEmpty: this.isRedoTargetEmpty,
        },
      };
    },

    minimumSegmentDurationMsec() { return minimumSegmentDurationMsec; },

    visibleTimelineDataSet() {
      let visibleTimelineDataSet = new TimelineDataSet();
      for (let [ timelineDataId, timelineData ] of Object.entries(this.scattData.timelineDataSet)) {
        if (timelineData.hidden) continue;
        visibleTimelineDataSet[timelineDataId] = timelineData;
      }
      return visibleTimelineDataSet;
    },

    visibleTimelineDataIdxToId() {
      return Object.keys(this.visibleTimelineDataSet)
        .sort((timelineDataIdA, timelineDataIdB) => {
          let timelineDataA = this.visibleTimelineDataSet[timelineDataIdA];
          let timelineDataB = this.visibleTimelineDataSet[timelineDataIdB];
          return (timelineDataA.timelineDataIdx - timelineDataB.timelineDataIdx);
        });
    },

    sortedVisibleSegmentIdxToIdMaps() {
      let sortedSegmentIdxToIdMaps = new Object();
      for (let [ timelineDataId, timelineData ] of Object.entries(this.visibleTimelineDataSet)) {
        let segments = timelineData.segments;
        sortedSegmentIdxToIdMaps[timelineDataId] = Object.keys(segments)
          .sort(timelineData.getBeginTimeAscendingOrderIdFunction());
      }
      return sortedSegmentIdxToIdMaps;
    },

    visibleSegmentIdToSortedIdxMaps() {
      let visibleSegmentIdToSortedIdxMaps = new Object();
      for (let [ timelineDataId, sortedVisibleSegmentIdxToIdMap ] of Object.entries(this.sortedVisibleSegmentIdxToIdMaps)) {
        visibleSegmentIdToSortedIdxMaps[timelineDataId] = new Object();
        sortedVisibleSegmentIdxToIdMap.forEach((segmentId, sortedSegmentIdx) => {
          visibleSegmentIdToSortedIdxMaps[timelineDataId][segmentId] = sortedSegmentIdx;
        })
      }
      return visibleSegmentIdToSortedIdxMaps;
    },

    timelineDataIdOfNoteByCurrentUser() {
      return this.scattData.timelineDataSet.ids.find((timelineDataId) => {
        let timelineData = this.scattData.timelineDataSet[timelineDataId];
        if (timelineData.segmentDataType === NoteSegmentData) {
          return timelineData.authorId === this.annotatorId;
        }
      });
    },

    timelineDataIdOfPlaybackHistoryByCurrentUser() {
      return this.scattData.timelineDataSet.ids.find((timelineDataId) => {
        let timelineData = this.scattData.timelineDataSet[timelineDataId];
        if (timelineData.segmentDataType === PlaybackHistorySegmentData) {
          return timelineData.authorId === this.annotatorId;
        }
      });
    },

    toolSemanticVersionString() { return toolSemanticVersionString; },

    videoAndEditorViewContainerStyle() {
      let style = new Object();
      if (this.loadedConfig.showTimelineView) {
        let height = String(this.loadedConfig.videoAndEditorViewHeightPx) + 'px';
        style['minHeight'] = height;
        style['maxHeight'] = height;
      }
      return style;
    },

    videoPlayerContainerStyle() {
      return {
        minWidth: String(this.loadedConfig.videoPlayerWidthPercentage) + '%',
        maxWidth: String(this.loadedConfig.videoPlayerWidthPercentage) + '%',
      };
    },

    isTimelineMarkerSet() {
      return (this.timelineMarker !== null);
    },

    timelineMarkerDurationMsec() {
      if (!this.isTimelineMarkerSet) return null;
      return this.timelineMarker.durationMsec;
    },

    mergedSegmentDataTypes() {
      let segmentDataTypes = this.segmentDataTypes;
      if (!segmentDataTypes.includes(LabelSegmentData)) segmentDataTypes.push(LabelSegmentData);
      if (!segmentDataTypes.includes(NoteSegmentData)) segmentDataTypes.push(NoteSegmentData);
      return segmentDataTypes;
    },

    isAnySelectedTimelineSegmentImmutable() {
      for (let timelineSegmentId of this.selectedTimelineSegmentIds) {
        if (this.scattData.timelineDataSet.isTimelineSegmentImmutable(timelineSegmentId)) return true;
      }
      return false;
    },
  },

  provide() {
    let mergedProvidedFunctions = {
      generateNewTimelineDataFromExistingTimelines: this.generateNewTimelineDataFromExistingTimelines,
      incrementVisibleTimelineDataIdx: this.incrementVisibleTimelineDataIdx,
      filterTimelineData: this.filterTimelineData,
      selectNextTimelineSegment: this.selectNextTimelineSegment,
      selectPreviousTimelineSegment: this.selectPreviousTimelineSegment,
      showDurationEditor: this.showDurationEditor,
      showViewFilter: this.showViewFilter,
      showHelp: this.showHelp,
      showVersion: this.showVersion,
      showFooterMessageLogs: this.showFooterMessageLogs,
      undoChange: this.undoChange,
      redoChange: this.redoChange,
    };
    for (let providedFunctionName of providedFunctions) {
      mergedProvidedFunctions[providedFunctionName] = this[providedFunctionName];
    }
    return mergedProvidedFunctions;
  },

  methods: {
    onMetaDataLoaded(metaData) {
      let durationMsec = metaData.durationMsec;
      this.updateTimelineListViewHeight();
      this.durationMsec = durationMsec;
      this.videoDurationMsec = durationMsec;
      this.loopDefinition.beginTimeMsec = 0;
      this.loopDefinition.endTimeMsec = durationMsec;
    },

    onPlayInfoUpdated(playInfo) {
      this.playTimeMsec = playInfo.playTimeMsec;
    },

    onResize() {
      this.updateTimelineListViewHeight();
    },

    onMousedown(event) {
      if (utils.isInRect(this.$refs.horizontalViewSeparator.getBoundingClientRect(), event.clientX, event.clientY)) {
        this.clientYAtMousedownOnSeparator = event.clientY;
        this.videoAndEditorViewHeightPxAtMousedownOnSeparator = this.loadedConfig.videoAndEditorViewHeightPx;
      } else {
        this.$refs.timelineListView.onMousedown(event);
      }
    },

    onMousemove(event) {
      if (this.clientYAtMousedownOnSeparator) {
        if (event.which === 0) {
          this.onMouseup(event);
          return;
        }
        let deltaY = event.clientY - this.clientYAtMousedownOnSeparator;
        let videoAndEditorViewHeightPx = this.videoAndEditorViewHeightPxAtMousedownOnSeparator + deltaY;
        videoAndEditorViewHeightPx = utils.clamp(videoAndEditorViewHeightPx, videoAndEditorViewHeightPxMin, window.innerHeight - videoAndEditorViewBottomMarginPxMin);
        this.loadedConfig.videoAndEditorViewHeightPx = videoAndEditorViewHeightPx;
      } else {
        this.$refs.timelineListView.onMousemove(event);
      }
    },

    onMouseup(event) {
      if (this.clientYAtMousedownOnSeparator) {
        this.clientYAtMousedownOnSeparator = null;
        this.videoAndEditorViewHeightPxAtMousedownOnSeparator = null;
      } else {
        this.$refs.timelineListView.onMouseup(event);
      }
    },

    onScroll(event) {
      if (this.$refs.timelineListView.onScroll(event)) {
        event.preventDefault();
      }
    },

    async onKeydown(event) {
      let self = this;
      {
        if (event.code === 'Escape') {
          if (document.activeElement !== document.body) {
            document.activeElement.blur();
            event.preventDefault();
            return;
          }
        }
        let withCtrl = event.ctrlKey;
        let withShift = event.shiftKey;
        if (document.activeElement !== document.body) return;
        if (this.onKeydownCallback) {
          if (await this.onKeydownCallback(event, this.externalProps)) {
            event.preventDefault();
            return;
          }
        }
        switch (event.code) {
        case 'Space':
          (this.isVideoPlaying)? this.pauseVideo() : this.playVideo();
          break;
        case 'BracketLeft':
          if (!this.loadedConfig.showVideoAndEditorView) return;
          spreadVideoArea(true);
          break;
        case 'BracketRight':
          if (!this.loadedConfig.showVideoAndEditorView) return;
          spreadVideoArea(false);
          break;
        case 'KeyV':
          if (!await this.$refs.timelineListView.onKeydown(event)) {
            await this.showViewFilter();
          }
          break;
        case 'KeyS':
          if (!await this.$refs.timelineListView.onKeydown(event)) return;
          break;
        case 'KeyH':
          this.showHelp();
          break;
        case 'KeyZ':
          if (withCtrl) {
            if (!withShift) {
              this.undoChange();
            } else if (withShift) {
              this.redoChange();
            }
          } else {
            if (!await this.$refs.timelineListView.onKeydown(event)) return;
          }
          break;
        default:
          if (!await this.$refs.timelineListView.onKeydown(event)) return;
          break;
        }
        event.preventDefault();
      }

      function spreadVideoArea(inverted) {
        let videoPlayerWidthPercentage = self.loadedConfig.videoPlayerWidthPercentage;
        if (inverted) {
          videoPlayerWidthPercentage = self.loadedConfig.videoPlayerWidthPercentage - 10;
          if (videoPlayerWidthPercentage < 10) {
            videoPlayerWidthPercentage = 10;
          }
        } else {
          videoPlayerWidthPercentage = self.loadedConfig.videoPlayerWidthPercentage + 10;
          if (videoPlayerWidthPercentage > 90) {
            videoPlayerWidthPercentage = 90;
          }
        }
        self.loadedConfig.videoPlayerWidthPercentage = videoPlayerWidthPercentage;
      }
    },

    updateTimelineTitleWidthPx(timelineTitleWidthPx) {
      this.loadedConfig.timelineTitleWidthPx = timelineTitleWidthPx;
    },

    updateIsMouseMode(isMouseMode) {
      this.loadedConfig.isMouseMode = isMouseMode;
    },

    updateIsSnapEnabled(isSnapEnabled) {
      this.loadedConfig.isSnapEnabled = isSnapEnabled;
    },

    normalizeSelectedTimelineSegmentIds() {
      let validTimelineSegmentIds = new Array();
      for (let selectedTimelineSegmentId of this.selectedTimelineSegmentIds) {
        let selectedTimelineData = this.scattData.timelineDataSet[selectedTimelineSegmentId.timelineDataId];
        if (!selectedTimelineData) continue;
        if (selectedTimelineData.hidden) continue;
        if (this.scattData.timelineDataSet.getSegment(selectedTimelineSegmentId)) {
          validTimelineSegmentIds.push(selectedTimelineSegmentId);
        }
      }
      this.selectedTimelineSegmentIds = validTimelineSegmentIds;
    },

    getMinimumBeginSegmentInSelectedTimelineSegments() {
      if (this.isNoTimelineSegmentIdSelected()) return null;
      let initialSelectedTimelineSegmentId = this.selectedTimelineSegmentIds[0];
      let minimumBeginSegment = this.scattData.timelineDataSet.getSegment(initialSelectedTimelineSegmentId);
      let minimumBeginMsec = minimumBeginSegment.begin;
      for(let selectedTimelineSegmentId of this.selectedTimelineSegmentIds) {
        let segment = this.scattData.timelineDataSet.getSegment(selectedTimelineSegmentId);
        if (segment.begin === null) {
          return segment;
        }
        if (segment.begin < minimumBeginMsec) {
          minimumBeginMsec = segment.begin;
          minimumBeginSegment = segment;
        }
      }
      return minimumBeginSegment;
    },

    getMaximumEndSegmentInSelectedTimelineSegments() {
      if (this.isNoTimelineSegmentIdSelected()) return undefined;
      let initialSelectedTimelineSegmentId = this.selectedTimelineSegmentIds[0];
      let maximumEndSegment = this.scattData.timelineDataSet.getSegment(initialSelectedTimelineSegmentId);
      let maximumEndMsec = maximumEndSegment.end;
      for(let selectedTimelineSegmentId of this.selectedTimelineSegmentIds) {
        let segment = this.scattData.timelineDataSet.getSegment(selectedTimelineSegmentId);
        if (segment.end === null) {
          return segment;
        }
        if (segment.end > maximumEndMsec) {
          maximumEndMsec = segment.end;
          maximumEndSegment = segment;
        }
      }
      return maximumEndSegment;
    },

    playVideo() {
      this.isVideoPlaying = true;
    },

    pauseVideo() {
      this.isVideoPlaying = false;
    },

    enableLoopFlag() {
      this.isLoopEnabled = true;
    },

    disableLoopFlag() {
      this.isLoopEnabled = false;
    },

    setLoopFullDuration() {
      this.loopDefinition.beginTimeMsec = 0;
      this.loopDefinition.endTimeMsec = this.durationMsec;
      this.isLoopEnabled = true;
    },

    setLoopByMarker() {
      if (this.isTimelineMarkerSet) {
        this.loopDefinition.beginTimeMsec = (this.timelineMarker.beginTimeMsec === null)? 0 : this.timelineMarker.beginTimeMsec;
        this.loopDefinition.endTimeMsec = (this.timelineMarker.endTimeMsec === null)? this.durationMsec : this.timelineMarker.endTimeMsec;
        this.isLoopEnabled = true;
      }
    },

    setMarker(markerBeginTimeMsec, markerEndTimeMsec) {
      this.timelineMarker = new TimelineMarker(markerBeginTimeMsec, markerEndTimeMsec);
    },

    clearMarker() {
      this.timelineMarker = null;
    },

    generateNoteTimelineDataNameByCurrentUser() {
      return 'Notes - ' + this.annotatorName;
    },

    generatePlaybackHistoryTimelineDataNameByCurrentUser() {
      return 'PlaybackHistory - ' + this.annotatorName;
    },

    generateNoteTimelineDataByCurrentUser() {
      let newTimelineDataId = this.scattData.timelineDataSet.newValidId;
      let newTimelineData = TimelineData.generateEmpty(
        this.generateNoteTimelineDataNameByCurrentUser(),
        NoteSegmentData,
        this.annotatorId,
        this.annotatorName,
        this.scattData.timelineDataSet.nextIdx,
        false,
      );
      newTimelineData.hidden = true;
      this.$set(this.scattData.timelineDataSet, newTimelineDataId, newTimelineData);
      this.registerChange('generating note timeline', 'Note timeline generated.');
    },

    generatePlaybackHistoryTimelineDataByCurrentUser() {
      let newTimelineDataId = this.scattData.timelineDataSet.newValidId;
      let newTimelineData = TimelineData.generateEmpty(
        this.generatePlaybackHistoryTimelineDataNameByCurrentUser(),
        PlaybackHistorySegmentData,
        this.annotatorId,
        this.annotatorName,
        this.scattData.timelineDataSet.nextIdx,
        true,
      );
      newTimelineData.hidden = true;
      this.$set(this.scattData.timelineDataSet, newTimelineDataId, newTimelineData);
    },

    async generateNewTimelineData(timelineSegmentDataType, newDefaultTimelineName, isReadonly) {
      return await new Promise(resolve => {
        let newTimelineDataId = null;
        this.initializeDialog(
          'general-text-field-editor',
          {
            title: 'Timeline data name',
            text: newDefaultTimelineName,
            okCallback: (newTimelineName) => {
              newTimelineDataId = this.generateNewTimelineDataCore(timelineSegmentDataType, newTimelineName, isReadonly);
              this.registerChange(
                'generating new ' + String(timelineSegmentDataType) + ' timeline',
                'New ' + String(timelineSegmentDataType) + ' timeline generated.',
              );
            },
            finalizeCallback: () => {
              this.finalizeDialog();
              resolve(newTimelineDataId);
            },
          }
        );
      });
    },

    generateNewHiddenTimelineData(timelineSegmentDataType, newTimelineName, isReadonly) {
      let newTimelineDataId = this.generateNewTimelineDataCore(timelineSegmentDataType, newTimelineName, isReadonly);
      this.scattData.timelineDataSet[newTimelineDataId].hidden = true;
      this.registerChange(
        'generating new ' + String(timelineSegmentDataType) + ' timeline',
        'New ' + String(timelineSegmentDataType) + ' timeline generated.',
      );
      return newTimelineDataId;
    },

    async generateNewTimelineDataFromExistingTimelines(isReadonly) {
      return await new Promise(resolve => {
        this.initializeDialog(
          'timeline-data-selector',
          {
            timelineDataSet: this.scattData.timelineDataSet,
            okCallback: (timelineSegmentDataType, selectedTimelineDataIds) => {
              this.atomicChanges(
                'generating timeline data from existing timelines',
                'Timeline data generated from existing timelines.',
                () => {
                  let newTimelineName = 'New ' + String(timelineSegmentDataType) + ' Timeline';
                  let newTimelineDataId = this.generateNewTimelineDataCore(timelineSegmentDataType, newTimelineName, isReadonly);
                  if (newTimelineDataId === null) return;
                  let newTimelineData = this.scattData.timelineDataSet[newTimelineDataId];
                  for (let selectedTimelineDataId of selectedTimelineDataIds) {
                    let selectedTimelineData = this.scattData.timelineDataSet[selectedTimelineDataId];
                    for (let segment of Object.values(selectedTimelineData.segments)) {
                      let newSegmentId = newTimelineData.newValidSegmentId;
                      let newTimelineSegmentId = new TimelineSegmentId(newTimelineDataId, newSegmentId);
                      this.setSegmentCore(newTimelineSegmentId, timelineSegmentDataType, segment, false);
                    }
                  }
                  this.registerChange();
                },
              );
            },
            finalizeCallback: () => {
              this.finalizeDialog();
              resolve();
            },
          },
        );
      });
    },

    duplicateTimelineData(timelineDataId) {
      let newTimelineDataId = this.scattData.timelineDataSet.newValidId;
      let newTimelineDataIdx = this.scattData.timelineDataSet.nextIdx;
      let timelineData = this.scattData.timelineDataSet[timelineDataId];
      let timelineDataIdx = timelineData.timelineDataIdx;
      let duplicatedTimelineData = timelineData.clone(false);
      duplicatedTimelineData.timelineDataIdx = newTimelineDataIdx;
      this.$set(this.scattData.timelineDataSet, newTimelineDataId, duplicatedTimelineData);
      this.moveTimelineDataIdxTo(newTimelineDataIdx, timelineDataIdx + 1);
      this.registerChange('duplicating timeline', 'Timeline duplicated.');
    },

    removeTimelineData(timelineDataId) {
      let referenceIdsToDelete = new Array();
      for (let [ referenceId, reference ] of Object.entries(referencePool.getAllReferences())) {
        if ((reference.referFrom.timelineDataId === timelineDataId) || (reference.referTo.timelineDataId === timelineDataId)) {
          referenceIdsToDelete.push(referenceId);
        }
      }
      this.unregisterReferences(...referenceIdsToDelete);
      this.$delete(this.scattData.timelineDataSet, timelineDataId);
      this.registerChange('removing timeline', 'Timeline removed.');
    },

    async renameTimelineData(timelineDataId) {
      let timelineData = this.scattData.timelineDataSet[timelineDataId];
      return await new Promise(resolve => {
        this.initializeDialog(
          'general-text-field-editor',
          {
            title: 'Timeline data name',
            text: timelineData.name,
            okCallback: (res) => {
              timelineData.name = res;
              this.registerChange('renaming timeline', 'Timeline renamed.');
            },
            finalizeCallback: () => {
              this.finalizeDialog();
              resolve();
            },
          }
        );
      });
    },

    incrementVisibleTimelineDataIdx(visibleTimelineDataIdx, inverted) {
      let numVisibleTimelines = Object.keys(this.visibleTimelineDataSet).length;
      let visibleTimelineDataIdxL = (inverted)? (visibleTimelineDataIdx - 1) : visibleTimelineDataIdx;
      if (visibleTimelineDataIdxL < 0) return;
      let visibleTimelineDataIdxH = (inverted)? visibleTimelineDataIdx : (visibleTimelineDataIdx + 1);
      if (visibleTimelineDataIdxH >= numVisibleTimelines) return;
      let timelineDataIdL = this.visibleTimelineDataIdxToId[visibleTimelineDataIdxL];
      let timelineDataIdH = this.visibleTimelineDataIdxToId[visibleTimelineDataIdxH];
      let timelineDataL = this.scattData.timelineDataSet[timelineDataIdL];
      let timelineDataH = this.scattData.timelineDataSet[timelineDataIdH];
      let timelineDataIdxL = timelineDataL.timelineDataIdx;
      let timelineDataIdxH = timelineDataH.timelineDataIdx;
      this.moveTimelineDataIdxTo(timelineDataIdxL, timelineDataIdxH);
      this.registerChange('swapping timelines', 'Timeline swapped.');
    },

    async filterTimelineData() {
      return await new Promise(resolve => {
        this.initializeDialog(
          'timeline-data-filter',
          {
            timelineDataSet: this.scattData.timelineDataSet,
            okCallback: (isTimelineDataHiddenSet) => {
              let isHiddennessUpdated = false;
              for (let [ timelineDataId, isTimelineDataHidden ] of Object.entries(isTimelineDataHiddenSet)) {
                let timelineData = this.scattData.timelineDataSet[timelineDataId];
                if (timelineData.hidden !== isTimelineDataHidden) {
                  timelineData.hidden = isTimelineDataHidden;
                  isHiddennessUpdated = true;
                }
              }
              if (isHiddennessUpdated) {
                this.registerChange('updating timeline filter', 'Timeline filter updated.');
              }
            },
            finalizeCallback: () => {
              this.finalizeDialog();
              resolve();
            },
          },
        )
      });
    },

    hideTimelineData(timelineDataId) {
      let timelineData = this.scattData.timelineDataSet[timelineDataId];
      if (!timelineData.isHidden) {
        timelineData.hidden = true;
        this.registerChange('hide timeline', 'Hid timeline.');
      }
    },

    unhideTimelineData(timelineDataId) {
      let timelineData = this.scattData.timelineDataSet[timelineDataId];
      if (timelineData.isHidden) {
        timelineData.hidden = false;
        this.registerChange('unhide timeline', 'Unhid timeline.');
      }
    },

    lockTimeline(timelineDataId) {
      let timelineData = this.scattData.timelineDataSet[timelineDataId];
      if (!timelineData.locked) {
        timelineData.locked = true;
        this.registerChange('locking timeline', 'Timeline locked');
      }
    },

    unlockTimeline(timelineDataId) {
      let timelineData = this.scattData.timelineDataSet[timelineDataId];
      if (timelineData.locked) {
        timelineData.locked = false;
        this.registerChange('unlocking timeline', 'Timeline unlocked.');
      }
    },

    selectTimelineSegments(...timelineSegmentIds) {
      for (let timelineSegmentId of timelineSegmentIds) {
        if (!this.isTimelineSegmentIdSelected(timelineSegmentId)) {
          this.selectedTimelineSegmentIds.push(timelineSegmentId);
        }
      }
    },

    selectTimelineSegmentAndSeek(timelineSegmentId) {
      let timelineSegment = this.scattData.timelineDataSet.getSegment(timelineSegmentId);
      this.seekInMsec(timelineSegment.begin);
      this.$refs.timelineListView.selectTimelineSegment(timelineSegmentId);
    },

    selectNextTimelineSegment() {
      this.$refs.timelineListView.selectNextSegment();
    },

    selectPreviousTimelineSegment() {
      this.$refs.timelineListView.selectPreviousSegment();
    },

    isTimelineSegmentIdSelected(timelineSegmentId) {
      for (let selectedTimelineSegmentId of this.selectedTimelineSegmentIds) {
        if (selectedTimelineSegmentId.isSame(timelineSegmentId)) return true;
      }
      return false;
    },

    clearSelectedTimelineSegments() {
      this.selectedTimelineSegmentIds = new Array();
    },

    isNoTimelineSegmentIdSelected() {
      return (this.selectedTimelineSegmentIds.length === 0);
    },

    getOnlyOneSelectedTimelineSegmentId() {
      if (this.selectedTimelineSegmentIds.length === 1) {
        return this.selectedTimelineSegmentIds[0];
      } else {
        return null;
      }
    },

    async invokeActionOnSelectedTimelineSegments(action) {
      let self = this;
      {
        let numTimelineSegments = this.selectedTimelineSegmentIds.length;
        if (numTimelineSegments === 0) return;
        let minimumBeginInSelectedTimelineSegmentIds = this.getMinimumBeginSegmentInSelectedTimelineSegments().begin;
        let onlyOneSelectedTimelineSegmentId = this.getOnlyOneSelectedTimelineSegmentId();
        switch(action) {
        case 'general':
          if (this.selectSegmentsCallback) {
            invokeSelectSegmentsCallback(this.selectedTimelineSegmentIds);
          } else {
            this.seekInMsec(minimumBeginInSelectedTimelineSegmentIds);
          }
          break;
        case 'seek':
          this.seekInMsec(minimumBeginInSelectedTimelineSegmentIds);
          break;
        case 'editSegment':
          if (onlyOneSelectedTimelineSegmentId) {
            await editSegment(onlyOneSelectedTimelineSegmentId);
          }
          break;
        case 'deleteSegment':
          this.deleteSegments(this.selectedTimelineSegmentIds);
          break;
        case 'clearSegment':
          this.clearSegments(this.selectedTimelineSegmentIds);
          break;
        case 'mergeSegment':
          this.mergeSegments(this.selectedTimelineSegmentIds);
          break;
        case 'addNote':
          await addNotes(this.selectedTimelineSegmentIds);
          break;
        case 'setLoop':
          setLoopBySelectedSegments(this.selectedTimelineSegmentIds);
          break;
        case 'setMarker':
          setMarkerBySelectedSegments(this.selectedTimelineSegmentIds, false);
          break;
        case 'setExclusiveMarker':
          setMarkerBySelectedSegments(this.selectedTimelineSegmentIds, true);
          break;
        case 'lock':
          setSelectedSegmentsLocked(this.selectedTimelineSegmentIds, true);
          break;
        case 'unlock':
          setSelectedSegmentsLocked(this.selectedTimelineSegmentIds, false);
          break;
        case 'setCopySource':
          if (onlyOneSelectedTimelineSegmentId) {
            setCopySourceBySelectedSegments(onlyOneSelectedTimelineSegmentId);
          }
          break;
        }
      }

      function invokeSelectSegmentsCallback(timelineSegmentIds) {
        self.selectSegmentsCallback(timelineSegmentIds);
      }

      async function editSegment(timelineSegmentId) {
        let timelineData = self.scattData.timelineDataSet[timelineSegmentId.timelineDataId];
        let segment = self.scattData.timelineDataSet.getSegment(timelineSegmentId);
        let isSegmentImmutable = timelineData.isSegmentImmutable(timelineSegmentId.segmentId);
        let visibleSegmentIdToSortedIdxMap = self.visibleSegmentIdToSortedIdxMaps[timelineSegmentId.timelineDataId];
        let sortedSegmentIdx = visibleSegmentIdToSortedIdxMap[timelineSegmentId.segmentId];
        let numSegments = timelineData.numSegments;
        let reopenPreviousSegmentEnabled = (sortedSegmentIdx > 0);
        let reopenNextSegmentEnabled = (sortedSegmentIdx < (numSegments - 1));
        return await new Promise(resolve => {
          self.initializeDialog(
            'timeline-segment-editor',
            {
              timelineDataSet: self.scattData.timelineDataSet,
              minimumSegmentDurationMsec: minimumSegmentDurationMsec,
              targetSegment: segment,
              targetTimelineSegmentId: timelineSegmentId,
              isImmutable: isSegmentImmutable,
              reopenPreviousSegmentEnabled: reopenPreviousSegmentEnabled,
              reopenNextSegmentEnabled: reopenNextSegmentEnabled,
              okCallback: (segmentEdited) => {
                if (self.setSegmentCore(timelineSegmentId, timelineData.segmentDataType, segmentEdited, false)) {
                  self.registerChange('editing segment', 'Segment edited.');
                }
              },
              finalizeCallback: () => {
                self.finalizeDialog();
                resolve();
              },
            },
          );
        });
      }

      async function addNotes(timelineSegmentIdsReferTo) {
        if (!self.timelineDataIdOfNoteByCurrentUser) {
          self.generateNoteTimelineDataByCurrentUser();
        }
        let lastSeletedTimelineSegmentId = timelineSegmentIdsReferTo[timelineSegmentIdsReferTo.length - 1];
        let representativeSelectedTimelineSegment = self.scattData.timelineDataSet.getSegment(lastSeletedTimelineSegmentId);
        let noteTimelineData = self.scattData.timelineDataSet[self.timelineDataIdOfNoteByCurrentUser];
        let noteTimelineDataId = self.timelineDataIdOfNoteByCurrentUser;
        let newNoteSegmentId = noteTimelineData.newValidSegmentId;
        let noteTimelineSegmentId = new TimelineSegmentId(noteTimelineDataId, newNoteSegmentId);
        let emptyNoteSegment = NoteSegmentData.generateEmpty(representativeSelectedTimelineSegment.begin, representativeSelectedTimelineSegment.end);

        return await new Promise(resolve => {
          self.initializeDialog(
            'timeline-segment-editor',
            {
              timelineDataSet: self.scattData.timelineDataSet,
              minimumSegmentDurationMsec: minimumSegmentDurationMsec,
              targetSegment: emptyNoteSegment,
              targetTimelineSegmentId: newNoteSegmentId,
              isImmutable: false,
              reopenPreviousSegmentEnabled: false,
              reopenNextSegmentEnabled: false,
              okCallback: (segment) => {
                self.atomicChanges(
                  'adding note',
                  'Note added.',
                  () => {
                    if (self.setSegmentCore(noteTimelineSegmentId, NoteSegmentData, segment, false)) {
                      self.registerChange();
                      let references = timelineSegmentIdsReferTo
                        .map(timelineSegmentId => new Reference('Note', noteTimelineSegmentId, timelineSegmentId));
                      self.registerReferences(...references);
                    }
                  },
                );
              },
              finalizeCallback: () => {
                self.finalizeDialog();
                resolve();
              },
            },
          );
        });
      }

      function setLoopBySelectedSegments(selectedTimelineSegmentIds) {
        if (selectedTimelineSegmentIds.length > 0) {
          let beginMsecMin = self.getMinimumBeginSegmentInSelectedTimelineSegments().begin;
          let endMsecMax = self.getMaximumEndSegmentInSelectedTimelineSegments().end;
          self.loopDefinition.beginTimeMsec = (beginMsecMin === null)? 0 : beginMsecMin;
          self.loopDefinition.endTimeMsec = (endMsecMax === null)? self.durationMsec : endMsecMax;
          self.isLoopEnabled = true;
        }
      }

      function setMarkerBySelectedSegments(selectedTimelineSegmentIds, isExcludingSegments) {
        if (selectedTimelineSegmentIds.length > 1) {
          let beginMsecSegment = self.getMinimumBeginSegmentInSelectedTimelineSegments();
          let endMsecSegment = self.getMaximumEndSegmentInSelectedTimelineSegments();
          let beginMsec = (isExcludingSegments)? beginMsecSegment.end : beginMsecSegment.begin;
          let endMsec = (isExcludingSegments)? endMsecSegment.begin : endMsecSegment.end;
          if (endMsec < beginMsec) {
            let temp = beginMsec;
            beginMsec = endMsec;
            endMsec = temp;  
          }
          self.setMarker(beginMsec, endMsec);
        } else if (selectedTimelineSegmentIds.length == 1) {
          if (isExcludingSegments) return;
          let onlyOneSelectedTimelineSegmentId = self.getOnlyOneSelectedTimelineSegmentId();
          let onlyOneSelectedSegment = self.scattData.timelineDataSet.getSegment(onlyOneSelectedTimelineSegmentId);
          let beginMsec = onlyOneSelectedSegment.begin;
          let endMsec = onlyOneSelectedSegment.end;
          self.setMarker(beginMsec, endMsec);
        } else {
          self.clearMarker();
        }
      }

      function setSelectedSegmentsLocked(selectedTimelineSegmentIds, isLocked) {
        let isLockedSet = false;
        for(let selectedTimelineSegmentId of selectedTimelineSegmentIds) {
          let segment = self.scattData.timelineDataSet.getSegment(selectedTimelineSegmentId);
          if (segment.locked !== isLocked) {
            segment.locked = isLocked;
            isLockedSet = true;
          }
        }
        if (isLockedSet) {
          self.registerChange(
            ((isLocked)? 'locking' : 'unlocking') + ' segments',
            'Segments ' + ((isLocked)? 'locked' : 'unlocked'),
          );
        }
      }

      function setCopySourceBySelectedSegments(selectedTimelineDataId) {
        self.timelineSegmentIdCopySource = selectedTimelineDataId;
      }
    },

    getTimelineDataImmutableReason(timelineData, ignoresReadonly) {
      if (!ignoresReadonly && timelineData.readonly) {
        return 'Timeline is readonly';
      }
      if (timelineData.locked) {
        return 'Timeline is locked';
      }
      return null;
    },

    getSegmentUnsettableReason(timelineSegmentId, segmentDataType, segment, ignoresReadonly) {
      let timelineData = this.scattData.timelineDataSet[timelineSegmentId.timelineDataId];
      let timelineImmutableReason = this.getTimelineDataImmutableReason(timelineData, ignoresReadonly);
      if (timelineImmutableReason !== null) return timelineImmutableReason;
      if (timelineData.segmentDataType !== segmentDataType) {
        return 'Segment data type does not match.';
      }
      let segmentAlreadyExisting = this.scattData.timelineDataSet.getSegment(timelineSegmentId);
      if (segmentAlreadyExisting) {
        if (segmentAlreadyExisting.locked) {
          return 'Segment is locked.';
        }
      }
      if ((segment.begin !== null) && (segment.end !== null)) {
        let segmentDurationMsec = segment.end - segment.begin;
        if (segmentDurationMsec < minimumSegmentDurationMsec) {
          return 'Segment is too short.';
        }
      }
      return null;
    },

    setSegmentCore(timelineSegmentId, segmentDataType, segment, ignoresReadonly) {
      if (this.getSegmentUnsettableReason(timelineSegmentId, segmentDataType, segment, ignoresReadonly)) return false;
      let segmentAlreadyExisting = this.scattData.timelineDataSet.getSegment(timelineSegmentId);
      if (segmentAlreadyExisting) {
        if (segmentAlreadyExisting.isSame(segment)) return false;
      }
      let timelineDataId = timelineSegmentId.timelineDataId;
      let segmentId = timelineSegmentId.segmentId;
      this.$set(this.scattData.timelineDataSet[timelineDataId].segments, segmentId, segment);
      return true;
    },

    setSegment(
      timelineSegmentId, segmentDataType, segment, footerMessage = null, changeSummary = null,
      { undoCallback = null, redoCallback = null, ignoresReadonly = false } = {})
    {
      let unsettableReason = this.getSegmentUnsettableReason(timelineSegmentId, segmentDataType, segment, ignoresReadonly);
      if (unsettableReason) {
        this.registerFooterMessage(unsettableReason);
        return false;
      }
      if (this.setSegmentCore(timelineSegmentId, segmentDataType, segment, ignoresReadonly)) {
        this.registerChange(changeSummary, footerMessage, { undoCallback, redoCallback });
      }
      return true;
    },

    setNewSegment(
      timelineDataId, segmentDataType, segment, footerMessage = null, changeSummary = null,
      { undoCallback = null, redoCallback = null, ignoresReadonly = false } = {})
    {
      let timelineData = this.scattData.timelineDataSet[timelineDataId];
      let newSegmentId = timelineData.newValidSegmentId;
      let newTimelineSegmentId = new TimelineSegmentId(timelineDataId, newSegmentId);
      this.setSegment(
        newTimelineSegmentId, segmentDataType, segment, footerMessage, changeSummary,
        { undoCallback, redoCallback, ignoresReadonly });
      return newTimelineSegmentId;
    },

    async createSegment(timelineDataId, begin, end) {
      let timelineData = this.scattData.timelineDataSet[timelineDataId];
      let emptySegment = timelineData.segmentDataType.generateEmpty(begin, end);
      let newSegmentId = timelineData.newValidSegmentId;
      let newTimelineSegmentId = new TimelineSegmentId(timelineDataId, newSegmentId);
      let unsettableReason = this.getSegmentUnsettableReason(newTimelineSegmentId, timelineData.segmentDataType, emptySegment, false);
      if (unsettableReason) {
        this.registerFooterMessage(unsettableReason);
        return null;
      }
      return await new Promise(resolve => {
        this.initializeDialog(
          'timeline-segment-editor',
          {
            timelineDataSet: this.scattData.timelineDataSet,
            minimumSegmentDurationMsec: minimumSegmentDurationMsec,
            targetSegment: emptySegment,
            targetTimelineSegmentId: newTimelineSegmentId,
            isImmutable: false,
            reopenPreviousSegmentEnabled: false,
            reopenNextSegmentEnabled: false,
            okCallback: (segmentEdited) => {
              this.setSegmentCore(newTimelineSegmentId, timelineData.segmentDataType, segmentEdited, false);
              this.registerChange('creating segment', 'Segment created.');
            },
            finalizeCallback: (res) => {
              this.finalizeDialog();
              resolve(res);
            },
            cancelledValue: null,
          },
        );
      });
    },

    deleteSegmentsCore(timelineSegmentIds) {
      let referenceIdsToDelete = new Array();
      let deletionCount = 0;
      for (let timelineSegmentId of timelineSegmentIds) {
        if (this.scattData.timelineDataSet.isTimelineSegmentImmutable(timelineSegmentId)) continue;
        for (let [ referenceId, reference ] of Object.entries(referencePool.getAllReferences())) {
          if (reference.referFrom.isSame(timelineSegmentId) || reference.referTo.isSame(timelineSegmentId)) {
            referenceIdsToDelete.push(referenceId);
          }
        }
        ++deletionCount;
      }
      if (deletionCount > 0) {
        if (referenceIdsToDelete.length > 0) {
          this.unregisterReferences(...referenceIdsToDelete);
        }
        for (let timelineSegmentId of timelineSegmentIds) {
          let timelineDataId = timelineSegmentId.timelineDataId;
          let segmentId = timelineSegmentId.segmentId;
          this.$delete(this.scattData.timelineDataSet[timelineDataId].segments, segmentId);
        }
      }
      return deletionCount;
    },

    deleteSegments(timelineSegmentIds) {
      this.atomicChanges(
        'deleting segment',
        'Segment deleted.',
        () => {
          this.deleteSegmentsCore(timelineSegmentIds);
          if (this.registerChange()) {
            this.clearSelectedTimelineSegments();
          }
        },
      );
    },

    clearSegments(timelineSegmentIds) {
      for (let timelineSegmentId of timelineSegmentIds) {
        if (this.scattData.timelineDataSet.isTimelineSegmentImmutable(timelineSegmentId)) continue;
        let timelineData = this.scattData.timelineDataSet[timelineSegmentId.timelineDataId];
        let segment = this.scattData.timelineDataSet.getSegment(timelineSegmentId);
        let newEmptySegment = timelineData.segmentDataType.generateEmpty(segment.begin, segment.end);
        if (!segment.isSame(newEmptySegment)) {
          this.setSegmentCore(timelineSegmentId, timelineData.segmentDataType, newEmptySegment, false);
        }
      }
      this.registerChange('clearing segment', 'Segment cleared.');
    },

    divideSegment(timelineSegmentId, timeMsec) {
      if (!this.isTimelineSegmentIdSelected(timelineSegmentId)) {
        this.clearSelectedTimelineSegments();
        this.selectTimelineSegments(timelineSegmentId);
      }
      let dividedTimelineSegmentIds = new Array();
      for (let selectedTimelineSegmentId of this.selectedTimelineSegmentIds) {
        if (this.scattData.timelineDataSet.isTimelineSegmentImmutable(selectedTimelineSegmentId)) continue;
        let selectedTimelineSegment = this.scattData.timelineDataSet.getSegment(selectedTimelineSegmentId);
        let isTimeMsecEqualsToOrMoreThanSegmentBegin = (selectedTimelineSegment.begin === null)? true : (timeMsec >= selectedTimelineSegment.begin);
        let isTimeMsecLessThanSegmentEnd = (selectedTimelineSegment.end === null)? true : (timeMsec < selectedTimelineSegment.end);
        if (isTimeMsecEqualsToOrMoreThanSegmentBegin && isTimeMsecLessThanSegmentEnd) {
          let isSegmentDurationMsecFirstHalfEqualsToOrMoreThanMinimum =
            (selectedTimelineSegment.begin === null)? true : ((timeMsec - selectedTimelineSegment.begin) >= minimumSegmentDurationMsec);
          let isSegmentDurationMsecSecondHalfEqualsToOrMoreThanMinimum =
            (selectedTimelineSegment.end === null)? true : ((selectedTimelineSegment.end - timeMsec) >= minimumSegmentDurationMsec);
          if (!isSegmentDurationMsecFirstHalfEqualsToOrMoreThanMinimum || !isSegmentDurationMsecSecondHalfEqualsToOrMoreThanMinimum) continue;
          let dividedSegmentFirstHalf = selectedTimelineSegment;
          let dividedSegmentSecondHalf = selectedTimelineSegment.clone(false);
          dividedSegmentFirstHalf.end = timeMsec;
          dividedSegmentSecondHalf.begin = timeMsec;
          let selectedTimelineDataId = selectedTimelineSegmentId.timelineDataId;
          let selectedTimelineData = this.scattData.timelineDataSet[selectedTimelineDataId];
          let newSegmentIdSecondHalf = selectedTimelineData.newValidSegmentId;
          let newTimelineSegmentId = new TimelineSegmentId(selectedTimelineDataId, newSegmentIdSecondHalf);
          this.setSegmentCore(newTimelineSegmentId, selectedTimelineData.segmentDataType, dividedSegmentSecondHalf, false);
          dividedTimelineSegmentIds.push(selectedTimelineSegmentId, newTimelineSegmentId);
        }
      }
      if (this.registerChange('dividing segment', 'Segment divided.')) {
        this.clearSelectedTimelineSegments();
        this.selectTimelineSegments(...dividedTimelineSegmentIds);
      }
    },

    mergeSegments(timelineSegmentIds) {
      let segmentIdsByTimelineDataId = new Object();
      for (let timelineSegmentId of timelineSegmentIds) {
        if (this.scattData.timelineDataSet.isTimelineSegmentImmutable(timelineSegmentId)) continue;
        let timelineDataId = timelineSegmentId.timelineDataId;
        let segmentId = timelineSegmentId.segmentId;
        if (!Object.keys(segmentIdsByTimelineDataId).includes(timelineDataId)) {
          segmentIdsByTimelineDataId[timelineDataId] = new Array();
        }
        segmentIdsByTimelineDataId[timelineDataId].push(segmentId);
      }
      this.atomicChanges(
        'merging segment',
        'Segment merged.',
        () => {
          for (let [ timelineDataId, segmentIds ] of Object.entries(segmentIdsByTimelineDataId)) {
            if (segmentIds.length < 2) continue;
            let timelineData = this.scattData.timelineDataSet[timelineDataId];
            let sortedSegmentIds = segmentIds.sort(timelineData.getBeginTimeAscendingOrderIdFunction());
            let emptySegmentData = timelineData.segmentDataType.generateEmpty(null, null).data;
            let mergedSegmentData = emptySegmentData;
            for (let segmentId of sortedSegmentIds) {
              let segmentData = timelineData.segments[segmentId].data;
              if (segmentData.isSame(emptySegmentData)) continue;
              mergedSegmentData = segmentData;
              break;
            }
            let [ mergedSegmentId, ...deletingSegmentIds ] = sortedSegmentIds;
            let lastSegmentId = sortedSegmentIds[segmentIds.length - 1];
            let lastSegmentEnd = timelineData.segments[lastSegmentId].end;
            timelineData.segments[mergedSegmentId].end = lastSegmentEnd
            timelineData.segments[mergedSegmentId].data = mergedSegmentData;
            let deletingTimelineSegmentIds = deletingSegmentIds.map(segmentId => new TimelineSegmentId(timelineDataId, segmentId));
            this.deleteSegmentsCore(deletingTimelineSegmentIds);
          }
          this.registerChange();
        },
      );
    },

    generateEmptySegment(timelineDataId, begin, end) {
      let emptyTimelineSegmentId = this.generateAndSetEmptySegmentCore(timelineDataId, begin, end, false);
      if (emptyTimelineSegmentId) {
        this.registerChange('generating empty segment', 'Empty segment added.');
      }
      return emptyTimelineSegmentId;
    },

    generateAndSetEmptySegmentCore(timelineDataId, begin, end, ignoresReadonly) {
      let timelineData = this.scattData.timelineDataSet[timelineDataId];
      let segmentDataType = timelineData.segmentDataType;
      let newSegmentId = timelineData.newValidSegmentId;
      let newTimelineSegmentId = new TimelineSegmentId(timelineDataId, newSegmentId);
      let newSegment = segmentDataType.generateEmpty(begin, end);
      let unsettableReason = this.getSegmentUnsettableReason(newTimelineSegmentId, segmentDataType, newSegment, ignoresReadonly);
      if (unsettableReason) {
        this.registerFooterMessage(unsettableReason);
        return null;
      }
      this.setSegmentCore(newTimelineSegmentId, segmentDataType, newSegment, ignoresReadonly);
      return newTimelineSegmentId;
    },

    generateEmptyNoteReferTo(timelineSegmentIdsReferTo) {
      let numTimelineSegmentIdsReferTo = timelineSegmentIdsReferTo.length;
      if (numTimelineSegmentIdsReferTo === 0) return;
      if (!this.timelineDataIdOfNoteByCurrentUser) {
        this.generateNoteTimelineDataByCurrentUser();
      }
      let noteTimelineDataId =this.timelineDataIdOfNoteByCurrentUser;
      let noteTimelineData = this.scattData.timelineDataSet[noteTimelineDataId];
      let referencesToNewNoteSegments = new Array;
      this.atomicChanges(
        'adding note',
        'Note added.',
        () => {
          for (let timelineSegmentIdReferTo of timelineSegmentIdsReferTo) {
            let segmentReferTo = this.scattData.timelineDataSet.getSegment(timelineSegmentIdReferTo);
            let emptyNoteSegmentData = NoteSegmentData.generateEmpty(segmentReferTo.begin, segmentReferTo.end);
            let newNoteSegmentId = noteTimelineData.newValidSegmentId;
            let timelineSegmentIdReferFrom = new TimelineSegmentId(noteTimelineDataId, newNoteSegmentId);
            if (this.setSegmentCore(timelineSegmentIdReferFrom, NoteSegmentData, emptyNoteSegmentData, false)) {
              let reference = new Reference('Note', timelineSegmentIdReferFrom, timelineSegmentIdReferTo);
              referencesToNewNoteSegments.push(reference);
            }
          }
          if (this.registerChange()) {
            this.registerReferences(...referencesToNewNoteSegments);
          }
        },
      );
    },

    generateAndAttachEmptyNote(timelineSegmentIdReferTo, noteSegmentBegin, noteSegmentEnd) {
      let emptyNoteTimelineSegmentId = this.generateEmptyNoteCore(noteSegmentBegin, noteSegmentEnd);
      this.registerChange('adding note', 'Note added.');
      this.registerReferences(new Reference('Note', emptyNoteTimelineSegmentId, timelineSegmentIdReferTo));
    },

    generateEmptyNoteCore(begin, end) {
      if (!this.timelineDataIdOfNoteByCurrentUser) {
        this.generateNoteTimelineDataByCurrentUser();
      }
      return this.generateAndSetEmptySegmentCore(this.timelineDataIdOfNoteByCurrentUser, begin, end, false);
    },

    generatePlaybackHistory(begin, end) {
      if (!this.segmentDataTypes.includes(PlaybackHistorySegmentData)) return;
      if (!this.timelineDataIdOfPlaybackHistoryByCurrentUser) {
        this.generatePlaybackHistoryTimelineDataByCurrentUser();
      }
      return this.generateAndSetEmptySegmentCore(this.timelineDataIdOfPlaybackHistoryByCurrentUser, begin, end, true);
    },

    generateScattDataRawObj() {
      let allReferences = referencePool.getAllReferences();
      return this.scattData.generateRawObj(allReferences);
    },

    undoChange() {
      let result = changeHistory.undo(this.scattData);
      if (result) {
        this.registerFooterMessage('Undo ' + result.summary + ' successful.');
        this.scattData = result.scattData;
        this.updateChangeHistoryStatus();
        if (result.callback) result.callback();
      } else {
        this.registerFooterMessage('Nothing to undo.');
      }
      this.isCurrentScattDataSameAsLastSaved = changeHistory.isSameAsLastSaved();
    },

    redoChange() {
      let result = changeHistory.redo(this.scattData);
      if (result) {
        this.registerFooterMessage('Redo ' + result.summary + ' successful.');
        this.scattData = result.scattData;
        this.updateChangeHistoryStatus();
        if (result.callback) result.callback();
      } else {
        this.registerFooterMessage('Nothing to redo.');
      }
      this.isCurrentScattDataSameAsLastSaved = changeHistory.isSameAsLastSaved();
    },

    registerChange(changeSummary = null, footerMessage = null, { undoCallback = null, redoCallback = null } = {}) {
      if (changeHistory.registerChange(this.scattData, changeSummary, undoCallback, redoCallback)) {
        this.isCurrentScattDataSameAsLastSaved = changeHistory.isSameAsLastSaved();
        this.updateChangeHistoryStatus();
        if (footerMessage !== null) this.registerFooterMessage(footerMessage);
        return true;
      }
      return false;
    },

    atomicChanges(changeSummary, footerMessage, callback) {
      changeHistory.beginAtomicChanges();
      callback();
      if (changeHistory.endAtomicChanges(changeSummary)) {
        this.registerFooterMessage(footerMessage);
      }
    },

    updateChangeHistoryStatus() {
      this.isUndoTargetEmpty = changeHistory.isFirstChange();
      this.isRedoTargetEmpty = changeHistory.isLastChange();
    },

    registerFooterMessage(message) {
      this.footerMessages.push(message);
    },

    moveTimelineDataIdxTo(timelineDataIdxFrom, timelineDataIdxTo) {
      let self = this;
      {
        if (timelineDataIdxFrom === timelineDataIdxTo) return;
        let timelineDataIdFrom = getTimelineDataIdByIdx(timelineDataIdxFrom);
        if (timelineDataIdxFrom < timelineDataIdxTo) {
          let timelineDataIdxDecrementGroupBegin = timelineDataIdxFrom + 1;
          let timelineDataIdxDecrementGroupEnd = timelineDataIdxTo + 1;
          for (let timelineDataIdx = timelineDataIdxDecrementGroupBegin; timelineDataIdx < timelineDataIdxDecrementGroupEnd; ++timelineDataIdx) {
            let timelineDataId = getTimelineDataIdByIdx(timelineDataIdx);
            this.scattData.timelineDataSet[timelineDataId].timelineDataIdx = timelineDataIdx - 1;
          }
        } else {
          let timelineDataIdxIncrementGroupReversedBegin = timelineDataIdxFrom - 1;
          let timelineDataIdxIncrementGroupReversedEnd = timelineDataIdxTo - 1;
          for (let timelineDataIdx = timelineDataIdxIncrementGroupReversedBegin; timelineDataIdx > timelineDataIdxIncrementGroupReversedEnd; --timelineDataIdx) {
            let timelineDataId = getTimelineDataIdByIdx(timelineDataIdx);
            this.scattData.timelineDataSet[timelineDataId].timelineDataIdx = timelineDataIdx + 1;
          }
        }
        this.scattData.timelineDataSet[timelineDataIdFrom].timelineDataIdx = timelineDataIdxTo;
      }

      function getTimelineDataIdByIdx(timelineDataIdx) {
        return Object.keys(self.scattData.timelineDataSet).find(timelineDataId => {
          let timelineData = self.scattData.timelineDataSet[timelineDataId];
          return (timelineData.timelineDataIdx === timelineDataIdx);
        });
      }
    },

    async showViewFilter() {
      return await new Promise(resolve => {
        this.initializeDialog(
          'view-filter',
          {
            showVideoAndEditorView: this.loadedConfig.showVideoAndEditorView,
            showTimelineView: this.loadedConfig.showTimelineView,
            okCallback: (showVideoAndEditorView, showTimelineView) => {
              this.loadedConfig.showVideoAndEditorView = showVideoAndEditorView;
              this.loadedConfig.showTimelineView = showTimelineView;
            },
            finalizeCallback: () => {
              this.finalizeDialog();
              resolve();
            },
          },
        );
      });
    },

    async showDurationEditor() {
      return await new Promise(resolve => {
        this.initializeDialog(
          'timeline-duration-editor',
          {
            durationMsec: this.durationMsec,
            videoDurationMsec: this.videoDurationMsec,
            okCallback: (durationMsec) => {
              this.durationMsec = durationMsec;
              this.registerChange(
                'updating timeline duration',
                'Timeline duration is updated.',
              );
            },
            finalizeCallback: () => {
              this.finalizeDialog();
              resolve();
            },
          }
        );
      });
    },

    async showHelp() {
      return await new Promise(resolve => {
        this.initializeDialog(
          'help-message',
          {
            finalizeCallback: () => {
              this.finalizeDialog();
              resolve();
            },
          },
        );
      });
    },

    async showVersion() {
      return await new Promise(resolve => {
        this.initializeDialog(
          'general-message',
          {
            title: toolTitle,
            messages: [ 'Version: ' + toolSemanticVersionString ],
            finalizeCallback: () => {
              this.finalizeDialog();
              resolve();
            },
          }
        );
      });
    },

    async showFooterMessageLogs() {
      return await new Promise(resolve => {
        this.initializeDialog(
          'general-message',
          {
            title: 'Messages',
            messages: this.footerMessages.logMessages,
            finalizeCallback: () => {
              this.finalizeDialog();
              resolve();
            },
          }
        );
      });
    },

    confirmClosingWindow(event) {
      if (!this.isCurrentScattDataSameAsLastSaved) {
        event.preventDefault();
        event.returnValue = '';
      }
    },

    copySegmentData(copySourceTimelineSegmentId, copyTargetTimelineSegmentIds) {
      let self = this;
      {
        let sourceTimelineSegmentDataType = getTimelineSegmentDataType(copySourceTimelineSegmentId);
        let targetTimelineSegmentDataType = getSingleTimelineSegmentDataType(copyTargetTimelineSegmentIds)
        if (!targetTimelineSegmentDataType) return;
        if (sourceTimelineSegmentDataType !== targetTimelineSegmentDataType) return;
        let copySourceSegment = this.scattData.timelineDataSet.getSegment(copySourceTimelineSegmentId);
        for (let copyTargetTimelineSegmentId of copyTargetTimelineSegmentIds) {
          let copyTargetSegment = this.scattData.timelineDataSet.getSegment(copyTargetTimelineSegmentId);
          let clonedSegment = copySourceSegment.clone(false);
          clonedSegment.begin = copyTargetSegment.begin;
          clonedSegment.end = copyTargetSegment.end;
          this.setSegmentCore(copyTargetTimelineSegmentId, targetTimelineSegmentDataType, clonedSegment, false);
        }
        this.registerChange('copying segment data', 'Segment data copied.');
      }

      function getTimelineSegmentDataType(timelineSegmentId) {
        let timelineData = self.scattData.timelineDataSet[timelineSegmentId.timelineDataId];
        if (!timelineData) return null;
        return timelineData.segmentDataType;
      }

      function getSingleTimelineSegmentDataType(timelineSegmentIds) {
        if (timelineSegmentIds.length === 0) return null;
        let singleSegmentDataType = getTimelineSegmentDataType(timelineSegmentIds[0]);
        for (let timelineSegmentId of timelineSegmentIds) {
          let currentSegmentDataType = getTimelineSegmentDataType(timelineSegmentId);
          if (currentSegmentDataType !== singleSegmentDataType) return null;
        }
        return singleSegmentDataType;
      }
    },

    copySegmentsFromCopySource() {
      if (this.timelineSegmentIdCopySource) {
        if (this.scattData.timelineDataSet.getSegment(this.timelineSegmentIdCopySource)) {
          if (!this.isAnySelectedTimelineSegmentImmutable) {
            this.copySegmentData(this.timelineSegmentIdCopySource, this.selectedTimelineSegmentIds);
          }
        } else {
          this.timelineSegmentIdCopySource = null;
        }
      }
    },

    enableInputEventListener() {
      window.addEventListener('wheel', this.onScroll, { passive: false });
      window.addEventListener('keydown', this.onKeydown, { passive: false });
    },

    disableInputEventListener() {
      window.removeEventListener('wheel', this.onScroll);
      window.removeEventListener('keydown', this.onKeydown);
    },

    registerSelectSegmentsCallback(callback) {
      this.selectSegmentsCallback = callback;
      this.registerFooterMessage('Select-segment callback registered.');
    },

    unregisterSelectSegmentsCallback() {
      this.selectSegmentsCallback = null;
      this.registerFooterMessage('Select-segment callback unregistered.');
    },

    registerReferences(...referenceIds) {
      referencePool.registerReferences(...referenceIds);
    },

    unregisterReferences(...referenceIds) {
      referencePool.unregisterReferences(...referenceIds);
    },

    seekInMsec(playTimeMsec) {
      if (playTimeMsec === null) playTimeMsec = 0;
      this.playTimeMsec = (playTimeMsec < 0)? 0 : playTimeMsec;
      this.seekTimeMsec = playTimeMsec;
      this.$nextTick(() => { this.seekTimeMsec = null });
    },

    updateTimelineListViewHeight() {
      if (this.loadedConfig.showTimelineView) {
        let videoAndEditorViewHeightPx = this.$refs.videoAndEditorViewContainer.getBoundingClientRect().height;
        videoAndEditorViewHeightPx = utils.clamp(videoAndEditorViewHeightPx, videoAndEditorViewHeightPxMin, this.$el.clientHeight - videoAndEditorViewBottomMarginPxMin);
        this.loadedConfig.videoAndEditorViewHeightPx = videoAndEditorViewHeightPx;
      }
    },

    generateNewTimelineDataCore(timelineSegmentDataType, newTimelineName, isReadonly) {
      let newTimelineDataId = this.scattData.timelineDataSet.newValidId;
      let newTimelineDataIdx = this.scattData.timelineDataSet.nextIdx;
      let newTimelineData = TimelineData.generateEmpty(
        newTimelineName,
        timelineSegmentDataType,
        this.annotatorId,
        this.annotatorName,
        newTimelineDataIdx,
        isReadonly,
      );
      this.$set(this.scattData.timelineDataSet, newTimelineDataId, newTimelineData);
      return newTimelineDataId;
    },

    initializeDialog(dialogComponentName, dialogProps = {}) {
      this.dialogComponentName = dialogComponentName;
      this.dialogProps = dialogProps;
      this.showsDialog = true;
      this.disableInputEventListener();
    },

    finalizeDialog() {
      this.enableInputEventListener();
      this.showsDialog = false;
    },

    async loadMediaFile(videoFilePath, waveformDigestFilePath = null) {
      let videoSource = await utils.readFileAsUint8Array(videoFilePath);
      if (waveformDigestFilePath === null) {
        await this.loadMediaData(videoSource);
      } else {
        let waveformDigestData = await utils.readFileAsUint8Array(waveformDigestFilePath);
        await this.loadMediaData(videoSource, waveformDigestData);
      }
    },

    async loadMediaData(videoSource, waveformDigestData = null) {
      this.mediaLoadingStatus = mediaLoadingStatus.loading;
      this.videoSource = videoSource;
      if (waveformDigestData === null) {
        let audioContext = new AudioContext();
        let audioBuffer = await audioContext.decodeAudioData(videoSource.buffer.slice(0));
        try {
          this.waveformDigest = await WaveformDigest.loadFromAudioBufferByWebAssembly(audioBuffer, this.webAssemblyFileLocation);
        } catch (error) {
          if (error instanceof WebAssembly.RuntimeError) {
            console.warn(error);
            this.waveformDigest = WaveformDigest.loadFromAudioBuffer(audioBuffer);
          } else {
            console.error(error);
          }
        }
        audioContext.close();
      } else {
        let waveformDigest = WaveformDigest.load(waveformDigestData);
        this.waveformDigest = waveformDigest;
      }
      this.mediaLoadingStatus = mediaLoadingStatus.loaded;
    },

    async loadScattFile(scattDataFilePath) {
      let scattDataUint8Array = await utils.readFileAsUint8Array(scattDataFilePath);
      let textDecoder = new TextDecoder();
      let scattDataRawObj = JSON.parse(textDecoder.decode(scattDataUint8Array));
      this.loadScattData(scattDataRawObj);
    },

    loadScattData(scattDataRawObj) {
      this.unregisterReferences(...Object.keys(referencePool.getAllReferences()));
      this.registerReferences(...ScattData.loadReferences(scattDataRawObj));
      this.scattData = new ScattData(scattDataRawObj, this.mergedSegmentDataTypes);
      this.registerChange('loading scatt data file', 'Scatt data file loaded.');
    },

    saveScattData() {
      changeHistory.markAsSaved();
      this.isCurrentScattDataSameAsLastSaved = changeHistory.isSameAsLastSaved();
      let scattDataRawObj = this.generateScattDataRawObj();
      this.$emit('save', scattDataRawObj);
      return scattDataRawObj;
    },

    getCurrentScattData() {
      let currentScattDataRawObj = this.generateScattDataRawObj();
      this.$emit('current-data', currentScattDataRawObj);
      return currentScattDataRawObj;
    },
  },
};
</script>

export default {
    data() {
        return {
            nanoMixInFileUploadStatusChangeHandlers: [],
        }
    },
    props: ['nanoMixInFileUploadQueue'],
    computed: {
        fileUploadStatus() {
            return this.nanoMixInFileUploadQueue.map(item => item.status);
        },
        fileUploadPaths() {
            return this.nanoMixInFileUploadQueue.map(item => item.savedPath);
        },
        allFileUploadComplete() {
            return this.fileUploadStatus.every(s => s === 'complete');
        }
    },
    methods: {
        async _addToFileUploadQueue(item) {
            const lastIndex = this.nanoMixInFileUploadQueue.length;
            this.$emit('update:nanoMixInFileUploadQueue', [ ...this.nanoMixInFileUploadQueue, item ]);
            return lastIndex;
        },
        uploadQueuedFile(index) {
            let queue = [ ...this.nanoMixInFileUploadQueue ];
            const file = {
                data: queue[index].data,
                path: queue[index].name,
            };
            const path = queue[index].path;
            this.$emit("upload-file", file, path, index);
            queue[index].status = 'uploading';
            this.$emit('update:nanoMixInFileUploadQueue', queue);
        },
        async addBufferToFileUploadQueue(buffer, path, name, uploadInstantly = false) {
            const f = {
                data: buffer,
                path,
                name,
                status: 'waiting',
                savedPath: '',
            };
            const index = await this._addToFileUploadQueue(f);
            if(uploadInstantly) { this.uploadQueuedFile(index); }
            return index;
        },
        removeFromFileUploadQueue(index) {
            let queue = [ ...this.nanoMixInFileUploadQueue ];
            delete queue[index];
            this.$emit('update:nano-mix-in-upload-queue', queue);
        },

        addFileUploadStatusChangeHandler(handler) {
            this.nanoMixInFileUploadStatusChangeHandlers.push(handler)
            return this.nanoMixInFileUploadStatusChangeHandlers.length-1;
        },
        removeFileUploadStatusChangeHandler(handlerId) {
            delete this.nanoMixInFileUploadStatusChangeHandlers[handlerId];
        },
    },
    watch: {
        fileUploadStatus(newValue, oldValue) {
            if(newValue.length > 0) {
                const index = newValue.findIndex((v,i) => v !== oldValue[i]);
                for(const handler of this.nanoMixInFileUploadStatusChangeHandlers) {
                    handler(index, newValue[index]);
                }
            }
        }
    }
}
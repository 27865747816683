var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      attrs: { id: "editable-text" },
      on: { focusin: _vm.$_onFocusin, focusout: _vm.$_onFocusout },
    },
    [
      _c("input", {
        ref: "inputElement",
        staticClass: "flex-grow-1",
        attrs: {
          id: "text-input",
          autocomplete: "off",
          disabled: _vm.readonly,
          readonly: _vm.$_apparentlyReadonly,
          list: _vm.$_datalistId,
        },
        on: {
          compositionstart: _vm.$_onCompositionstart,
          compositionend: _vm.$_onCompositionend,
          click: function ($event) {
            $event.stopPropagation()
          },
          keydown: _vm.$_onKeydownMayStopPropagation,
          dblclick: _vm.$_onDblclick,
          input: _vm.$_onInput,
          change: _vm.$_onChange,
        },
      }),
      _vm.$_hasOptions
        ? _c(
            "datalist",
            { attrs: { id: _vm.$_datalistId } },
            _vm._l(_vm.options, function (option) {
              return _c("option", { key: option, domProps: { value: option } })
            }),
            0
          )
        : _vm._e(),
      !_vm.simple
        ? [
            _vm.$data.$_isEditing
              ? [
                  _c(
                    "v-btn",
                    {
                      staticClass: "flex-grow-0",
                      attrs: { icon: "", "x-small": "" },
                      on: {
                        click: _vm.$_finishEditing,
                        keydown: function ($event) {
                          return _vm.$_onKeydownOnButtonMayStopPropagation(
                            $event,
                            _vm.$_finishEditing
                          )
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-check")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "flex-grow-0",
                      attrs: { icon: "", "x-small": "" },
                      on: {
                        click: _vm.$_cancelEditing,
                        keydown: function ($event) {
                          return _vm.$_onKeydownOnButtonMayStopPropagation(
                            $event,
                            _vm.$_cancelEditing
                          )
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-cancel")])],
                    1
                  ),
                ]
              : [
                  !_vm.readonly
                    ? _c(
                        "v-btn",
                        {
                          attrs: { icon: "", "x-small": "" },
                          on: {
                            click: _vm.$_startEditing,
                            keydown: function ($event) {
                              return _vm.$_onKeydownOnButtonMayStopPropagation(
                                $event,
                                _vm.$_startEditing
                              )
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-pencil")])],
                        1
                      )
                    : _vm._e(),
                ],
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("t-audio-recorder", {
    attrs: { "device-id": _vm.deviceId },
    on: {
      "device-enumerate": _vm.onDeviceEnumerate,
      "set-device-id": _vm.onSetDeviceId,
      "stand-by": _vm.onStandBy,
      start: _vm.onRecordStart,
      pause: _vm.onRecordPause,
      resume: _vm.onRecordResume,
      stop: _vm.onRecordStop,
      clear: _vm.onClear,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ recording, start, stop, pause, resume, clear }) {
          return [
            _c(
              "v-card",
              { staticClass: "mx-auto my-8", attrs: { width: "500" } },
              [
                !recording
                  ? _c(
                      "v-btn",
                      { attrs: { block: "" }, on: { click: start } },
                      [
                        _c(
                          "v-icon",
                          { attrs: { color: "red", "x-large": "" } },
                          [_vm._v(" mdi-record-rec ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                recording && !_vm.pausing
                  ? _c(
                      "v-btn",
                      { attrs: { block: "" }, on: { click: pause } },
                      [
                        _c("v-icon", { attrs: { dark: "", "x-large": "" } }, [
                          _vm._v(" mdi-pause-circle "),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                recording && !_vm.pausing
                  ? _c(
                      "v-btn",
                      { attrs: { block: "" }, on: { click: stop } },
                      [
                        _c("v-icon", { attrs: { dark: "", "x-large": "" } }, [
                          _vm._v(" mdi-stop-circle "),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                recording && _vm.pausing
                  ? _c(
                      "v-btn",
                      { attrs: { block: "" }, on: { click: resume } },
                      [
                        _c("v-icon", { attrs: { dark: "", "x-large": "" } }, [
                          _vm._v(" mdi-record-rec "),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("v-select", {
                  attrs: {
                    label: "Select your audio device:",
                    items: _vm.deviceItems,
                  },
                  model: {
                    value: _vm.deviceId,
                    callback: function ($$v) {
                      _vm.deviceId = $$v
                    },
                    expression: "deviceId",
                  },
                }),
                _c("v-divider", { staticClass: "mx-4" }),
                _vm.media.url
                  ? _c(
                      "div",
                      [
                        _c("audio", {
                          ref: "player",
                          attrs: {
                            id: "player",
                            src: _vm.media.url,
                            type: _vm.media.metadata.mimeType,
                            controls: "",
                          },
                        }),
                        _c("v-simple-table", [
                          _c("thead", [
                            _c("tr", [
                              _c("th", { staticClass: "text-center" }, [
                                _vm._v(" key "),
                              ]),
                              _c("th", { staticClass: "text-center" }, [
                                _vm._v(" value "),
                              ]),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            [
                              _vm._l(_vm.media.metadata, function (value, key) {
                                return _c("tr", { key: key }, [
                                  _c("td", [_vm._v(_vm._s(key))]),
                                  _c("td", [_vm._v(_vm._s(value))]),
                                ])
                              }),
                              _vm._l(_vm.media.input, function (value, key) {
                                return _c("tr", { key: key }, [
                                  _c("td", [_vm._v(_vm._s(key))]),
                                  _c(
                                    "td",
                                    [
                                      _c("v-text-field", {
                                        ref: key,
                                        refInFor: true,
                                        attrs: { type: "text" },
                                        model: {
                                          value: _vm.media.input[key],
                                          callback: function ($$v) {
                                            _vm.$set(_vm.media.input, key, $$v)
                                          },
                                          expression: "media.input[key]",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              }),
                            ],
                            2
                          ),
                        ]),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              text: "",
                              block: "",
                              href: _vm.media.url,
                              download: _vm.media.input.filename,
                            },
                          },
                          [
                            _c("v-icon", [_vm._v(" mdi-tray-arrow-down ")]),
                            _vm._v(" Download "),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("v-card-text", [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { disabled: !_vm.media.url },
                          on: { click: clear },
                        },
                        [_vm._v("Clear")]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: !_vm.media.url,
                            loading: _vm.submitting,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.uploadAndSubmit()
                            },
                          },
                        },
                        [_vm._v("Submit")]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
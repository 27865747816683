<!-- Your HTML code here -->
<template>   
    <v-card width="500" class="mx-auto my-8">
        <v-card-text>
            <v-file-input
                chips
                multiple
                placeholder="Upload files ..."
                @change="onFileChange"
            ></v-file-input>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn @click="submit()">Submit</v-btn>
            </div>
        </v-card-text>
    </v-card>
</template>

<!-- Your JavaScript code here -->
<script>
import nanoMixIn from "@/mixins/nano";
export default {
    mixins: [nanoMixIn],
    data: () => ({
        defaultNanoProps: {
        },
        defaultNanoAnswers: {
        }
    }),
    methods: {
        onFileChange(files) {
            Promise.all(files.map( (f) => new Promise(
                (resolve, reject) => {
                    if(!f) reject();
                    let fr = new FileReader();
                    fr.onload = () => {
                        resolve({
                            name: f.webkitRelativePath !== '' ? f.webkitRelativePath : f.name,
                            data: fr.result,
                        });
                    }
                    fr.onerror = reject;
                    fr.readAsArrayBuffer(f);
                }
            ) )).then(async (files) => {
                for(const f of files) {
                    await this.addBufferToFileUploadQueue(f.data, 'example-upload-path', f.name);
                }
            });
        }
    }
};
</script>

<!-- Your CSS here -->
<style scoped>   
.card {
    width: 600px;
    margin: 100px auto;
    border: 1px solid black;
    padding: 20px;
    box-sizing: border-box;
}
div.block {
    padding: 15px 0;
}
input[type=text] {
    width: 100%;
    border: 1px solid grey;
    font-size: 1.2em;
    padding: 3px 10px;
    border-radius: 3px;
    box-sizing: border-box;
}
div.next-btn-wrapper {
    padding: 10px 0;
    text-align: right;
}
div.next-btn-wrapper>button {
    width: 100px;
    padding: 10px;
    background-color: #eee;
    border-radius: 5px;
}
div.next-btn-wrapper>button:disabled {
    color: grey;
}
</style>

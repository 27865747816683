var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    { attrs: { icon: "", small: "", href: _vm.src, download: "sample" } },
    [_c("v-icon", { attrs: { color: "#616161" } }, [_vm._v("mdi-download ")])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
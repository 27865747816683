var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("canvas-container", {
    ref: "canvasContainer",
    attrs: {
      "begin-sample-offset": _vm.$_canvasBeginSampleOffset,
      "end-sample-offset": _vm.$_canvasEndSampleOffset,
      "sampling-rate": _vm.audioSegmentSequence.samplingRate,
    },
    on: {
      mousedown: _vm.$_onMousedown,
      wheel: _vm.$_onWheel,
      resize: _vm.$_onResize,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ widthPx, heightPx, durationSec }) {
          return [
            _c("waveform-canvas", {
              attrs: {
                "width-px": widthPx,
                "height-px": heightPx,
                "waveform-digest": _vm.waveformDigest,
                "canvas-begin-sample-offset": _vm.$_canvasBeginSampleOffset,
                "canvas-end-sample-offset": _vm.$_canvasEndSampleOffset,
                "canvas-duration-sec": durationSec,
              },
            }),
            _c("center-bar-canvas", {
              attrs: { "width-px": widthPx, "height-px": heightPx },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticStyle: { "max-width": "1000px" }, attrs: { "pa-10": "" } },
    [
      _c(
        "v-card",
        { staticClass: "my-10" },
        [
          _c(
            "v-system-bar",
            { attrs: { window: "", dark: "", color: "indigo" } },
            [_c("h3", [_vm._v("Your task")])]
          ),
          _c("div", { staticClass: "pa-4 text-body-1 font-weight-medium" }, [
            _vm._v(
              ' Compare two musics and give an answer which sounds more "bright". '
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mx-auto my-6 pa-6" },
        [
          _c(
            "v-row",
            { staticClass: "pt-10", attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("t-audio", {
                    attrs: {
                      src: _vm.nano.props.audioPath1,
                      "display-flags": _vm.audioDisplayFlags,
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "2", align: "center", justify: "center" } },
                [
                  _c("v-icon", { attrs: { "x-large": "" } }, [
                    _vm._v("mdi-arrow-left-right-bold"),
                  ]),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("t-audio", {
                    attrs: {
                      src: _vm.nano.props.audioPath2,
                      "display-flags": _vm.audioDisplayFlags,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ma-10",
                  attrs: { "x-large": "", dark: "", color: "blue darken-1" },
                  on: {
                    click: function ($event) {
                      return _vm.submitWithChoice("A")
                    },
                  },
                },
                [_c("h3", [_vm._v("A")])]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ma-10",
                  attrs: { "x-large": "" },
                  on: {
                    click: function ($event) {
                      return _vm.submitWithChoice("cant_tell")
                    },
                  },
                },
                [_vm._v("Can't tell")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ma-10",
                  attrs: { "x-large": "", dark: "", color: "pink darken-1" },
                  on: {
                    click: function ($event) {
                      return _vm.submitWithChoice("B")
                    },
                  },
                },
                [_c("h3", [_vm._v("B")])]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <v-form ref="noteEditorFilterDateSelectorForm">
      <v-row no-gutters dense>
        <v-col v-bind:cols="cols">
          <v-text-field
            label="year" type="number"
            v-model.number="$data.$_fullYear"
            v-bind:rules="$_rulesFullYear"
            v-on:input="$_validateInput"
          />
        </v-col>
        <v-col v-bind:cols="cols">
          <v-text-field
            label="month" type="number"
            v-model.number="$data.$_month"
            v-bind:rules="$_rulesMonth"
            v-on:input="$_validateInput"
          />
        </v-col>
        <v-col v-bind:cols="cols">
          <v-text-field
            label="date" type="number"
            v-model.number="$data.$_date"
            v-bind:rules="$_rulesDate"
            v-on:input="$_validateInput"
          />
        </v-col>
        <v-col v-bind:cols="cols">
          <v-text-field
            label="hours" type="number"
            v-model.number="$data.$_hours"
            v-bind:rules="$_rulesHours"
            v-on:input="$_validateInput"
          />
        </v-col>
        <v-col v-bind:cols="cols">
          <v-text-field
            label="minutes" type="number"
            v-model.number="$data.$_minutes"
            v-bind:rules="$_rulesMinutes"
            v-on:input="$_validateInput"
          />
        </v-col>
        <v-col v-bind:cols="cols">
          <v-text-field
            label="seconds" type="number"
            v-model.number="$data.$_seconds"
            v-bind:rules="$_rulesSeconds"
            v-on:input="$_validateInput"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
const dateTmp = new Date();
const minFullYear = 1970;
const minMonth = 1;
const maxMonth = 12;
const minDate = 1;
const minHours = 0;
const maxHours = 23;
const minMinutes = 0;
const maxMinutes = 59;
const minSeconds = 0;
const maxSeconds = 59;

export default {
  props: {
    cols: { type: Number, default: 4 }
  },

  data() {
    return {
      $_fullYear: 0,
      $_month: 0,
      $_date: 0,
      $_hours: 0,
      $_minutes: 0,
      $_seconds: 0,
    }
  },

  computed: {
    $_maxDate()     {
      dateTmp.setFullYear(this.$data.$_fullYear);
      dateTmp.setMonth(this.$data.$_month); // (this.$data.$_month - 1) + 1
      dateTmp.setDate(0);
      dateTmp.setHours(0);
      dateTmp.setMinutes(0);
      dateTmp.setSeconds(0);
      return dateTmp.getDate();
    },

    $_rulesFullYear() {
      return [
        this.$_rulesMustNotBeEmpty,
        this.$_rulesMustBeEqualToOrMoreThanMinFullYear,
      ];
    },

    $_rulesMonth() {
      return [
        this.$_rulesMustNotBeEmpty,
        this.$_rulesMustBeEqualToOrMoreThanMinMonth,
        this.$_rulesMustBeEqualToOrLessThanMaxMonth,
      ];
    },

    $_rulesDate() {
      return [
        this.$_rulesMustNotBeEmpty,
        this.$_rulesMustBeEqualToOrMoreThanMinDate,
        this.$_rulesMustBeEqualToOrLessThanMaxDate,
      ];
    },

    $_rulesHours() {
      return [
        this.$_rulesMustNotBeEmpty,
        this.$_rulesMustBeEqualToOrMoreThanMinHours,
        this.$_rulesMustBeEqualToOrLessThanMaxHours,
      ];
    },

    $_rulesMinutes() {
      return [
        this.$_rulesMustNotBeEmpty,
        this.$_rulesMustBeEqualToOrMoreThanMinMinutes,
        this.$_rulesMustBeEqualToOrLessThanMaxMinutes,
      ];
    },

    $_rulesSeconds() {
      return [
        this.$_rulesMustNotBeEmpty,
        this.$_rulesMustBeEqualToOrMoreThanMinSeconds,
        this.$_rulesMustBeEqualToOrLessThanMaxSeconds,
      ];
    },
  },

  methods: {
    /* public */
    getDate() {
      let date = new Date(0);
      date.setFullYear(this.$data.$_fullYear);
      date.setMonth(this.$data.$_month - 1);
      date.setDate(this.$data.$_date);
      date.setHours(this.$data.$_hours);
      date.setMinutes(this.$data.$_minutes);
      date.setSeconds(this.$data.$_seconds);
      return date;
    },

    setDate(date) {
      this.$data.$_fullYear = date.getFullYear();
      this.$data.$_month = date.getMonth() + 1;
      this.$data.$_date = date.getDate();
      this.$data.$_hours = date.getHours();
      this.$data.$_minutes = date.getMinutes();
      this.$data.$_seconds = date.getSeconds();
    },

    /* private */
    $_validateInput() {
      let hasError = !this.$refs.noteEditorFilterDateSelectorForm.validate();
      this.$emit('update-error', hasError);
    },

    $_rulesMustNotBeEmpty(numberOrStringValue) {
      let stringValue = String(numberOrStringValue);
      return (stringValue.length > 0) || 'Must not be empty.';
    },

    $_rulesMustBeEqualToOrMoreThanMinValue(valueName, minValue, numberOrStringValue) {
      let value = Number(numberOrStringValue);
      return (value >= minValue) || (valueName + ' must be equal to or more than ' + minValue + '.');
    },

    $_rulesMustBeEqualToOrLessThanMaxValue(valueName, maxValue, numberOrStringValue) {
      let value = Number(numberOrStringValue);
      return (value <= maxValue) || (valueName + ' must be equal to or less than ' + maxValue + '.');
    },

    $_rulesMustBeEqualToOrMoreThanMinFullYear(numberOrStringFullYear) {
      return this.$_rulesMustBeEqualToOrMoreThanMinValue('FullYear', minFullYear, numberOrStringFullYear);
    },

    $_rulesMustBeEqualToOrMoreThanMinMonth(numberOrStringMonth) {
      return this.$_rulesMustBeEqualToOrMoreThanMinValue('Month', minMonth, numberOrStringMonth);
    },

    $_rulesMustBeEqualToOrLessThanMaxMonth(numberOrStringMonth) {
      return this.$_rulesMustBeEqualToOrLessThanMaxValue('Month', maxMonth, numberOrStringMonth);
    },

    $_rulesMustBeEqualToOrMoreThanMinDate(numberOrStringDate) {
      return this.$_rulesMustBeEqualToOrMoreThanMinValue('Date', minDate, numberOrStringDate);
    },

    $_rulesMustBeEqualToOrLessThanMaxDate(numberOrStringDate) {
      return this.$_rulesMustBeEqualToOrLessThanMaxValue('Date', this.$_maxDate, numberOrStringDate);
    },

    $_rulesMustBeEqualToOrMoreThanMinHours(numberOrStringHours) {
      return this.$_rulesMustBeEqualToOrMoreThanMinValue('Hours', minHours, numberOrStringHours);
    },

    $_rulesMustBeEqualToOrLessThanMaxHours(numberOrStringHours) {
      return this.$_rulesMustBeEqualToOrLessThanMaxValue('Hours', maxHours, numberOrStringHours);
    },

    $_rulesMustBeEqualToOrMoreThanMinMinutes(numberOrStringMinutes) {
      return this.$_rulesMustBeEqualToOrMoreThanMinValue('Minutes', minMinutes, numberOrStringMinutes);
    },

    $_rulesMustBeEqualToOrLessThanMaxMinutes(numberOrStringMinutes) {
      return this.$_rulesMustBeEqualToOrLessThanMaxValue('Minutes', maxMinutes, numberOrStringMinutes);
    },

    $_rulesMustBeEqualToOrMoreThanMinSeconds(numberOrStringSeconds) {
      return this.$_rulesMustBeEqualToOrMoreThanMinValue('Seconds', minSeconds, numberOrStringSeconds);
    },

    $_rulesMustBeEqualToOrLessThanMaxSeconds(numberOrStringSeconds) {
      return this.$_rulesMustBeEqualToOrLessThanMaxValue('Seconds', maxSeconds, numberOrStringSeconds);
    },
  },
};
</script>
<template>
  <v-container>
    <v-select
      multiple persistent-hint
      label="Timeline Data ID"
      v-model="$data.$_currentTimelineDataIds"
      v-bind:items="$_timelineLabelDataIdAndNames"
      v-on:keydown.stop
    />
    <v-data-table
      dense hide-default-footer
      v-if="$data.$_currentTimelineDataIds"
      v-bind:headers="$_timelineSegmentHeaders"
      v-bind:items="$_currentTimelineSegments"
      v-bind:items-per-page="$_currentTimelineSegments.length"
      v-on:click:row="$_onClickRow"
    >
      <template v-slot:item.actions="{ item }">
        <div class="table-actions">
          <v-btn
            icon small class="flex-nowrap"
            v-on:click.stop="$_openDialog(item)"
          >
            <v-icon small>mdi-dock-window</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<style scoped>
.table-actions {
  display: flex;
  flex-wrap: nowrap;
}
</style>

<script>
import { TimelineSegmentId } from '../../../external/modules/ScattData.js'
import LabelSegmentData from '../../../external/modules/segmentDataTypes/LabelSegmentData.js'

export default {
  props: {
    timelineDataSet: { type: Object },
  },

  data() {
    return {
      $_currentTimelineDataIds: new Array(),
    };
  },

  computed: {
    $_timelineLabelDataIdAndNames() {
      let timelineLabelDataIdAndNames = new Array();
      for (let [ timelineDataId, timelineData ] of Object.entries(this.timelineDataSet)) {
        if (timelineData.segmentDataType === LabelSegmentData) {
          timelineLabelDataIdAndNames.push({
            text: '[' + timelineDataId + '] ' + timelineData.name,
            value: timelineDataId,
          });
        }
      }
      return timelineLabelDataIdAndNames;
    },

    $_timelineSegmentHeaders() {
      return [
        { value: 'begin',            text: 'Begin' },
        { value: 'end',              text: 'End' },
        { value: 'timelineDataName', text: 'Timeline' },
        { value: 'label',            text: 'Label' },
        { value: 'actions' },
      ];
    },

    $_currentTimelineSegments() {
      if (!this.$data.$_currentTimelineDataIds) return [];
      let timelineSegmentItems = new Array();
      for (let timelineDataId of this.$data.$_currentTimelineDataIds) {
        let timelineData = this.timelineDataSet[timelineDataId];
        for (let [ segmentId, segment ] of Object.entries(timelineData.segments)) {
          timelineSegmentItems.push({
            begin: segment.begin,
            end: segment.end,
            timelineDataName: timelineData.name,
            label: segment.data,
            timelineSegmentId: new TimelineSegmentId(timelineDataId, segmentId),
          });
        }
      }
      return timelineSegmentItems;
    },
  },

  inject: [
    'invokeActionOnSelectedTimelineSegments',
    'selectTimelineSegments',
    'clearSelectedTimelineSegments',
    'selectTimelineSegmentAndSeek',
  ],

  methods: {
    /* private */
    $_onClickRow(event) { this.selectTimelineSegmentAndSeek(event.timelineSegmentId); },

    $_openDialog(event) {
      this.clearSelectedTimelineSegments();
      this.selectTimelineSegments(event.timelineSegmentId);
      this.invokeActionOnSelectedTimelineSegments('editSegment');
    },
  },
};
</script>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._t("left-icons-prepend"),
      _vm.displayFlags.skipBackward
        ? _c(
            "v-btn",
            { attrs: { icon: "", small: "" }, on: { click: _vm.skipBackward } },
            [
              _c("v-icon", { attrs: { color: "#616161" } }, [
                _vm._v("mdi-skip-backward"),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.displayFlags.rewind
        ? _c(
            "v-btn",
            {
              attrs: { icon: "", small: "" },
              on: {
                mousedown: _vm.startRewindingAudio,
                mouseup: _vm.stopRewindingAudio,
              },
            },
            [
              _c("v-icon", { attrs: { color: "#616161" } }, [
                _vm._v("mdi-rewind"),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.displayFlags.play
        ? _c(
            "v-btn",
            {
              attrs: { icon: "", small: "" },
              on: { click: _vm.playPauseAudio },
            },
            [
              _c("v-icon", { attrs: { color: "#616161" } }, [
                _vm._v(_vm._s(_vm.icon)),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.displayFlags.fastForward
        ? _c(
            "v-btn",
            {
              attrs: { icon: "", small: "" },
              on: {
                mousedown: _vm.startFastForwardingAudio,
                mouseup: _vm.stopFastForwardingAudio,
              },
            },
            [
              _c("v-icon", { attrs: { color: "#616161" } }, [
                _vm._v("mdi-fast-forward"),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.displayFlags.skipForward
        ? _c(
            "v-btn",
            { attrs: { icon: "", small: "" }, on: { click: _vm.skipForward } },
            [
              _c("v-icon", { attrs: { color: "#616161" } }, [
                _vm._v("mdi-skip-forward"),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._t("left-icons-append"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
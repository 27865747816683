var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Scatt", {
    ref: "scatt",
    attrs: { "on-keydown-callback": _vm.onKeydown },
    scopedSlots: _vm._u([
      {
        key: "app-bar",
        fn: function ({ callback, state }) {
          return [
            _c(
              "AppBarButton",
              {
                attrs: { disabled: state.isUndoTargetEmpty },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return callback.undoChange.apply(null, arguments)
                  },
                },
              },
              [_c("v-icon", [_vm._v("mdi-undo")]), _vm._v("Undo ")],
              1
            ),
            _c(
              "AppBarButton",
              {
                attrs: { disabled: state.isRedoTargetEmpty },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return callback.redoChange.apply(null, arguments)
                  },
                },
              },
              [_c("v-icon", [_vm._v("mdi-redo")]), _vm._v("Redo ")],
              1
            ),
            _c(
              "AppBarButton",
              {
                attrs: { disabled: state.isCurrentScattDataSameAsLastSaved },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.showSaveDialog(callback)
                  },
                },
              },
              [_c("v-icon", [_vm._v("mdi-content-save")]), _vm._v("Save ")],
              1
            ),
            _c(
              "AppBarButton",
              {
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.$_submit(callback)
                  },
                },
              },
              [_c("v-icon", [_vm._v("mdi-send")]), _vm._v("Submit ")],
              1
            ),
            _c(
              "AppBarButton",
              {
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return callback.showViewFilter.apply(null, arguments)
                  },
                },
              },
              [
                _c("v-icon", [_vm._v("mdi-format-list-checkbox")]),
                _vm._v("Filter view "),
              ],
              1
            ),
            _c(
              "AppBarButton",
              {
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return callback.showHelp.apply(null, arguments)
                  },
                },
              },
              [_c("v-icon", [_vm._v("mdi-help-circle")]), _vm._v("Help ")],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
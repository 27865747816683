<template>
  <v-card
    v-bind="$attrs"
    dark tile
    id="video-player-controller"
    v-if="shows || $data.$_isSeeking"
  >
    <v-btn
      icon data-testid="cue"
      v-on:mousedown.stop="$_seekToHead"
    >
      <v-icon>mdi-skip-previous</v-icon>
    </v-btn>

    <v-btn
      icon data-testid="rewind"
      v-on:mousedown.stop="$_seekBackwardStart"
      v-on:mouseup.stop="$_seekBackwardEnd"
      v-on:mouseout.stop="$_seekBackwardEnd"
    >
      <v-icon>mdi-rewind</v-icon>
    </v-btn>

    <v-btn
      v-if="isPlaying"
      icon data-testid="pause"
      v-on:mousedown.stop="$_pause"
    >
      <v-icon >mdi-pause</v-icon>
    </v-btn>

    <v-btn
      v-else
      icon data-testid="play"
      v-on:mousedown.stop="$_play"
    >
      <v-icon>mdi-play</v-icon>
    </v-btn>

    <v-btn
      icon data-testid="fast-forward"
      v-on:mousedown.stop="$_seekForwardStart"
      v-on:mouseup.stop="$_seekForwardEnd"
      v-on:mouseout.stop="$_seekForwardEnd"
    >
      <v-icon>mdi-fast-forward</v-icon>
    </v-btn>

    <v-btn
      icon data-testid="go-to-end"
      v-on:mousedown.stop="$_seekToTail"
    >
      <v-icon>mdi-skip-next</v-icon>
    </v-btn>

    <VideoPlayerSeekBar
      class="mr-2"
      v-bind:current-time-msec="playTimeMsec"
      v-bind:duration-msec="durationMsec"
      v-bind:is-loop-enabled="isLoopEnabled"
      v-bind:loop-definition="loopDefinition"
      v-bind:timeline-marker="timelineMarker"
      v-on:seek="$_seekBySlider"
      v-on:seek-start="$_seekBySliderStart"
      v-on:seek-end="$_seekBySliderEnd"
    />

    <VideoPlayerTimeIndicator
      v-bind:milliseconds="playTimeMsec"
    />

    <TextDevider
      content="/"
      v-bind:margin-px="3"
    />

    <VideoPlayerTimeIndicator
      v-bind:milliseconds="durationMsec"
    />

    <v-btn
      icon data-testid="loop"
      v-on:mousedown.stop="$_toggleEnableLoopFlag"
    >
      <v-icon v-bind:class="$_loopIconClass">mdi-restore</v-icon>
    </v-btn>
  </v-card>
</template>

<style scoped>
#video-player-controller {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>

<style src="./video_player.css" scoped />

<script>
import VideoPlayerSeekBar from './VideoPlayerSeekBar.vue';
import VideoPlayerTimeIndicator from './VideoPlayerTimeIndicator.vue';
import TextDevider from '../Parts/TextDivider.vue';
import TimelineMarker from '../../modules/TimelineMarker.js';
import LoopDefinition from '../../modules/LoopDefinition.js'

const seekIntervalTimeMsec = 100;
const seekTimeStepMsec = 1000;

export default {
  components: {
    VideoPlayerSeekBar,
    VideoPlayerTimeIndicator,
    TextDevider,
  },

  props: {
    shows: { type: Boolean, default: false },
    durationMsec: { type: Number, default: 0 },
    playTimeMsec: { type: Number, default: 0 },
    isPlaying: { type: Boolean, default: false },
    isLoopEnabled: { type: Boolean, default: false },
    loopDefinition: { type: LoopDefinition, default: null },
    timelineMarker: { type: TimelineMarker, default: null },
  },

  data() {
    return {
      $_isPlayingOnSeek: null,
      $_isSeeking: false,
      $_seekTargetTimeMsec: null,
      $_intervalId: null,
    };
  },

  computed: {
    $_loopIconClass() {
      return (this.isLoopEnabled)? [ 'loop' ] : null;
    },
  },

  methods: {
    $_seekToHead() {
      this.$emit('seek-in-msec', 0);
    },

    $_seekToTail() {
      this.$emit('seek-in-msec', this.durationMsec);
    },

    $_toggleEnableLoopFlag() {
      (this.isLoopEnabled)? this.$emit('disable-loop') : this.$emit('enable-loop');
    },

    $_play() {
      this.$emit('play');
    },

    $_pause() {
      this.$emit('pause');
    },

    $_seekBackwardStart() {
      if (this.$data.$_isSeeking) return;
      this.$data.$_isSeeking = true;
      this.$data.$_isPlayingOnSeek = this.isPlaying;
      if (this.$data.$_isPlayingOnSeek) this.$_pause();
      this.$data.$_seekTargetTimeMsec = this.playTimeMsec;
      this.$_updateSeekBackwardTargetTime();
      this.$_setInterval(this.$_updateSeekBackwardTargetTime, seekIntervalTimeMsec);
    },

    $_updateSeekBackwardTargetTime() {
      let nextSeekTargetTimeMsec = this.$data.$_seekTargetTimeMsec - seekTimeStepMsec;
      if (nextSeekTargetTimeMsec < 0) nextSeekTargetTimeMsec = 0;
      this.$data.$_seekTargetTimeMsec = nextSeekTargetTimeMsec;
      this.$emit('seek-in-msec', nextSeekTargetTimeMsec);
    },

    $_seekBackwardEnd() {
      if (!this.$data.$_isSeeking) return;
      this.$_clearInterval();
      if (this.$data.$_isPlayingOnSeek) this.$_play();
      this.$data.$_isPlayingOnSeek = null;
      this.$data.$_isSeeking = false;
    },

    $_seekForwardStart() {
      if (this.$data.$_isSeeking) return;
      this.$data.$_isSeeking = true;
      this.$data.$_isPlayingOnSeek = this.isPlaying;
      if (this.$data.$_isPlayingOnSeek) this.$_pause();
      this.$data.$_seekTargetTimeMsec = this.playTimeMsec;
      this.$_updateSeekForwardTargetTime();
      this.$_clearInterval();
      this.$_setInterval(this.$_updateSeekForwardTargetTime, seekIntervalTimeMsec);
    },

    $_updateSeekForwardTargetTime() {
      let nextSeekTargetTimeMsec = this.$data.$_seekTargetTimeMsec + seekTimeStepMsec;
      if (nextSeekTargetTimeMsec > this.durationMsec) nextSeekTargetTimeMsec = this.durationMsec;
      this.$data.$_seekTargetTimeMsec = nextSeekTargetTimeMsec;
      this.$emit('seek-in-msec', nextSeekTargetTimeMsec);
    },

    $_seekForwardEnd() {
      if (!this.$data.$_isSeeking) return;
      this.$_clearInterval();
      if (this.$data.$_isPlayingOnSeek) this.$_play();
      this.$data.$_isPlayingOnSeek = null;
      this.$data.$_isSeeking = false;
    },

    $_seekBySliderStart() {
      this.$data.$_isSeeking = true;
      this.$data.$_isPlayingOnSeek = this.isPlaying;
      if (this.$data.$_isPlayingOnSeek) this.$_pause();
    },

    $_seekBySliderEnd() {
      if (this.$data.$_isPlayingOnSeek) this.$_play();
      this.$data.$_isPlayingOnSeek = null;
      this.$data.$_isSeeking = false;
    },

    $_seekBySlider(timeMsec) {
      if (!this.$data.$_isSeeking) return;
      this.$emit('seek-in-msec', timeMsec);
    },

    $_clearInterval() {
      if (this.$data.$_intervalId !== null) window.clearInterval(this.$data.$_intervalId);
      this.$data.$_intervalId = null;
    },

    $_setInterval(callback, intervalMsec) {
      this.$_clearInterval();
      this.$data.$_intervalId = window.setInterval(callback, intervalMsec);
    }
  },
}
</script>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column" },
    [
      _c("label", { staticClass: "caption" }, [
        _vm._v("Segment Label Sequence"),
      ]),
      _c("editable-text", {
        model: {
          value: _vm.$_transcription,
          callback: function ($$v) {
            _vm.$_transcription = $$v
          },
          expression: "$_transcription",
        },
      }),
      _c(
        "div",
        _vm._l(_vm.labelOptions, function (labelOption) {
          return _c(
            "v-btn",
            {
              key: labelOption,
              attrs: { "x-small": "", outlined: "", rounded: "" },
              on: {
                click: function ($event) {
                  return _vm.$_replaceOrPushLabel(labelOption)
                },
              },
            },
            [_vm._v(" " + _vm._s(labelOption) + " ")]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        absolute: "",
        "offset-y": "",
        transition: "false",
        value: _vm.$data.$_menuVisibility,
        "position-x": _vm.$data.$_menuPositionX,
        "position-y": _vm.$data.$_menuPositionY,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ attrs }) {
            return [
              _c(
                "div",
                _vm._b(
                  {
                    style: {
                      width: _vm.widthPx + "px",
                      height: _vm.heightPx + "px",
                    },
                    attrs: { id: "timeline-clickable-area", tabindex: "1" },
                    on: {
                      blur: _vm.$_closeMenu,
                      keydown: [
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "up", 38, $event.key, [
                              "Up",
                              "ArrowUp",
                            ])
                          )
                            return null
                          $event.stopPropagation()
                          return _vm.$_decrementSelectedIdx.apply(
                            null,
                            arguments
                          )
                        },
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "down", 40, $event.key, [
                              "Down",
                              "ArrowDown",
                            ])
                          )
                            return null
                          $event.stopPropagation()
                          return _vm.$_incrementSelectedIdx.apply(
                            null,
                            arguments
                          )
                        },
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "space", 32, $event.key, [
                              " ",
                              "Spacebar",
                            ])
                          )
                            return null
                          $event.stopPropagation()
                          return _vm.$_selectMenuItem.apply(null, arguments)
                        },
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          $event.stopPropagation()
                          return _vm.$_selectMenuItem.apply(null, arguments)
                        },
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "escape",
                              undefined,
                              $event.key,
                              undefined
                            )
                          )
                            return null
                          $event.stopPropagation()
                          return _vm.$_blurMenu.apply(null, arguments)
                        },
                      ],
                      mouseup: [
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "left", 37, $event.key, [
                              "Left",
                              "ArrowLeft",
                            ])
                          )
                            return null
                          if ("button" in $event && $event.button !== 0)
                            return null
                          return _vm.$_blurMenu.apply(null, arguments)
                        },
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "right", 39, $event.key, [
                              "Right",
                              "ArrowRight",
                            ])
                          )
                            return null
                          if ("button" in $event && $event.button !== 2)
                            return null
                          return _vm.$_openMenu.apply(null, arguments)
                        },
                      ],
                      contextmenu: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  "div",
                  attrs,
                  false
                )
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        { attrs: { dense: "" } },
        _vm._l(_vm.menuContents, function (menuContent, menuContentIdx) {
          return _c(
            "v-list-item",
            {
              key: menuContentIdx,
              attrs: {
                disabled: menuContent.disabled,
                "input-value": _vm.$_isSelected[menuContentIdx],
              },
              on: {
                mousedown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "left", 37, $event.key, [
                      "Left",
                      "ArrowLeft",
                    ])
                  )
                    return null
                  if ("button" in $event && $event.button !== 0) return null
                  $event.stopPropagation()
                  return _vm.$_select(menuContent)
                },
                contextmenu: function ($event) {
                  $event.preventDefault()
                },
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("v-list-item-title", {
                domProps: { textContent: _vm._s(menuContent.name) },
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
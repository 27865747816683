var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    _vm._g(
      _vm._b(
        {
          staticClass: "pa-0",
          attrs: { text: "", "min-width": "0", width: "100%", height: "100%" },
          scopedSlots: _vm._u(
            [
              _vm._l(_vm.$scopedSlots, function (value, name) {
                return {
                  key: name,
                  fn: function (slotProps) {
                    return [_vm._t(name, null, null, slotProps)]
                  },
                }
              }),
            ],
            null,
            true
          ),
        },
        "v-btn",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <dl class="insepctor-definition-list">
    <dt>{{ title }}</dt>
    <dd>
      <template
        v-if="$_isObject(definition)"
      >
        <InspectorDefinition
          v-for="(definition_in_definition, title_in_definition) in definition"
          v-bind:key="title_in_definition"
          v-bind:title="title_in_definition"
          v-bind:definition="definition_in_definition"
        />
      </template>
      <template v-else>{{ definition }}</template>
    </dd>
  </dl>
</template>

<style scoped src="./inspector.css" />

<script>
import InspectorDefinition from './InspectorDefinition.vue'

export default {
  name: 'InspectorDefinition',

  components: {
    InspectorDefinition,
  },

  props: {
    title: { type: String },
    definition: {},
  },

  methods: {
    $_isObject(value) {
      if (value instanceof Date) return false;
      return value instanceof Object;
    },
  },
};
</script>

<template>
  <canvas-container
    v-bind:begin-sample-offset="$_audioSegmentBeginSampleOffset"
    v-bind:end-sample-offset="$_audioSegmentEndSampleOffset"
    v-bind:sampling-rate="audioSegmentSequence.samplingRate"
  >
    <template v-slot:default="{ widthPx, heightPx, durationSec }">
      <waveform-canvas
        v-bind:width-px="widthPx"
        v-bind:height-px="heightPx"
        v-bind:waveform-digest="waveformDigest"
        v-bind:canvas-begin-sample-offset="$_audioSegmentBeginSampleOffset"
        v-bind:canvas-end-sample-offset="$_audioSegmentEndSampleOffset"
        v-bind:canvas-duration-sec="durationSec"
      >
      </waveform-canvas>
    </template>
  </canvas-container>
</template>

<script>
import CanvasContainer from './canvases/CanvasContainer.vue';
import WaveformCanvas from './canvases/WaveformCanvas.vue';
import WaveformDigest from '../modules/WaveformDigest.js';
import AudioSegmentSequence from '../modules/AudioSegmentSequence.js';

export default {
  components: {
    CanvasContainer,
    WaveformCanvas,
  },

  model: {
    prop: 'audioSegmentSequence',
    event: 'update',
  },

  props: {
    audioSegmentSequence: { type: AudioSegmentSequence },
    audioSegmentIdx:      { type: Number },
    waveformDigest:       { type: WaveformDigest },
    beginSampleOffset:    { type: Number },
    endSampleOffset:      { type: Number },
  },

  computed: {
    $_audioSegment() {
      return this.audioSegmentSequence.audioSegments[this.audioSegmentIdx];
    },

    $_audioSegmentBeginSampleOffset() {
      return this.audioSegmentSequence.convertTime(this.$_audioSegment.begin, AudioSegmentSequence.TimeUnit.sample).toNumber() + this.beginSampleOffset;
    },

    $_audioSegmentEndSampleOffset() {
      return this.audioSegmentSequence.convertTime(this.$_audioSegment.end, AudioSegmentSequence.TimeUnit.sample).toNumber() + this.endSampleOffset;
    },
  },

  mounted() {
    this.$emit('register-component-instance', this);
  },

  beforeDestroy() {
    this.$emit('unregister-component-instance');
  },
}
</script>

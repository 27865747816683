var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.deviceId,
          expression: "deviceId",
        },
      ],
      attrs: { id: "wrapper" },
    },
    [
      _c("t-camera", {
        attrs: {
          "device-id": _vm.deviceId,
          "always-show-camera": false,
          "mime-type": _vm.mimeType,
          quality: _vm.quality,
          "flip-camera": _vm.flip,
          "flip-preview": _vm.flip,
          "max-width": "500px",
        },
        on: {
          "device-enumerate": _vm.onDeviceEnumerate,
          "set-device-id": _vm.onSetDeviceId,
          "stand-by": _vm.onStandBy,
          "capture-photo": _vm.onCapturePhoto,
        },
        scopedSlots: _vm._u([
          {
            key: "camera-preview",
            fn: function () {
              return [
                _vm.media
                  ? _c("v-img", { attrs: { src: _vm.media.url } })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "overlay",
            fn: function ({ videoShown, capturePhoto, clear, isStandby }) {
              return [
                _c(
                  "v-overlay",
                  {
                    attrs: { absolute: "", dark: false },
                    model: {
                      value: _vm.devicesMenuShown,
                      callback: function ($$v) {
                        _vm.devicesMenuShown = $$v
                      },
                      expression: "devicesMenuShown",
                    },
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-list",
                          {
                            staticClass: "overflow-y-auto",
                            attrs: { dense: "", "max-height": "200" },
                          },
                          _vm._l(_vm.deviceItems, function (item, i) {
                            return _c(
                              "v-list-item",
                              {
                                key: `device-item-${i}`,
                                on: {
                                  click: function ($event) {
                                    _vm.deviceId = item.value
                                    _vm.devicesMenuShown = false
                                  },
                                },
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(item.text)),
                                ]),
                                _c(
                                  "v-list-item-icon",
                                  [
                                    item.value === _vm.deviceId
                                      ? _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("mdi-check"),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                isStandby
                  ? _c("div", { staticClass: "camera-overlay" }, [
                      _c("div", { staticClass: "flex-grow-1" }),
                      _c("div", { staticClass: "camera-action-buttons" }, [
                        _c(
                          "div",
                          [
                            !_vm.submitting
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      fab: "",
                                      outlined: "",
                                      dark: "",
                                      small: "",
                                      color: "white",
                                    },
                                    on: {
                                      click: () => {
                                        if (videoShown) {
                                          _vm.devicesMenuShown = true
                                        } else {
                                          clear()
                                          _vm.media = null
                                        }
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            videoShown
                                              ? "mdi-camera-flip-outline"
                                              : "mdi-arrow-u-left-top"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  fab: "",
                                  dark: "",
                                  color: "blue",
                                  loading: _vm.submitting,
                                },
                                on: {
                                  click: () => {
                                    if (videoShown) {
                                      capturePhoto()
                                    } else {
                                      _vm.uploadAndSubmit()
                                    }
                                  },
                                },
                              },
                              [
                                !videoShown
                                  ? _c("v-icon", [_vm._v("mdi-check")])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            videoShown && !_vm.submitting
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      fab: "",
                                      outlined: "",
                                      dark: "",
                                      small: "",
                                      color: "white",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.flip = !_vm.flip
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-flip-horizontal"),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export default {
  isString(obj) {
    if (typeof obj === 'string') return true;
    if (obj instanceof String) return true;
    return false;
  },

  isNumber(obj) {
    return ((typeof obj) === 'number');
  },

  isNullOrUndefined(obj) {
    if (obj === null) return true;
    if (obj === undefined) return true;
    return false;
  },

  clamp(x, min, max) {
    x = (x > max)? max : x;
    x = (x < min)? min : x;
    return x;
  },

  cloneArray(array) {
    return [ ...array ];
  },

  getSequentialNumbers(numberOfNumbers) {
    return [ ...Array(numberOfNumbers).keys() ] ;
  },

  mapWithTimes(times, callback) {
    return this.getSequentialNumbers(times).map(number => callback(number));
  },

  areRectsOverwrapped(rect1, rect2) {
    if ((rect1.x + rect1.width) <= rect2.x) return false;
    if (rect1.x >= (rect2.x + rect2.width)) return false;
    if ((rect1.y + rect1.height) <= rect2.y) return false;
    if (rect1.y >= (rect2.y + rect2.height)) return false;
    return true;
  },

  convertWheelEventToHorizontalOffsetPx(wheelEvent) {
    if (wheelEvent.deltaX === 0) {
      return wheelEvent.deltaY;
    } else {
      return wheelEvent.deltaX;
    }
  },
}
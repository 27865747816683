<template>
  <div class="d-flex flex-column">
    <label class="caption">Segment Label Sequence</label>
    <editable-text v-model="$_transcription"></editable-text>
    <div>
      <v-btn
        x-small outlined rounded 
        v-for="labelOption of labelOptions"
        v-bind:key="labelOption"
        v-on:click="$_replaceOrPushLabel(labelOption)"
      >
        {{ labelOption }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import EditableText from './EditableText.vue';

export default {
  components: {
    EditableText,
  },

  model: {
    prop: 'labelSequence',
    event: 'update',
  },

  props: {
    labelSequence: { type: Array },
    labelOptions: { type: Array },
    delimiter: { type: String },
    selectedAudioSegmentIdcs: { type: Array },
  },

  computed: {
    $_transcription: {
      get()              { return this.labelSequence.join(this.delimiter) },
      set(transcription) { this.$emit('update', transcription.split(this.delimiter)) },
    }
  },

  methods: {
    $_replaceOrPushLabel(selectedLabel) {
      if (this.selectedAudioSegmentIdcs.length > 0) {
        let replacedLabelSequence = this.labelSequence.map((label, labelIdx) => {
          if (this.selectedAudioSegmentIdcs.includes(labelIdx)) {
            return selectedLabel;
          } else {
            return label;
          }
        });
        this.$emit('update', replacedLabelSequence);
      } else {
        this.$emit('update', [ ...this.labelSequence, selectedLabel ]);
      }
    },
  },
}
</script>
<template>
  <v-toolbar>
    <audio-player-controller
      ref="audioPlayerController"
      v-bind:duration-sec="audioBuffer.duration"
      v-bind:play-time-sec="$data.$_playTimeSec"
      v-bind:is-playing="$data.$_isPlaying"
      v-bind:is-seeking="$data.$_isSeeking"
      v-bind:is-loop-enabled="isLoopEnabled"
      v-bind:loop-definition="$_loopDefinition"
      v-on:seek-start="$_seekStart"
      v-on:seek-in-sec="$_seekInSec"
      v-on:seek-instantly-in-sec="$_seekInstantlyInSec"
      v-on:seek-end="$_seekEnd"
      v-on:play="$_play"
      v-on:pause="$_pause"
      v-on:enable-loop="enableLoopPlayback"
      v-on:disable-loop="disableLoopPlayback"
    >
    </audio-player-controller>
  </v-toolbar>
</template>

<script>
import AudioPlayerController from './AudioPlayer/AudioPlayerController.vue';
import AudioPlaybackLoopDefinition from './AudioPlayer/modules/AudioPlaybackLoopDefinition.js';

export default {
  components: {
    AudioPlayerController,
  },

  watch: {
    async '$data.$_audioBufferSourceNodePool'(audioBufferSourceNodePool) {
      if (Object.keys(audioBufferSourceNodePool).length === 0) {
        await this.$_suspend();
        this.$_seekInSec(0);
      }
    },

    '$data.$_audioBufferSourceNodesWaitingForStop'(audioBufferSourceNodesWaitingForStop) {
      if (this.$data.$_isPlaying) {
        if (audioBufferSourceNodesWaitingForStop.length === 0) return;
        let audioBufferSourceNodeWaitingForStop = this.$data.$_audioBufferSourceNodesWaitingForStop.pop();
        audioBufferSourceNodeWaitingForStop.stop();
      }
    },

    '$data.$_isPlaying'(isPLaying) {
      if (isPLaying) {
        if (this.$data.$_audioBufferSourceNodesWaitingForStop.length === 0) return;
        let audioBufferSourceNodeWaitingForStop = this.$data.$_audioBufferSourceNodesWaitingForStop.pop();
        audioBufferSourceNodeWaitingForStop.stop();
      }
    },

    isLoopEnabled(isLoopEnabled) {
      if (this.$_latestAudioBufferSourceNode === null) return;
      if (!isLoopEnabled) {
        this.$_seekInSec(this.$_getPlayTimeSec());
      } else {
        this.$_seekInstantlyInSec(this.$_loopDefinition.beginTimeSec);
      }
      this.$_latestAudioBufferSourceNode.loop = isLoopEnabled;
    },

    $_loopDefinition(loopDefinition) {
      if (this.isLoopEnabled) {
        this.$_seekInstantlyInSec(loopDefinition.beginTimeSec);
      }
    },
  },

  props: {
    audioBuffer:    { type: AudioBuffer },
    audioContext:   { type: AudioContext },
    loopDefinition: { type: AudioPlaybackLoopDefinition },
    isLoopEnabled:  { type: Boolean },
  },

  data() {
    return {
      $_previousTimeSec: 0,
      $_playTimeSec: 0,
      $_isPlaying: false,
      $_isSeeking: false,
      $_wasPlayingOnSeek: null,

      $_audioBufferSourceNodePool: new Object(),
      $_audioBufferSourceNodesWaitingForStop: new Array(),
      $_audioBufferSourceNodeStartOffsetSec: 0,
      $_latestAudioBufferSourceNodeId: null,
      $_originOfCurrentTime: 0,
    };
  },

  computed: {
    $_latestAudioBufferSourceNode() {
      if (!Object.keys(this.$data.$_audioBufferSourceNodePool).includes(this.$data.$_latestAudioBufferSourceNodeId)) return null;
      return this.$data.$_audioBufferSourceNodePool[this.$data.$_latestAudioBufferSourceNodeId];
    },

    $_loopDefinition() {
      if (this.$_isApplicableLoopDefinition) {
        return this.loopDefinition;
      } else {
        return new AudioPlaybackLoopDefinition(0, this.audioBuffer.duration);
      }
    },

    $_isApplicableLoopDefinition() {
      if (this.loopDefinition === null) return false;
      if (this.loopDefinition.beginTimeSec < this.loopDefinition.endTimeSec) return true;
      return false;
    },
  },

  mounted() {
    this.$emit('register-component-instance', this);
  },

  async beforeDestroy() {
    if (this.$_latestAudioBufferSourceNode !== null) {
      this.$_requestAudioBufferSourceNodeToStop();
      await this.$_resume();
    }
    this.$emit('unregister-component-instance');
  },

  inject: [
    'enableLoopPlayback',
    'disableLoopPlayback',
  ],

  methods: {
    /* public */
    onRequestAnimationFrame() {
      this.$_update();
    },

    onKeydown(keyboardEvent) {
      switch (keyboardEvent.code) {
        case 'Space':
          togglePlayAndPause(this);
          return true;
        default:
          return false;
      }

      function togglePlayAndPause(self) {
        (self.$data.$_isPlaying)? self.$_pause() : self.$_play();
      }
    },

    onMousemove(mouseEvent) {
      this.$refs.audioPlayerController.onMousemove(mouseEvent);
    },

    onMouseup(mouseEvent) {
      this.$refs.audioPlayerController.onMouseup(mouseEvent);
    },

    /* private */
    async $_suspend() {
      switch (this.audioContext.state) {
        case 'running':
          await this.audioContext.suspend();
          break;
      }
      this.$data.$_isPlaying = false;
    },

    async $_resume() {
      if (this.$_latestAudioBufferSourceNode === null) {
        this.$_seekInSec(0);
        this.$_createNewAudioBufferSourceNode();
      }
      switch (this.audioContext.state) {
        case 'suspended':
          await this.audioContext.resume();
          break;
      }
      this.$data.$_isPlaying = true;
    },

    async $_seekInstantlyInSec(seekTimeSec) {
      await this.$_seekStart();
      this.$_seekInSec(seekTimeSec);
      await this.$_seekEnd();
    },

    $_seekInSec(seekTimeSec) {
      this.$data.$_audioBufferSourceNodeStartOffsetSec = seekTimeSec;
      this.$data.$_originOfCurrentTime = this.audioContext.currentTime - seekTimeSec;
    },

    async $_seekStart() {
      this.$data.$_isSeeking = true;
      this.$data.$_wasPlayingOnSeek = this.$data.$_isPlaying;
      await this.$_suspend();
    },

    async $_seekEnd() {
      let audioBufferSourceNodeIds = Object.keys(this.$data.$_audioBufferSourceNodePool);
      this.$_requestAudioBufferSourceNodeToStop(audioBufferSourceNodeIds);
      this.$_createNewAudioBufferSourceNode();
      if (this.$data.$_wasPlayingOnSeek) {
        await this.$_resume();
      }
      this.$data.$_wasPlayingOnSeek = null;
      this.$data.$_isSeeking = false;
    },

    $_createNewAudioBufferSourceNode() {
      let newAudioBufferSourceNodeId = String(new Date().getTime());
      let newAudioBufferSourceNode = this.audioContext.createBufferSource();
      this.$data.$_latestAudioBufferSourceNodeId = newAudioBufferSourceNodeId;
      this.$set(this.$data.$_audioBufferSourceNodePool, newAudioBufferSourceNodeId, newAudioBufferSourceNode);
      newAudioBufferSourceNode.buffer = this.audioBuffer;
      newAudioBufferSourceNode.connect(this.audioContext.destination);
      newAudioBufferSourceNode.onended = () => {
        this.$delete(this.$data.$_audioBufferSourceNodePool, newAudioBufferSourceNodeId);
      };
      newAudioBufferSourceNode.loop = this.isLoopEnabled;
      newAudioBufferSourceNode.loopStart = this.$_loopDefinition.beginTimeSec;
      newAudioBufferSourceNode.loopEnd = this.$_loopDefinition.endTimeSec;
      newAudioBufferSourceNode.start(0, this.$data.$_audioBufferSourceNodeStartOffsetSec);
    },

    $_getPlayTimeSec() {
      let offsetTime = this.audioContext.currentTime - this.$data.$_originOfCurrentTime;
      if (this.$_latestAudioBufferSourceNode === null) return offsetTime;
      if (this.$_latestAudioBufferSourceNode.loop) {
        if (offsetTime > this.$_latestAudioBufferSourceNode.loopStart) {
          let loopDuration = this.$_latestAudioBufferSourceNode.loopEnd - this.$_latestAudioBufferSourceNode.loopStart;
          let offsetFromLoopStart = (offsetTime - this.$_latestAudioBufferSourceNode.loopStart) % loopDuration;
          offsetTime = this.$_latestAudioBufferSourceNode.loopStart + offsetFromLoopStart;
        }
      }
      return offsetTime;
    },

    async $_play() {
      await this.$_resume();
    },

    async $_pause() {
      await this.$_suspend();
    },

    $_update() {
      let playTimeSec = this.$_getPlayTimeSec();
      this.$data.$_previousTimeSec = this.$data.$_playTimeSec;
      this.$data.$_playTimeSec = playTimeSec;
      this.$emit('update-play-time', playTimeSec);
    },

    $_requestAudioBufferSourceNodeToStop() {
      for (let [ audioBufferSourceNodeId, audioBufferSourceNode ] of Object.entries(this.$data.$_audioBufferSourceNodePool)) {
        this.$delete(this.$data.$_audioBufferSourceNodePool, audioBufferSourceNodeId);
        this.$data.$_audioBufferSourceNodesWaitingForStop.push(audioBufferSourceNode);
        audioBufferSourceNode.onended = null;
      }
    },
  },
}
</script>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("dialog-base", {
    attrs: {
      shows: _vm.shows,
      "initialize-callback": _vm.$_onInitialize,
      "ok-callback": _vm.$_okCallback,
      "finalize-callback": _vm.finalizeCallback,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("v-card-title", [_vm._v(_vm._s(_vm.title))]),
            _c(
              "v-card-text",
              [
                _c("v-text-field", {
                  attrs: { dense: "", autofocus: "" },
                  model: {
                    value: _vm.$data.$_textTmp,
                    callback: function ($$v) {
                      _vm.$set(_vm.$data, "$_textTmp", $$v)
                    },
                    expression: "$data.$_textTmp",
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
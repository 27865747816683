var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      attrs: { id: "app-main-container" },
      on: {
        mousedown: _vm.onMousedown,
        mousemove: _vm.onMousemove,
        mouseup: _vm.onMouseup,
      },
    },
    [
      _c(
        "v-overlay",
        { attrs: { absolute: "", value: _vm.isMediaLoading } },
        [_c("v-progress-circular", { attrs: { indeterminate: "" } })],
        1
      ),
      _c(
        "AppBar",
        _vm._b(
          {
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function () {
                    return [_vm._t("app-bar", null, null, _vm.externalProps)]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          },
          "AppBar",
          _vm.externalProps,
          false
        )
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loadedConfig.showVideoAndEditorView,
              expression: "loadedConfig.showVideoAndEditorView",
            },
          ],
          ref: "videoAndEditorViewContainer",
          style: _vm.videoAndEditorViewContainerStyle,
          attrs: { id: "video-and-editor-view-container" },
        },
        [
          _c(
            "div",
            {
              style: _vm.videoPlayerContainerStyle,
              attrs: { id: "video-player-container" },
            },
            [
              _c("VideoPlayer", {
                attrs: {
                  "video-source": _vm.videoSource,
                  "seek-time-msec": _vm.seekTimeMsec,
                  "is-video-playing": _vm.isVideoPlaying,
                  "is-loop-enabled": _vm.isLoopEnabled,
                  "loop-definition": _vm.loopDefinition,
                  "timeline-marker": _vm.timelineMarker,
                  "audio-only": _vm.audioOnly,
                },
                on: {
                  "meta-data-loaded": _vm.onMetaDataLoaded,
                  "play-info-updated": _vm.onPlayInfoUpdated,
                  play: _vm.playVideo,
                  pause: _vm.pauseVideo,
                  "enable-loop": _vm.enableLoopFlag,
                  "disable-loop": _vm.disableLoopFlag,
                  "playback-fixed": _vm.generatePlaybackHistory,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { id: "editor-view-container" } },
            [
              _c("EditorView", {
                attrs: {
                  "timeline-data-set": _vm.scattData.timelineDataSet,
                  "selected-timeline-segment-ids":
                    _vm.selectedTimelineSegmentIds,
                  "annotator-id": _vm.annotatorId,
                  "annotator-name": _vm.annotatorName,
                  "editor-definitions": _vm.editorDefinitions,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.loadedConfig.showTimelineView &&
              _vm.loadedConfig.showVideoAndEditorView,
            expression:
              "loadedConfig.showTimelineView && loadedConfig.showVideoAndEditorView",
          },
        ],
        ref: "horizontalViewSeparator",
        staticClass: "border-common",
        attrs: { id: "horizontal-view-separator" },
      }),
      _c("TimelineListView", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loadedConfig.showTimelineView,
            expression: "loadedConfig.showTimelineView",
          },
        ],
        ref: "timelineListView",
        attrs: {
          "duration-msec": _vm.durationMsec,
          "play-time-msec": _vm.playTimeMsec,
          "is-video-playing": _vm.isVideoPlaying,
          "visible-timeline-data-set": _vm.visibleTimelineDataSet,
          "visible-timeline-data-idx-to-id": _vm.visibleTimelineDataIdxToId,
          "sorted-visible-segment-idx-to-id-maps":
            _vm.sortedVisibleSegmentIdxToIdMaps,
          "visible-segment-id-to-sorted-idx-maps":
            _vm.visibleSegmentIdToSortedIdxMaps,
          "waveform-digest": _vm.waveformDigest,
          "minimum-segment-duration-msec": _vm.minimumSegmentDurationMsec,
          "selected-timeline-segment-ids": _vm.selectedTimelineSegmentIds,
          "is-any-selected-timeline-segment-immutable":
            _vm.isAnySelectedTimelineSegmentImmutable,
          "is-loop-enabled": _vm.isLoopEnabled,
          "is-mouse-mode": _vm.loadedConfig.isMouseMode,
          "is-snap-enabled": _vm.loadedConfig.isSnapEnabled,
          "loop-definition": _vm.loopDefinition,
          "timeline-marker": _vm.timelineMarker,
          "segment-data-types": _vm.mergedSegmentDataTypes,
          "timeline-title-width-px": _vm.loadedConfig.timelineTitleWidthPx,
        },
        on: {
          "update-timeline-title-width-px": _vm.updateTimelineTitleWidthPx,
          "update-is-mouse-mode": _vm.updateIsMouseMode,
          "update-is-snap-enabled": _vm.updateIsSnapEnabled,
        },
      }),
      _c("Footer", {
        attrs: {
          "tool-title": _vm.toolTitle,
          messages: _vm.footerMessages,
          version: _vm.toolSemanticVersionString,
        },
      }),
      _c(
        _vm.dialogComponentName,
        _vm._b(
          { tag: "component", attrs: { shows: _vm.showsDialog } },
          "component",
          _vm.dialogProps,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
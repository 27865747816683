class TimeMsecAndTimelinePosition {
  constructor(timeMsec, snappedTimeMsec, timelinePosition) {
    this.timeMsec = timeMsec;
    this.snappedTimeMsec = snappedTimeMsec;
    this.timelinePosition = timelinePosition;
  }

  static epsilonTimeMsec = 0.001;
  static epsilonTimelinePosition = 0.01;

  isSame(that) {
    let differenceTimeMsec = Math.abs(that.timeMsec - this.timeMsec);
    let differenceTimelinePosition = Math.abs(that.timelinePosition - this.timelinePosition);
    return (differenceTimeMsec < TimeMsecAndTimelinePosition.epsilonTimeMsec) && (differenceTimelinePosition < TimeMsecAndTimelinePosition.epsilonTimelinePosition);
  }
}

export default TimeMsecAndTimelinePosition;

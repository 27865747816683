var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "timeline-info-bar" } },
    [
      _vm.$_singlySelectedTimelineSegment !== null
        ? [
            String(_vm.$_singlySelectedTimelineSegment.data)
              ? [
                  _c("span", { staticClass: "flex-grow-1 text-truncate" }, [
                    _vm._v(
                      "Data: " +
                        _vm._s(_vm.$_singlySelectedTimelineSegment.data)
                    ),
                  ]),
                  _c("TextDivider"),
                ]
              : _vm._e(),
            _c(
              "span",
              { staticClass: "time-msec text-no-wrap" },
              [
                _vm.$_singlySelectedTimelineSegment.begin === null
                  ? [_vm._v("-Inf")]
                  : [_vm._v(_vm._s(_vm.$_singlySelectedTimelineSegment.begin))],
                _c("TextDivider", { attrs: { content: "-" } }),
                _vm.$_singlySelectedTimelineSegment.end === null
                  ? [_vm._v("Inf")]
                  : [_vm._v(_vm._s(_vm.$_singlySelectedTimelineSegment.end))],
              ],
              2
            ),
            _c("TextDivider"),
          ]
        : _vm._e(),
      _c("span", { staticClass: "time-msec text-no-wrap" }, [
        _vm._v(" " + _vm._s(_vm.playTimeMsec) + " "),
      ]),
      _c("TextDivider"),
      _c(
        "span",
        {
          staticClass: "time-msec text-no-wrap link",
          on: { click: _vm.showDurationEditor },
        },
        [_vm._v(" " + _vm._s(_vm.durationMsec) + " ")]
      ),
      _c("TextDivider"),
      _c("span", { staticClass: "text-no-wrap" }, [
        _vm._v(_vm._s(_vm.viewMode)),
      ]),
      _c("TextDivider"),
      _c("TimelineViewResolutionMenu", {
        attrs: {
          "time-resolution": _vm.timeResolution,
          "time-resolution-min": _vm.timeResolutionMin,
          "time-resolution-max": _vm.timeResolutionMax,
          "timeline-position-resolution": _vm.timelinePositionResolution,
          "timeline-position-resolution-min": _vm.timelinePositionResolutionMin,
          "timeline-position-resolution-max": _vm.timelinePositionResolutionMax,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
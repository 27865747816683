<template>
  <dialog-base
    v-bind:shows="shows"
    v-bind:finalize-callback="finalizeCallback"
  >
    <template v-slot:body>
      <v-card-title v-if="title">{{ title }}</v-card-title>
      <v-card-text v-if="messages">
        <div
          v-for="(message, messageIdx) in messages"
          v-bind:key="messageIdx"
        >
          {{ message }}
        </div>
      </v-card-text>
    </template>

    <template v-slot:buttons="{ on, attrs }">
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        v-bind="attrs"
        v-on:click="on.okClicked"
      >
        OK
      </v-btn>
    </template>
  </dialog-base>
</template>

<script>
import DialogBase from './DialogBase.vue';

export default {
  components: {
    DialogBase,
  },

  props: {
    shows: { type: Boolean, default: false },
    finalizeCallback: { type: Function, default: null },
    title: { type: String, default: null },
    messages: { type: Array, default: null },
  },
}
</script>
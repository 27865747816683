<template>
    <div id="wrapper" v-if="nano.props">
        <v-card class="my-10">
            <v-system-bar window dark color="indigo">
                <h3>Your task</h3>
            </v-system-bar>
            <div class="pa-4 text-body-1 font-weight-medium text-left">
                Sort images in the order you think they are "strong".
            </div>
        </v-card>
        <div v-if="nano.props" :key="`ranking${refreshCnt}`">
            <div class="d-flex align-center justify-center">
                <div style="width:100px;"><v-chip dark color="indigo"><h3>Rank</h3></v-chip></div>
                <div style="width:500px;"><v-chip dark color="indigo"><h3>Image</h3></v-chip></div>
                <div style="width:200px;"></div>
            </div>
            <div v-for="rank,idx in rankingIndex" :key="`rank${idx}`">
                <div class="d-flex align-center justify-center my-4" style="min-height:150px;">
                    <div style="width:100px;"><h2>{{ idx+1 }}</h2></div>
                    <div style="width:500px;height:100%;"><img :src="imgPaths[rank]" style="max-height:200px;" /></div>
                    <div style="width:200px;">
                        <v-btn icon large color="indigo" v-if="idx>0" @click="swapRanks(idx, 'up')"><v-icon large>mdi-arrow-up</v-icon></v-btn><br>
                        <v-btn icon large color="indigo" v-if="idx<rankingIndex.length-1" @click="swapRanks(idx, 'down')"><v-icon large>mdi-arrow-down</v-icon></v-btn>
                    </div>
                </div>
                <v-divider></v-divider>
            </div>
        </div>
        <v-btn color="primary mt-4" @click="submit()"><v-icon left dark>mdi-send-outline</v-icon>submit</v-btn>
    </div>
</template>
<script>
import nanoMixIn from "@/mixins/nano";

export default {
    mixins: [nanoMixIn],
    data: () => ({
        refreshCnt: 0,
        rankingIndex: [],
        defaultNanoProps: {
            imgIds: [
                'cat.jpeg',
                'cow.jpeg',
                'kangaroo.jpeg',
                'rabbit.jpeg',
                'shoebill.jpeg',
                'tiger.jpeg'
            ],
        },
        defaultNanoAnswers: {
            rankingIndex: [],
            ranking: []
        },
        imgPaths: [],
    }),
    methods: {
        swapRanks(thisIdx, direction) {
            const nextIdx = direction==='up' ? thisIdx-1 : thisIdx+1;
            const thisRank = this.rankingIndex.splice(thisIdx, 1);
            this.rankingIndex.splice(nextIdx, 0, thisRank[0]);
            this.refreshCnt++;
        },
    },
    watch: {
        'nano.props'(props) {
            this.imgPaths = this.nano.props.imgIds.map((id) => (`/static/samples/images/ranking/${id}`)),
            this.rankingIndex = Object.keys(this.nano.props.imgIds).map((key) => (parseInt(key)));
        },
        rankingIndex(rankingIndex) {
            this.nano.ans.rankingIndex = rankingIndex;
            this.nano.ans.ranking = JSON.parse(JSON.stringify(rankingIndex.map(i => this.nano.props.imgIds[i])));
        }
    },
};
</script>
<style scoped>
#wrapper {
    max-width: 1000px;
    width: 80%;
    text-align: center;
    margin: 50px auto;
}
</style>

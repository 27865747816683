var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("t-audio-segmentation", {
    attrs: {
      "audio-url": _vm.audioUrl,
      "audio-segment-sequence": _vm.$_audioSegmentSequence,
      "label-sequence": _vm.$_labelSequence,
      "label-options": _vm.labelOptions,
    },
    on: {
      "update:audioSegmentSequence": function ($event) {
        _vm.$_audioSegmentSequence = $event
      },
      "update:audio-segment-sequence": function ($event) {
        _vm.$_audioSegmentSequence = $event
      },
      "update:labelSequence": function ($event) {
        _vm.$_labelSequence = $event
      },
      "update:label-sequence": function ($event) {
        _vm.$_labelSequence = $event
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { TimelineSegmentDataBase, TimelineSegmentContentDataBase } from '../ScattData.js';

class NoteData extends TimelineSegmentContentDataBase {
  constructor(text, date) {
    super();
    this.text = text;
    this.date = date;
  }

  generateRawObj() {
    let noteDataRawObj = new Object();
    noteDataRawObj.text = this.text;
    noteDataRawObj.date = this.date.toISOString();
    return noteDataRawObj;
  }

  toString() {
    return this.text;
  }

  isSame(that) {
    if (this.text !== that.text) return false;
    if (this.date.getTime() !== that.date.getTime()) return false;
    return true;
  }
}

class NoteSegmentData extends TimelineSegmentDataBase {
  static contentData = NoteData;

  constructor(begin, end, noteData, isLocked) {
    super(begin, end, noteData, isLocked);
  }

  static generateFromRawObj(begin, end, rawObj) {
    return new NoteSegmentData(begin, end, new NoteData(rawObj.text, new Date(rawObj.date)), false);
  }

  static generateEmpty(begin, end) {
    return new NoteSegmentData(begin, end, new NoteData('', new Date()), false);
  }

  static toString() {
    return 'NoteSegmentData';
  }

  static get name() {
    return 'Note Data';
  }

  generateRawObj() {
    let noteSegmentDataRawObj = new Object();
    noteSegmentDataRawObj.begin = this.begin;
    noteSegmentDataRawObj.end = this.end;
    noteSegmentDataRawObj.data = this.data.generateRawObj();
    noteSegmentDataRawObj.locked = this.locked;
    return noteSegmentDataRawObj;
  }

  isSame(that) {
    return super.isSame(that) && this.data.isSame(that.data);
  }
}

export default NoteSegmentData;

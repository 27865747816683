<template>
    <div>
        <slot
            v-bind="{ isStandby, recording, standBy, start, stop, pause, resume, clear }"
        ></slot>
    </div>
</template>
<script>
const getUserMediaConstraint = (deviceId) => {
    return { audio: { deviceId: { exact: deviceId } } }
};

const defaultMedia = {
    metadata: {
        mimeType: '',
        size: 0,
        audioBitsPerSecond: 0,
    },
    input: {
        dir: 'dev/audio',
        filename: '',
        memo: '',
    },
    url: null,
    buf: null,
};  
    
export default {
    data: () => ({
        recorder: null,
        mediaData: [],
        media: { ...defaultMedia },
        recording: false,
        isStandby: false,
    }),
    props: {
        deviceId: { type: String, default: null }
    },
    methods: {
        async standBy() {
            if (this.deviceId === null || this.recording) return false;

            const constraint = getUserMediaConstraint(this.deviceId);
            const userMedia = await navigator.mediaDevices.getUserMedia(constraint);
            this.recorder = new MediaRecorder(userMedia);

            this.recorder.onstart = (evt) => {
                this.mediaData.length = 0;
                this.media.metadata.mimeType = evt.srcElement.mimeType;
                this.media.metadata.audioBitsPerSecond = evt.srcElement.audioBitsPerSecond;
                this.$emit('start', evt);
            };
            this.recorder.onpause = (evt) => {
                this.$emit('pause', evt);
            };
            this.recorder.onresume = (evt) => {
                this.$emit('resume', evt);
            };
            this.recorder.ondataavailable = (evt) => {
                this.mediaData.push(evt.data);
            };
            this.recorder.onstop = async (evt) => {
                const blob = new Blob(this.mediaData, {type: this.media.metadata.mimeType});
                this.media.metadata.size = blob.size;
                this.media.url = URL.createObjectURL(blob);
                this.media.buf = await blob.arrayBuffer();
                this.media.input.filename = Math.floor(Date.now() / 1000).toString();
                this.$emit('stop', this.media, evt);
            };
            this.isStandby = true;
            this.$emit('stand-by', this.deviceId);
        },
        async start() {
            this.recording = true;
            this.recorder.start();
        },
        pause() {
            this.recorder.pause();
        },
        resume() {
            this.recorder.resume();
        },
        stop() {
            if (!this.recording)
                return false;
            this.recorder.stop();
            this.recording = false;
        },
        clear() {
            this.media = { ...defaultMedia };
            this.$emit('clear', this.media);
        }
    },
    watch: {
        deviceId(deviceId) {
            this.isStandby = false;
            if(deviceId !== null) this.$emit('set-device-id', deviceId);
            this.standBy();
        }
    },
    async mounted() {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            const allDevices = await navigator.mediaDevices.enumerateDevices();
            const devices = allDevices.filter((dev) => dev.kind === 'audioinput');
            this.$emit('device-enumerate', devices);
        }
    }
}
</script>
<template>
  <div id="audio-player-controller">
    <button-with-tooltip
      icon 
      v-on:mousedown.stop="$_seekToHead"
    >
      <v-icon>mdi-skip-previous</v-icon>
      <template v-slot:tooltip>Seek To Head</template>
    </button-with-tooltip>

    <button-with-tooltip
      icon
      v-on:mousedown.stop="$_seekBackwardStart"
      v-on:mouseup.stop="$_seekBackwardEnd"
      v-on:mouseout.stop="$_seekBackwardEnd"
    >
      <v-icon>mdi-rewind</v-icon>
      <template v-slot:tooltip>Seek Backward</template>
    </button-with-tooltip>

    <button-with-tooltip
      icon
      v-if="isPlaying"
      v-on:mousedown.stop="$_pause"
    >
      <v-icon >mdi-pause</v-icon>
      <template v-slot:tooltip>Pause</template>
    </button-with-tooltip>

    <button-with-tooltip
      icon
      v-else
      v-on:mousedown.stop="$_play"
    >
      <v-icon>mdi-play</v-icon>
      <template v-slot:tooltip>Play</template>
    </button-with-tooltip>

    <button-with-tooltip
      icon
      v-on:mousedown.stop="$_seekForwardStart"
      v-on:mouseup.stop="$_seekForwardEnd"
      v-on:mouseout.stop="$_seekForwardEnd"
    >
      <v-icon>mdi-fast-forward</v-icon>
      <template v-slot:tooltip>Seek Forward</template>
    </button-with-tooltip>

    <button-with-tooltip
      icon
      v-on:mousedown.stop="$_seekToTail"
    >
      <v-icon>mdi-skip-next</v-icon>
      <template v-slot:tooltip>Seek To Tail</template>
    </button-with-tooltip>

    <audio-player-seek-bar
      ref="audioPlayerSeekBar"
      v-bind:current-time-sec="playTimeSec"
      v-bind:duration-sec="durationSec"
      v-bind:is-loop-enabled="isLoopEnabled"
      v-bind:is-seeking="isSeeking"
      v-bind:loop-definition="loopDefinition"
      v-on:seek="$_seekBySlider"
      v-on:seek-start="$_seekBySliderStart"
      v-on:seek-end="$_seekBySliderEnd"
    >
    </audio-player-seek-bar>

    <button-with-tooltip
      icon class="loop-enabled"
      v-if="isLoopEnabled"
      v-on:mousedown.stop="$_disableLoop"
    >
      <v-icon>mdi-restart</v-icon>
      <template v-slot:tooltip>Disable Loop</template>
    </button-with-tooltip>
    <button-with-tooltip
      icon
      v-else
      v-on:mousedown.stop="$_enableLoop"
    >
      <v-icon>mdi-restart</v-icon>
      <template v-slot:tooltip>Enable Loop</template>
    </button-with-tooltip>
  </div>
</template>

<style scoped>
#audio-player-controller {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.loop-enabled {
  color: #16f43f !important;
}
</style>

<script>
import AudioPlayerSeekBar from './AudioPlayerSeekBar.vue';
import AudioPlaybackLoopDefinition from './modules/AudioPlaybackLoopDefinition.js';
import ButtonWithTooltip from '../ButtonWithTooltip.vue';

const seekIntervalTimeSec = 0.1;
const seekTimeStepSec = 0.1;

export default {
  components: {
    AudioPlayerSeekBar,
    ButtonWithTooltip,
  },

  props: {
    durationSec: { type: Number, default: 0 },
    playTimeSec: { type: Number, default: 0 },
    isPlaying: { type: Boolean, default: false },
    isSeeking: { type: Boolean, default: false },
    isLoopEnabled: { type: Boolean },
    loopDefinition: { type: AudioPlaybackLoopDefinition },
  },

  data() {
    return {
      $_seekTargetTimeSec: null,
      $_intervalId: null,
    };
  },

  methods: {
    $_seekToHead() { this.$emit('seek-instantly-in-sec', 0) },

    $_seekToTail() { this.$emit('seek-instantly-in-sec', this.durationSec) },

    $_enableLoop() { this.$emit('enable-loop') },

    $_disableLoop() { this.$emit('disable-loop') },

    $_play() { this.$emit('play') },

    $_pause() { this.$emit('pause') },

    $_seekBackwardStart() {
      if (this.isSeeking) return;
      this.$emit('seek-start');
      this.$data.$_seekTargetTimeSec = this.playTimeSec;
      this.$_updateSeekBackwardTargetTime();
      this.$_setInterval(this.$_updateSeekBackwardTargetTime, seekIntervalTimeSec);
    },

    $_updateSeekBackwardTargetTime() {
      let nextSeekTargetTimeSec = this.$data.$_seekTargetTimeSec - seekTimeStepSec;
      if (nextSeekTargetTimeSec < 0) nextSeekTargetTimeSec = 0;
      this.$data.$_seekTargetTimeSec = nextSeekTargetTimeSec;
      this.$emit('seek-in-sec', nextSeekTargetTimeSec)
    },

    $_seekBackwardEnd() {
      if (!this.isSeeking) return;
      this.$_clearInterval();
      this.$emit('seek-end');
    },

    $_seekForwardStart() {
      if (this.isSeeking) return;
      this.$emit('seek-start');
      this.$data.$_seekTargetTimeSec = this.playTimeSec;
      this.$_updateSeekForwardTargetTime();
      this.$_clearInterval();
      this.$_setInterval(this.$_updateSeekForwardTargetTime, seekIntervalTimeSec);
    },

    $_updateSeekForwardTargetTime() {
      let nextSeekTargetTimeSec = this.$data.$_seekTargetTimeSec + seekTimeStepSec;
      if (nextSeekTargetTimeSec > this.durationSec) nextSeekTargetTimeSec = this.durationSec;
      this.$data.$_seekTargetTimeSec = nextSeekTargetTimeSec;
      this.$emit('seek-in-sec', nextSeekTargetTimeSec)
    },

    $_seekForwardEnd() {
      if (!this.isSeeking) return;
      this.$_clearInterval();
      this.$emit('seek-end');
    },

    $_seekBySliderStart() {
      this.$emit('seek-start');
    },

    $_seekBySliderEnd() {
      this.$emit('seek-end');
    },

    $_seekBySlider(timeSec) {
      if (this.isSeeking) this.$emit('seek-in-sec', timeSec)
    },

    $_clearInterval() {
      if (this.$data.$_intervalId !== null) window.clearInterval(this.$data.$_intervalId);
      this.$data.$_intervalId = null;
    },

    $_setInterval(callback, intervalSec) {
      this.$_clearInterval();
      this.$data.$_intervalId = window.setInterval(callback, intervalSec);
    },

    onMousemove(mouseEvent) {
      this.$refs.audioPlayerSeekBar.onMousemove(mouseEvent);
    },

    onMouseup(mouseEvent) {
      this.$refs.audioPlayerSeekBar.onMouseup(mouseEvent);
    },
  },
}
</script>
<template>
    <v-container pa-10 style="max-width: 1000px;">
        <v-card class="my-10">
            <v-system-bar window dark color="indigo">
                <h3>Your task</h3>
            </v-system-bar>
            <div class="pa-4 text-body-1 font-weight-medium">
                Choose the one that best describes the genre of the literary text.
            </div>
        </v-card>

        <v-card id="text-card" class="mx-auto my-6 px-6 py-2">
            <div class="d-flex">
                <div>
                    <v-radio-group row v-model="nano.ans.choice">
                        <v-radio
                            v-for="item,i in radioItems"
                            :key="`radio-item-${i}`"
                            :label="item.label"
                            :value="item.value"
                        />
                    </v-radio-group>
                </div>

                <v-spacer></v-spacer>

                <div class="my-auto">
                    <v-btn
                        :disabled="nano.ans.choice === null"
                        color="primary"
                        @click="submit()"
                    >Submit</v-btn>
                </div>
            </div>
        </v-card>

        <v-card id="text-card" class="mx-auto my-6 pa-6">
            <div v-if="nano.props.text" v-html="nano.props.text.replaceAll('\n', '<br>')"></div>
        </v-card>
    </v-container>
</template>
<script>
import nanoMixIn from '@/mixins/nano';
export default {
    mixins: [nanoMixIn],
    data: () => ({
        radioItems: [
            { label: 'Fantasy', value: 'fantasy' },
            { label: 'Horror', value: 'horror' },
            { label: 'Poetry', value: 'poetry' },
            { label: 'Essay', value: 'Essay' },
        ],
        defaultNanoProps: {
            text: `Proident laboris occaecat incididunt duis ea sed reprehenderit ullamco tempor tempor do veniam elit labore velit ut. Est ad anim quis sunt in pariatur ut in aliquip occaecat aute ipsum. Anim do lorem labore eu cupidatat elit dolor id irure fugiat sit. Deserunt qui incididunt veniam et deserunt excepteur ullamco labore ut ut lorem consequat velit amet officia. Amet nostrud ut pariatur laboris occaecat ea est ad non consequat eu anim ea velit.
Voluptate culpa mollit tempor proident et pariatur in cillum commodo nulla exercitation ex fugiat ipsum excepteur excepteur. Enim officia officia ad nulla voluptate mollit quis aute ut anim do. Sed fugiat eiusmod eu consectetur eiusmod sed fugiat eu excepteur est elit deserunt nisi. Aute qui ad cillum dolore nisi veniam do officia enim quis exercitation cillum minim.
Sed esse aliqua do ullamco et mollit ea fugiat laborum dolor non sit culpa. Enim consequat amet exercitation ad magna nulla cillum lorem aliquip est aliqua labore. Lorem velit ex mollit eiusmod sint non dolor minim ullamco in dolor eu sunt ex nisi. Proident commodo labore labore eu eiusmod velit aute magna aliquip tempor laboris. Mollit sed eu sit qui irure ullamco esse minim ex mollit culpa. Ut culpa laboris laboris mollit reprehenderit sed anim ea sunt sint mollit occaecat duis cupidatat.
            `,
        },
        defaultNanoAnswers: {
            choice: null
        }
    }),
};
</script>
<style scoped>
#text-card {
    line-height: 3;
}
</style>
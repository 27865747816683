<template>
  <canvas v-bind:height="timeScrollBarCanvasHeightPx"></canvas>
</template>

<script>
import timelineCanvas from './mixins/timelineCanvas.js'
import canvasUtils from './modules/canvasUtils.js'

export default {
  mixins: [
    timelineCanvas,
  ],

  watch: {
    timeScrollBarDomRect() { this.$_setDirty(true); },
    timeScrollBarCanvasHeightPx() { this.$_setDirty(true); },
  },

  props: {
    timeScrollBarDomRect: { type: DOMRect },
    timeScrollBarCanvasHeightPx: { type: Number },
  },

  methods: {
    /* public */
    draw() {
      this.$_draw(() => {
        let timeScrollBarCanvas = this.$data.$_timelineCanvasContext;
        timeScrollBarCanvas.beginPath();
        timeScrollBarCanvas.rect(...canvasUtils.dotByDotOffsetRectArgs(
          this.timeScrollBarDomRect.x,
          this.timeScrollBarDomRect.y,
          this.timeScrollBarDomRect.width,
          this.timeScrollBarDomRect.height,
        ));
        timeScrollBarCanvas.fillStyle = 'rgba(187, 187, 187, 1)';
        timeScrollBarCanvas.fill();
      });
    },
  }
};
</script>
import { render, staticRenderFns } from "./TVideo.vue?vue&type=template&id=264b1d42&"
import script from "./TVideo.vue?vue&type=script&lang=js&"
export * from "./TVideo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('264b1d42')) {
      api.createRecord('264b1d42', component.options)
    } else {
      api.reload('264b1d42', component.options)
    }
    module.hot.accept("./TVideo.vue?vue&type=template&id=264b1d42&", function () {
      api.rerender('264b1d42', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/template-ui/TVideo.vue"
export default component.exports
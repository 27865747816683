<template>   
    <div v-show="audioDeviceId && videoDeviceId" id="wrapper">
        <t-video-recorder
            :audio-device-id="audioDeviceId"
            :video-device-id="videoDeviceId"
            :always-show-camera="false"
            :mime-type="mimeType"
            max-width="500px"
            @device-enumerate="onDeviceEnumerate"
            @set-audio-device-id="onSetAudioDeviceId"
            @set-video-device-id="onSetVideoDeviceId"
            @stand-by="onStandBy"
            @start="onRecordStart"
            @pause="onRecordPause"
            @resume="onRecordResume"
            @stop="onRecordStop"
            @clear="onClear"
        >
            <template v-slot:camera-preview="{ media }">
                <div style="position: relative;" :style="{ zIndex: isPlayMode ? 10 : 'auto' }">
                    <video
                        v-if="media"
                        id="player"
                        :src="media.url"
                        :type="media.metadata.mimeType"
                        :controls="isPlayMode"
                        playsinline
                    ></video>
                    <div v-if="isPlayMode" style="position: absolute; top: 0; left: 0; width: 100%;">
                        <v-btn large dark icon class="ma-2" @click="isPlayMode = false;"><v-icon>mdi-arrow-left</v-icon></v-btn>
                    </div>
                </div>
            </template>

            <template v-slot:overlay="{
                isReady,
                recorderState,
                media,
                start,
                stop,
                pause,
                resume,
                clear
            }">
                <v-overlay :value="(shownDevicesMenu !== null)" absolute :dark="false">
                    <v-card>
                        <v-list dense max-height="200" class="overflow-y-auto">
                            <v-list-item
                                v-for="item, i in deviceItems[shownDevicesMenu]"
                                :key="`device-item-${i}`"
                                @click="() => {
                                    if(shownDevicesMenu === 'audio')
                                        audioDeviceId = item.value;
                                    else if(shownDevicesMenu === 'video')
                                        videoDeviceId = item.value;
                                    shownDevicesMenu = null;
                                }"
                            >
                                <v-list-item-title>{{ item.text }}</v-list-item-title>
                                <v-list-item-icon>
                                    <v-icon
                                        v-if="
                                            (shownDevicesMenu === 'audio' && item.value === audioDeviceId)
                                            || (shownDevicesMenu === 'video' && item.value === videoDeviceId)
                                        "
                                        small
                                    >mdi-check</v-icon>
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-overlay>

                <div v-if="isReady && !isPlayMode" class="camera-overlay">
                    <div class="flex-grow-1"></div>
                    <div class="camera-action-buttons">
                        <template v-if="(recorderState === 'inactive' && media === null)">
                            <div>
                                <v-btn small fab outlined dark color="white" @click="shownDevicesMenu = 'video';">
                                    <v-icon>mdi-camera-flip-outline</v-icon>
                                </v-btn>
                            </div>
                            <div>
                                <v-btn fab dark color="red" @click="start">
                                    <v-icon>mdi-circle</v-icon>
                                </v-btn>
                            </div>
                            <div>
                                <v-btn small fab outlined dark color="white" @click="shownDevicesMenu = 'audio';">
                                    <v-icon>mdi-microphone-settings</v-icon>
                                </v-btn>
                            </div>
                        </template>

                        <template v-else-if="recorderState === 'recording'">
                            <div>
                                <v-btn small fab dark outlined @click="pause">
                                    <v-icon>mdi-pause</v-icon>
                                </v-btn>
                            </div>
                            <div>
                                <v-btn fab dark color="red" @click="stop">
                                    <v-icon>mdi-stop</v-icon>
                                </v-btn>
                            </div>
                            <div></div>
                        </template>

                        <template v-else-if="recorderState === 'paused'">
                            <div>
                                <v-btn small fab outlined color="white" @click="resume">
                                    <v-icon color="red">mdi-circle</v-icon>
                                </v-btn>
                            </div>
                            <div>
                                <v-btn small fab dark color="red" @click="stop">
                                    <v-icon>mdi-stop</v-icon>
                                </v-btn>
                            </div>
                            <div></div>
                        </template>

                        <template v-else-if="(recorderState === 'inactive' && media !== null)">
                            <div>
                                <v-btn small fab outlined color="white" @click="clear">
                                    <v-icon>mdi-arrow-u-left-top</v-icon>
                                </v-btn>
                            </div>
                            <div>
                                <v-btn fab dark color="red" :loading="submitting" @click="uploadAndSubmit(media)">
                                    <v-icon>mdi-check</v-icon>
                                </v-btn>
                            </div>
                            <div>
                                <v-btn fab outlined dark small color="white" @click="isPlayMode = true;">
                                    <v-icon>mdi-play</v-icon>
                                </v-btn>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
        </t-video-recorder>
    </div>    
</template>

<script>
import nanoMixIn from "@/mixins/nano";
import TVideoRecorder from '@/components/template-ui/TVideoRecorder.vue'
    
export default {
    mixins: [nanoMixIn],
    components: { TVideoRecorder },
    data: () => ({
        defaultNanoProps: {
        },
        defaultNanoAnswers: {
        },
        audioDeviceId: null,
        videoDeviceId: null,
        devices: { audio: [], video: [] },
        mimeType: 'video/mp4',
        submitting: false,
        shownDevicesMenu: null,
        isPlayMode: false,
    }),
    computed: {
        deviceItems() {
            return {
                audio: this.devices.audio.map((dev) => ({
                        text: dev.label ? dev.label : dev.deviceId,
                        value: dev.deviceId,
                    })),
                video: this.devices.video.map((dev) => ({
                        text: dev.label ? dev.label : dev.deviceId,
                        value: dev.deviceId,
                    })),
                };
        }
    },
    methods: {
        async uploadAndSubmit(media) {
            let extension = '.mp4';
            await this.addBufferToFileUploadQueue(media.buf, media.input.dir, media.input.filename+extension);
            this.nano.ans.metadata = media.metadata;
            this.nano.ans.input = media.input;
            this.submitting = true;
            this.submit();
        },

        onDeviceEnumerate(devices) {
            this.devices = devices;
            if(this.devices.audio.length > 0 && this.devices.video.length > 0) {
                this.$nextTick(() => {
                    this.audioDeviceId = this.deviceItems.audio[0].value;
                    this.videoDeviceId = this.deviceItems.video[0].value;
                });
            } else {
                if(this.devices.audio.length == 0)
                    alert('No audio device found. Please connect a microphone device and refresh the page.');
                if(this.devices.video.length == 0)
                    alert('No camera device found. Please connect a camera device and refresh the page.');
            }
        },
        onSetAudioDeviceId(id) { console.log('onSetAudioDeviceId', id); },
        onSetVideoDeviceId(id) { console.log('onSetVideoDeviceId', id); },
        onStandBy() { console.log('onStandBy'); },
        onRecordStart(evt) { console.log('onRecordStart', evt); },
        onRecordPause(evt) { console.log('onRecordPause', evt); },
        onRecordResume(evt) { console.log('onRecordResume', evt); },
        onRecordStop(evt) { console.log('onRecordStop', evt); },
        onClear() { console.log('onClear'); },
    },
    async mounted() {
        this.preview = this.$refs.preview;
        this.addFileUploadStatusChangeHandler(() => {
            if(this.allFileUploadComplete) this.submitting = false;
        });
    },
};
</script>

<style scoped>   
#wrapper {
    height: 100%;
    background-color: black;
}
#player {
    width: 100%;
}
div.camera-overlay {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
div.camera-action-buttons {
    display: flex;
    flex-direction: row;
    padding: 40px 0;
    align-items: center;
    height: 150px;
}
div.camera-action-buttons > div {
    flex: 1;
    text-align: center;
}
</style>
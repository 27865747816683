import Color from '../../../modules/Color.js';
import Utils from '../../../modules/Utils.js';

const withinDotOffsetPx = 0.5;

export default {
  watch: {
    color: {
      handler() { this.$_setDirty(true) },
      immediate: true,
    },

    widthPx: {
      handler(widthPx) { this.$_canvasWidthPx = widthPx },
    },

    heightPx: {
      handler(heightPx) { this.$_canvasHeightPx = heightPx },
    },

    '$data.$_canvasWidthPxInternal': {
      handler(canvasWidthPxInternal) {
        if (Utils.isNullOrUndefined(canvasWidthPxInternal)) return;
        this.$el.style.width = String(canvasWidthPxInternal) + 'px';
        this.$el.width = canvasWidthPxInternal;
        this.$_setDirty(true);
      },
    },

    '$data.$_canvasHeightPxInternal': {
      handler(canvasHeightPxInternal) {
        if (Utils.isNullOrUndefined(canvasHeightPxInternal)) return;
        this.$el.style.height = String(canvasHeightPxInternal) + 'px';
        this.$el.height = canvasHeightPxInternal;
        this.$_setDirty(true);
      },
    },
  },

  mounted() {
    this.$_canvasWidthPx = this.widthPx;
    this.$_canvasHeightPx = this.heightPx;
    const recursiveDrawFunction = () => {
      this.$_draw();
      this.$data.$_requestAnimationFrameCallbackId = window.requestAnimationFrame(recursiveDrawFunction);
    }
    recursiveDrawFunction();
  },

  props: {
    color: { type: Color, default: () => Color.black },
    widthPx: { type: Number, default: null },
    heightPx: { type: Number, default: null },
  },

  data() {
    return {
      $_isDirty: false,
      $_requestAnimationFrameCallbackId: null,
      $_canvasWidthPxInternal: null,
      $_canvasHeightPxInternal: null,
    };
  },

  computed: {
    $_canvasWidthPx: {
      get()              { return this.$data.$_canvasWidthPxInternal },
      set(canvasWidthPx) { this.$data.$_canvasWidthPxInternal = canvasWidthPx },
    },

    $_canvasHeightPx: {
      get()               { return this.$data.$_canvasHeightPxInternal },
      set(canvasHeightPx) { this.$data.$_canvasHeightPxInternal = canvasHeightPx },
    },
  },

  methods: {
    $_setDirty(isDirty) {
      this.$data.$_isDirty = isDirty;
    },

    $_draw() {
      if (!this.$data.$_isDirty) return;

      let canvas = this.$el.getContext('2d');
      let width = this.$el.width;
      let height = this.$el.height;
      clearCanvas(canvas, width, height);
      this.draw(this.$el);
      this.$_setDirty(false);

      function clearCanvas(canvas, width, height) {
        canvas.beginPath();
        canvas.clearRect(0, 0, width, height);
      }
    },

    $_dotByDotOffsetCoordArgs(x, y, offsetPx = withinDotOffsetPx) {
      let offsetX = x;
      let offsetY = y;
      return [ offsetX + offsetPx, offsetY + offsetPx ];
    },

    $_dotByDotOffsetRectArgs(x, y, width, height, offsetPx = withinDotOffsetPx) {
      let offsetX = x;
      let offsetY = y;
      let endX = x + width;
      let endY = y + height;
      let offsetEndX = endX;
      let offsetEndY = endY;
      let offsetWidth = offsetEndX - offsetX;
      let offsetHeight = offsetEndY - offsetY;
      return [ offsetX + offsetPx, offsetY + offsetPx, offsetWidth, offsetHeight ];
    },
  },
}
class CanvasLocalCoords {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }

  static generateFromMouseEvent(mouseEvent, canvasBoundingClientRect) {
    return new this(
      mouseEvent.clientX - canvasBoundingClientRect.x,
      mouseEvent.clientY - canvasBoundingClientRect.y,
    );
  }

  static epsilon = 0.001;

  isEqualTo(otherCoords) {
    let differenceX = Math.abs(otherCoords.x - this.x);
    let differenceY = Math.abs(otherCoords.y - this.y);
    return (differenceX < this.constructor.epsilon) && (differenceY < this.constructor.epsilon);
  }

  isInside(domRect) {
    if (this.x < domRect.x) return false;
    if (this.x > (domRect.x + domRect.width)) return false;
    if (this.y < domRect.y) return false;
    if (this.y > (domRect.y + domRect.height)) return false;
    return true;
  }

  getOffsetFrom(that) {
    return new this.constructor(
      this.x - that.x,
      this.y - that.y,
    );
  }
}

export default CanvasLocalCoords;

import { TimelineSegmentDataBase, TimelineSegmentContentDataBase } from '../ScattData.js';

class LabelData extends TimelineSegmentContentDataBase {
  constructor(label) {
    super();
    this.label = label;
  }

  generateRawObj() {
    let labelDataRawObj = new Object();
    labelDataRawObj.label = this.label;
    return labelDataRawObj;
  }

  toString() {
    return this.label;
  }

  isSame(that) {
    if (this.label !== that.label) return false;
    return true;
  }
}

class LabelSegmentData extends TimelineSegmentDataBase {
  static contentData = LabelData;

  constructor(begin, end, labelData, isLocked) {
    super(begin, end, labelData, isLocked);
  }

  static generateFromRawObj(begin, end, rawObj) {
    return new LabelSegmentData(begin, end, new LabelData(rawObj.label), false);
  }

  static generateEmpty(begin, end) {
    return new LabelSegmentData(begin, end, new LabelData(''), false);
  }

  static toString() {
    return 'LabelSegmentData';
  }

  static get name() {
    return 'Label Data';
  }

  generateRawObj() {
    let labelSegmentDataRawObj = new Object();
    labelSegmentDataRawObj.begin = this.begin;
    labelSegmentDataRawObj.end = this.end;
    labelSegmentDataRawObj.data = this.data.generateRawObj();
    labelSegmentDataRawObj.locked = this.locked;
    return labelSegmentDataRawObj;
  }

  isSame(segmentData) {
    return super.isSame(segmentData) && this.data.isSame(segmentData.data);
  }
}

export default LabelSegmentData;

<template>
  <canvas></canvas>
</template>

<script>
import viewMode from '../modules/viewMode.js'
import TimelineCanvasLocalCoords from './modules/TimelineCanvasLocalCoords.js'
import TimelineSegmentDomRectsToDragWithInfo from './modules/TimelineSegmentDomRectsToDragWithInfo.js'
import timelineCanvas from './mixins/timelineCanvas.js'
import utils from '../../../modules/utils.js'
import canvasUtils from './modules/canvasUtils.js'

export default {
  mixins: [
    timelineCanvas,
  ],

  watch: {
    verticalOffsetPx() { this.$_setDirty(true); },
    timelineCanvasLocalCoordsAtMousedown() { this.$_setDirty(true); },
    timelineCanvasLocalCoordsAtMousemove() { this.$_setDirty(true); },
    viewMode() { this.$_setDirty(true); },
  },

  props: {
    timelineCanvasLocalCoordsAtMousedown: { type: TimelineCanvasLocalCoords },
    timelineCanvasLocalCoordsAtMousemove: { type: TimelineCanvasLocalCoords },
    isMousedownOnRulerArea: { type: Boolean },
    timelineSegmentDomRectsToDragWithInfo: { type: TimelineSegmentDomRectsToDragWithInfo },
    segmentDragOffsetTimePx: { type: Number },
    numVisibleTimelines: { type: Number },
    viewMode: { type: String },
    verticalOffsetPx: { type: Number },
    timelineHeightPx: { type: Number },
    minimumSegmentDurationPx: { type: Number },
    timeResolution: { type: Number },
    timelineViewBeginMsec: { type: Number },
  },

  data() {
    return {
      $_timelineCanvasLocalXOnMousemove: null,
      $_timelineCanvasLocalYOnMousemove: null,
      $_timeMsecOnMousemove: null,
    };
  },

  computed: {
    $_visibleTimelineDataIdxAtMousedown() {
      if (!this.timelineCanvasLocalCoordsAtMousedown) return null;
      let mousedownVisibleVerticalOffsetPx = this.timelineCanvasLocalCoordsAtMousedown.y - this.verticalOffsetPx;
      let mousedownVisibleTimelineDataIdx = Math.floor(mousedownVisibleVerticalOffsetPx / this.timelineHeightPx);
      if ((mousedownVisibleTimelineDataIdx < 0) || (mousedownVisibleTimelineDataIdx >= this.numVisibleTimelines)) return null;
      return mousedownVisibleTimelineDataIdx;
    },
  },

  methods: {
    /* public */
    onMousemove(event) {
      let timelineOverlayCanvasBoundingClientRect = this.$el.getBoundingClientRect();
      if (
        (event.clientY >= timelineOverlayCanvasBoundingClientRect.y) &&
        (event.clientY < timelineOverlayCanvasBoundingClientRect.y + timelineOverlayCanvasBoundingClientRect.height) &&
        (event.clientX >= timelineOverlayCanvasBoundingClientRect.x) &&
        (event.clientX < timelineOverlayCanvasBoundingClientRect.x + timelineOverlayCanvasBoundingClientRect.width)
      ) {
        let timelineCanvasLocalXOnMousemove = event.clientX - timelineOverlayCanvasBoundingClientRect.x;
        let timeMsecOnMousemove = this.timelineViewBeginMsec + utils.resolve(timelineCanvasLocalXOnMousemove, this.timeResolution);
        if (timeMsecOnMousemove < 0) timeMsecOnMousemove = 0;
        this.$data.$_timeMsecOnMousemove = timeMsecOnMousemove;
        this.$data.$_timelineCanvasLocalXOnMousemove = utils.unitize(timeMsecOnMousemove - this.timelineViewBeginMsec, this.timeResolution);
        this.$data.$_timelineCanvasLocalYOnMousemove = event.clientY - timelineOverlayCanvasBoundingClientRect.y;
      } else {
        this.$data.$_timelineCanvasLocalXOnMousemove = null;
        this.$data.$_timelineCanvasLocalYOnMousemove = null;
        this.$data.$_timeMsecOnMousemove = null;
      }
      this.$_setDirty(true);
    },

    draw() {
      let self = this;
      let timelineOverlayCanvas = this.$data.$_timelineCanvasContext;
      this.$_draw(() => {
        if (this.$data.$_timelineCanvasLocalXOnMousemove !== null) {
          drawCursorBar();
        }
        if (this.timelineCanvasLocalCoordsAtMousedown && this.timelineCanvasLocalCoordsAtMousemove) {
          if (this.isMousedownOnRulerArea) {
            drawMarkerAreaGhost();
          } else {
            switch (this.viewMode) {
            case viewMode.create:
              if (this.$_visibleTimelineDataIdxAtMousedown !== null) {
                drawNewSegment(this.$_visibleTimelineDataIdxAtMousedown);
              }
              break;
            default:
              if (this.timelineSegmentDomRectsToDragWithInfo) {
                drawSegmentGhost();
              } else {
                drawDragArea();
              }
              break;
            }
          }
        }
      });

      function drawNewSegment(visibleTimelineDataIdx) {
        let timelineSegmentOffsetY = visibleTimelineDataIdx * self.timelineHeightPx + self.verticalOffsetPx;
        timelineOverlayCanvas.beginPath();
        timelineOverlayCanvas.rect(...canvasUtils.dotByDotOffsetRectArgs(
          self.timelineCanvasLocalCoordsAtMousedown.x,
          timelineSegmentOffsetY,
          self.timelineCanvasLocalCoordsAtMousemove.x - self.timelineCanvasLocalCoordsAtMousedown.x,
          self.timelineHeightPx,
        ));
        timelineOverlayCanvas.fillStyle = 'rgba(207, 207, 207, 0.6)';
        timelineOverlayCanvas.fill();
        timelineOverlayCanvas.setLineDash([ 4, 4 ]);
        timelineOverlayCanvas.lineWidth = 1;
        timelineOverlayCanvas.strokeStyle = 'rgb(130, 130, 130)';
        timelineOverlayCanvas.stroke();
      }

      function drawCursorBar() {
        timelineOverlayCanvas.beginPath();
        timelineOverlayCanvas.moveTo(...canvasUtils.dotByDotOffsetCoordArgs(self.$data.$_timelineCanvasLocalXOnMousemove, 0));
        timelineOverlayCanvas.lineTo(...canvasUtils.dotByDotOffsetCoordArgs(self.$data.$_timelineCanvasLocalXOnMousemove, self.$data.$_timelineCanvasHeightPx));
        timelineOverlayCanvas.setLineDash([]);
        timelineOverlayCanvas.lineWidth = 1;
        timelineOverlayCanvas.strokeStyle = 'rgb(0, 0, 0)';
        timelineOverlayCanvas.stroke();
        timelineOverlayCanvas.font = 'normal 12px sans-serif';
        timelineOverlayCanvas.fillStyle = 'rgb(0, 0, 0)';
        timelineOverlayCanvas.textAlign = 'left';
        timelineOverlayCanvas.textBaseline = 'middle';

        let remainingTimeMsec = self.$data.$_timeMsecOnMousemove;
        let timeHour = utils.msecToHour(remainingTimeMsec);
        remainingTimeMsec -= utils.hourToMsec(timeHour);
        let timeMinutes = utils.msecToMin(remainingTimeMsec);
        remainingTimeMsec -= utils.minToMsec(timeMinutes);
        let timeSec = utils.msecToSec(remainingTimeMsec);
        remainingTimeMsec -= utils.secToMsec(timeSec);
        let timeMsec = remainingTimeMsec;
        timelineOverlayCanvas.fillText(
          utils.formatTime(timeHour, timeMinutes, timeSec, timeMsec),
          self.$data.$_timelineCanvasLocalXOnMousemove + 10,
          self.$data.$_timelineCanvasLocalYOnMousemove,
        );
      }

      function drawMarkerAreaGhost() {
        timelineOverlayCanvas.beginPath();
        timelineOverlayCanvas.rect(...canvasUtils.dotByDotOffsetRectArgs(
          self.timelineCanvasLocalCoordsAtMousedown.x,
          0,
          self.timelineCanvasLocalCoordsAtMousemove.x - self.timelineCanvasLocalCoordsAtMousedown.x,
          self.$data.$_timelineCanvasHeightPx,
        ));
        timelineOverlayCanvas.setLineDash([ 4, 4 ]);
        timelineOverlayCanvas.lineWidth = 1;
        timelineOverlayCanvas.strokeStyle = 'rgb(130, 130, 130)';
        timelineOverlayCanvas.stroke();
      }

      function drawSegmentGhost() {
        let originalSegmentDomRects = self.timelineSegmentDomRectsToDragWithInfo.domRects;
        let endTypes = self.timelineSegmentDomRectsToDragWithInfo.endTypes;
        let edgeType = self.timelineSegmentDomRectsToDragWithInfo.edgeType;
        let numSegments = originalSegmentDomRects.length;
        for (let segmentIdx = 0; segmentIdx < numSegments; ++segmentIdx) {
          let originalSegmentDomRect = originalSegmentDomRects[segmentIdx];
          let endType = endTypes[segmentIdx];
          let x = null;
          let width = null;
          switch (edgeType) {
          case TimelineSegmentDomRectsToDragWithInfo.edgeType.right:
            x = originalSegmentDomRect.x;
            width = originalSegmentDomRect.width + self.segmentDragOffsetTimePx;
            if (width < self.minimumSegmentDurationPx) {
              width = self.minimumSegmentDurationPx;
            }
            break;
          case TimelineSegmentDomRectsToDragWithInfo.edgeType.left:
            {
              x = originalSegmentDomRect.x + self.segmentDragOffsetTimePx;
              let maximumX = originalSegmentDomRect.x + originalSegmentDomRect.width - self.minimumSegmentDurationPx;
              if (x > maximumX) {
                x = maximumX;
              }
              width = originalSegmentDomRect.width - (x - originalSegmentDomRect.x);
            }
            break;
          case TimelineSegmentDomRectsToDragWithInfo.edgeType.none:
            switch (endType) {
              case TimelineSegmentDomRectsToDragWithInfo.endType.bothEndFinite:
                x = originalSegmentDomRect.x + self.segmentDragOffsetTimePx;
                width = originalSegmentDomRect.width;
                break;
              case TimelineSegmentDomRectsToDragWithInfo.endType.rightEndInfinite:
                x = originalSegmentDomRect.x + self.segmentDragOffsetTimePx;
                width = originalSegmentDomRect.width - self.segmentDragOffsetTimePx;
                break;
              case TimelineSegmentDomRectsToDragWithInfo.endType.leftEndInfinite:
                x = originalSegmentDomRect.x;
                width = originalSegmentDomRect.width + self.segmentDragOffsetTimePx;
                break;
              case TimelineSegmentDomRectsToDragWithInfo.endType.bothEndInfinite:
                x = originalSegmentDomRect.x;
                width = originalSegmentDomRect.width;
                break;
            }
            break;
          }
          timelineOverlayCanvas.beginPath();
          timelineOverlayCanvas.rect(...canvasUtils.dotByDotOffsetRectArgs(
            x,
            originalSegmentDomRect.y,
            width,
            originalSegmentDomRect.height,
          ));
          timelineOverlayCanvas.setLineDash([ 4, 4 ]);
          timelineOverlayCanvas.lineWidth = 1;
          timelineOverlayCanvas.strokeStyle = 'rgba(50, 50, 50, 1)';
          timelineOverlayCanvas.stroke();
        }
      }

      function drawDragArea() {
        timelineOverlayCanvas.beginPath();
        timelineOverlayCanvas.rect(...canvasUtils.dotByDotOffsetRectArgs(
          self.timelineCanvasLocalCoordsAtMousedown.x,
          self.timelineCanvasLocalCoordsAtMousedown.y,
          self.timelineCanvasLocalCoordsAtMousemove.x - self.timelineCanvasLocalCoordsAtMousedown.x,
          self.timelineCanvasLocalCoordsAtMousemove.y - self.timelineCanvasLocalCoordsAtMousedown.y,
        ));
        timelineOverlayCanvas.setLineDash([ 4, 4 ]);
        timelineOverlayCanvas.lineWidth = 1;
        timelineOverlayCanvas.strokeStyle = 'rgba(50, 50, 50, 1)';
        timelineOverlayCanvas.stroke();
      }
    },
  }
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "video",
    _vm._g(
      _vm._b(
        {
          ref: "video",
          on: {
            play: function ($event) {
              _vm.isPlaying = true
            },
            pause: function ($event) {
              _vm.isPlaying = false
            },
            timeupdate: _vm.notifySeconds,
            seeked: _vm.fixNotifiedSecondsArrayIdx,
          },
        },
        "video",
        _vm.videoProps,
        false
      ),
      _vm.videoListeners
    ),
    [
      _vm._l(_vm.sourcePropsArray, function (props, i) {
        return _c(
          "source",
          _vm._b({ key: `source-props-${i}` }, "source", props, false)
        )
      }),
      _vm._l(_vm.trackPropsArray, function (props, i) {
        return _c(
          "track",
          _vm._b({ key: `track-props-${i}` }, "track", props, false)
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Logger from '../../internal/modules/logger.js'
import utils from '../../internal/modules/utils.js'
import { Reference } from './referencePool.js'

const referenceKeyDelimiter = '::';
const supportedDataVersions = [ '0.5' ];
const generatingDataVersion = '0.5';

class ScattData {
  constructor(scattDataRawObj, segmentDataTypes) {
    if (!supportedDataVersions.includes(scattDataRawObj.data_version)) {
      Logger.error('Data version ' + scattDataRawObj.data_version + ' is not supported');
      return;
    }
    for (let timelineDataId in scattDataRawObj.timeline_data_set) {
      if (timelineDataId.includes(referenceKeyDelimiter)) {
        Logger.error('Can not contain ' + referenceKeyDelimiter + ' in timeline data ID(' + timelineDataId + ')');
        return;
      }
      let timelineData = scattDataRawObj.timeline_data_set[timelineDataId];
      for (let segmentId in timelineData) {
        if (segmentId.includes(referenceKeyDelimiter)) {
          Logger.error('Can not contain ' + referenceKeyDelimiter + ' in segment ID(' + segmentId + ')');
          return;
        }
      }
    }

    this.dataVersion = scattDataRawObj.data_version;
    this.timelineDataSet = new TimelineDataSet();
    if (Object.keys(scattDataRawObj).includes('timeline_data_set')) {
      for (let [ timelineDataId, timelineDataRawObj ] of Object.entries(scattDataRawObj.timeline_data_set)) {
        let timelineData = TimelineData.generateFromRawObj(timelineDataRawObj, segmentDataTypes);
        if (timelineData === null) continue;
        this.timelineDataSet[timelineDataId] = timelineData;
      }
    }
  }

  static loadReferences(scattDataRawObj) {
    return Object.values(scattDataRawObj.references).map(timelineSegmentIdRawObj => {
      let timelineSegmentIdReferFrom = TimelineSegmentId.generateFromRawObj(timelineSegmentIdRawObj.refer_from);
      let timelineSegmentIdReferTo = TimelineSegmentId.generateFromRawObj(timelineSegmentIdRawObj.refer_to);
      return new Reference(timelineSegmentIdRawObj.ref_class, timelineSegmentIdReferFrom, timelineSegmentIdReferTo);
    });
  }

  generateRawObj(allReferences) {
    let scattDataRawObj = new Object();
    scattDataRawObj.data_version = generatingDataVersion;
    scattDataRawObj.timeline_data_set = new Object();
    for (let [ timelineDataId, timelineData ] of Object.entries(this.timelineDataSet)) {
      scattDataRawObj.timeline_data_set[timelineDataId] = timelineData.generateRawObj();
    }
    scattDataRawObj.references = new Array();
    if (allReferences) {
      for (let reference of Object.values(allReferences)) {
        let referenceRawObj = new Object();
        referenceRawObj.ref_class = reference.refClass;
        referenceRawObj.refer_from = reference.referFrom.mergedId;
        referenceRawObj.refer_to = reference.referTo.mergedId;
        scattDataRawObj.references.push(referenceRawObj);
      }
    }
    return scattDataRawObj;
  }

  static generateEmpty() {
    return new ScattData({ data_version: generatingDataVersion, timeline_data_set: {} }, []);
  }

  clone(isRetainingLocked) {
    let newScattData = ScattData.generateEmpty();
    for (let [ timelineDataId, timelineData ] of Object.entries(this.timelineDataSet)) {
      newScattData.timelineDataSet[timelineDataId] = timelineData.clone(isRetainingLocked);
    }
    return newScattData;
  }
}

class TimelineDataDifferencePair {
  constructor(before = new Object(), after = new Object()) {
    this.before = before;
    this.after = after;
  }
}

class TimelineDataSet extends Object {
  get ids() {
    return Object.keys(this);
  }

  get newValidId() {
    return utils.generateUniqueId(this.ids, 0, null);
  }

  get nextIdx() {
    return this.ids.length;
  }

  getSegment(timelineSegmentId) {
    let timelineData = this[timelineSegmentId.timelineDataId];
    if (!timelineData) return null;
    if (!Object.keys(timelineData.segments).includes(timelineSegmentId.segmentId)) return null;
    return timelineData.segments[timelineSegmentId.segmentId];
  }

  isTimelineSegmentImmutable(timelineSegmentId) {
    let timelineData = this[timelineSegmentId.timelineDataId];
    return timelineData.isSegmentImmutable(timelineSegmentId.segmentId);
  }
}

class TimelineData {
  constructor(
    name,
    segmentDataType,
    segments,
    createdAt,
    modifiedAt,
    authorId,
    authorName,
    timelineDataIdx,
    hidden,
    readonly,
    locked,
  )
  {
    this.name = name;
    this.segmentDataType = segmentDataType;
    this.segments = segments;
    this.createdAt = new Date(createdAt);
    this.modifiedAt = new Date(modifiedAt);
    this.authorId = authorId;
    this.authorName = authorName;
    this.hidden = hidden;
    this.locked = locked;
    this.readonly = readonly;
    this.timelineDataIdx = timelineDataIdx;
  }

  generateRawObj() {
    let timelineDataRawObj = new Object();
    timelineDataRawObj.name = this.name;
    timelineDataRawObj.segment_data_type = String(this.segmentDataType);
    timelineDataRawObj.segments = new Object();
    for (let [ segmentId, segment ] of Object.entries(this.segments)) {
      timelineDataRawObj.segments[segmentId] = segment.generateRawObj();
    }
    timelineDataRawObj.created_at = this.createdAt.toISOString();
    timelineDataRawObj.modified_at = this.modifiedAt.toISOString();
    timelineDataRawObj.author_id = this.authorId;
    timelineDataRawObj.author_name = this.authorName;
    timelineDataRawObj.timeline_data_idx = this.timelineDataIdx;
    timelineDataRawObj.hidden = this.hidden;
    timelineDataRawObj.readonly = this.readonly;
    timelineDataRawObj.locked = this.locked;
    return timelineDataRawObj;
  }

  static generateEmpty(name, segmentDataType, authorId, authorName, timelineDataIdx, readonly) {
    let createdAt = new Date();
    let modifiedAt = new Date(createdAt);
    return new TimelineData(
      name,
      segmentDataType,
      new Object(),
      createdAt,
      modifiedAt,
      authorId,
      authorName,
      timelineDataIdx,
      false,
      readonly,
      false,
    );
  }

  static constructSegmentData(segmentDataType, segmentsRawObj) {
    let segments = new Object();
    for (let [ segmentId, segmentRawObj ] of Object.entries(segmentsRawObj)) {
      let segment = segmentDataType.generateFromRawObj(segmentRawObj.begin, segmentRawObj.end, segmentRawObj.data);
      segments[segmentId] = segment;
    }
    return segments;
  }

  static getSegmentDataType(segmentDataTypeString, segmentDataTypes) {
    /***************************************************************
     * NOTE: Don't use .name property! (Webpack minifies class name)
     ***************************************************************/
    let foundSegmentDataType = segmentDataTypes.find(segmentDataType => (String(segmentDataType) === segmentDataTypeString));
    if (foundSegmentDataType === undefined) return null;
    return foundSegmentDataType;
  }

  static generateFromRawObj(timelineDataRawObj, segmentDataTypes) {
    let segmentDataType = TimelineData.getSegmentDataType(timelineDataRawObj.segment_data_type, segmentDataTypes);
    if (segmentDataType === null) return null;
    let segments = TimelineData.constructSegmentData(segmentDataType, timelineDataRawObj.segments);
    return new TimelineData(
      timelineDataRawObj.name,
      segmentDataType,
      segments,
      timelineDataRawObj.created_at,
      timelineDataRawObj.modified_at,
      timelineDataRawObj.author_id,
      timelineDataRawObj.author_name,
      timelineDataRawObj.timeline_data_idx,
      timelineDataRawObj.hidden,
      timelineDataRawObj.readonly,
      timelineDataRawObj.locked,
    );
  }

  get segmentIds() {
    return Object.keys(this.segments);
  }

  get numSegments() {
    return this.segmentIds.length;
  }

  get newValidSegmentId() {
    return utils.generateUniqueId(this.segmentIds, 0, null);
  }

  clone(isRetainingLocked) {
    let newSegments = new Object();
    for (let [ segmentId, segment ] of Object.entries(this.segments)) {
      newSegments[segmentId] = segment.clone(isRetainingLocked);
    }
    let newTimelineData = new TimelineData(
      this.name,
      this.segmentDataType,
      newSegments,
      this.createdAt,
      this.modifiedAt,
      this.authorId,
      this.authorName,
      this.timelineDataIdx,
      this.hidden,
      (isRetainingLocked)? this.readonly : false,
      (isRetainingLocked)? this.locked : false,
    );
    return newTimelineData;
  }

  isSegmentImmutable(segmentId) {
    if (this.readonly) return true;
    if (this.locked) return true;
    if (this.segments[segmentId].locked) return true;
    return false;
  }

  getDifferencePair(that) {
    let before = new Object();
    let after = new Object();
    if (this.segmentDataType !== that.segmentDataType) {
      before.segmentDataType = this.segmentDataType;
      after.segmentDataType = that.segmentDataType;
    }
    if (this.timelineDataIdx !== that.timelineDataIdx) {
      before.timelineDataIdx = this.timelineDataIdx;
      after.timelineDataIdx = that.timelineDataIdx;
    }
    if (this.name !== that.name) {
      before.name = this.name;
      after.name = that.name;
    }
    if (this.hidden !== that.hidden) {
      before.hidden = this.hidden;
      after.hidden = that.hidden;
    }
    if (this.locked !== that.locked) {
      before.locked = this.locked;
      after.locked = that.locked;
    }
    let wholeSegmentIdSet = new Set(this.segmentIds.concat(that.segmentIds));
    before.segments = new Object();
    after.segments = new Object();
    for (let segmentId of wholeSegmentIdSet) {
      let thisSegment = this.segments[segmentId];
      let thatSegment = that.segments[segmentId];
      if ((thisSegment !== undefined) && (thatSegment !== undefined)) {
        if (!thisSegment.isSame(thatSegment)) {
          before.segments[segmentId] = thisSegment.clone();
          after.segments[segmentId] = thatSegment.clone();
        }
      } else if (thisSegment !== undefined) {
        before.segments[segmentId] = thisSegment.clone();
        after.segments[segmentId] = null;
      } else if (thatSegment !== undefined) {
        before.segments[segmentId] = null;
        after.segments[segmentId] = thatSegment.clone();
      }
    }
    if (Object.keys(before.segments).length === 0) delete before.segments;
    if (Object.keys(after.segments).length === 0) delete after.segments;
    if ((Object.keys(before).length === 0) && (Object.keys(after).length === 0)) {
      return null;
    } else {
      return new TimelineDataDifferencePair(before, after);
    }
  }

  isSame(that) {
    if (this.segmentDataType !== that.segmentDataType) return false;
    if (this.timelineDataIdx !== that.timelineDataIdx) return false;
    if (this.name !== that.name) return false;
    if (this.hidden !== that.hidden) return false;
    if (this.locked !== that.locked) return false;
    if (this.segmentIds.length !== that.segmentIds.length) return false;
    for (let thisSegmentid of this.segmentIds) {
      if (!that.segmentIds.includes(thisSegmentid)) return false;
    }
    for (let thatSegmentid of that.segmentIds) {
      if (!this.segmentIds.includes(thatSegmentid)) return false;
    }
    for (let segmentId of this.segmentIds) {
      if (!this.segments[segmentId].isSame(that.segments[segmentId])) return false;
    }
    return true;
  }

  getBeginTimeAscendingOrderIdFunction() {
    return (segmentIdA, segmentIdB) => {
      let segmentA = this.segments[segmentIdA];
      let segmentB = this.segments[segmentIdB];
      return (segmentA.begin === null)? -1 : (segmentA.begin - segmentB.begin)
    };
  }
}

class TimelineSegmentId {
  constructor(timelineDataId, segmentId) {
    this.timelineDataId = timelineDataId;
    this.segmentId = segmentId;
  }

  get mergedId() {
    return this.timelineDataId + referenceKeyDelimiter + this.segmentId;
  }

  isSame(otherTimelineSegmentId) {
    if (this.timelineDataId !== otherTimelineSegmentId.timelineDataId) return false;
    if (this.segmentId !== otherTimelineSegmentId.segmentId) return false;
    return true;
  }

  static generateFromRawObj(timelineSegmentIdRawObj) {
    return new TimelineSegmentId(...timelineSegmentIdRawObj.split(referenceKeyDelimiter, 2));
  }
}

class TimelineSegmentContentDataBase {
  drawOnTimelineSegment({ canvas2d, rect, paddingPx }) {
    const segmentFontSizePxMax = 12;
    const segmentTextOffsetPx = 5;
    const segmentTextOmissionIndicator = '...';

    let text = this.toString();
    let textFontSizePx = rect.height - 4;
    let textLeftX = rect.x + segmentTextOffsetPx;
    let textMiddleY = rect.y + rect.height / 2;
    let textWidthPxMax = rect.x + rect.width - (textLeftX + paddingPx * 2);
    canvas2d.textBaseline = 'middle';
    canvas2d.font = 'normal ' + String((textFontSizePx > segmentFontSizePxMax) ? segmentFontSizePxMax : textFontSizePx) + 'px sans-serif';
    let textWidthPx = canvas2d.measureText(text).width;
    if (textWidthPx < textWidthPxMax) {
      canvas2d.fillText(text, textLeftX, textMiddleY);
    } else {
      let segmentTextOmissionIndicatorWidthPx = canvas2d.measureText(segmentTextOmissionIndicator).width;
      if (segmentTextOmissionIndicatorWidthPx > textWidthPxMax) {
        canvas2d.fillText(segmentTextOmissionIndicator, textLeftX, textMiddleY, textWidthPxMax);
      } else {
        let subTextLastIdxOfFirstOverflow = searchSubTextLastIdxOfFirstOverflow(canvas2d, text, textWidthPxMax);
        let subTextOfFirstOverflow = text.substring(0, subTextLastIdxOfFirstOverflow);
        let subTextOfFirstOverflowWithOmissionIndicator = subTextOfFirstOverflow + segmentTextOmissionIndicator;
        canvas2d.fillText(subTextOfFirstOverflowWithOmissionIndicator, textLeftX, textMiddleY);
      }
    }

    function searchSubTextLastIdxOfFirstOverflow(canvas2d, text, textWidthPxMax) {
      let isOverflowedAt = (idx) => {
        let subTextWithOmissionIndicator = text.substring(0, idx) + segmentTextOmissionIndicator;
        let subTextWithOmissionIndicatorWidthPx = canvas2d.measureText(subTextWithOmissionIndicator).width;
        return (subTextWithOmissionIndicatorWidthPx > textWidthPxMax);
      };
      let leftIdx = 0;
      let rightIdx = text.length - 2;
      while (leftIdx <= rightIdx) {
        let middleIdx = Math.floor((leftIdx + rightIdx) / 2);
        if (!isOverflowedAt(middleIdx) && isOverflowedAt(middleIdx + 1)) {
          return middleIdx;
        } else {
          if (isOverflowedAt(middleIdx) && isOverflowedAt(middleIdx + 1)) {
            rightIdx = middleIdx - 1;
          } else if (!isOverflowedAt(middleIdx) && !isOverflowedAt(middleIdx + 1)) {
            leftIdx = middleIdx + 1;
          }
        }
      }
      return (text.length - 2);
    }
  }

  toString() {
    return '';
  }
}

class TimelineSegmentDataBase {
  static contentData = TimelineSegmentContentDataBase;

  constructor(begin, end, data, isLocked) {
    this.begin = begin;
    this.end = end;
    this.data = data;
    this.locked = isLocked;
  }

  isSame(segmentData) {
    return (this.begin === segmentData.begin) &&
      (this.end === segmentData.end) &&
      (this.locked === segmentData.locked);
  }

  get hasNoRange() {
    return (this.begin === null) && (this.end === null);
  }

  clone(isRetainingLocked) {
    let newSegmentData = this.constructor.generateFromRawObj(this.begin, this.end, this.data.generateRawObj());
    if (isRetainingLocked) {
      newSegmentData.locked = this.locked;
    }
    return newSegmentData;
  }
}

export {
  ScattData,
  TimelineDataSet,
  TimelineSegmentId,
  TimelineData,
  TimelineSegmentDataBase,
  TimelineSegmentContentDataBase,
};

function getCommonConstructor(valueA, valueB) {
  if ((valueA === null) || (valueB === null)) return null;
  let valueAConstructor = valueA.constructor;
  let valueBConstructor = valueB.constructor;
  if (valueAConstructor !== valueBConstructor) return null;
  return valueAConstructor;
}

export default {
  minimumMultipleGreaterThan(multiplier, value) {
    return Math.ceil(value / multiplier) * multiplier;
  },

  areRectsOverwrapped(rect1, rect2) {
    if ((rect1.x + rect1.width) <= rect2.x) {
      return false;
    }
    if (rect1.x >= (rect2.x + rect2.width)) {
      return false;
    }
    if ((rect1.y + rect1.height) <= rect2.y) {
      return false;
    }
    if (rect1.y >= (rect2.y + rect2.height)) {
      return false;
    }
    return true;
  },

  isRectInRect(rect1, rect2) {
    if (rect1.x < rect2.x) {
      return false;
    }
    if (rect1.y < rect2.y) {
      return false;
    }
    if ((rect1.y + rect1.height) < (rect2.y + rect2.height)) {
      return false;
    }
    if ((rect1.x + rect1.width) < (rect2.x + rect2.width)) {
      return false;
    }
    return true;
  },

  isInRect(rect, x, y) {
    if (rect.x > x) {
      return false;
    }
    if (rect.y > y) {
      return false;
    }
    if ((rect.x + rect.width) < x) {
      return false;
    }
    if ((rect.y + rect.height) < y) {
      return false;
    }
    return true;
  },

  getOffsetRectToBeInRect(rect1, rect2) {
    let offsetRect = new DOMRect(0, 0, rect1.width, rect1.height);
    if ((rect1.width > rect2.width) || (rect1.x < rect2.x)) {
      offsetRect.x = rect1.x - rect2.x;
    } else if ((rect1.x + rect1.width) >= (rect2.x + rect2.width)) {
      offsetRect.x = (rect1.x + rect1.width) - (rect2.x + rect2.width);
    }
    if ((rect1.height > rect2.height) || (rect1.y < rect2.y)) {
      offsetRect.y = rect2.y - rect1.y;
    } else if ((rect1.y + rect1.height) >= (rect2.y + rect2.height)) {
      offsetRect.y = (rect2.y + rect2.height) - (rect1.y + rect1.height);
    }
    return offsetRect;
  },

  clamp(x, min, max) {
    x = (x > max)? max : x;
    x = (x < min)? min : x;
    return x;
  },

  countNumLines(text) {
    let stringLength = text.length;
    let lineEndCount = 0;
    for (let i = 0; i < stringLength; ++i) {
      if (text[i] == '\n') {
        lineEndCount++;
      }
    }
    return lineEndCount + 1;
  },

  getNextPowerOf2(x, inverted) {
    let log2OfX = Math.log2(x);
    return Math.pow(2.0, (inverted)? (Math.ceil(log2OfX) - 1) : (Math.floor(log2OfX) + 1));
  },

  getNextInteger(x, inverted) {
    return (inverted)? (Math.ceil(x) - 1) : (Math.floor(x) + 1);
  },

  minimumMultiplyOfN(value, n) {
    return (Math.floor((value - Number.MIN_VALUE) / n) + 1) * n;
  },

  interpolateLinearly(rate, value1, value2) {
    if (rate > 1) rate = 1;
    else if (rate < 0) rate = 0;
    return value1 * (1 - rate) + value2 * rate;
  },

  generateUniqueId(existingIds, count, prefix) {
    count = Math.floor(count);
    if (prefix) {
      let uniqueId = prefix;
      if (count > 0) uniqueId += String(count);
      if (!existingIds.includes(uniqueId)) {
        return uniqueId;
      }
    } else {
      let uniqueId = String(count);
      if (!existingIds.includes(uniqueId)) {
        return uniqueId
      }
    }
    return this.generateUniqueId(existingIds, count + 1, prefix);
  },

  resolve(value, resolution) {
    if (resolution === 0) throw 'resolution is zero';
    return value / resolution;
  },

  unitize(value, resolution) {
    return value * resolution;
  },

  generateCSSRgbColorString(red, green, blue) {
    return 'rgb(' + String(red) + ', ' + String(green) + ', ' + String(blue) + ')';
  },

  generateCSSRgbaColorString(red, green, blue, alpha) {
    return 'rgba(' + String(red) + ', ' + String(green) + ', ' + String(blue) + ', ' + String(alpha) + ')';
  },

  isSame(valueA, valueB, ignoreArrayOrder = false) {
    if ((valueA === null) && (valueB === null)) return true;
    let valueConstructor = getCommonConstructor(valueA, valueB);
    switch (valueConstructor) {
    case Object:
      return this.isSameObject(valueA, valueB, ignoreArrayOrder);
    case Array:
      return this.isSameArray(valueA, valueB, ignoreArrayOrder);
    default:
      if (Object.getOwnPropertyNames(valueConstructor.prototype).includes('isSame')) {
        return valueA.isSame(valueB);
      } else {
        return (valueA === valueB);
      }
    case null:
      return false;
    }
  },

  isSameArray(arrayA, arrayB, ignoreArrayOrder = false) {
    if (getCommonConstructor(arrayA, arrayB) !== Array) throw new TypeError();
    let numArrayAComponents = arrayA.length;
    let numArrayBComponents = arrayB.length;
    if (numArrayAComponents !== numArrayBComponents) return false;
    let numArrayComponents = numArrayAComponents;
    if (ignoreArrayOrder) {
      let foundSameIdcs = new Array();
      for (let valueA of arrayA) {
        let foundSameIdx = null;
        for (let idx = 0; idx < numArrayComponents; ++idx) {
          if (foundSameIdcs.includes(idx)) continue;
          let valueB = arrayB[idx];
          if (this.isSame(valueA, valueB, ignoreArrayOrder)) {
            foundSameIdx = idx;
            break;
          }
        }
        if (foundSameIdx === null) return false;
        foundSameIdcs.push(foundSameIdx);
      }
    } else {
      let numArrayAComponents = arrayA.length;
      for (let idx = 0; idx < numArrayAComponents; ++idx) {
        if (!this.isSame(arrayA[idx], arrayB[idx], ignoreArrayOrder)) return false;
      }
    }
    return true;
  },

  isSameObject(objectA, objectB, ignoreArrayOrder) {
    if (getCommonConstructor(objectA, objectB) !== Object) throw new TypeError();
    let keysOfObjectA = Object.keys(objectA);
    let keysOfObjectB = Object.keys(objectB);
    for (let keyOfObjectA of keysOfObjectA) {
      if (!keysOfObjectB.includes(keyOfObjectA)) return false;
    }
    for (let keyOfObjectB of keysOfObjectB) {
      if (!keysOfObjectA.includes(keyOfObjectB)) return false;
    }
    let keysOfObject = keysOfObjectA;
    for (let keyOfObject of keysOfObject) {
      if (!this.isSame(objectA[keyOfObject], objectB[keyOfObject], ignoreArrayOrder)) return false;
    }
    return true;
  },

  startPromiseChain() {
    return Promise.resolve();
  },

  padZero(number, numDigits) {
    return ('0'.repeat(numDigits) + String(number)).slice(-numDigits);
  },

  async readFileAsUint8Array(filePath) {
    let response = await fetch(filePath);
    let dataLength = Number(response.headers.get('Content-Length'));
    let buffer = new Uint8Array(dataLength);
    let reader = response.body.getReader();
    let writeOffset = 0;
    return await reader.read().then(
      async function writeBackOnBuffer({ done, value }) {
        if (done) return buffer;
        buffer.set(value, writeOffset);
        writeOffset += value.length;
        return await reader.read().then(writeBackOnBuffer);
      }
    );
  },

  msecToHour(msec) {
    return Math.trunc(msec / (60 * 60 * 1000));
  },

  hourToMsec(hour) {
    return hour * (60 * 60 * 1000);
  },

  msecToMin(msec) {
    return Math.trunc(msec / (60 * 1000));
  },

  minToMsec(minutes) {
    return minutes * (60 * 1000);
  },

  msecToSec(msec) {
    return Math.trunc(msec / 1000);
  },

  secToMsec(sec) {
    return sec * 1000;
  },

  formatTime(hour, min, sec, msec) {
    let zeroPaddingMin = '00';
    let zeroPaddingSec = '00';
    let zeroPaddingMsec = '000';
    return String(hour) + ':' +
      (zeroPaddingMin + String(min)).slice(-zeroPaddingMin.length) + ':' +
      (zeroPaddingSec + String(sec)).slice(-zeroPaddingSec.length) + ':' +
      (zeroPaddingMsec + String(msec)).slice(-zeroPaddingMsec.length);
  },
};
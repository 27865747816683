var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-slider", {
    staticClass: "pl-1",
    attrs: {
      value: _vm.currentTime,
      "hide-details": "",
      color: "#616161",
      "track-color": "#616161",
      step: "0.01",
      min: "0",
      max: _vm.duration,
    },
    on: {
      change: _vm.changeTime,
      mousedown: _vm.emitMouseCondition,
      mouseup: _vm.emitMouseCondition,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "border-common", attrs: { id: "footer" } }, [
    _c(
      "div",
      {
        staticClass: "text-truncate",
        attrs: { id: "footer-message" },
        on: { click: _vm.showFooterMessageLogs },
      },
      [_vm._v(" " + _vm._s(_vm.$_latestMessage) + " ")]
    ),
    _c(
      "div",
      { attrs: { id: "footer-menu" } },
      [
        _c("a", { on: { click: _vm.showHelp } }, [_vm._v("Help(h)")]),
        _c("TextDivider", { attrs: { content: "-" } }),
        _c("a", { on: { click: _vm.showVersion } }, [
          _vm._v(_vm._s(_vm.toolTitle) + "(" + _vm._s(_vm.version) + ")"),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
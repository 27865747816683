<template>
  <dialog-base
    v-bind:shows="shows"
    v-bind:ok-callback="okCallback"
    v-bind:finalize-callback="finalizeCallback"
  >
    <template v-slot:body>
      <v-card-title v-if="title">{{ title }}</v-card-title>
      <v-card-text v-if="message">{{ message }}</v-card-text>
    </template>
  </dialog-base>
</template>

<script>
import DialogBase from './DialogBase.vue';

export default {
  components: {
    DialogBase,
  },

  props: {
    shows: { type: Boolean, default: false },
    okCallback: { type: Function, default: null },
    finalizeCallback: { type: Function, default: null },
    title: { type: String, default: null },
    message: { type: String, default: null },
  },
}
</script>
<template>
  <canvas-container
    ref="canvasContainer"
    v-bind:begin-sample-offset="$_canvasBeginSampleOffset"
    v-bind:end-sample-offset="$_canvasEndSampleOffset"
    v-bind:sampling-rate="audioSegmentSequence.samplingRate"
    v-on:mousedown="$_onMousedown"
    v-on:wheel="$_onWheel"
    v-on:resize="$_onResize"
  >
    <template v-slot:default="{ widthPx, heightPx, durationSec }">
      <waveform-canvas
        v-bind:width-px="widthPx"
        v-bind:height-px="heightPx"
        v-bind:waveform-digest="waveformDigest"
        v-bind:canvas-begin-sample-offset="$_canvasBeginSampleOffset"
        v-bind:canvas-end-sample-offset="$_canvasEndSampleOffset"
        v-bind:canvas-duration-sec="durationSec"
      >
      </waveform-canvas>

      <center-bar-canvas
        v-bind:width-px="widthPx"
        v-bind:height-px="heightPx"
      >
      </center-bar-canvas>
    </template>
  </canvas-container>
</template>

<script>
import CanvasContainer from './canvases/CanvasContainer.vue';
import WaveformCanvas from './canvases/WaveformCanvas.vue';
import CenterBarCanvas from './canvases/CenterBarCanvas.vue';
import WaveformDigest from '../modules/WaveformDigest.js';
import CanvasLocalCoords from './canvases/modules/CanvasLocalCoords.js';
import AudioSegmentSequence from '../modules/AudioSegmentSequence.js'; 
import RationalNumber from '../modules/RationalNumber.js';
import Utils from '../modules/Utils.js';
import ControlMode from '../modules/ControlMode.js';

const wheelEventFixDelayMs = 200;
const numContextSamplesMin = 100;

export default {
  components: {
    CanvasContainer,
    WaveformCanvas,
    CenterBarCanvas,
  },

  model: {
    prop: 'audioSegmentSequence',
    event: 'update',
  },

  watch: {
    readonly() { this.$_updateCursorStyle() },
    shiftKeyPressed() { this.$_updateCursorStyle() },
    '$data.$_canvasLocalCoordsAtMousedown'() { this.$_updateCursorStyle() },
  },

  props: {
    audioSegmentSequence: { type: AudioSegmentSequence },
    audioSegmentIdx:      { type: Number },
    waveformDigest:       { type: WaveformDigest },
    numContextSamples:    { type: Number },
    readonly:             { type: Boolean, default: false },
    begin:                { type: Boolean, default: false },
    end:                  { type: Boolean, default: false },
    controlMode:          { type: ControlMode },
    shiftKeyPressed:      { type: Boolean },
    sampleOffset:         { type: Number },
  },

  data() {
    return {
      $_boundingClientRect: null,
      $_canvasLocalCoordsAtMousedown: null,
      $_canvasLocalCoordsOnMousemove: null,
      $_timeoutIdOnWheel: null,
    };
  },

  computed: {
    $_firstAudioSegmentIdx() {
      if (this.audioSegmentSequence.numAudioSegments === 0) return null;
      return 0;
    },

    $_lastAudioSegmentIdx() {
      if (this.audioSegmentSequence.numAudioSegments === 0) return null;
      return this.audioSegmentSequence.numAudioSegments - 1;
    },

    $_previousAudioSegment() {
      if (this.$_firstAudioSegmentIdx === null) return null;
      if (this.audioSegmentIdx === this.$_firstAudioSegmentIdx) return null;
      return this.audioSegmentSequence.audioSegments[this.audioSegmentIdx - 1];
    },

    $_nextAudioSegment() {
      if (this.$_lastAudioSegmentIdx === null) return null;
      if (this.audioSegmentIdx === this.$_lastAudioSegmentIdx) return null;
      return this.audioSegmentSequence.audioSegments[this.audioSegmentIdx + 1];
    },

    $_targetAudioSegment() {
      return this.audioSegmentSequence.audioSegments[this.audioSegmentIdx];
    },

    $_targetAudioSegmentSample() {
      if (this.begin) return this.audioSegmentSequence.convertTime(
        this.$_targetAudioSegment.begin,
        AudioSegmentSequence.TimeUnit.sample,
      );
      if (this.end) return this.audioSegmentSequence.convertTime(
        this.$_targetAudioSegment.end,
        AudioSegmentSequence.TimeUnit.sample,
      );
      return null;
    },

    $_canvasCenterSampleMin() {
      if (this.begin) {
        if (this.$_previousAudioSegment === null) return null;
        return this.audioSegmentSequence.convertTime(
          this.$_previousAudioSegment.begin,
          AudioSegmentSequence.TimeUnit.sample,
        );
      } else if (this.end) {
        return this.audioSegmentSequence.convertTime(
          this.$_targetAudioSegment.begin,
          AudioSegmentSequence.TimeUnit.sample,
        );
      }
      return null;
    },

    $_canvasCenterSampleMax() {
      if (this.begin) {
        return this.audioSegmentSequence.convertTime(
          this.$_targetAudioSegment.end,
          AudioSegmentSequence.TimeUnit.sample,
        );
      } else if (this.end) {
        if (this.$_nextAudioSegment === null) return null;
        return this.audioSegmentSequence.convertTime(
          this.$_nextAudioSegment.end,
          AudioSegmentSequence.TimeUnit.sample,
        );
      }
      return null;
    },

    $_dragSampleOffsetMin() {
      if (this.$_targetAudioSegmentSample === null) return 0;
      if (this.$_canvasCenterSampleMin === null) return 0;
      let dragOffsetRationalTimeMax = this.$_canvasCenterSampleMin.subtract(this.$_targetAudioSegmentSample);
      return dragOffsetRationalTimeMax.toNumber();
    },

    $_dragSampleOffsetMax() {
      if (this.$_targetAudioSegmentSample === null) return 0;
      if (this.$_canvasCenterSampleMax === null) return 0;
      let dragOffsetRationalTimeMax = this.$_canvasCenterSampleMax.subtract(this.$_targetAudioSegmentSample);
      return dragOffsetRationalTimeMax.toNumber();
    },

    $_canvasBeginSampleOffset() {
      return this.$_targetAudioSegmentSample.toNumber() - this.numContextSamples + this.sampleOffset;
    },

    $_canvasEndSampleOffset() {
      return this.$_targetAudioSegmentSample.toNumber() + this.numContextSamples + this.sampleOffset;
    },

    $_isInsideCanvasOnMousemove() {
      if (this.$data.$_canvasLocalCoordsOnMousemove === null) return false;
      return this.$data.$_canvasLocalCoordsOnMousemove.isInside(this.$data.$_canvasLocalRect);
    },

    $_numContextSamplesMax() {
      return this.audioSegmentSequence.samplingRate.toNumber();
    },
  },

  mounted() {
    this.$emit('register-component-instance', this);
  },

  beforeDestroy() {
    this.$emit('unregister-component-instance');
  },

  methods: {
    $_onResize({ boundingClientRect, canvasLocalRect }) {
      this.$data.$_boundingClientRect = boundingClientRect;
      this.$data.$_canvasLocalRect = canvasLocalRect;
      this.$_updateNumContextSamples(this.numContextSamples);
    },

    $_onMousedown(mouseEvent) {
      this.$data.$_canvasLocalCoordsAtMousedown = CanvasLocalCoords.generateFromMouseEvent(mouseEvent, this.$data.$_boundingClientRect);
      switch (this.controlMode) {
        case ControlMode.zoom:
          return zoom(this, ((mouseEvent.shiftKey)? 0.5 : 2));
      }

      function zoom(self, scale) {
        return self.$_updateNumContextSamples(self.numContextSamples / scale);
      }
    },

    onMousemove(mouseEvent) {
      this.$data.$_canvasLocalCoordsOnMousemove = CanvasLocalCoords.generateFromMouseEvent(mouseEvent, this.$data.$_boundingClientRect);
      this.$_updateCursorStyle();
      switch (this.controlMode) {
        case ControlMode.zoom:
          return false;
        default:
          return drag(this);
      }

      function drag(self) {
        if (self.$data.$_canvasLocalCoordsAtMousedown === null) return false;
        if (self.readonly) return false;
        let dragOffsetPx = self.$data.$_canvasLocalCoordsOnMousemove.x - self.$data.$_canvasLocalCoordsAtMousedown.x;
        let dragSampleOffset = -(self.$refs.canvasContainer.sampleResolution * dragOffsetPx);
        self.$_updateSampleOffset(dragSampleOffset);
        return true;
      }
    },

    onMouseup() {
      let canvasLocalCoordsAtMousedown = this.$data.$_canvasLocalCoordsAtMousedown;
      this.$data.$_canvasLocalCoordsAtMousedown = null;
      switch (this.controlMode) {
        case ControlMode.zoom:
          return false;
        default:
          return fixDrag(this, canvasLocalCoordsAtMousedown);
      }

      function fixDrag(self, canvasLocalCoordsAtMousedown) {
        if (canvasLocalCoordsAtMousedown === null) return false;
        if (!self.readonly) self.$_fixSampleOffset();
        return true;
      }
    },

    $_onWheel(wheelEvent) {
      if (onWheel(this, wheelEvent)) {
        wheelEvent.preventDefault();
      }

      function onWheel(self, wheelEvent) {
        if (self.readonly) return false;
        let horizontalOffsetPx = Utils.convertWheelEventToHorizontalOffsetPx(wheelEvent);
        if (wheelEvent.shiftKey) {
          return zoom(self, (horizontalOffsetPx > 0)? 1.25: 0.75);
        } else {
          return scroll(self, horizontalOffsetPx * 20);
        }
      }

      function zoom(self, scale) {
        return self.$_updateNumContextSamples(self.numContextSamples * scale);
      }

      function scroll(self, offsetValue) {
        if (!self.$_updateSampleOffset(self.sampleOffset + offsetValue)) return false;
        window.clearTimeout(self.$data.$_timeoutIdOnWheel);
        self.$data.$_timeoutIdOnWheel = window.setTimeout(
          () => {
            self.$_fixSampleOffset();
            self.$data.$_timeoutIdOnWheel = null;
          },
          wheelEventFixDelayMs,
        );
        return true;
      }
    },

    $_updateSampleOffset(sampleOffset) {
      let newDragSampleOffset = Utils.clamp(sampleOffset, this.$_dragSampleOffsetMin, this.$_dragSampleOffsetMax);
      if (this.sampleOffset === newDragSampleOffset) return false;
      this.$emit('offset', newDragSampleOffset);
      return true;
    },

    $_fixSampleOffset() {
      let newAudioSegmentSequence = this.audioSegmentSequence.clone();
      let rationalTime = RationalNumber.generateFrom(Math.floor(this.$_targetAudioSegmentSample.toNumber() + this.sampleOffset));
      if (this.begin) {
        newAudioSegmentSequence.replaceSegmentBegin(this.audioSegmentIdx, rationalTime, AudioSegmentSequence.TimeUnit.sample);
      } else if (this.end) {
        newAudioSegmentSequence.replaceSegmentEnd(this.audioSegmentIdx, rationalTime, AudioSegmentSequence.TimeUnit.sample);
      }
      this.$emit('update', newAudioSegmentSequence);
      this.$emit('offset', 0);
    },

    $_updateCursorStyle() {
      this.$el.style.cursor = getCursorStyle(this);

      function getCursorStyle(self) {
        if (self.$_isInsideCanvasOnMousemove) {
          switch (self.controlMode) {
            case ControlMode.zoom:
              return (self.shiftKeyPressed)? 'zoom-out' : 'zoom-in';
            default:
              if (self.readonly) return 'not-allowed';
              return (self.$data.$_canvasLocalCoordsAtMousedown === null)? 'grab' : 'grabbing';
          }
        } else {
          return 'default';
        }
      }
    },

    $_updateNumContextSamples(numContextSamples) {
      let clampedNumContextSamples = Utils.clamp(
        numContextSamples,
        numContextSamplesMin,
        this.$_numContextSamplesMax,
      );
      if (clampedNumContextSamples === this.numContextSamples) return false;
      this.$emit('update-num-context-samples', clampedNumContextSamples);
      return true;
    },
  }
}

</script>
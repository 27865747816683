<template>
  <div
    id="seek-bar-container"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div
      id="seek-bar-base"
      ref="seekBarBase"
    >
      <div
        id="seek-bar-played"
        ref="seekBarPlayed"
      >
        <div
          id="seek-bar-handle"
          ref="seekBarHandle"
          v-on:mousedown.stop="$_seekStart"
        >
        </div>
      </div>

      <div
        id="seek-bar-loop"
        class="loop"
        ref="seekBarLoop"
        v-show="isLoopEnabled"
      >
      </div>

      <div
        id="seek-bar-clickable-area"
        ref="seekBarClickableArea"
        v-on:mousedown.stop="$_seekStart"
      >
      </div>
    </div>
  </div>
</template>

<style scoped>
div#seek-bar-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  user-select: none;
}

div#seek-bar-base {
  position: relative;
  flex-grow: 1;
  height: 3px;
  background-color: #dddddd;
}

div#seek-bar-played {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #666666;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

div#seek-bar-handle {
  flex-shrink: 0;
  background-color: #666666;
  height: 15px;
  width: 15px;
  margin-right: -8px;
  border-radius: 50%;
}

div#seek-bar-loop {
  position: absolute;
  top: 0;
  height: 100%;
  opacity: 0.5;
}

div#seek-bar-clickable-area {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: -10px;
  padding: 10px 0;
}

div.loop {
  background-color: #16f43f;
}
</style>

<script>
import AudioPlaybackLoopDefinition from './modules/AudioPlaybackLoopDefinition.js';
import Utils from '../modules/Utils.js';

export default {
  watch: {
    currentTimeSec(newCurrentTimeSec) {
      this.$_updateSeekBarPosition(newCurrentTimeSec, this.$data.$_seekBarScale);
    },

    durationSec(newDurationSec) {
      this.$data.$_seekBarScale = newDurationSec / this.$data.$_seekBarBaseWidthPx;
      this.$_updateSeekBarPosition(this.currentTimeSec, this.$data.$_seekBarScale);
    },

    loopDefinition(loopDefinition) {
      this.$_updateLoopRange(loopDefinition, this.$data.$_seekBarScale);
    },

    '$data.$_seekBarBaseWidthPx'(seekBarBaseWidthPx) {
      this.$data.$_seekBarScale = this.durationSec / seekBarBaseWidthPx;
    },

    '$data.$_seekBarScale'(seekBarScale) {
      this.$_updateSeekBarPosition(this.currentTimeSec, seekBarScale);
    },
  },

  mounted() {
    this.$_updateSeekBarPosition(this.currentTimeSec, this.$data.$_seekBarScale);
    this.$_updateLoopRange(this.loopDefinition, this.$data.$_seekBarScale);
    this.$data.$_seekBarBaseResizeObserver = new ResizeObserver(
      resizeObserverEntries => {
        let resizeObserverEntry = resizeObserverEntries[0];
        this.$data.$_seekBarBaseWidthPx = resizeObserverEntry.contentRect.width;
      },
    );
    this.$data.$_seekBarBaseResizeObserver.observe(this.$refs.seekBarBase);
  },

  destroyed() {
    this.$data.$_seekBarBaseResizeObserver.disconnect();
  },

  props: {
    currentTimeSec: { type: Number },
    durationSec: { type: Number },
    isLoopEnabled: { type: Boolean },
    isSeeking: { type: Boolean },
    loopDefinition: { type: AudioPlaybackLoopDefinition },
  },

  data() {
    return {
      $_seekBarBaseWidthPx: null,
      $_seekBarBaseResizeObserver: null,
      $_seekBarScale: null,
      $_isSeekingInternal: false,
    };
  },

  methods: {
    $_updateSeekBarPosition(currentTimeSec, seekBarScale) {
      this.$refs.seekBarPlayed.style.width = String(currentTimeSec / seekBarScale) + 'px';
    }, 

    $_updateLoopRange(loopDefinition, seekBarScale) {
      this.$refs.seekBarLoop.style.left = String(loopDefinition.beginTimeSec / seekBarScale) + 'px';
      this.$refs.seekBarLoop.style.width = String(loopDefinition.loopDurationSec / seekBarScale) + 'px';
    },

    $_getSeekTimeSecByMouseEvent(mouseEvent) {
      let seekBarBaseClientRect = this.$refs.seekBarBase.getBoundingClientRect();
      let seekBarOffsetPx = mouseEvent.clientX - seekBarBaseClientRect.x;
      return seekBarOffsetPx * this.$data.$_seekBarScale;
    },

    $_seek(mouseEvent) {
      let seekTimeSec = this.$_getSeekTimeSecByMouseEvent(mouseEvent);
      seekTimeSec = Utils.clamp(seekTimeSec, 0, this.durationSec);
      this.$emit('seek', seekTimeSec);
    },

    $_seekStart(mouseEvent) {
      if (this.isSeeking) return;
      this.$data.$_isSeekingInternal = true;
      this.$emit('seek-start');
      this.$_seek(mouseEvent);
    },

    $_seekEnd(mouseEvent) {
      this.$_seek(mouseEvent);
      this.$emit('seek-end');
      this.$data.$_isSeekingInternal = false;
    },

    onMousemove(mouseEvent) {
      if (this.$data.$_isSeekingInternal) this.$_seek(mouseEvent);
    },

    onMouseup(mouseEvent) {
      if (this.$data.$_isSeekingInternal) this.$_seekEnd(mouseEvent);
    },
  },
}
</script>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._g(
      _vm._b({ attrs: { id: "seek-bar-container" } }, "div", _vm.$attrs, false),
      _vm.$listeners
    ),
    [
      _c("div", { ref: "seekBarBase", attrs: { id: "seek-bar-base" } }, [
        _c("div", { ref: "seekBarPlayed", attrs: { id: "seek-bar-played" } }, [
          _c("div", {
            ref: "seekBarHandle",
            attrs: { id: "seek-bar-handle" },
            on: {
              mousedown: function ($event) {
                $event.stopPropagation()
                return _vm.$_seekStart.apply(null, arguments)
              },
            },
          }),
        ]),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isLoopEnabled,
              expression: "isLoopEnabled",
            },
          ],
          ref: "seekBarLoop",
          staticClass: "loop",
          attrs: { id: "seek-bar-loop" },
        }),
        _c("div", {
          ref: "seekBarClickableArea",
          attrs: { id: "seek-bar-clickable-area" },
          on: {
            mousedown: function ($event) {
              $event.stopPropagation()
              return _vm.$_seekStart.apply(null, arguments)
            },
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
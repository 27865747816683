var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("dialog-base", {
    attrs: { shows: _vm.shows, "finalize-callback": _vm.finalizeCallback },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("v-card-title", [_vm._v("Help")]),
            _c(
              "v-card-text",
              [
                _c("v-card-subtitle", [_vm._v("Common")]),
                _c("v-simple-table", { attrs: { dense: "" } }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("th", [_vm._v("h")]),
                      _c("td", [_vm._v("show help")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("f")]),
                      _c("td", [_vm._v("toggle follow-play-bar mode")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("s")]),
                      _c("td", [_vm._v("select mode")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("c")]),
                      _c("td", [_vm._v("create segment mode")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("d")]),
                      _c("td", [_vm._v("divide segment mode")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("m")]),
                      _c("td", [_vm._v("merge segment mode")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("p")]),
                      _c("td", [_vm._v("position edit mode")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("l")]),
                      _c("td", [_vm._v("toggle loop enalbled/disabled")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("v")]),
                      _c("td", [_vm._v("show a view filter dialog")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + l")]),
                      _c("td", [
                        _vm._v(
                          "set loop begin/end by selected timeline segments or a marker and enable loop"
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Space")]),
                      _c("td", [_vm._v("toggle play/pause")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Esc")]),
                      _c("td", [
                        _vm._v(
                          "normal mode, unset marker, clear selected timeline segments, clear realtime segment creation target"
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Click(timeline segment)")]),
                      _c("td", [_vm._v("select a segment")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Click(ruler)")]),
                      _c("td", [_vm._v("seek media")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Drag(ruler)")]),
                      _c("td", [_vm._v("set marker")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Ctrl + l")]),
                      _c("td", [_vm._v("lock selected timeline segments")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Ctrl + Shift + l")]),
                      _c("td", [_vm._v("unlock selected timeline segments")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Ctrl + s")]),
                      _c("td", [_vm._v("save result")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Ctrl + z")]),
                      _c("td", [_vm._v("undo")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Ctrl + Shift + z")]),
                      _c("td", [_vm._v("redo")]),
                    ]),
                  ]),
                ]),
                _c("v-card-subtitle", [_vm._v("Common (Mouse Control Mode)")]),
                _c("v-simple-table", { attrs: { dense: "" } }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("th", [_vm._v("Wheel-Up")]),
                      _c("td", [_vm._v("scroll-up")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Wheel-Down")]),
                      _c("td", [_vm._v("scroll-down")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Wheel-Up")]),
                      _c("td", [_vm._v("scroll-left")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Wheel-Down")]),
                      _c("td", [_vm._v("scroll-right")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Ctrl + Wheel-Up")]),
                      _c("td", [_vm._v("zoom-in(horizontal, cursor fixed)")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Ctrl + Wheel-Down")]),
                      _c("td", [_vm._v("zoom-out(horizontal, cursor fixed)")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Ctrl + Shift + Wheel-Up")]),
                      _c("td", [_vm._v("zoom-in(vertical, cursor fixed)")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Ctrl + Shift + Wheel-Down")]),
                      _c("td", [_vm._v("zoom-out(vertical, cursor fixed)")]),
                    ]),
                  ]),
                ]),
                _c("v-card-subtitle", [
                  _vm._v("Common (Trackpad Control Mode)"),
                ]),
                _c("v-simple-table", { attrs: { dense: "" } }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("th", [_vm._v("Swipe-Up")]),
                      _c("td", [_vm._v("scroll-up")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Swipe-Down")]),
                      _c("td", [_vm._v("scroll-down")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Swipe-Left")]),
                      _c("td", [_vm._v("scroll-left")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Swipe-Right")]),
                      _c("td", [_vm._v("scroll-right")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Ctrl + Swipe-Right")]),
                      _c("td", [_vm._v("zoom-in(horizontal, cursor fixed)")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Ctrl + Swipe-Left")]),
                      _c("td", [_vm._v("zoom-out(horizontal, cursor fixed)")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Ctrl + Shift + Swipe-Up")]),
                      _c("td", [_vm._v("zoom-in(vertical, cursor fixed)")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Ctrl + Shift + Swipe-Down")]),
                      _c("td", [_vm._v("zoom-out(vertical, cursor fixed)")]),
                    ]),
                  ]),
                ]),
                _c("v-card-subtitle", [_vm._v("Normal Mode")]),
                _c("v-simple-table", { attrs: { dense: "" } }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("th", [_vm._v("n")]),
                      _c("td", [
                        _vm._v(
                          "add note and attach it to the selected segment"
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Click(timeline segment)")]),
                      _c("td", [_vm._v("select a segment(add)")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Click(timeline)")]),
                      _c("td", [_vm._v("seek media(normal mode)")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Drag(timeline)")]),
                      _c("td", [
                        _vm._v("select timeline segments in drag area"),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Drag(timeline)")]),
                      _c("td", [
                        _vm._v("select timeline segments in drag area(add)"),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [
                        _vm._v(
                          "Enter(when realtime timeline segment creation target is set)"
                        ),
                      ]),
                      _c("td", [
                        _vm._v("set begin/end of a new timeline segment"),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Right")]),
                      _c("td", [_vm._v("scroll right")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Left")]),
                      _c("td", [_vm._v("scroll left")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Up")]),
                      _c("td", [_vm._v("scroll up")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Down")]),
                      _c("td", [_vm._v("scroll down")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Home")]),
                      _c("td", [_vm._v("scroll to the beginning")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("End")]),
                      _c("td", [_vm._v("scroll to the end")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("PageUp")]),
                      _c("td", [_vm._v("scroll right(page)")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("PageDown")]),
                      _c("td", [_vm._v("scroll left(page)")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Right")]),
                      _c("td", [
                        _vm._v("zoom-in(horizontal, view left fixed)"),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Left")]),
                      _c("td", [
                        _vm._v("zoom-out(horizontal, view left fixed)"),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Up")]),
                      _c("td", [_vm._v("zoom-in(vertical, view top fixed)")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Down")]),
                      _c("td", [_vm._v("zoom-out(vertical, view top fixed)")]),
                    ]),
                  ]),
                ]),
                _c("v-card-subtitle", [_vm._v("Select Mode")]),
                _c("v-simple-table", { attrs: { dense: "" } }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("th", [_vm._v("Drag(timeline)")]),
                      _c("td", [
                        _vm._v("select timeline segments in drag area"),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Drag(timeline)")]),
                      _c("td", [
                        _vm._v("select timeline segments in drag area(add)"),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [
                        _vm._v("Drag(selected timeline timeline segment)"),
                      ]),
                      _c("td", [_vm._v("move timeline segment")]),
                    ]),
                    _c("tr", [
                      _c("th", [
                        _vm._v("Shift + Click(timeline timeline segment)"),
                      ]),
                      _c("td", [_vm._v("select a timeline segment(add)")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Drag(timeline)")]),
                      _c("td", [
                        _vm._v("select timeline segments in drag area(add)"),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Enter")]),
                      _c("td", [
                        _vm._v(
                          "settle timeline segment(default: seek to the timeline segment)"
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [
                        _vm._v("Enter(single selected timeline segment)"),
                      ]),
                      _c("td", [
                        _vm._v("show a timeline segment editor dialog"),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [
                        _vm._v("Click(single selected timeline segment)"),
                      ]),
                      _c("td", [
                        _vm._v("show a timeline segment editor dialog"),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Ctrl + c")]),
                      _c("td", [_vm._v("copy timeline segment data")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Ctrl + v")]),
                      _c("td", [_vm._v("paste timeline segment data")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Delete / Backspace")]),
                      _c("td", [_vm._v("clear selected timeline segments")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Delete / Shift + Backspace")]),
                      _c("td", [_vm._v("delete selected timeline segments")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Right")]),
                      _c("td", [_vm._v("select next timeline segment")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Left")]),
                      _c("td", [_vm._v("select previous timeline segment")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Up")]),
                      _c("td", [_vm._v("select previous timeline segment")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Down")]),
                      _c("td", [_vm._v("select next timeline segment")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Home")]),
                      _c("td", [_vm._v("select first timeline segment")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("End")]),
                      _c("td", [_vm._v("select last timeline segment")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Right")]),
                      _c("td", [
                        _vm._v(
                          "zoom-in(horizontal, last selected timeline segment's left fixed)"
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Left")]),
                      _c("td", [
                        _vm._v(
                          "zoom-out(horizontal, last selected timeline segment's left fixed)"
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Up")]),
                      _c("td", [
                        _vm._v(
                          "zoom-in(vertical, last selected timeline segment's top fixed)"
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Down")]),
                      _c("td", [
                        _vm._v(
                          "zoom-out(vertical, last selected timeline segment's top fixed)"
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("v-card-subtitle", [_vm._v("Create Segment Mode")]),
                _c("v-simple-table", { attrs: { dense: "" } }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("th", [_vm._v("Drag(timeline)")]),
                      _c("td", [_vm._v("create timeline segment")]),
                    ]),
                  ]),
                ]),
                _c("v-card-subtitle", [_vm._v("Merge Segment Mode")]),
                _c("v-simple-table", { attrs: { dense: "" } }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("th", [_vm._v("Click(selected timeline segments)")]),
                      _c("td", [_vm._v("merge selected segments")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Click(timeline segment)")]),
                      _c("td", [_vm._v("select a timeline segment(add)")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Drag(timeline)")]),
                      _c("td", [
                        _vm._v("select timeline segments in drag area"),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Drag(timeline)")]),
                      _c("td", [
                        _vm._v("select timeline segments in drag area(add)"),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Right")]),
                      _c("td", [
                        _vm._v(
                          "zoom-in(horizontal, last selected timeline segment's left fixed)"
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Left")]),
                      _c("td", [
                        _vm._v(
                          "zoom-out(horizontal, last selected timeline segment's left fixed)"
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Up")]),
                      _c("td", [
                        _vm._v(
                          "zoom-in(vertical, last selected timeline segment's top fixed)"
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Down")]),
                      _c("td", [
                        _vm._v(
                          "zoom-out(vertical, last selected timeline segment's top fixed)"
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("v-card-subtitle", [_vm._v("Divide Segment Mode")]),
                _c("v-simple-table", { attrs: { dense: "" } }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("th", [_vm._v("Click(selected timeline segments)")]),
                      _c("td", [_vm._v("divide selected segments")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Click(timeline segment)")]),
                      _c("td", [_vm._v("select a timeline segment(add)")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Drag(timeline)")]),
                      _c("td", [
                        _vm._v("select timeline segments in drag area"),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Drag(timeline)")]),
                      _c("td", [
                        _vm._v("select timeline segments in drag area(add)"),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Right")]),
                      _c("td", [
                        _vm._v(
                          "zoom-in(horizontal, last selected timeline segment's left fixed)"
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Left")]),
                      _c("td", [
                        _vm._v(
                          "zoom-out(horizontal, last selected timeline segment's left fixed)"
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Up")]),
                      _c("td", [
                        _vm._v(
                          "zoom-in(vertical, last selected timeline segment's top fixed)"
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Down")]),
                      _c("td", [
                        _vm._v(
                          "zoom-out(vertical, last selected timeline segment's top fixed)"
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("v-card-subtitle", [_vm._v("Position Edit Mode")]),
                _c("v-simple-table", { attrs: { dense: "" } }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("th", [_vm._v("Right")]),
                      _c("td", [_vm._v("offset end time")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Left")]),
                      _c("td", [_vm._v("offset end time")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Right")]),
                      _c("td", [_vm._v("offset start time")]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Shift + Left")]),
                      _c("td", [_vm._v("offset start time")]),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "buttons",
        fn: function ({ on, attrs }) {
          return [
            _c("v-spacer"),
            _c(
              "v-btn",
              _vm._b(
                { attrs: { color: "primary" }, on: { click: on.okClicked } },
                "v-btn",
                attrs,
                false
              ),
              [_vm._v(" OK ")]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-app-bar color="primary" height="30" max-height="30" dark>
    <template v-slot:default>
      <slot name="default">
        <AppBarButton
          v-bind:disabled="state.isUndoTargetEmpty"
          v-on:click.stop="callback.undoChange"
        >
          <v-icon>mdi-undo</v-icon>Undo
        </AppBarButton>

        <AppBarButton
          v-bind:disabled="state.isRedoTargetEmpty"
          v-on:click.stop="callback.redoChange"
        >
          <v-icon>mdi-redo</v-icon>Redo
        </AppBarButton>

        <AppBarButton v-on:click.stop="callback.loadScattDataFromLocalFile">
          <v-icon>mdi-folder-open</v-icon>Load
        </AppBarButton>

        <AppBarButton v-on:click.stop="callback.downloadScattData">
          <v-icon>mdi-content-save</v-icon>Save
        </AppBarButton>

        <AppBarButton v-on:click.stop="callback.showViewFilter">
          <v-icon>mdi-format-list-checkbox</v-icon>Filter view
        </AppBarButton>

        <AppBarButton v-on:click.stop="callback.showHelp">
          <v-icon>mdi-help-circle</v-icon>Help
        </AppBarButton>
      </slot>
    </template>
  </v-app-bar>
</template>

<script>
import AppBarButton from '../../external/components/AppBar/AppBarButton.vue';

export default {
  components: {
    AppBarButton,
  },

  props: {
    state: { type: Object },
    callback: { type: Object },
  },
}
</script>
<template>
  <v-menu
    open-on-hover top offset-y
    close-delay="250"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        small
        class="px-1"
        v-bind="attrs"
        v-on="on"
      >
        mdi-magnify
      </v-icon>
    </template>

    <v-list dense width="200">
      <v-list-item v-on:click.stop>
        <v-icon x-small>mdi-arrow-expand-vertical</v-icon>
        <v-slider
          hide-details dense
          step="0.1"
          v-model="$_timelinePositionResolution"
          v-bind:min="timelinePositionResolutionMin"
          v-bind:max="timelinePositionResolutionMax"
          v-on:click.stop
        />
      </v-list-item>

      <v-list-item v-on:click.stop>
        <v-icon x-small>mdi-arrow-expand-horizontal</v-icon>
        <v-slider
          hide-details dense
          step="0.01"
          v-model="$_timeResolutionLog10"
          v-bind:min="$_timeResolutionLog10Min"
          v-bind:max="$_timeResolutionLog10Max"
          v-on:click.stop
        />
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    timeResolution: { type: Number },
    timeResolutionMin: { type: Number },
    timeResolutionMax: { type: Number },
    timelinePositionResolution: { type: Number },
    timelinePositionResolutionMin: { type: Number },
    timelinePositionResolutionMax: { type: Number },
  },

  computed: {
    $_timeResolutionLog10: {
      get() { return Math.log10(this.timeResolution) },
      set(value) { return this.updateTimeResolution(Math.pow(10, value)) },
    },
    $_timeResolutionLog10Min() { return Math.log10(this.timeResolutionMin) },
    $_timeResolutionLog10Max() { return Math.log10(this.timeResolutionMax) },
    $_timelinePositionResolution: {
      get() { return this.timelinePositionResolution },
      set(value) { return this.updateTimelinePositionResolution(value) },
    },
  },

  inject: [
    'updateTimeResolution',
    'updateTimelinePositionResolution',
  ],
}
</script>
<template>
  <dialog-base
    v-bind:shows="shows"
    v-bind:initialize-callback="$_onInitialize"
    v-bind:ok-callback="$_okCallback"
    v-bind:finalize-callback="finalizeCallback"
  >
    <template v-slot:body>
      <v-card-title>Timeline Duration</v-card-title>
      <v-card-text>
        <v-checkbox
          v-if="$_isVideoDurationSet"
          v-bind:label="$_usesVideoDurationCheckboxLabel"
          v-model="$data.$_usesVideoDuration"
        />
        <v-text-field
          dense autofocus 
          suffix="msec"
          v-bind:disabled="$data.$_usesVideoDuration"
          v-model.number="$_durationMsec"
        />
      </v-card-text>
    </template>
  </dialog-base>
</template>

<script>
import DialogBase from './DialogBase.vue';

export default {
  components: {
    DialogBase,
  },

  props: {
    shows: { type: Boolean, default: false },
    okCallback: { type: Function, default: null },
    finalizeCallback: { type: Function, default: null },
    durationMsec: { type: Number, default: null },
    videoDurationMsec: { type: Number, default: null },
  },

  data() {
    return {
      $_usesVideoDuration: false,
      $_customDurationMsec: null,
    };
  },

  computed: {
    $_usesVideoDurationCheckboxLabel() {
      return 'Use Video Duration(' + String(this.videoDurationMsec) + ' msec)';
    },

    $_isVideoDurationSet() {
      return (this.videoDurationMsec !== null);
    },

    $_durationMsec: {
      get() {
        if (this.$data.$_usesVideoDuration) {
          return this.videoDurationMsec;
        } else {
          return this.$data.$_customDurationMsec;
        }
      },

      set(newDuration) {
        this.$data.$_customDurationMsec = newDuration;
      },
    },
  },

  watch: {
    durationMsec() { this.$_onInitialize() },
  },

  methods: {
    $_onInitialize() {
      this.$data.$_customDurationMsec = this.durationMsec;
      if (this.$_isVideoDurationSet) {
        if (this.durationMsec === this.videoDurationMsec) {
          this.$data.$_usesVideoDuration = true;
        }
      }
    },

    $_okCallback() {
      this.okCallback(this.$_durationMsec);
    },
  },
}
</script>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      attrs: { tile: "", text: "", outlined: "", id: "timeline-title-footer" },
    },
    [
      _c(
        "v-btn",
        {
          attrs: { icon: "", small: "" },
          on: { click: _vm.filterTimelineData },
        },
        [_c("v-icon", [_vm._v("mdi-format-list-checkbox")])],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: { icon: "", small: "" },
          on: { click: _vm.$_toggleMouseMode },
        },
        [
          _vm.isMouseMode
            ? _c("v-icon", [_vm._v("mdi-mouse")])
            : _c("v-icon", [_vm._v("mdi-trackpad")]),
        ],
        1
      ),
      _c(
        "v-btn",
        { attrs: { icon: "", small: "" }, on: { click: _vm.$_emitToggleSnap } },
        [
          _c("v-icon", { attrs: { disabled: !_vm.isSnapEnabled } }, [
            _vm._v("mdi-format-horizontal-align-center"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import EditorDefinition from '../../modules/EditorDefinition.js'
import LabelSegmentData from '../../modules/segmentDataTypes/LabelSegmentData.js'

export default new EditorDefinition(
  'ScattTimelineLabelEnumerator',
  'Transcription',
  timelineDataSet => {
    for (let timelineData of Object.values(timelineDataSet)) {
      if (timelineData.segmentDataType === LabelSegmentData) return true;
    }
    return false;
  },
);

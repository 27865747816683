var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("dialog-base", {
    attrs: {
      shows: _vm.shows,
      "ok-callback": _vm.okCallback,
      "finalize-callback": _vm.finalizeCallback,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _vm.title
              ? _c("v-card-title", [_vm._v(_vm._s(_vm.title))])
              : _vm._e(),
            _vm.message
              ? _c("v-card-text", [_vm._v(_vm._s(_vm.message))])
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <v-container pa-10 style="max-width: 1000px;">
        <v-card class="my-10">
            <v-system-bar window dark color="indigo">
                <h3>Your task</h3>
            </v-system-bar>
            <div class="pa-4 text-body-1 font-weight-medium">
                Choose the one that best describes the video content.
            </div>
        </v-card>
        <v-card max-width="1000" class="mx-auto my-6 pa-8">
            <t-video 
                :video-props="{
                    width: 600,
                    controls: true
                }"
                :source-props="{
                    src: nano.props.videoPath,
                    type: 'video/mp4'
                }"
            />

            <v-radio-group v-model="nano.ans.choice">
                <v-radio 
                    v-for="choice in choices"
                    :key="choice.val"
                    :label="choice.text"
                    :value="choice.val"
                />
            </v-radio-group>

            <v-btn
                :disabled="nano.ans.choice === null"
                color="primary" 
                @click="submit()"
            >Submit
            </v-btn>
        </v-card>
    </v-container>
</template>
<script>
import nanoMixIn from '@/mixins/nano';
import TVideo from '@/components/template-ui/TVideo'

const choices = [
    { text: 'Product advertisement', val: 'product_advertisement' },
    { text: 'Social problems', val: 'social_problems' },
    { text: 'TV show', val:'tv_show' },
    { text: 'Horror film', val: 'horror_film' },
];

export default {
    mixins: [nanoMixIn],
    components: {
        TVideo
    },
    data: () => ({
        choices,
        defaultNanoProps: {
            videoPath: 'https://storage.googleapis.com/iflab-public/tutti.works/videos/scatt-intro.mp4',
        },
        defaultNanoAnswers: {
            choice: null
        }
    }),
};
</script>
<template>
    <video 
        ref="video"
        v-bind="videoProps"
        v-on="videoListeners"
        @play="isPlaying = true"
        @pause="isPlaying = false"
        @timeupdate="notifySeconds"
        @seeked="fixNotifiedSecondsArrayIdx"
    >
        <source
            v-for="props,i in sourcePropsArray"
            :key="`source-props-${i}`"
            v-bind="props"
        />
        <track
            v-for="props,i in trackPropsArray"
            :key="`track-props-${i}`"
            v-bind="props"
        />
    </video>
</template>
<script>
export default{
    data:()=>({
        isPlaying:true,
        currentTime:0,
        timeBeforeOneFrame:0,
        notifiedSecondsArrayIdx:0
    }),
    props: {
        videoProps: {
            type: Object,
            default: () => ({})
        },
        sourceProps: {
            type: [Array, Object],
            default: () => ([{}])
        },
        trackProps: {
            type: Object,
            default: () => ({})
        },
        videoListeners: {
            type: Object,
            default: () => ({})
        },
        notifiedSeconds: {
            type: [Number, Array],
            default: () => (null)
        }
    },
    computed: {
        sourcePropsArray() {
            return this.propsAsArray(this.sourceProps);
        },
        trackPropsArray() {
            return this.propsAsArray(this.trackProps);
        },
        notifiedSecondsArray(){
            if(typeof this.notifiedSeconds == Number) return [this.notifiedSeconds];
            else return this.notifiedSeconds;
        },
    },
    methods:{
        propsAsArray(props) {
            if(typeof props === 'object') {
                return Array.isArray(props) ? props : [props];
            } else {
                throw 'Invalid type for props';
            }
        },
        notifySeconds(){
            if(this.notifiedSecondsArray != null){
                const video = this.$refs.video;
                this.timeBeforeOneFrame = this.currentTime;
                this.currentTime = video.currentTime;
                const _idx = this.notifiedSecondsArrayIdx;
                if(
                    this.isPlaying==true && 
                    this.timeBeforeOneFrame < this.notifiedSecondsArray[_idx] && 
                    this.notifiedSecondsArray[_idx] <= this.currentTime
                ){
                    this.$emit('notify-seconds', { index: _idx, seconds: this.notifiedSecondsArray[_idx] });
                    this.notifiedSecondsArrayIdx++;
                }
            }
        },
        fixNotifiedSecondsArrayIdx(){
            const video = this.$refs.video;
            const _currentTime = video.currentTime;
            for(let idx=0; idx < this.notifiedSecondsArray.length; idx++){
                if(_currentTime < this.notifiedSecondsArray[idx]){
                    this.notifiedSecondsArrayIdx = idx;
                    break;
                }
            }
        }
    },
}
</script>

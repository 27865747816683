<template>
  <div id="editor-view">
    <v-slide-group
      id="editor-selector"
      show-arrows mandatory height="24"
      v-if="$data.$_renderComponent"
      v-bind:value="$data.$_selectedEditorComponentName"
      v-on:change="$_selectEditor"
    >
      <template v-for="editorDefinition of editorDefinitions">
        <v-slide-item
          v-if="editorDefinition.isAvailable(timelineDataSet)"
          v-bind:key="editorDefinition.componentName"
          v-bind:value="editorDefinition.componentName"
          v-slot="{ active, toggle }"
        >
          <v-btn
            tile text small
            v-bind:input-value="active"
            v-on:click="toggle"
            v-on:space="toggle"
          >
            {{ editorDefinition.title }}
          </v-btn>
        </v-slide-item>
      </template>
    </v-slide-group>

    <div id="editor-container">
      <component
        v-bind:is="$data.$_selectedEditorComponentName"
        v-bind:annotator-id="annotatorId"
        v-bind:annotator-name="annotatorName"
        v-bind:timeline-data-set="timelineDataSet"
        v-bind:selected-timeline-segment-ids="selectedTimelineSegmentIds"
      >
      </component>
    </div>
  </div>
</template>

<style scoped>
#editor-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow-x: clip;
}

#editor-selector {
  position: absolute;
  top: 0;
  left: 0;
}

#editor-container {
  margin-top: 24px;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>

<script>
import TimelineLabelEnumerator from './Editors/TimelineLabelEnumerator.vue'
import NoteEditor from './Editors/NoteEditor.vue'
import Inspector from './Editors/Inspector.vue'

export default {
  props: {
    annotatorId: { type: String },
    annotatorName: { type: String },
    timelineDataSet: { type: Object },
    selectedTimelineSegmentIds: { type: Array },
    editorDefinitions: { type: Array },
  },

  components: {
    ScattNoteEditor: NoteEditor,
    ScattInspector: Inspector,
    ScattTimelineLabelEnumerator: TimelineLabelEnumerator,
  },

  data() {
    return {
      $_renderComponent: true,
      $_selectedEditorComponentName: null,
      $_editorViewResizeObserver: new ResizeObserver(this.$_rerenderComponent),
    };
  },

  mounted() {
    this.$data.$_editorViewResizeObserver.observe(this.$el);
  },

  destroyed() {
    this.$data.$_editorViewResizeObserver.disconnect();
  },

  methods: {
    $_rerenderComponent() {
      this.$data.$_renderComponent = false;
      this.$nextTick(() => {
        this.$data.$_renderComponent = true;
      });
    },

    $_selectEditor(selectedEditorComponentName) {
      this.$data.$_selectedEditorComponentName = selectedEditorComponentName;
    },
  },
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { attrs: { icon: "", small: "", elevation: "0" } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_c("v-icon", [_vm._v("mdi-play-speed")])],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        { attrs: { flat: "" } },
        [
          _c(
            "v-list-item-group",
            {
              attrs: { color: "#E53935" },
              model: {
                value: _vm.selectedItem,
                callback: function ($$v) {
                  _vm.selectedItem = $$v
                },
                expression: "selectedItem",
              },
            },
            _vm._l(_vm.items, function (item, i) {
              return _c(
                "v-list-item",
                {
                  key: `playback_speed_${i}`,
                  attrs: { dense: "" },
                  on: {
                    click: function ($event) {
                      return _vm.changePlaybackSpeed(item.val)
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-content",
                    { attrs: { color: item.color } },
                    [_c("v-list-item-title", [_vm._v(_vm._s(item.title))])],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
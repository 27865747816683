<template>
  <div id="timeline-info-bar">
    <template v-if="$_singlySelectedTimelineSegment !== null">
      <template v-if="String($_singlySelectedTimelineSegment.data)">
        <span class="flex-grow-1 text-truncate">Data: {{ $_singlySelectedTimelineSegment.data }}</span>
        <TextDivider />
      </template>
      <span class="time-msec text-no-wrap">
        <template v-if="$_singlySelectedTimelineSegment.begin === null">-Inf</template>
        <template v-else>{{ $_singlySelectedTimelineSegment.begin }}</template>
        <TextDivider content="-" />
        <template v-if="$_singlySelectedTimelineSegment.end === null">Inf</template>
        <template v-else>{{ $_singlySelectedTimelineSegment.end }}</template>
      </span>
      <TextDivider />
    </template>
    <span class="time-msec text-no-wrap">
      {{ playTimeMsec }}
    </span>
    <TextDivider />
    <span class="time-msec text-no-wrap link" v-on:click="showDurationEditor">
      {{ durationMsec }}
    </span>
    <TextDivider />
    <span class="text-no-wrap">{{ viewMode }}</span>
    <TextDivider />
    <TimelineViewResolutionMenu
      v-bind:time-resolution="timeResolution"
      v-bind:time-resolution-min="timeResolutionMin"
      v-bind:time-resolution-max="timeResolutionMax"
      v-bind:timeline-position-resolution="timelinePositionResolution"
      v-bind:timeline-position-resolution-min="timelinePositionResolutionMin"
      v-bind:timeline-position-resolution-max="timelinePositionResolutionMax"
    />
  </div>
</template>

<style scoped>
span.time-msec:after {
  margin-left: 5px;
  content: 'ms';
}

div#timeline-info-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  height: 20px;
  width: 100%;
  padding: 0 10px;
  font-size: 11px;
  line-height: 11px;
}

span.link {
  cursor: pointer;
}
</style>

<script>
import TextDivider from '../Parts/TextDivider.vue';
import TimelineViewResolutionMenu from './TimelineViewResolutionMenu.vue';
import { TimelineDataSet } from '../../../external/modules/ScattData.js';

export default {
  components: {
    TextDivider,
    TimelineViewResolutionMenu,
  },

  props: {
    durationMsec: { type: Number },
    playTimeMsec: { type: Number },
    visibleTimelineDataSet: { type: TimelineDataSet },
    selectedTimelineSegmentIds: { type: Array },
    viewMode: { type: String },
    timeResolution: { type: Number },
    timeResolutionMin: { type: Number },
    timeResolutionMax: { type: Number },
    timelinePositionResolution: { type: Number },
    timelinePositionResolutionMin: { type: Number },
    timelinePositionResolutionMax: { type: Number },
  },

  computed: {
    $_singlySelectedTimelineSegment() {
      if (this.selectedTimelineSegmentIds.length !== 1) return null;
      let singlySelectedTimelineSegmentId = this.selectedTimelineSegmentIds[0];
      return this.visibleTimelineDataSet.getSegment(singlySelectedTimelineSegmentId);
    },
  },

  inject: [
    'showDurationEditor',
  ],
}
</script>
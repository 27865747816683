var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "mx-auto my-8", attrs: { width: "500" } },
    [
      _c(
        "v-card-text",
        [
          _c("v-file-input", {
            attrs: { chips: "", multiple: "", placeholder: "Upload files ..." },
            on: { change: _vm.onFileChange },
          }),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  on: {
                    click: function ($event) {
                      return _vm.submit()
                    },
                  },
                },
                [_vm._v("Submit")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <dialog-base
    v-bind:shows="shows"
    v-bind:initialize-callback="$_onInitialize"
    v-bind:ok-callback="$_onOkClicked"
    v-bind:finalize-callback="finalizeCallback"
    v-bind:ok-disabled="$_isNoTimelineDataSelected"
  >
    <template v-slot:body>
      <v-card-title>Select Timeline Data</v-card-title>
      <v-card-text>
        <template
          v-for="(timelineDataSelectionWithInfo, timelineDataId) in $data.$_timelineDataSelectionWithInfoSet"
        >
          <v-checkbox
            dense
            v-model="timelineDataSelectionWithInfo.selected"
            v-bind:disabled="$_isCheckBoxDisabled[timelineDataId]"
            v-bind:label="timelineDataSelectionWithInfo.selectionItemLabel"
            v-bind:key="timelineDataId"
          />
        </template>
      </v-card-text>
    </template>
  </dialog-base>
</template>

<script>
import { TimelineDataSet } from '../../../external/modules/ScattData.js';
import DialogBase from './DialogBase.vue';

class TimelineDataSelectionWithInfo {
  constructor(segmentDataType, timelineDataName) {
    this.selected = false;
    this.segmentDataType = segmentDataType;
    this.selectionItemLabel = '[' + String(segmentDataType) + '] ' + String(timelineDataName);
  }
}

export default {
  components: {
    DialogBase,
  },

  props: {
    shows: { type: Boolean, default: false },
    okCallback: { type: Function, default: null },
    finalizeCallback: { type: Function, default: null },
    timelineDataSet: { type: TimelineDataSet, default: null },
  },

  data() {
    return {
      $_timelineDataSelectionWithInfoSet: new Object(),
    };
  },

  computed: {
    $_isNoTimelineDataSelected() {
      return (this.$_currentSegmentDataType === null);
    },

    $_isCheckBoxDisabled() {
      let isCheckBoxDisabled = new Object();
      for (let [ timelineDataId, timelineDataSelectionWithInfo ] of Object.entries(this.$data.$_timelineDataSelectionWithInfoSet)) {
        if (this.$_currentSegmentDataType) {
          isCheckBoxDisabled[timelineDataId] = (timelineDataSelectionWithInfo.segmentDataType !== this.$_currentSegmentDataType);
        } else {
          isCheckBoxDisabled[timelineDataId] = false;
        }
      }
      return isCheckBoxDisabled;
    },

    $_currentSegmentDataType() {
      for (let timelineDataSelectionWithInfo of Object.values(this.$data.$_timelineDataSelectionWithInfoSet)) {
        if (timelineDataSelectionWithInfo.selected) {
          return timelineDataSelectionWithInfo.segmentDataType;
        }
      }
      return null;
    },
  },

  watch: {
    timelineDataSet: {
      handler() { this.$_onInitialize() },
      deep: true,
    },
  },

  methods: {
    $_onInitialize() {
      let timelineDataSelectionWithInfoSet = new Object();
      for (let [ timelineDataId, timelineData ] of Object.entries(this.timelineDataSet)) {
        timelineDataSelectionWithInfoSet[timelineDataId] = new TimelineDataSelectionWithInfo(timelineData.segmentDataType, timelineData.name);
      }
      this.$data.$_timelineDataSelectionWithInfoSet = timelineDataSelectionWithInfoSet;
    },

    $_onOkClicked() {
      let selectedTimelineDataIds = new Array();
      for (let [ timelineDataId, timelineDataSelectionWithInfo ] of Object.entries(this.$data.$_timelineDataSelectionWithInfoSet)) {
        if (timelineDataSelectionWithInfo.selected) {
          selectedTimelineDataIds.push(timelineDataId);
        }
      }
      this.okCallback(this.$_currentSegmentDataType, selectedTimelineDataIds);
    },
  },
}
</script>

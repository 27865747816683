var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column" },
    [
      _c("label", { staticClass: "caption" }, [_vm._v("Delimiter")]),
      _c("editable-text", {
        model: {
          value: _vm.$_delimiter,
          callback: function ($$v) {
            _vm.$_delimiter = $$v
          },
          expression: "$_delimiter",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-app-bar", {
    attrs: { color: "primary", height: "30", "max-height": "30", dark: "" },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function () {
            return [
              _vm._t("default", function () {
                return [
                  _c(
                    "AppBarButton",
                    {
                      attrs: { disabled: _vm.state.isUndoTargetEmpty },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.callback.undoChange.apply(null, arguments)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-undo")]), _vm._v("Undo ")],
                    1
                  ),
                  _c(
                    "AppBarButton",
                    {
                      attrs: { disabled: _vm.state.isRedoTargetEmpty },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.callback.redoChange.apply(null, arguments)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-redo")]), _vm._v("Redo ")],
                    1
                  ),
                  _c(
                    "AppBarButton",
                    {
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.callback.loadScattDataFromLocalFile.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-folder-open")]),
                      _vm._v("Load "),
                    ],
                    1
                  ),
                  _c(
                    "AppBarButton",
                    {
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.callback.downloadScattData.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-content-save")]),
                      _vm._v("Save "),
                    ],
                    1
                  ),
                  _c(
                    "AppBarButton",
                    {
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.callback.showViewFilter.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-format-list-checkbox")]),
                      _vm._v("Filter view "),
                    ],
                    1
                  ),
                  _c(
                    "AppBarButton",
                    {
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.callback.showHelp.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-help-circle")]),
                      _vm._v("Help "),
                    ],
                    1
                  ),
                ]
              }),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
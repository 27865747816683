var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    { ref: "timelineSegmentEditorForm" },
    [
      _c("dialog-base", {
        attrs: {
          shows: _vm.shows,
          "initialize-callback": _vm.$_onInitialize,
          "ok-callback": _vm.$_onOkClicked,
          "finalize-callback": _vm.$_onFinalize,
          "ok-disabled": _vm.$data.$_hasError,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("v-card-title", [_vm._v("Edit Timeline Segment")]),
                _c(
                  "v-card-text",
                  { staticClass: "ma-0 pa-0" },
                  [
                    _c(
                      "v-container",
                      { attrs: { fluid: "" } },
                      [
                        _c(
                          "v-row",
                          {
                            attrs: {
                              justify: "center",
                              "align-content": "center",
                              "no-gutters": "",
                            },
                          },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "1" } },
                              [
                                _c(
                                  "TimelineSegmentEditorSideButton",
                                  {
                                    attrs: {
                                      disabled:
                                        !_vm.reopenPreviousSegmentEnabled,
                                    },
                                    on: {
                                      click:
                                        _vm.$_reopenPreviousTimelineSegment,
                                    },
                                  },
                                  [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "10" } },
                              [
                                _c(
                                  "v-container",
                                  { attrs: { fluid: "" } },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _vm.$_isLabelSegment
                                              ? _c("v-text-field", {
                                                  attrs: {
                                                    label: "Label",
                                                    dense: "",
                                                    autofocus: "",
                                                    disabled: _vm.isImmutable,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.$data.$_segmentTemp
                                                        .data.label,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.$data.$_segmentTemp
                                                          .data,
                                                        "label",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "$data.$_segmentTemp.data.label",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm.$_isNoteSegment
                                              ? _c("v-text-field", {
                                                  attrs: {
                                                    label: "Note",
                                                    dense: "",
                                                    autofocus: "",
                                                    disabled: _vm.isImmutable,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.$data.$_segmentTemp
                                                        .data.text,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.$data.$_segmentTemp
                                                          .data,
                                                        "text",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "$data.$_segmentTemp.data.text",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label:
                                                  "Begin time in millisecond",
                                                dense: "",
                                                type: "number",
                                                disabled: _vm.isImmutable,
                                                rules: _vm.$_rules.begin,
                                              },
                                              on: {
                                                input: _vm.$_validateInput,
                                              },
                                              model: {
                                                value:
                                                  _vm.$data.$_segmentTemp.begin,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.$data.$_segmentTemp,
                                                    "begin",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "$data.$_segmentTemp.begin",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label:
                                                  "End time in millisecond",
                                                dense: "",
                                                type: "number",
                                                disabled: _vm.isImmutable,
                                                rules: _vm.$_rules.end,
                                              },
                                              on: {
                                                input: _vm.$_validateInput,
                                              },
                                              model: {
                                                value:
                                                  _vm.$data.$_segmentTemp.end,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.$data.$_segmentTemp,
                                                    "end",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "$data.$_segmentTemp.end",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-expansion-panels",
                                  { attrs: { flat: "" } },
                                  [
                                    _c(
                                      "v-expansion-panel",
                                      [
                                        _c("v-expansion-panel-header", [
                                          _vm._v("Notes"),
                                        ]),
                                        _c(
                                          "v-expansion-panel-content",
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "timeline-segment-editor-add-note-container",
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      outlined: "",
                                                      text: "",
                                                      "x-small": "",
                                                    },
                                                    on: {
                                                      click: _vm.$_addNote,
                                                    },
                                                  },
                                                  [_vm._v(" Add Note ")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._l(
                                              _vm.$data
                                                .$_noteWithInfoReferingToTargetSegmentArray,
                                              function (
                                                noteWithInfoReferingToTargetSegment,
                                                noteWithInfoReferingToTargetSegmentIdx
                                              ) {
                                                return _c(
                                                  "TimelineSegmentEditorNote",
                                                  {
                                                    key: noteWithInfoReferingToTargetSegmentIdx,
                                                    attrs: {
                                                      "note-timeline-segment-id":
                                                        noteWithInfoReferingToTargetSegment.noteTimelineSegmentId,
                                                      note: noteWithInfoReferingToTargetSegment.note,
                                                    },
                                                    on: {
                                                      "save-note":
                                                        _vm.$_saveNote,
                                                    },
                                                  }
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "1" } },
                              [
                                _c(
                                  "TimelineSegmentEditorSideButton",
                                  {
                                    attrs: {
                                      disabled: !_vm.reopenNextSegmentEnabled,
                                    },
                                    on: {
                                      click: _vm.$_reopenNextTimelineSegment,
                                    },
                                  },
                                  [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <canvas>
  </canvas>
</template>

<script>
import CanvasBase from './mixins/CanvasBase.js';
import Color from '../../modules/Color.js';

const canvasCenterBarColor = new Color(0, 0, 0);

export default {
  mixins: [
    CanvasBase,
  ],

  computed: {
    $_canvasCenterOffsetPx() {
      return Math.round(this.$_canvasWidthPx / 2);
    },
  },

  methods: {
    draw(canvasElement) {
      let canvasContext = canvasElement.getContext('2d');
      this.$_drawCenterBar(canvasContext);
    },

    $_drawCenterBar(canvasContext) {
      canvasContext.beginPath();
      canvasContext.setLineDash([]);
      canvasContext.lineWidth = 1;
      canvasContext.strokeStyle = canvasCenterBarColor.styleString;
      canvasContext.moveTo(...this.$_dotByDotOffsetCoordArgs(this.$_canvasCenterOffsetPx, 0));
      canvasContext.lineTo(...this.$_dotByDotOffsetCoordArgs(this.$_canvasCenterOffsetPx, this.$_canvasHeightPx));
      canvasContext.stroke();
    },
  },
}
</script>
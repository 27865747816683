var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("canvas-container", {
    ref: "canvasContainer",
    attrs: {
      "begin-sample-offset": _vm.$_canvasBeginSampleOffsetWithDrag,
      "end-sample-offset": _vm.$_canvasEndSampleOffsetWithDrag,
      "sampling-rate": _vm.audioSegmentSequence.samplingRate,
    },
    on: {
      mousedown: _vm.$_onMousedown,
      wheel: _vm.$_onWheel,
      resize: _vm.$_onResize,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({
          widthPx,
          heightPx,
          canvasLocalRect,
          beginSec,
          endSec,
          durationSec,
        }) {
          return [
            _c("waveform-canvas", {
              attrs: {
                "width-px": widthPx,
                "height-px": heightPx,
                "waveform-digest": _vm.waveformDigest,
                "canvas-begin-sample-offset":
                  _vm.$_canvasBeginSampleOffsetWithDrag,
                "canvas-end-sample-offset": _vm.$_canvasEndSampleOffsetWithDrag,
                "canvas-duration-sec": durationSec,
              },
            }),
            _c("audio-segment-canvas", {
              attrs: {
                "width-px": widthPx,
                "height-px": heightPx,
                "audio-segment-sequence": _vm.audioSegmentSequence,
                "label-sequence": _vm.labelSequence,
                "selected-segment-idcs": _vm.selectedAudioSegmentIdcs,
                "canvas-begin-sample-offset":
                  _vm.$_canvasBeginSampleOffsetWithDrag,
                "canvas-end-sample-offset": _vm.$_canvasEndSampleOffsetWithDrag,
                "canvas-local-rect": canvasLocalRect,
              },
              on: {
                "register-component-instance":
                  _vm.$_registerAudioSegmentCanvasComponentInstance,
                "unregister-component-instance":
                  _vm.$_unregisterAudioSegmentCanvasComponentInstance,
              },
            }),
            _vm.playTimeSec !== null
              ? _c("play-time-bar-canvas", {
                  attrs: {
                    "width-px": widthPx,
                    "height-px": heightPx,
                    "play-time-sec": _vm.playTimeSec,
                    "canvas-begin-sec": beginSec,
                    "canvas-end-sec": endSec,
                    "canvas-duration-sec": durationSec,
                  },
                })
              : _vm._e(),
            _c("drag-trajectory-canvas", {
              attrs: {
                "width-px": widthPx,
                "height-px": heightPx,
                "control-mode": _vm.controlMode,
                "canvas-local-coords-on-mousedown":
                  _vm.$data.$_canvasLocalCoordsOnMousedown,
                "canvas-local-coords-on-mousemove":
                  _vm.$data.$_canvasLocalCoordsOnMousemove,
              },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div id="wrapper" :style="wrapperStyle">
        <div id="camera-preview">
            <video
                v-show="videoShown || alwaysShowCamera"
                ref="video"
                id="video"
                :class="flipCamera ? 'flip' : ''"
                autoplay
                playsinline
            ></video>
            <slot name="camera-preview">
            </slot>
        </div>
        <div id="div-overlay">
            <slot
                name="overlay"
                v-bind="{
                    videoShown,
                    isStandby,
                    standBy,
                    capturePhoto,
                    clear
                }"
            ></slot>
        </div>
    </div>
</template>
<script>
const getUserMediaConstraint = (deviceId) => {
    return { video: { deviceId: { exact: deviceId } } }
};

const defaultMedia = {
    metadata: {
        mimeType: '',
        size: 0,
    },
    input: {
        dir: 'dev/pics',
        filename: '',
        memo: '',
    },
    url: null,
    buf: null,
};  

export default {
    data: () => ({
        canvas: null,
        videoShown: false,
        isStandby: false,
        media: { ...defaultMedia },
    }),
    props: {
        deviceId: { type: String, default: null },
        mimeType: { type: String, default: 'image/png' },
        quality: { type: Number, default: 1 },
        alwaysShowCamera: { type: Boolean, default: false },
        flipCamera: { type: Boolean, default: true },
        flipPreview: { type: Boolean, default: true },
        width: { type: [Number,String], default: '100%' },
        maxWidth: { type: [Number,String], default: '100%' },
    },
    computed: {
        wrapperStyle() {
            return {
                width: this.width,
                maxWidth: this.maxWidth,
                margin: '0 auto',
                height: '100%'
            }
        },
    },
    methods: {
        async standBy() {
            if (this.deviceId === null) return false;

            this.videoShown = true;
            let constraint = getUserMediaConstraint(this.deviceId);
            if (this.video.srcObject) {
                this.video.srcObject.getTracks().forEach( (track) => {
                    if (track.readyState == 'live') {
                        track.stop();
                    }
                });
            }
            this.video.srcObject = await navigator.mediaDevices.getUserMedia(constraint);
            this.video.onloadedmetadata = () => {
                this.video.play();
                this.isStandby = true;
                this.$emit('stand-by', this.deviceId);
            };
        },
        capturePhoto() {
            this.canvas = document.createElement('canvas');
            this.canvas.width = this.video.clientWidth;
            this.canvas.height = this.video.clientHeight;
            this.context = this.canvas.getContext('2d');
            if(this.flipPreview) {
                this.context.translate(this.canvas.width, 0);
                this.context.scale(-1, 1);
            }
            this.context.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
            this.captured = true;
            this.canvas.toBlob(async (blob) => {
                this.media.metadata.mimeType = blob.type;
                this.media.metadata.size = blob.size;
                this.media.url = URL.createObjectURL(blob);
                this.media.buf = await blob.arrayBuffer();
                this.media.input.filename = Math.floor(Date.now() / 1000).toString();
                this.videoShown = false;
                this.$emit('capture-photo', this.media)
			}, this.mimeType, this.quality);
        },
        clear() {
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.videoShown = true;
            this.media = { ...defaultMedia };
        },
    },
    watch: {
        deviceId(deviceId) {
            this.isStandby = false;
            if(deviceId !== null) this.$emit('set-device-id', deviceId);
            this.standBy();
        }
    },
    async mounted() {
        this.video = this.$refs.video;
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            const allDevices = await navigator.mediaDevices.enumerateDevices();
            const devices = allDevices.filter((dev) => dev.kind === 'videoinput');
            this.$emit('device-enumerate', devices);
        }
    }
}
</script>
<style scoped>
#wrapper {
    width: 100%;    
    position: relative;
}
#camera-preview {
    height: 100%;
    display: flex;
    align-items: center;
}
#camera-preview > * {
    display: block;
}
#video {
    width: 100%;
    object-fit: contain;
    display: block;
}
#div-overlay {
    width: 100%;    
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.flip {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
</style>
<template>     
    <div class="pa-6">
        <t-audio-segmentation
            v-model="nano.ans.audioSegmentationInput"
            v-bind:audio-url="nano.props.audioUrl"
        >
        </t-audio-segmentation>
        <div class="text-end">
            <v-btn color="primary" @click="submit()">Submit</v-btn>
        </div>
    </div>
</template>

<script>     
import TAudioSegmentation from './TAudioSegmentation.vue';
import nanoMixIn from "@/mixins/nano";

export default {
    components: { TAudioSegmentation },

    mixins: [ nanoMixIn ],

    data: () => ({
        defaultNanoProps: {
            audioUrl: '/static/samples/audios/utterance-1.wav',
        },
        defaultNanoAnswers: {
            audioSegmentationInput: {
                audioSegments: [
                    [ 0, 172 ],
                    [ 172, 238 ],
                    [ 238, 434 ],
                    [ 434, 489 ],
                    [ 489, 585 ],
                    [ 585, 659 ],
                    [ 659, 756 ],
                    [ 756, 846 ],
                    [ 846, 922 ],
                    [ 922, 943 ],
                    [ 943, 1027 ],
                    [ 1027, 1128 ],
                    [ 1128, 1150 ],
                    [ 1150, 1194 ],
                    [ 1194, 1245 ],
                    [ 1245, 1263 ],
                    [ 1263, 1399 ],
                    [ 1399, 1724 ],
                ],
                timeUnit: 'Millisecond',
                labelSequence: [ 'silB', 'ky', 'o:', 'w', 'a', 'pau', 'i', 'i', 'tcl', 't', 'e', 'N', 'kcl', 'k', 'i', 'd', 'a', 'silE' ],
            }
        },
    }),

    computed: {
        labelOptions() { return [ 'A', 'B', 'C', 'D', 'E' ] },
    },
};
</script>

<style>
</style>

<template>
    <v-container pa-10 style="max-width: 1000px;">
        <v-card class="my-10 mx-auto">
            <v-system-bar window dark color="indigo">
                <h3>Your task</h3>
            </v-system-bar>
            <div class="pa-4 text-body-1 font-weight-medium">
                Select the most suitable category of the object in the image.
            </div>
        </v-card>
        <v-card class="mx-auto my-6 pa-6">
            <div class="d-flex align-center">
                <div class="card-inner">
                    <img :src="nano.props.imgPath" style="width: 100%;" />
                </div>
                <div class="card-inner">
                    <v-radio-group v-model="nano.ans.choice">
                        <v-radio 
                            v-for="choice in choices"
                            :key="choice.val"
                            :label="choice.text"
                            :value="choice.val"
                        />
                    </v-radio-group>
                </div>
            </div>

            <div class="text-end">
                <v-btn
                    :disabled="nano.ans.choice === null"
                    color="primary" 
                    @click="submit()"
                >Submit
                </v-btn>
            </div>
        </v-card>
    </v-container>
</template>
<script>
import nanoMixIn from '@/mixins/nano';

const choices = [
    { text: 'Dog', val: 'dog'},
    { text: 'Cat', val: 'cat'},
    { text: 'Cow', val: 'cow'},
    { text: 'Tiger', val: 'tiger'},
];

export default{
    mixins: [nanoMixIn],
    data: ()=>({
        choices,
        defaultNanoProps: {
            imgPath: '/static/samples/images/comparison/pomeranian.jpeg',
        },
        defaultNanoAnswers: {
            choice: null
        }
    }),
}
</script>
<style scoped>
.card-inner {
    width: 50%;
    padding: 20px;
}
</style>
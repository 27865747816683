var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        absolute: "",
        right: "",
        temporary: "",
        permanent: _vm.$data.$_enabled,
      },
      model: {
        value: _vm.$data.$_enabled,
        callback: function ($$v) {
          _vm.$set(_vm.$data, "$_enabled", $$v)
        },
        expression: "$data.$_enabled",
      },
    },
    [
      _c(
        "v-card",
        {
          attrs: { flat: "" },
          on: {
            keydown: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c(
            "v-subheader",
            [
              _c("v-checkbox", {
                attrs: { dense: "", label: "Author Name" },
                model: {
                  value: _vm.$data.$_enableAuthorName,
                  callback: function ($$v) {
                    _vm.$set(_vm.$data, "$_enableAuthorName", $$v)
                  },
                  expression: "$data.$_enableAuthorName",
                },
              }),
            ],
            1
          ),
          _vm.$data.$_enableAuthorName
            ? _c(
                "v-card-text",
                [
                  _c("v-text-field", {
                    attrs: { dense: "" },
                    model: {
                      value: _vm.$data.$_authorName,
                      callback: function ($$v) {
                        _vm.$set(_vm.$data, "$_authorName", $$v)
                      },
                      expression: "$data.$_authorName",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-subheader",
            [
              _c("v-checkbox", {
                attrs: { dense: "", label: "Author ID" },
                model: {
                  value: _vm.$data.$_enableAuthorId,
                  callback: function ($$v) {
                    _vm.$set(_vm.$data, "$_enableAuthorId", $$v)
                  },
                  expression: "$data.$_enableAuthorId",
                },
              }),
            ],
            1
          ),
          _vm.$data.$_enableAuthorId
            ? _c(
                "v-card-text",
                [
                  _c("v-text-field", {
                    attrs: { dense: "" },
                    model: {
                      value: _vm.$data.$_authorId,
                      callback: function ($$v) {
                        _vm.$set(_vm.$data, "$_authorId", $$v)
                      },
                      expression: "$data.$_authorId",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-subheader",
            [
              _c("v-checkbox", {
                attrs: { dense: "", label: "Since" },
                model: {
                  value: _vm.$data.$_enableDateSince,
                  callback: function ($$v) {
                    _vm.$set(_vm.$data, "$_enableDateSince", $$v)
                  },
                  expression: "$data.$_enableDateSince",
                },
              }),
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$data.$_enableDateSince,
                  expression: "$data.$_enableDateSince",
                },
              ],
            },
            [
              _c("NoteEditorFilterDateSelector", {
                ref: "noteEditorFilterDateSinceSelector",
                on: { "update-error": _vm.$_onUpdateErrorDateSince },
              }),
            ],
            1
          ),
          _c(
            "v-subheader",
            [
              _c("v-checkbox", {
                attrs: { dense: "", label: "Until" },
                model: {
                  value: _vm.$data.$_enableDateUntil,
                  callback: function ($$v) {
                    _vm.$set(_vm.$data, "$_enableDateUntil", $$v)
                  },
                  expression: "$data.$_enableDateUntil",
                },
              }),
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$data.$_enableDateUntil,
                  expression: "$data.$_enableDateUntil",
                },
              ],
            },
            [
              _c("NoteEditorFilterDateSelector", {
                ref: "noteEditorFilterDateUntilSelector",
                on: { "update-error": _vm.$_onUpdateErrorDateUntil },
              }),
            ],
            1
          ),
          _c(
            "v-subheader",
            [
              _c("v-checkbox", {
                attrs: { dense: "", label: "Refer to" },
                model: {
                  value: _vm.$data.$_enableReferences,
                  callback: function ($$v) {
                    _vm.$set(_vm.$data, "$_enableReferences", $$v)
                  },
                  expression: "$data.$_enableReferences",
                },
              }),
              _c("v-spacer"),
              _vm.$data.$_enableReferences
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        outlined: "",
                        text: "",
                        "x-small": "",
                        fab: "",
                        disabled: !_vm.$_isTimelineSegmentIdFilterRegistered,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$_clearTimelineSegmentIdFilters()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-delete")])],
                    1
                  )
                : _vm._e(),
              _vm.$data.$_enableReferences
                ? _c(
                    "v-btn",
                    {
                      attrs: { outlined: "", text: "", "x-small": "", fab: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$_toggleSelectSegmentsCallbackRegistoration(
                            $event
                          )
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-playlist-plus")])],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.$data.$_enableReferences
            ? _c(
                "v-card-text",
                _vm._l(
                  _vm.$data.$_timelineSegmentIdFilters,
                  function (timelineSegmentId, timelineSegmentIdFilterIdx) {
                    return _c(
                      "v-btn",
                      {
                        key: timelineSegmentIdFilterIdx,
                        attrs: { text: "", small: "", tile: "" },
                        on: {
                          click: function ($event) {
                            return _vm.$_onClickReference(timelineSegmentId)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(timelineSegmentId.mergedId) + " ")]
                    )
                  }
                ),
                1
              )
            : _vm._e(),
          _c(
            "v-subheader",
            [
              _c("v-checkbox", {
                attrs: { dense: "", label: "Text" },
                model: {
                  value: _vm.$data.$_enableText,
                  callback: function ($$v) {
                    _vm.$set(_vm.$data, "$_enableText", $$v)
                  },
                  expression: "$data.$_enableText",
                },
              }),
            ],
            1
          ),
          _vm.$data.$_enableText
            ? _c(
                "v-card-text",
                [
                  _c("v-text-field", {
                    attrs: { dense: "" },
                    model: {
                      value: _vm.$data.$_text,
                      callback: function ($$v) {
                        _vm.$set(_vm.$data, "$_text", $$v)
                      },
                      expression: "$data.$_text",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.$_disable },
                },
                [_vm._v(" Close ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    text: "",
                    disabled: _vm.$_hasError,
                  },
                  on: { click: _vm.$_applyFilter },
                },
                [_vm._v(" Apply ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
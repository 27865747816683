var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      attrs: { id: "video-player-container" },
      on: {
        mouseenter: _vm.$_showController,
        mouseleave: _vm.$_hideController,
      },
    },
    [
      _vm.audioOnly
        ? _c(
            "div",
            {
              staticClass: "d-flex justify-center",
              attrs: { id: "video-player" },
              on: { click: _vm.$_togglePlayAndPause },
            },
            [
              _c("v-icon", { attrs: { dark: "", "x-large": "" } }, [
                _vm._v("mdi-volume-low"),
              ]),
            ],
            1
          )
        : _c("video", {
            ref: "videoPlayer",
            attrs: { id: "video-player" },
            on: {
              contextmenu: function ($event) {
                $event.preventDefault()
              },
              click: _vm.$_togglePlayAndPause,
            },
          }),
      _c("VideoPlayerController", {
        attrs: {
          id: "video-player-controller",
          shows: _vm.$data.$_isControllerShown,
          "duration-msec": _vm.$_durationMsec,
          "play-time-msec": _vm.$_playTimeMsec,
          "is-playing": _vm.isVideoPlaying,
          "is-loop-enabled": _vm.isLoopEnabled,
          "loop-definition": _vm.loopDefinition,
          "timeline-marker": _vm.timelineMarker,
        },
        on: {
          "seek-in-msec": _vm.$_seekInMsec,
          play: _vm.$_play,
          pause: _vm.$_pause,
          "enable-loop": _vm.$_enableLoop,
          "disable-loop": _vm.$_disableLoop,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
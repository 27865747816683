class TimelineCanvasLocalCoords {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }

  static epsilon = 0.001;

  isSame(otherCoords) {
    let differenceX = Math.abs(otherCoords.x - this.x);
    let differenceY = Math.abs(otherCoords.y - this.y);
    return (differenceX < TimelineCanvasLocalCoords.epsilon) && (differenceY < TimelineCanvasLocalCoords.epsilon);
  }
}

export default TimelineCanvasLocalCoords;

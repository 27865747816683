var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("dialog-base", {
    attrs: {
      shows: _vm.shows,
      "initialize-callback": _vm.$_onInitialize,
      "ok-callback": _vm.$_onOkClicked,
      "finalize-callback": _vm.finalizeCallback,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("v-card-title", [_vm._v("Filter Timeline Data")]),
            _c(
              "v-card-text",
              _vm._l(
                _vm.$data.$_timelineDataAppearenceWithInfoSet,
                function (timelineDataAppearenceWithInfo, timelineDataId) {
                  return _c("v-checkbox", {
                    key: timelineDataId,
                    attrs: {
                      dense: "",
                      label: timelineDataAppearenceWithInfo.timelineDataName,
                    },
                    model: {
                      value: timelineDataAppearenceWithInfo.show,
                      callback: function ($$v) {
                        _vm.$set(timelineDataAppearenceWithInfo, "show", $$v)
                      },
                      expression: "timelineDataAppearenceWithInfo.show",
                    },
                  })
                }
              ),
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "buttons",
        fn: function ({ on, attrs }) {
          return [
            _c(
              "v-btn",
              _vm._b(
                {
                  attrs: { color: "primary" },
                  on: { click: _vm.$_onToggleCheckAllClicked },
                },
                "v-btn",
                attrs,
                false
              ),
              [_vm._v(" Toggle Check All ")]
            ),
            _c("v-spacer"),
            _c(
              "v-btn",
              _vm._b(
                {
                  attrs: { color: "secondary" },
                  on: { click: on.cancelClicked },
                },
                "v-btn",
                attrs,
                false
              ),
              [_vm._v(" Cancel ")]
            ),
            _c(
              "v-btn",
              _vm._b(
                { attrs: { color: "primary" }, on: { click: on.okClicked } },
                "v-btn",
                attrs,
                false
              ),
              [_vm._v(" OK ")]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <canvas></canvas>
</template>

<script>
import timelineCanvas from './mixins/timelineCanvas.js'
import utils from '../../../modules/utils.js'
import canvasUtils from './modules/canvasUtils.js'

export default {
  mixins: [
    timelineCanvas,
  ],

  watch: {
    playTimeMsec() { this.$_setDirty(true); },
    isPlayTimeInView() { this.$_setDirty(true); },
    followPlayBarFlag() { this.$_setDirty(true); },
    timelineViewBeginMsec() { this.$_setDirty(true); },
    timeResolution() { this.$_setDirty(true); },
  },

  props: {
    rulerVerticalOffsetPx: { type: Number },
    playTimeMsec: { type: Number },
    isPlayTimeInView: { type: Boolean },
    followPlayBarFlag: { type: Boolean },
    timeResolution: { type: Number },
    timelineViewBeginMsec: { type: Number },
  },

  methods: {
    /* public */
    draw() {
      let timelinePlayTimeBarCanvas = this.$data.$_timelineCanvasContext;
      this.$_draw(() => {
        if (this.isPlayTimeInView) {
          let playTimelineViewOffsetMsec = this.playTimeMsec - this.timelineViewBeginMsec;
          let playTimelineViewOffsetPx = utils.unitize(playTimelineViewOffsetMsec, this.timeResolution);
          let playTimeBarColor = 'rgba(255, 0, 0, 1)';
          drawBar(playTimelineViewOffsetPx, this.$data.$_timelineCanvasHeightPx, playTimeBarColor);
          if (this.followPlayBarFlag) {
            drawFollowPlayTimeBarIndicator(playTimelineViewOffsetPx, playTimeBarColor, this.rulerVerticalOffsetPx);
          }
        }
      });

      function drawBar(timeOffsetPx, timelineCanvasHeightPx, color) {
        timelinePlayTimeBarCanvas.beginPath();
        timelinePlayTimeBarCanvas.setLineDash([]);
        timelinePlayTimeBarCanvas.lineWidth = 1;
        timelinePlayTimeBarCanvas.strokeStyle = color;
        timelinePlayTimeBarCanvas.moveTo(...canvasUtils.dotByDotOffsetRectArgs(timeOffsetPx, 0));
        timelinePlayTimeBarCanvas.lineTo(...canvasUtils.dotByDotOffsetRectArgs(timeOffsetPx, timelineCanvasHeightPx));
        timelinePlayTimeBarCanvas.stroke();
      }

      function drawFollowPlayTimeBarIndicator(playTimelineViewOffsetPx, playTimeBarColor, rulerVerticalOffsetPx) {
        timelinePlayTimeBarCanvas.beginPath();
        timelinePlayTimeBarCanvas.setLineDash([]);
        timelinePlayTimeBarCanvas.lineWidth = 1;
        timelinePlayTimeBarCanvas.strokeStyle = playTimeBarColor;
        timelinePlayTimeBarCanvas.moveTo(...canvasUtils.dotByDotOffsetRectArgs(playTimelineViewOffsetPx - 10, 2));
        timelinePlayTimeBarCanvas.lineTo(...canvasUtils.dotByDotOffsetRectArgs(playTimelineViewOffsetPx - 4, rulerVerticalOffsetPx / 2));
        timelinePlayTimeBarCanvas.lineTo(...canvasUtils.dotByDotOffsetRectArgs(playTimelineViewOffsetPx - 10, rulerVerticalOffsetPx - 2));
        timelinePlayTimeBarCanvas.stroke()
      }
    },
  }
};
</script>

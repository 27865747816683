export default {
  error(...arg) {
    console.error('[ERROR]', ...arg);
  },

  warn(...arg) {
    console.warn('[WARNING]', ...arg);
  },

  log(...arg) {
    console.log('[LOG]', ...arg);
  },
}
<template>
    <tutti-dialog
        ref="dialog"
        max-width="400"
        :buttons="[
            {
                label: $t('console.templates.create.dialog.buttons.cancel'),
                attrs: {
                    color: 'grey darken-1',
                    text: true
                },
                on: { click() { $refs.dialog.hide(); } }
            },
            {
                label: $t('console.templates.create.dialog.buttons.create'),
                attrs: {
                    color: 'indigo darken-1',
                    text: true,
                },
                on: {
                    click: createTemplate
                }
            },
        ]">
        <template #title>
            {{ $t('console.templates.create.dialog.title') }}
        </template>
        <template #body>
            <v-card-text>
                <v-form v-model="valid" @submit.prevent>
                    <v-text-field
                        filled
                        autofocus
                        v-model="newTemplateName"
                        prepend-icon="mdi-pencil"
                        :label="$t('console.templates.create.dialog.label')"
                        :rules="[(val) => (rules.reg(/^[a-zA-Z0-9-_]+$/, 'Only numbers, alphabets, _, and - are allowed', val))]"
                        :error-messages="errorMessage"
                    />
                </v-form>
            </v-card-text>

        </template>
    </tutti-dialog>
</template>
<script>
import TuttiDialog from '@/components/ui/TuttiDialog'
import rules from '@/lib/input-rules'

export default {
    components: {
        TuttiDialog
    },
    data: () => ({
        rules,
        valid: false,
        errorMessage: '',
        newTemplateName: '',
    }),
    props: ['client', 'projectName', 'presetGroupName', 'presetName'],
    methods: {
        async createTemplate() {
            try {
                await this.client.resource.createTemplate({
                    project_name: this.projectName,
                    template_name: this.newTemplateName,
                    preset_group_name: this.presetGroupName,
                    preset_name: this.presetName
                });
                this.$root.$emit('tutti-snackbar', 'success', this.$t('console.templates.create.snackbar.success', { name: this.newTemplateName }));
                this.$emit('complete');
                this.$refs.dialog.hide();
                this.newTemplateName = '';
            } catch(e) {
                console.log(e);
                if(e.code===this.client.ERROR.PROJECT.EXST_TMPL){
                    this.errorMessage = this.$t('console.templates.create.dialog.alreadyExistsError');
                    setTimeout(() => { this.errorMessage = '' }, 2000);
                }
            }
        },
        show() {
            this.$refs.dialog.show();
        }
    }
}
</script>

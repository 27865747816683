<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="$_mergeAttributes(attrs)"
        v-on="$_mergeListeners(on)"
      >
        <template v-slot>
          <slot></slot>
        </template>
      </v-btn>
    </template>

    <template v-slot>
      <slot name="tooltip"></slot>
    </template>
  </v-tooltip>
</template>

<script>
export default {
  methods: {
    $_mergeAttributes(attributes) {
      let mergedAttributes = Object.assign(attributes, this.$attrs);
      mergedAttributes.class = this.$vnode.data.staticClass;
      return mergedAttributes;
    },

    $_mergeListeners(listeners) {
      let mergedListeners = Object.assign(listeners, this.$listeners);
      return mergedListeners;
    },
  },
}
</script>

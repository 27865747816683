var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { style: _vm.wrapperStyle, attrs: { id: "wrapper" } }, [
    _c(
      "div",
      { attrs: { id: "camera-preview" } },
      [
        _c("video", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.videoShown,
              expression: "videoShown",
            },
          ],
          ref: "video",
          attrs: { id: "video", autoplay: "", playsinline: "" },
        }),
        _vm._t("camera-preview", null, null, { media: _vm.media }),
      ],
      2
    ),
    _c(
      "div",
      { attrs: { id: "div-overlay" } },
      [
        _vm._t("overlay", null, null, {
          videoShown: _vm.videoShown,
          isReady: _vm.isReady,
          recorderState: _vm.recorderState,
          media: _vm.media,
          standBy: _vm.standBy,
          start: _vm.start,
          stop: _vm.stop,
          pause: _vm.pause,
          resume: _vm.resume,
          clear: _vm.clear,
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
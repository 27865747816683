<template>
    <v-container pa-10 style="max-width: 1000px;">
        <v-card class="my-10">
            <v-system-bar window dark color="indigo">
                <h3>Your task</h3>
            </v-system-bar>
            <div class="pa-4 text-body-1 font-weight-medium">
                Compare two dog images and give an answer whether they are the same breed.
            </div>
        </v-card>

        <v-card class="mx-auto my-6 pa-6">
            <v-row align="center">
                <v-col cols="5">
                    <v-card class="pa-3" color="grey lighten-4">
                        <v-img height="300" :src="nano.props.imgUrl0" contain>
                            <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="grey lighten-1"></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                   </v-card>
                </v-col>
                <v-col cols="2" align="center" justify="center"><v-icon x-large>mdi-arrow-left-right-bold</v-icon></v-col>
                <v-col cols="5">
                    <v-card class="pa-3" color="grey lighten-4">
                        <v-img height="300" :src="nano.props.imgUrl1" contain>
                            <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="grey lighten-1"></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                    </v-card>
                </v-col>
            </v-row>

            <v-row class="d-flex" justify="center">
                <v-btn class="ma-3" x-large dark color="green darken-4" @click="submitWithChoice('Same')">Same</v-btn>
                <v-btn class="ma-3" x-large dark color="green darken-1" @click="submitWithChoice('Maybe Same')">Maybe Same</v-btn>
                <v-btn class="ma-3" x-large dark color="red darken-1"   @click="submitWithChoice('Maybe Not Same')">Maybe Not Same</v-btn>
                <v-btn class="ma-3" x-large dark color="red darken-4"   @click="submitWithChoice('Not Same')">Not Same</v-btn>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
import nanoMixIn from "@/mixins/nano";
export default {
    mixins: [nanoMixIn],
    data: () => ({
        defaultNanoProps: {
            imgUrl0: '/static/samples/images/comparison/pomeranian.jpeg',
            imgUrl1: '/static/samples/images/comparison/corgi.jpeg'
        },
        defaultNanoAnswers: {
            choice: null
        }
    }),
    methods: {
        submitWithChoice(choice) {
            this.nano.ans.choice = choice;
            this.submit();
        }
    }
};
</script>

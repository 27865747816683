class ReferencePool {
  static registrationCount = 0;
  static data = new Object();
  static onRegisterCallbackRegistrationCount = 0;
  static onRegisterCallbackData = new Object();
  static onUnregisterCallbackRegistrationCount = 0;
  static onUnregisterCallbackData = new Object();

  static onRegister(registeredReferenceIds, registeredReferences) {
    for (let callback of Object.values(ReferencePool.onRegisterCallbackData)) {
      callback(registeredReferenceIds, registeredReferences);
    }
  }

  static onUnregister(unregisteredReferenceIds, unregisteredReferences) {
    for (let callback of Object.values(ReferencePool.onUnregisterCallbackData)) {
      callback(unregisteredReferenceIds, unregisteredReferences);
    }
  }
}

class Reference {
  constructor(refClass, referFrom, referTo) {
    this.refClass = refClass;
    this.referFrom = referFrom;
    this.referTo = referTo;
  }
}

export { Reference }

export default {
  registerOnRegisterCallback(callback) {
    let callbackId = String(ReferencePool.onRegisterCallbackRegistrationCount);
    ReferencePool.onRegisterCallbackData[callbackId] = callback;
    ++ReferencePool.onRegisterCallbackRegistrationCount;
    return callbackId;
  },

  unregisterOnRegisterCallback(callbackId) {
    if (Object.keys(ReferencePool.onRegisterCallbackData).includes(callbackId)) {
      delete ReferencePool.onRegisterCallbackData[callbackId];
    }
  },

  registerOnUnregisterCallback(callback) {
    let callbackId = String(ReferencePool.onUnregisterCallbackRegistrationCount);
    ReferencePool.onUnregisterCallbackData[callbackId] = callback;
    ++ReferencePool.onUnregisterCallbackRegistrationCount;
    return callbackId;
  },

  unregisterOnUnregisterCallback(callbackId) {
    if (Object.keys(ReferencePool.onUnregisterCallbackData).includes(callbackId)) {
      delete ReferencePool.onUnregisterCallbackData[callbackId];
    }
  },

  registerReferences(...references) {
    let numNewReferences = references.length;
    let referenceIds = new Array();
    for (let newReferenceIdx = 0; newReferenceIdx < numNewReferences; ++newReferenceIdx) {
      let referenceId = String(ReferencePool.registrationCount);
      referenceIds.push(referenceId);
      ReferencePool.data[referenceId] = references[newReferenceIdx];
      ++ReferencePool.registrationCount;
    }
    ReferencePool.onRegister(referenceIds, references);
    return referenceIds;
  },

  unregisterReferences(...referenceIds) {
    let references = referenceIds.map(referenceId => ReferencePool.data[referenceId])
    for (let referenceId of referenceIds) {
      delete ReferencePool.data[referenceId];
    }
    ReferencePool.onUnregister(referenceIds, references);
  },

  getReference(referenceId) {
    return ReferencePool.data[referenceId];
  },

  getAllReferences() {
    return ReferencePool.data;
  },
}
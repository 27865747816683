<template>
    <tutti-dialog
        ref="dialog"
        maxWidth="700"
        :buttons="[
            {
                label: $t('console.templates.responseDialog.closeButton'),
                attrs: {
                    color: 'indigo darken-1',
                    text: true
                },
                on: {
                    click() { $refs.dialog.hide(); }
                }
            }
        ]">
        <template v-slot:title>
            <v-icon class="mr-2" color="green">mdi-check-circle</v-icon>{{ $t('console.templates.responseDialog.title') }}
        </template>
        <template v-slot:body>
            <v-card-text>
                <vue-json-pretty :data="nanoAnswers"></vue-json-pretty>
            </v-card-text>
        </template>
    </tutti-dialog>
</template>
<script>
import 'vue-json-pretty/lib/styles.css'
import VueJsonPretty from 'vue-json-pretty/lib/vue-json-pretty'
import TuttiDialog from '@/components/ui/TuttiDialog'

export default {
    components: { TuttiDialog, VueJsonPretty },
    props: ['nanoAnswers'],
    methods: {
        show() { this.$refs.dialog.show(); }
    }
}
</script>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._t("default", null, null, {
        isStandby: _vm.isStandby,
        recording: _vm.recording,
        standBy: _vm.standBy,
        start: _vm.start,
        stop: _vm.stop,
        pause: _vm.pause,
        resume: _vm.resume,
        clear: _vm.clear,
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
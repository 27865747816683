<template>
  <v-data-table
    dense
    hide-default-footer
    v-bind:headers="$_headers"
    v-bind:items="$_items"
    v-bind:items-per-page="-1"
  >
    <template v-slot:item="{ index, item }">
      <tr
        class="audio-segment-list-row"
        v-on:click="$_onClickRow($event, index)"
        v-bind:style="$_rowStyles[index]"
      >
        <td class="px-1">
          <editable-text
            simple
            v-bind:value="item.beginString"
            v-bind:readonly="$_isBeginReadonly[index]"
            v-bind:validator="$_generateBeginValidator(index)"
            v-on:update="$_updateBegin(index, $event)"
          >
          </editable-text>
        </td>

        <td class="px-1">
          <editable-text
            simple
            v-bind:value="item.endString"
            v-bind:readonly="$_isEndReadonly[index]"
            v-bind:validator="$_generateEndValidator(index)"
            v-on:update="$_updateEnd(index, $event)"
          >
          </editable-text>
        </td>

        <td class="px-1">
          <editable-text
            simple
            v-bind:value="item.label"
            v-bind:options="labelOptions"
            v-on:update="$_updateLabel(index, $event)"
          >
          </editable-text>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<style scoped>
tr.audio-segment-list-row {
  user-select: none;
}
</style>

<script>
import EditableText from './EditableText.vue';
import AudioSegmentSequence, { AudioSegmentTimeUnit } from './modules/AudioSegmentSequence.js';
import Utils from './modules/Utils.js';
import Color from './modules/Color.js';

export default {
  components: {
    EditableText,
  },

  model: {
    prop: 'audioSegmentSequence',
    event: 'update',
  },

  props: {
    audioSegmentSequence:     { type: AudioSegmentSequence },
    selectedAudioSegmentIdcs: { type: Array },
    labelSequence:            { type: Array },
    labelOptions:             { type: Array },
    timeUnit:                 { type: AudioSegmentTimeUnit },
  },

  computed: {
    $_rowStyles() {
      return Utils.mapWithTimes(this.labelSequence.length, labelIdx => {
        if (this.selectedAudioSegmentIdcs.includes(labelIdx)) {
          return { backgroundColor: Color.clearBlue.styleString };
        } else {
          return {};
        }
      })
    },

    $_isBeginReadonly() {
      return Utils.mapWithTimes(this.labelSequence.length, labelIdx => {
        if (labelIdx === 0) {
          return true;
        } else if (labelIdx < this.audioSegmentSequence.numAudioSegments) {
          return false;
        } else {
          return true;
        }
      })
    },

    $_isEndReadonly() {
      let lastAudioSegmentIdx = this.audioSegmentSequence.numAudioSegments - 1;
      return Utils.mapWithTimes(this.labelSequence.length, labelIdx => {
        if (labelIdx < lastAudioSegmentIdx) {
          return false;
        } else {
          return true;
        }
      })
    },

    $_headers() {
      return [
        { value: 'begin', text: 'Begin', sortable: false, class: 'px-1' },
        { value: 'end',   text: 'End',   sortable: false, class: 'px-1' },
        { value: 'label', text: 'Label', sortable: false, class: 'px-1' },
      ];
    },

    $_items() {
      return this.labelSequence.map((label, labelIdx) => {
        if (labelIdx < this.audioSegmentSequence.numAudioSegments) {
          let audioSegment = this.audioSegmentSequence.audioSegments[labelIdx];
          let beginRationalTime = AudioSegmentSequence.convertTime(
            audioSegment.begin,
            this.audioSegmentSequence.timeUnit,
            this.timeUnit,
            { samplingRate: this.audioSegmentSequence.samplingRate },
          );
          let begin = beginRationalTime.toNumber();
          let endRationalTime = AudioSegmentSequence.convertTime(
            audioSegment.end,
            this.audioSegmentSequence.timeUnit,
            this.timeUnit,
            { samplingRate: this.audioSegmentSequence.samplingRate },
          );
          let end = endRationalTime.toNumber();
          return {
            begin,
            end,
            beginString: Math.floor(begin),
            endString: Math.floor(end),
            label: label,
          };
        } else {
          return {
            begin: null,
            end: null,
            beginString: '-',
            endString: '-',
            label: label,
          };
        }
      });
    },
  },

  inject: [
    'selectAudioSegments',
    'clearSelectedAudioSegments',
  ],

  methods: {
    $_generateBeginValidator(targetAudioSegmentIdx) {
      return this.audioSegmentSequence.generateBeginValidator(targetAudioSegmentIdx, this.timeUnit);
    },

    $_generateEndValidator(targetAudioSegmentIdx) {
      return this.audioSegmentSequence.generateEndValidator(targetAudioSegmentIdx, this.timeUnit);
    },

    $_updateBegin(targetAudioSegmentIdx, newBeginText) {
      let updatedAudioSegmentSequence = this.audioSegmentSequence.clone();
      updatedAudioSegmentSequence.replaceSegmentBegin(
        targetAudioSegmentIdx,
        newBeginText,
        this.timeUnit,
      );
      this.$emit('update', updatedAudioSegmentSequence);
    },

    $_updateEnd(targetAudioSegmentIdx, newEndText) {
      let updatedAudioSegmentSequence = this.audioSegmentSequence.clone();
      updatedAudioSegmentSequence.replaceSegmentEnd(
        targetAudioSegmentIdx,
        newEndText,
        this.timeUnit,
      );
      this.$emit('update', updatedAudioSegmentSequence);
    },

    $_updateLabel(targetAudioSegmentIdx, newLabel) {
      let newLabelSequence = Utils.cloneArray(this.labelSequence);
      newLabelSequence[targetAudioSegmentIdx] = newLabel;
      this.$emit('update-label-sequence', newLabelSequence);
    },

    $_onClickRow(mouseEvent, itemIdx) {
      if (!mouseEvent.shiftKey) this.clearSelectedAudioSegments();
      if (itemIdx < this.audioSegmentSequence.numAudioSegments) {
        this.selectAudioSegments([ itemIdx ]);
      }
    },
  },
}
</script>
/* eslint-disable no-console */
import referencePool from '../../external/modules/referencePool.js';

class ChangeHistoryResult {
  constructor(summary, scattData, callback) {
    this.summary = summary;
    this.scattData = scattData;
    this.callback = callback;
  }
}

const historyCapacity = 1000;

function applyChanges(scattData, timelineDataToDelete, timelineDataToAdd, timelineDataDifference, referenceObjToRegister, referenceObjToUnregister) {
  for (let timelineDataId of Object.keys(timelineDataToDelete)) {
    delete scattData.timelineDataSet[timelineDataId];
  }
  for (let [ timelineDataId, timelineData ] of Object.entries(timelineDataToAdd)) {
    scattData.timelineDataSet[timelineDataId] = timelineData;
  }
  for (let [ timelineDataId, timelineData ] of Object.entries(timelineDataDifference)) {
    for (let [ timelineDataKey, timelineDataValue ] of Object.entries(timelineData)) {
      if (timelineDataKey === 'segments') {
        for (let [ segmentId, segment ] of Object.entries(timelineDataValue)) {
          if (segment === null) {
            delete scattData.timelineDataSet[timelineDataId].segments[segmentId];
          } else {
            scattData.timelineDataSet[timelineDataId].segments[segmentId] = segment;
          }
        }
      } else {
        scattData.timelineDataSet[timelineDataId][timelineDataKey] = timelineDataValue;
      }
    }
  }

  ChangeHistory.unregisterReferencePoolCallbacks();
  let referenceIdsToRegister = Object.keys(referenceObjToRegister);
  let referencesToRegister = Object.values(referenceObjToRegister);
  let numReferencesToRegister = referenceIdsToRegister.length;
  let referenceIdsToRegisterNew = referencePool.registerReferences(...referencesToRegister);
  for (let referenceIdxToRegister = 0; referenceIdxToRegister < numReferencesToRegister; ++referenceIdxToRegister) {
    let referenceIdToRegister = referenceIdsToRegister[referenceIdxToRegister];
    let referenceIdToRegisterNew = referenceIdsToRegisterNew[referenceIdxToRegister];
    delete referenceObjToRegister[referenceIdToRegister];
    referenceObjToRegister[referenceIdToRegisterNew] = referencesToRegister[referenceIdxToRegister];
  }
  let referenceIdsToUnregister = Object.keys(referenceObjToUnregister);
  let referencesToUnregister = Object.values(referenceObjToUnregister);
  let numReferencesToUnregister = referenceIdsToUnregister.length;
  for (let referenceIdxToUnegister = 0; referenceIdxToUnegister < numReferencesToUnregister; ++referenceIdxToUnegister) {
    let referenceIdToUnregister = referenceIdsToUnregister[referenceIdxToUnegister];
    if (referencePool.getReference(referenceIdToUnregister)) {
      referencePool.unregisterReferences(referenceIdToUnregister);
    } else {
      let referenceToUnregister = referencesToUnregister[referenceIdxToUnegister];
      let allReferences = referencePool.getAllReferences();
      let referenceIdToUnregisterNew = Object.keys(allReferences).find((referenceId) => {
        let reference = allReferences[referenceId];
        return (reference.referFrom.isSame(referenceToUnregister.referFrom) &&
          reference.referTo.isSame(referenceToUnregister.referTo));
      });
      referencePool.unregisterReferences(referenceIdToUnregisterNew);
      delete referenceObjToUnregister[referenceIdToUnregister];
      referenceObjToUnregister[referenceIdToUnregisterNew] = referenceToUnregister;
    }
  }
  ChangeHistory.registerReferencePoolCallbacks();
}

function referencePoolOnRegisterCallback(registeredReferenceIds, registeredReferences) {
  let numRegisteredReferences = registeredReferenceIds.length;
  if (numRegisteredReferences === 0) return;
  let registeredReferenceObj = new Object();
  for (let registeredReferenceIdx = 0; registeredReferenceIdx < numRegisteredReferences; ++registeredReferenceIdx) {
    let registeredReferenceId = registeredReferenceIds[registeredReferenceIdx];
    let registeredReference = registeredReferences[registeredReferenceIdx];
    registeredReferenceObj[registeredReferenceId] = registeredReference;
  }
  let change = new Change('registering references', null, null, registeredReferenceObj, null, null, null);
  ChangeHistory.register(change);
}

function referencePoolOnUnregisterCallback(unregisteredReferenceIds, unregisteredReferences) {
  let numRegisteredReferences = unregisteredReferenceIds.length;
  if (numRegisteredReferences === 0) return;
  let unregisteredReferenceObj = new Object();
  for (let unregisteredReferenceIdx = 0; unregisteredReferenceIdx < numRegisteredReferences; ++unregisteredReferenceIdx) {
    let unregisteredReferenceId = unregisteredReferenceIds[unregisteredReferenceIdx];
    let unregisteredReference = unregisteredReferences[unregisteredReferenceIdx];
    unregisteredReferenceObj[unregisteredReferenceId] = unregisteredReference;
  }
  let change = new Change('unregistering references', null, null, null, unregisteredReferenceObj, null, null);
  ChangeHistory.register(change);
}

class AtomicChanges {
  constructor(summary, changes) {
    this.summary = summary;
    this.changes = changes;
  }

  hasNoChange() {
    for (let change of this.changes) {
      if (!change.hasNoChange()) return false;
    }
    return true;
  }

  undo(scattData) {
    let numChanges = this.changes.length;
    for (let changeIdx = (numChanges - 1); changeIdx >= 0; --changeIdx) {
      let change = this.changes[changeIdx];
      change.undo(scattData);
    }
  }

  redo(scattData) {
    for (let change of this.changes) {
      change.redo(scattData);
    }
  }
}

class Change {
  constructor(summary, currentScattData, previousScattData, registeredReference, unregisteredReference, undoCallback, redoCallback) {
    let addedTimelineData = new Object();
    let deletedTimelineData = new Object();
    let updatedTimelineDataDifferenceBefore = new Object();
    let updatedTimelineDataDifferenceAfter = new Object();
    if (!((currentScattData === null) && (previousScattData === null))) {
      let currentTimelineDataSet = (currentScattData === null)? new Object() : currentScattData.timelineDataSet;
      let previousTimelineDataSet = (previousScattData === null)? new Object() : previousScattData.timelineDataSet;
      let keptTimelineDataIds = new Array();
      let currentTimelineDataIds = Object.keys(currentTimelineDataSet);
      let previousTimelineDataIds = Object.keys(previousTimelineDataSet);
      for (let currentTimelineDataId of currentTimelineDataIds) {
        if (!previousTimelineDataIds.includes(currentTimelineDataId)) {
          let currentTimelineData = currentTimelineDataSet[currentTimelineDataId];
          if (currentTimelineData.readonly) continue;
          addedTimelineData[currentTimelineDataId] = currentTimelineData.clone(true);
        } else {
          keptTimelineDataIds.push(currentTimelineDataId);
        }
      }
      for (let previousTimelineDataId of previousTimelineDataIds) {
        if (!currentTimelineDataIds.includes(previousTimelineDataId)) {
          let previousTimelineData = previousTimelineDataSet[previousTimelineDataId];
          if (previousTimelineData.readonly) continue;
          deletedTimelineData[previousTimelineDataId] = previousTimelineData.clone(true);
        }
      }
      for (let keptTimelineDataId of keptTimelineDataIds) {
        let currentTimelineData = currentTimelineDataSet[keptTimelineDataId];
        let previousTimelineData = previousTimelineDataSet[keptTimelineDataId];
        if (currentTimelineData.readonly || previousTimelineData.readonly) continue;
        let timelineDifferencePair = previousTimelineData.getDifferencePair(currentTimelineData);
        if (timelineDifferencePair !== null) {
          updatedTimelineDataDifferenceBefore[keptTimelineDataId] = timelineDifferencePair.before;
          updatedTimelineDataDifferenceAfter[keptTimelineDataId] = timelineDifferencePair.after;
        }
      }
    }
    this.summary = summary;
    this.addedTimelineData = addedTimelineData;
    this.deletedTimelineData = deletedTimelineData;
    this.updatedTimelineDataDifferenceBefore = updatedTimelineDataDifferenceBefore;
    this.updatedTimelineDataDifferenceAfter = updatedTimelineDataDifferenceAfter;
    if (registeredReference === null) registeredReference = new Object();
    if (unregisteredReference === null) unregisteredReference = new Object();
    this.registeredReference = registeredReference;
    this.unregisteredReference = unregisteredReference;
    this.undoCallback = undoCallback;
    this.redoCallback = redoCallback;
  }

  hasNoChange() {
    if (Object.keys(this.addedTimelineData).length > 0) return false;
    if (Object.keys(this.addedTimelineData).length > 0) return false;
    if (Object.keys(this.deletedTimelineData).length > 0) return false;
    if (Object.keys(this.updatedTimelineDataDifferenceBefore).length > 0) return false;
    if (Object.keys(this.updatedTimelineDataDifferenceAfter).length > 0) return false;
    if (Object.keys(this.registeredReference).length > 0) return false;
    if (Object.keys(this.unregisteredReference).length > 0) return false;
    return true;
  }

  undo(scattData) {
    applyChanges(
      scattData,
      this.addedTimelineData,
      this.deletedTimelineData,
      this.updatedTimelineDataDifferenceBefore,
      this.unregisteredReference,
      this.registeredReference,
    );
  }

  redo(scattData) {
    applyChanges(
      scattData,
      this.deletedTimelineData,
      this.addedTimelineData,
      this.updatedTimelineDataDifferenceAfter,
      this.registeredReference,
      this.unregisteredReference,
    );
  }
}

class ChangeHistory {
  static readAndWriteIdx = 0;
  static lastSavedIdx = null;
  static data = new Array(0);
  static tempData = null;
  static currentScattData = null;
  static onReferencePoolRegisterCallbackId = null;
  static onReferencePoolUnregisterCallbackId = null;

  static isTempDataSet() {
    return this.tempData !== null;
  }

  static initializeTempData() {
    if (ChangeHistory.isTempDataSet()) return;
    this.tempData = new Array(0);
  }

  static finalizeTempData(summary) {
    if (!ChangeHistory.isTempDataSet()) return false;
    let atomicChanges = new AtomicChanges(summary, this.tempData);
    this.tempData = null;
    if (atomicChanges.hasNoChange()) return false;
    this.register(atomicChanges);
    return true;
  }

  static register(change) {
    if (this.readAndWriteIdx === historyCapacity) {
      this.data.splice(0, 1);
      if (this.readAndWriteIdx !== 0) --this.readAndWriteIdx;
    }
    let numData = this.data.length;
    if (this.readAndWriteIdx !== numData) {
      let numDataToDelete = numData - this.readAndWriteIdx;
      this.data.splice(this.readAndWriteIdx, numDataToDelete);
    }
    if (this.isTempDataSet()) {
      this.tempData.push(change);
    } else {
      this.data.push(change);
      ++this.readAndWriteIdx;
    }
  }

  static clearRegistration() {
    let numRegistrations = this.data.length;
    this.data.splice(0, numRegistrations);
    this.readAndWriteIdx = 0;
  }

  static get() {
    let readIdx = this.readAndWriteIdx - 1;
    if (readIdx < 0) return null;
    if (readIdx >= this.data.length) return null;
    return this.data[readIdx];
  }

  static forwardAndGet() {
    if (this.isPointingLastChange()) {
      return null;
    } else {
      ++this.readAndWriteIdx;
      let ret = this.get();
      return ret;
    }
  }

  static getAndBackward() {
    if (this.isPointingFirstChange()) {
      return null;
    } else {
      let ret = this.get();
      --this.readAndWriteIdx;
      return ret;
    }
  }

  static isPointingFirstChange() {
    return (this.readAndWriteIdx === 0);
  }

  static isPointingLastChange() {
    return (this.readAndWriteIdx === this.data.length);
  }

  static registerReferencePoolCallbacks() {
    this.onReferencePoolRegisterCallbackId = referencePool.registerOnRegisterCallback(referencePoolOnRegisterCallback);
    this.onReferencePoolUnregisterCallbackId = referencePool.registerOnUnregisterCallback(referencePoolOnUnregisterCallback);
  }

  static unregisterReferencePoolCallbacks() {
    referencePool.unregisterOnRegisterCallback(this.onReferencePoolRegisterCallbackId);
    referencePool.unregisterOnUnregisterCallback(this.onReferencePoolUnregisterCallbackId);
    this.onReferencePoolRegisterCallbackId = null;
    this.onReferencePoolUnregisterCallbackId = null;
  }
}

export default {
  initialize(initialScattData) {
    ChangeHistory.currentScattData = initialScattData.clone(true);
    ChangeHistory.registerReferencePoolCallbacks();
  },

  finalize() {
    ChangeHistory.unregisterReferencePoolCallbacks();
    ChangeHistory.currentScattData = null;
  },

  isFirstChange() {
    return ChangeHistory.isPointingFirstChange();
  },

  isLastChange() {
    return ChangeHistory.isPointingLastChange();
  },

  undo(scattData) {
    if (ChangeHistory.isTempDataSet()) return null;
    let change = ChangeHistory.getAndBackward();
    if (!change) return null;
    ChangeHistory.currentScattData = scattData.clone(true);
    change.undo(ChangeHistory.currentScattData);
    return new ChangeHistoryResult(change.summary, ChangeHistory.currentScattData.clone(true), change.undoCallback);
  },

  redo(scattData) {
    if (ChangeHistory.isTempDataSet()) return null;
    let change = ChangeHistory.forwardAndGet();
    if (!change) return null;
    ChangeHistory.currentScattData = scattData.clone(true);
    change.redo(ChangeHistory.currentScattData);
    return new ChangeHistoryResult(change.summary, ChangeHistory.currentScattData.clone(true), change.redoCallback);
  },

  markAsSaved() {
    ChangeHistory.lastSavedIdx = ChangeHistory.readAndWriteIdx;
  },

  isSameAsLastSaved() {
    if (ChangeHistory.lastSavedIdx === null) return false;
    return ChangeHistory.lastSavedIdx === ChangeHistory.readAndWriteIdx;
  },

  beginAtomicChanges() {
    ChangeHistory.initializeTempData();
  },

  endAtomicChanges(summary) {
    return ChangeHistory.finalizeTempData(summary);
  },

  registerChange(scattData, summary, undoCallback = null, redoCallback = null) {
    let change = new Change(summary, scattData, ChangeHistory.currentScattData, null, null, undoCallback, redoCallback);
    if (change.hasNoChange()) return false;
    if (ChangeHistory.lastSavedIdx < ChangeHistory.readAndWriteIdx) ChangeHistory.lastSavedIdx = null;
    ChangeHistory.register(change);
    ChangeHistory.currentScattData = scattData.clone(true);
    return true;
  },

  clearChangeRegistration() {
    if (ChangeHistory.isTempDataSet()) return;
    ChangeHistory.clearRegistration();
  },

  debugGetData() {
    return ChangeHistory.data;
  },
}
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-toolbar",
    [
      _c("audio-player-controller", {
        ref: "audioPlayerController",
        attrs: {
          "duration-sec": _vm.audioBuffer.duration,
          "play-time-sec": _vm.$data.$_playTimeSec,
          "is-playing": _vm.$data.$_isPlaying,
          "is-seeking": _vm.$data.$_isSeeking,
          "is-loop-enabled": _vm.isLoopEnabled,
          "loop-definition": _vm.$_loopDefinition,
        },
        on: {
          "seek-start": _vm.$_seekStart,
          "seek-in-sec": _vm.$_seekInSec,
          "seek-instantly-in-sec": _vm.$_seekInstantlyInSec,
          "seek-end": _vm.$_seekEnd,
          play: _vm.$_play,
          pause: _vm.$_pause,
          "enable-loop": _vm.enableLoopPlayback,
          "disable-loop": _vm.disableLoopPlayback,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
/* eslint-disable no-console */
class NoteWithInfo {
  constructor(noteDataName, timelineSegmentId, segment, authorId, authorName) {
    this.noteDataName = noteDataName;
    this.timelineSegmentId = timelineSegmentId;
    this.begin = segment.begin;
    this.end = segment.end;
    this.text = segment.data.text;
    this.date = segment.data.date;
    this.authorId = authorId;
    this.authorName = authorName;
    this.isLocked = segment.locked;
  }
}

export default NoteWithInfo;

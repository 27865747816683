var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticStyle: { "max-width": "1000px" }, attrs: { "pa-10": "" } },
    [
      _c(
        "v-card",
        { staticClass: "my-10" },
        [
          _c(
            "v-system-bar",
            { attrs: { window: "", dark: "", color: "indigo" } },
            [_c("h3", [_vm._v("Your task")])]
          ),
          _c("div", { staticClass: "pa-4 text-body-1 font-weight-medium" }, [
            _vm._v(" Select a genre of the suggested music. "),
          ]),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mx-auto my-6 pa-6", attrs: { "max-width": "1000" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    [
                      _c("t-audio", {
                        attrs: { src: _vm.nano.props.audioPath },
                        on: { "emit-current-time": _vm.getCurrentTime },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("div", [
                      _vm._v(
                        "Radio buttons are activated after the audio is played beyond 0:05."
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-0 pt-0" },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-radio-group",
                        {
                          model: {
                            value: _vm.nano.ans.choice,
                            callback: function ($$v) {
                              _vm.$set(_vm.nano.ans, "choice", $$v)
                            },
                            expression: "nano.ans.choice",
                          },
                        },
                        _vm._l(_vm.optionChoice, function (option) {
                          return _c("v-radio", {
                            key: option.val,
                            attrs: {
                              label: option.text,
                              value: option.val,
                              disabled: _vm.radioDisabled,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.nano.ans.choice === null,
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.submit()
                            },
                          },
                        },
                        [_vm._v("Submit ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div id="note-editor-container">
    <v-container id="note-editor-card-container">
      <v-card
        class="mb-3"
        v-if="$_hasNoEntry"
      >
        <v-card-text>(No entries)</v-card-text>
      </v-card>
      <v-card
        outlined
        class="mb-3"
        v-for="(noteWithInfo, timelineSegmentMergedId) in $_filteredNotesWithInfo"
        v-bind:key="timelineSegmentMergedId"
      >
        <v-row dense>
          <v-col>
            <v-subheader>Text</v-subheader>
            <v-card-text>
              <v-textarea
                dense
                v-bind:disabled="noteWithInfo.isLocked"
                v-bind:rows="$_numRows(noteWithInfo.text)"
                v-model="noteWithInfo.text"
                v-on:keydown.stop
                v-on:blur="$_saveNote(timelineSegmentMergedId)"
              />
            </v-card-text>
          </v-col>
        </v-row>
        <v-row no-gutters dense>
          <v-col cols="4">
            <v-subheader>Author Name(ID)</v-subheader>
            <v-card-text>{{ noteWithInfo.authorName }}({{ noteWithInfo.authorId }})</v-card-text>
          </v-col>
          <v-col cols="4">
            <v-subheader>Date</v-subheader>
            <v-card-text>{{ noteWithInfo.date.toLocaleString() }}</v-card-text>
          </v-col>
          <v-col cols="4">
            <v-subheader style="position: relative">
              <span>Refer to</span>
              <v-spacer />
              <v-btn
                outlined text small right
                v-bind:disabled="noteWithInfo.isLocked"
                v-on:click.stop="$_toggleSelectSegmentsCallbackRegistoration(noteWithInfo.timelineSegmentId, $event)"
                v-on:keydown.stop
              >
                Edit
              </v-btn>
            </v-subheader>
            <v-btn
              text small tile
              v-for="(timelineSegmentIdReferringTo, referenceId) in $data.$_timelineSegmentIdsReferringTo[timelineSegmentMergedId]"
              v-bind:key="referenceId"
              v-on:click.stop="selectTimelineSegmentAndSeek(timelineSegmentIdReferringTo)"
              v-on:keydown.stop
            >
              {{ timelineSegmentIdReferringTo.mergedId }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <div id="note-editor-filter-container">
      <v-btn
        id="note-editor-filter-button"
        tile x-small text outlined
        v-on:click.stop="$_openFilterMenu"
        v-on:keydown.stop
        v-bind:color="$_noteEditorFilterButtonColor"
      >
        <v-icon>mdi-filter</v-icon>
      </v-btn>
      <NoteEditorFilterMenu
        ref="noteEditorFilterMenu"
        id="note-editor-filter-menu"
        v-on:apply-filter="$_applyFilter"
      />
    </div>
  </div>
</template>

<style scoped>
#note-editor-container {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

#note-editor-card-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  padding-right: 50px;
}

#note-editor-filter-container {
  position: sticky;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  pointer-events: none;
}

#note-editor-filter-button {
  position: absolute;
  height: 100%;
  width: 30px;
  top: 0;
  right: 0;
  padding: 0;
  pointer-events: auto;
}

#note-editor-filter-menu {
  pointer-events: auto;
}

.attached-reference {
  background-color: #f03e1e !important;
}
</style>

<script>
import NoteEditorFilterMenu from './NoteEditor/NoteEditorFilterMenu.vue'
import { NoteFilter } from './NoteEditor/NoteEditorFilterMenu.vue'
import NoteWithInfo from './NoteEditor/NoteWithInfo.js'
import { TimelineSegmentId } from '../../../external/modules/ScattData.js'
import NoteSegmentData from '../../../external/modules/segmentDataTypes/NoteSegmentData.js'
import referencePool from '../../../external/modules/referencePool.js'
import { Reference } from '../../../external/modules/referencePool.js'
import utils from '../../modules/utils.js'

export default {
  components: {
    NoteEditorFilterMenu,
  },

  props: {
    timelineDataSet: { type: Object },
  },

  data() {
    return {
      $_registerSelectSegmentsCallbackButtonElement: null,
      $_filter: NoteFilter.emptyInstance,
      $_timelineSegmentIdsReferringTo: new Object(),
      $_onReferencePoolRegisterCallbackId: null,
      $_onReferencePoolUnregisterCallbackId: null,
    };
  },

  created() {
    this.$data.$_onReferencePoolRegisterCallbackId = referencePool.registerOnRegisterCallback(this.$_refreshTimelineSegmentIdsRefferingTo);
    this.$data.$_onReferencePoolUnregisterCallbackId = referencePool.registerOnUnregisterCallback(this.$_refreshTimelineSegmentIdsRefferingTo);
  },

  mounted() {
    this.$_refreshTimelineSegmentIdsRefferingTo();
  },

  destroyed() {
    if (this.$_isSelectSegmentsCallbackRegistered) {
      this.$_unregisterSelectSegmentsCallback();
    }
    referencePool.unregisterOnRegisterCallback(this.$data.$_onReferencePoolRegisterCallbackId);
    referencePool.unregisterOnUnregisterCallback(this.$data.$_onReferencePoolUnregisterCallbackId);
  },

  computed: {
    $_isSelectSegmentsCallbackRegistered() { return (this.$data.$_registerSelectSegmentsCallbackButtonElement !== null); },
    $_hasNoEntry() { return (Object.keys(this.$_filteredNotesWithInfo).length === 0); },
    $_notesWithInfo() {
      let notesWithInfo = new Object();
      for (let [ timelineDataId, timelineData ] of Object.entries(this.timelineDataSet)) {
        if (timelineData.segmentDataType === NoteSegmentData) {
          let authorId = timelineData.authorId;
          let authorName = timelineData.authorName;
          for (let [ segmentId, segment ] of Object.entries(timelineData.segments)) {
            let timelineSegmentId = new TimelineSegmentId(timelineDataId, segmentId);
            let timelineSegmentMergedId = timelineSegmentId.mergedId;
            notesWithInfo[timelineSegmentMergedId] = new NoteWithInfo(timelineData.name, timelineSegmentId, segment, authorId, authorName);
          }
        }
      }
      return notesWithInfo;
    },
    $_filteredNotesWithInfo() {
      let filteredNotes = new Object();
      let allReferences = referencePool.getAllReferences();
      for (let [ timelineSegmentMergedId, noteWithInfo ] of Object.entries(this.$_notesWithInfo)) {
        let filter = this.$data.$_filter;
        if (filter.authorName && !noteWithInfo.authorName.includes(filter.authorName)) continue;
        if (filter.authorId && !noteWithInfo.authorId.includes(filter.authorId)) continue;
        if (filter.text && !noteWithInfo.text.includes(filter.text)) continue;
        if (Object.keys(filter.timelineSegmentIdsReferringTo).length > 0) {
          let included = false;
          for (let reference of Object.values(allReferences)) {
            for (let timelineSegmentIdReferringTo of Object.values(filter.timelineSegmentIdsReferringTo)) {
              if (
                (reference.referFrom.isSame(noteWithInfo.timelineSegmentId)) &&
                (reference.referTo.isSame(timelineSegmentIdReferringTo)) &&
                (reference.refClass === 'Note'))
              {
                included = true;
                break;
              }
            }
            if (included) break;
          }
          if (!included) continue;
        }
        if (filter.timeSince) {
          if (noteWithInfo.date < filter.timeSince) continue;
        }
        if (filter.timeUntil) {
          if (noteWithInfo.date > filter.timeUntil) continue;
        }
        filteredNotes[timelineSegmentMergedId] = noteWithInfo;
      }
      return filteredNotes;
    },

    $_noteEditorFilterButtonColor() {
      if (this.$data.$_filter.isEmpty()) {
        return 'none';
      } else {
        return 'rgba(206, 144, 144, 0.8)';
      }
    },
  },

  inject: [
    'registerSelectSegmentsCallback',
    'unregisterSelectSegmentsCallback',
    'registerReferences',
    'unregisterReferences',
    'setSegment',
    'selectTimelineSegmentAndSeek',
  ],

  methods: {
    /* private */
    $_numRows(note) { return utils.countNumLines(note); },

    $_saveNote(timelineSegmentMergedId) {
      let noteWithInfo = this.$_filteredNotesWithInfo[timelineSegmentMergedId];
      let noteTimelineSegmentId = noteWithInfo.timelineSegmentId;
      let noteTimelineDataId = noteTimelineSegmentId.timelineDataId;
      let noteSegmentId = noteTimelineSegmentId.segmentId;
      let lastSavedNoteText = this.timelineDataSet[noteTimelineDataId].segments[noteSegmentId].data.text;
      if (noteWithInfo.text !== lastSavedNoteText) {
        let noteData = new NoteSegmentData.contentData(noteWithInfo.text, new Date());
        let noteDataSegment = new NoteSegmentData(noteWithInfo.begin, noteWithInfo.end, noteData);
        this.setSegment(noteTimelineSegmentId, NoteSegmentData, noteDataSegment, 'Note set.', 'setting note');
      }
    },

    $_toggleSelectSegmentsCallbackRegistoration(referFrom, event) {
      let registerSelectSegmentsCallbackButtonElement = event.currentTarget;
      if (this.$data.$_registerSelectSegmentsCallbackButtonElement === registerSelectSegmentsCallbackButtonElement) {
        this.$_unregisterSelectSegmentsCallback();
      } else {
        if (this.$_isSelectSegmentsCallbackRegistered) {
          this.$_unregisterSelectSegmentsCallback();
        }
        this.$_registerSelectSegmentsCallback(referFrom, registerSelectSegmentsCallbackButtonElement);
      }
    },

    $_openFilterMenu() {
      this.$refs.noteEditorFilterMenu.enable();
    },

    $_unregisterSelectSegmentsCallback() {
      if (this.$data.$_registerSelectSegmentsCallbackButtonElement) {
        this.$data.$_registerSelectSegmentsCallbackButtonElement.classList.remove('attached-reference');
        this.$data.$_registerSelectSegmentsCallbackButtonElement = null;
      }
      this.unregisterSelectSegmentsCallback();
    },

    $_registerSelectSegmentsCallback(referFrom, registerSelectSegmentsCallbackButtonElement) {
      this.$data.$_registerSelectSegmentsCallbackButtonElement = registerSelectSegmentsCallbackButtonElement;
      this.$data.$_registerSelectSegmentsCallbackButtonElement.classList.add('attached-reference');
      this.registerSelectSegmentsCallback((selectedTimelineSegmentIds) => {
        for (let selectedTimelineSegmentId of selectedTimelineSegmentIds) {
          let foundReferenceId = null;
          for (let [ referenceId, reference ] of Object.entries(referencePool.getAllReferences())) {
            if (reference.referTo.isSame(selectedTimelineSegmentId) && (reference.refClass === 'Note')) {
              foundReferenceId = referenceId;
              break;
            }
          }
          if (foundReferenceId) {
            this.unregisterReferences(foundReferenceId);
          } else {
            let reference = new Reference('Note', referFrom, selectedTimelineSegmentId);
            this.registerReferences(reference);
          }
        }
      });
    },

    $_applyFilter(filter) {
      this.$data.$_filter = filter;
    },

    $_refreshTimelineSegmentIdsRefferingTo() {
      let timelineSegmentIdsReferringTo = new Object();
      for (let [ referenceId, reference ] of Object.entries(referencePool.getAllReferences())) {
        if (reference.refClass === 'Note') {
          let noteTimelineSegmentMergedId = reference.referFrom.mergedId;
          if (!Object.keys(timelineSegmentIdsReferringTo).includes(noteTimelineSegmentMergedId)) {
            timelineSegmentIdsReferringTo[noteTimelineSegmentMergedId] = new Object();
          }
          timelineSegmentIdsReferringTo[noteTimelineSegmentMergedId][referenceId] = reference.referTo;
        }
      }
      this.$data.$_timelineSegmentIdsReferringTo = timelineSegmentIdsReferringTo;
    },
  },
};
</script>

class ControlMode {
  constructor(id, tooltipLabel, iconId, alternativeTooltipLabel = null, alternativeIconId = null) {
    this.id = id;
    this.tooltipLabel = tooltipLabel;
    this.iconId = iconId;
    this.alternativeTooltipLabel = (alternativeTooltipLabel === null)? tooltipLabel : alternativeTooltipLabel;
    this.alternativeIconId = (alternativeIconId === null)? iconId : alternativeIconId;
  }
}

Object.defineProperty(
  ControlMode,
  'normal',
  {
    value: new ControlMode(
      'normal',
      'Normal Mode',
      'mdi-cursor-default',
    ),
    writable: false,
  },
);

Object.defineProperty(
  ControlMode,
  'move',
  {
    value: new ControlMode(
      'move',
      'Move View Mode',
      'mdi-cursor-move',
    ),
    writable: false,
  },
);

Object.defineProperty(
  ControlMode,
  'divide',
  {
    value: new ControlMode(
      'divide',
      'Divide Segment Mode',
      'mdi-content-cut',
    ),
    writable: false,
  },
);

Object.defineProperty(
  ControlMode,
  'remove',
  {
    value: new ControlMode(
      'remove',
      'Remove Segment Mode',
      'mdi-eraser',
    ),
    writable: false,
  },
);

Object.defineProperty(
  ControlMode,
  'zoom',
  {
    value: new ControlMode(
      'zoom',
      'Zoom-in View Mode',
      'mdi-magnify-plus',
      'Zoom-out View Mode',
      'mdi-magnify-minus',
    ),
    writable: false,
  },
);

export default ControlMode;

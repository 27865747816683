<template>
  <canvas></canvas>
</template>

<script>
import utils from '../../../modules/utils.js'
import canvasUtils from './modules/canvasUtils.js'
import timelineCanvas from './mixins/timelineCanvas.js'
import TimelineMarker from '../../../modules/TimelineMarker.js'

export default {
  mixins: [
    timelineCanvas,
  ],

  watch: {
    timelineViewBeginMsec() { this.$_setDirty(true); },
    timelineMarker() { this.$_setDirty(true); },
    timeResolution() { this.$_setDirty(true); },
  },

  props: {
    timeResolution: { type: Number },
    timelineViewBeginMsec: { type: Number },
    timelineMarker: { type: TimelineMarker },
  },

  computed: {
    $_timelineViewEndMsec()      { return this.timelineViewBeginMsec + this.$_timelineViewDurationMsec; },
    $_timelineViewDurationMsec() { return utils.resolve(this.$data.$_timelineCanvasWidthPx, this.timeResolution); },
    $_isMarkerSet()              { return (this.timelineMarker !== null) },
  },

  methods: {
    /* public */
    draw() {
      let self = this;
      let timelineForegroundCanvas = this.$data.$_timelineCanvasContext;
      this.$_draw(() => {
        if (this.$_isMarkerSet) {
          drawMarker();
        }
      });

      function drawBar(timeOffsetPx, color) {
        timelineForegroundCanvas.beginPath();
        timelineForegroundCanvas.setLineDash([]);
        timelineForegroundCanvas.lineWidth = 1;
        timelineForegroundCanvas.strokeStyle = color;
        timelineForegroundCanvas.moveTo(...canvasUtils.dotByDotOffsetCoordArgs(timeOffsetPx, 0));
        timelineForegroundCanvas.lineTo(...canvasUtils.dotByDotOffsetCoordArgs(timeOffsetPx, self.$data.$_timelineCanvasHeightPx));
        timelineForegroundCanvas.stroke();
      }

      function drawMarker() {
        {
          let markerBeginTimeMsec = self.timelineMarker.beginTimeMsec;
          let markerEndTimeMsec = self.timelineMarker.endTimeMsec;
          if ((markerBeginTimeMsec !== null) && (markerBeginTimeMsec >= self.$_timelineViewEndMsec)) return;
          if ((markerEndTimeMsec !== null) && (markerEndTimeMsec < self.timelineViewBeginMsec)) return;

          let markerAreaBeginTimeOffsetPx = null;
          if (markerBeginTimeMsec === null) {
            markerAreaBeginTimeOffsetPx = 0;
          } else {
            let markerAreaBeginTimeOffsetMsec = null;
            let isMarkerBeginInTimelineView = (markerBeginTimeMsec >= self.timelineViewBeginMsec);
            if (isMarkerBeginInTimelineView) {
              let markerBeginTimeOffsetMsec = markerBeginTimeMsec - self.timelineViewBeginMsec;
              let markerBeginTimeOffsetPx = utils.unitize(markerBeginTimeOffsetMsec, self.timeResolution);
              drawBar(markerBeginTimeOffsetPx, 'rgb(20, 116, 30)');
              markerAreaBeginTimeOffsetMsec = markerBeginTimeOffsetMsec;
            } else {
              markerAreaBeginTimeOffsetMsec = 0;
            }
            markerAreaBeginTimeOffsetPx = utils.unitize(markerAreaBeginTimeOffsetMsec, self.timeResolution);
          }

          let markerAreaEndTimeOffsetPx = null;
          if (markerEndTimeMsec === null) {
            markerAreaEndTimeOffsetPx = utils.unitize(self.$_timelineViewDurationMsec, self.timeResolution);
          } else {
            let markerAreaEndTimeOffsetMsec = null;
            let isMarkerEndInTimelineView = (markerEndTimeMsec <= self.$_timelineViewEndMsec);
            if (isMarkerEndInTimelineView) {
              let markerEndTimeOffsetMsec = markerEndTimeMsec - self.timelineViewBeginMsec;
              let markerEndTimeOffsetPx = utils.unitize(markerEndTimeOffsetMsec, self.timeResolution);
              drawBar(markerEndTimeOffsetPx, 'rgb(10, 40, 142)');
              markerAreaEndTimeOffsetMsec = markerEndTimeOffsetMsec;
            } else {
              markerAreaEndTimeOffsetMsec = self.$_timelineViewDurationMsec;
            }
            markerAreaEndTimeOffsetPx = utils.unitize(markerAreaEndTimeOffsetMsec, self.timeResolution);
          }

          let markerAreaDurationPx = markerAreaEndTimeOffsetPx - markerAreaBeginTimeOffsetPx;
          drawMarkerArea(markerAreaBeginTimeOffsetPx, markerAreaDurationPx);
        }

        function drawMarkerArea(markerAreaBeginTimeOffsetPx, markerAreaDurationPx) {
          timelineForegroundCanvas.beginPath();
          timelineForegroundCanvas.rect(...canvasUtils.dotByDotOffsetRectArgs(
            markerAreaBeginTimeOffsetPx,
            0,
            markerAreaDurationPx,
            self.$data.$_timelineCanvasHeightPx,
          ));
          timelineForegroundCanvas.fillStyle = 'rgba(0, 0, 0, 0.05)';
          timelineForegroundCanvas.fill();
        }
      }
    },
  }
};
</script>

<template>
  <v-select
    dense hide-details
    label="Time Unit"
    v-model="$_timeUnit"
    v-bind:items="$_allTimeUnitItems"
  >
  </v-select>
</template>

<script>
import AudioSegmentSequence, { AudioSegmentTimeUnit } from './modules/AudioSegmentSequence.js'

export default {
  model: {
    prop: 'timeUnit',
    event: 'update',
  },

  props: {
    timeUnit: { type: AudioSegmentTimeUnit, default: AudioSegmentSequence.TimeUnit.sample }
  },

  computed: {
    $_timeUnit: {
      get()         { return this.timeUnit },
      set(timeUnit) { this.$emit('update', timeUnit) },
    },

    $_allTimeUnitItems() {
      return Object.values(AudioSegmentSequence.TimeUnit);
    },
  },
}
</script>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-textarea", {
        attrs: {
          dense: "",
          rows: _vm.$_numRowsOfNote,
          messages: _vm.$data.$_message,
        },
        on: { blur: _vm.$_saveNote },
        model: {
          value: _vm.$data.$_note,
          callback: function ($$v) {
            _vm.$set(_vm.$data, "$_note", $$v)
          },
          expression: "$data.$_note",
        },
      }),
      _c(
        "div",
        { staticClass: "timeline-segment-editor-note-button-container" },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { outlined: "", text: "", "x-small": "" },
              on: { click: _vm.$_deleteNote },
            },
            [_c("span", [_vm._v("Delete Note")])]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _vm.$_inspectionTargetSegments.length > 0
        ? _c(
            "v-card",
            [
              _c("v-card-text", [
                _c("dl", { staticClass: "insepctor-definition-list" }, [
                  _c("dt", [_vm._v("Timeline Segment IDs")]),
                  _c(
                    "dd",
                    _vm._l(
                      _vm.selectedTimelineSegmentIds,
                      function (timelineSegmentId, timelineSegmentIdIdx) {
                        return _c(
                          "span",
                          {
                            key: timelineSegmentIdIdx,
                            staticClass: "enumeration",
                          },
                          [
                            _vm._v(
                              " " + _vm._s(timelineSegmentId.mergedId) + " "
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]),
                _c("dl", { staticClass: "insepctor-definition-list" }, [
                  _c("dt", [_vm._v("Segment Type")]),
                  _c("dd", [
                    _vm._v(
                      _vm._s(_vm.$_inspectionTargetSegmentsSingleDataType)
                    ),
                  ]),
                ]),
                _vm.$_singleInspectionTargetTimelineSegmentId
                  ? _c("div", [
                      _c("dl", { staticClass: "insepctor-definition-list" }, [
                        _c("dt", [_vm._v("Data")]),
                        _c(
                          "dd",
                          [
                            _c("dl", [
                              _c("dt", [_vm._v("begin")]),
                              _c("dd", [
                                _vm._v(_vm._s(_vm.$_segmentDataTemp.begin)),
                              ]),
                            ]),
                            _c("dl", [
                              _c("dt", [_vm._v("end")]),
                              _c("dd", [
                                _vm._v(_vm._s(_vm.$_segmentDataTemp.end)),
                              ]),
                            ]),
                            _c("InspectorDefinition", {
                              attrs: {
                                title: "data",
                                definition: _vm.$_segmentDataTemp.data,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("dl", { staticClass: "insepctor-definition-list" }, [
                        _c("dt", [_vm._v("References")]),
                        _c(
                          "dd",
                          { staticClass: "as-block" },
                          [
                            _vm.$_inspectionTargetSegments.length === 1
                              ? _c(
                                  "v-container",
                                  { attrs: { fluid: "", "pt-0": "" } },
                                  [
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      _vm._l(
                                        _vm.$_inspectionTargetTimelineSegmentIdsReferToOrFrom,
                                        function (
                                          timelineSegmentIdsReferToOrFrom,
                                          referToOrFrom
                                        ) {
                                          return _c(
                                            "v-col",
                                            {
                                              key: referToOrFrom,
                                              attrs: { col: "6" },
                                            },
                                            [
                                              _c(
                                                "v-card",
                                                { attrs: { flat: "" } },
                                                [
                                                  _c(
                                                    "v-subheader",
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$_getTitleByReferToOrFrom(
                                                              referToOrFrom
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                      _c("v-spacer"),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            outlined: "",
                                                            text: "",
                                                            "x-small": "",
                                                            fab: "",
                                                            disabled:
                                                              Object.keys(
                                                                timelineSegmentIdsReferToOrFrom
                                                              ).length === 0,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.$_emitUnregisterAllReferences(
                                                                referToOrFrom
                                                              )
                                                            },
                                                            keydown: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-delete"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            outlined: "",
                                                            text: "",
                                                            "x-small": "",
                                                            fab: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.$_toggleSelectSegmentsCallbackRegistoration(
                                                                referToOrFrom,
                                                                $event
                                                              )
                                                            },
                                                            keydown: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-playlist-plus"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._l(
                                                    timelineSegmentIdsReferToOrFrom,
                                                    function (
                                                      timelineSegmentId,
                                                      referenceId
                                                    ) {
                                                      return _c(
                                                        "v-btn",
                                                        {
                                                          key: referenceId,
                                                          attrs: {
                                                            text: "",
                                                            small: "",
                                                            tile: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.selectTimelineSegmentAndSeek(
                                                                timelineSegmentId
                                                              )
                                                            },
                                                            keydown: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                timelineSegmentId.mergedId
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "pa-10": "" } },
    [
      _c(
        "v-card",
        { staticClass: "mx-auto my-6 pa-6", attrs: { width: "600" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("header", [
                  _c("b", [_vm._v("Q.")]),
                  _vm._v(" Copy-and-paste your Worker ID."),
                ]),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-text-field", {
                    attrs: { outlined: "", flat: "", label: "Worker ID" },
                    model: {
                      value: _vm.nano.ans.workerId,
                      callback: function ($$v) {
                        _vm.$set(_vm.nano.ans, "workerId", $$v)
                      },
                      expression: "nano.ans.workerId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "d-flex", attrs: { justify: "end" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3 mb-3",
                  attrs: { disabled: _vm.nano.ans.workerId === "" },
                  on: {
                    click: function ($event) {
                      return _vm.submit()
                    },
                  },
                },
                [_vm._v("next")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
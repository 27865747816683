var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticStyle: { "max-width": "1000px" }, attrs: { "pa-10": "" } },
    [
      _c(
        "v-card",
        { staticClass: "my-10" },
        [
          _c(
            "v-system-bar",
            { attrs: { window: "", dark: "", color: "indigo" } },
            [_c("h3", [_vm._v("Your task")])]
          ),
          _c("div", { staticClass: "pa-4 text-body-1 font-weight-medium" }, [
            _vm._v(
              " Choose the one that best describes the genre of the literary text. "
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mx-auto my-6 px-6 py-2", attrs: { id: "text-card" } },
        [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c(
                "div",
                [
                  _c(
                    "v-radio-group",
                    {
                      attrs: { row: "" },
                      model: {
                        value: _vm.nano.ans.choice,
                        callback: function ($$v) {
                          _vm.$set(_vm.nano.ans, "choice", $$v)
                        },
                        expression: "nano.ans.choice",
                      },
                    },
                    _vm._l(_vm.radioItems, function (item, i) {
                      return _c("v-radio", {
                        key: `radio-item-${i}`,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "div",
                { staticClass: "my-auto" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.nano.ans.choice === null,
                        color: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submit()
                        },
                      },
                    },
                    [_vm._v("Submit")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "v-card",
        { staticClass: "mx-auto my-6 pa-6", attrs: { id: "text-card" } },
        [
          _vm.nano.props.text
            ? _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.nano.props.text.replaceAll("\n", "<br>")
                  ),
                },
              })
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
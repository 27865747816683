<template>
    <v-container pa-10>
        <v-card width="600" class="mx-auto my-6 pa-6">
            <header class="pt-4 pb-6">
                <b>Pre-survey.</b> Please answer the following questions first.
            </header>

            <header>
                <b>Q1.</b> Which is the fruit you like the most?
            </header>
            <div class="question">
                <v-select
                    hide-details
                    v-model="nano.ans.fruit"
                    :items="fruitItems"
                    label="Select one"
                ></v-select>
            </div>

            <header>
                <b>Q2.</b> Select all your hobbies that apply.
            </header>
            <div class="question">
                <v-checkbox hide-details v-model="nano.ans.hobbies" class="pt-0" value="Sport" label="Sport"/>
                <v-checkbox hide-details v-model="nano.ans.hobbies" class="pt-0" value="Bookreading" label="Bookreading"/>
                <v-checkbox hide-details v-model="nano.ans.hobbies" class="pt-0" value="Traveling" label="Traveling"/>
                <v-checkbox hide-details v-model="nano.ans.hobbies" class="pt-0" value="Music" label="Music"/>
            </div>

            <header>
                <b>Q3.</b> Select one that describes your personality the best.
            </header>
            <div class="question">
                <v-radio-group v-model="nano.ans.personality" row>
                    <v-radio label="Inspector" value="Inspector"/>
                    <v-radio label="Performer" value="Performer"/>
                    <v-radio label="Counselor" value="Counselor"/>
                    <v-radio label="Giver" value="Giver"/>
                </v-radio-group>
            </div>

            <div class="text-end">
                <v-btn class="mr-3 mb-3" :disabled="submitDisabled" @click="submit()">next</v-btn>
            </div>
        </v-card>

    </v-container>
</template>

<script>
import nanoMixIn from '@/mixins/nano';
export default {
    mixins: [nanoMixIn],
    data: () => ({
        fruitItems: ['Banana', 'Apple', 'Grapes', 'Orange', 'Watermelon'],

        defaultNanoProps: {
        },
        defaultNanoAnswers: {
            fruit: null,
            hobbies: [],
            personality: null
        }
    }),
    computed: {
        submitDisabled() {
            return this.nano.ans.fruit === null
                || (!this.nano.ans.hobbies || this.nano.ans.hobbies.length === 0)
                || this.nano.ans.personality === null;
        }
    }
};
</script>
<style scoped>
.question {
    margin-bottom: 30px;
}
</style>
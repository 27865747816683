<template>
  <v-menu
    absolute offset-y transition="false"
    v-bind:value="$data.$_menuVisibility"
    v-bind:position-x="$data.$_menuPositionX"
    v-bind:position-y="$data.$_menuPositionY"
  >
    <template v-slot:activator="{ attrs }">
      <div
        id="timeline-clickable-area"
        tabindex="1"
        v-bind:style="{ width: widthPx + 'px', height: heightPx + 'px' }"
        v-bind="attrs"
        v-on:blur="$_closeMenu"
        v-on:keydown.up.stop="$_decrementSelectedIdx"
        v-on:keydown.down.stop="$_incrementSelectedIdx"
        v-on:keydown.space.stop="$_selectMenuItem"
        v-on:keydown.enter.stop="$_selectMenuItem"
        v-on:keydown.escape.stop="$_blurMenu"
        v-on:mouseup.left="$_blurMenu"
        v-on:mouseup.right="$_openMenu"
        v-on:click.right.prevent
      />
    </template>
    <v-list dense>
      <v-list-item
        v-for="(menuContent, menuContentIdx) in menuContents"
        v-bind:key="menuContentIdx"
        v-bind:disabled="menuContent.disabled"
        v-bind:input-value="$_isSelected[menuContentIdx]"
        v-on:mousedown.left.stop="$_select(menuContent)"
        v-on:click.right.prevent
        v-on:click.stop
      >
        <v-list-item-title v-text="menuContent.name" />
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style scoped>
#timeline-clickable-area {
  outline: none;
  cursor: inherit;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
</style>

<script>
export default {
  props: {
    widthPx: { type: Number },
    heightPx: { type: Number },
    menuContents: { type: Array },
  },

  data() {
    return {
      $_menuPositionX: 0,
      $_menuPositionY: 0,
      $_menuVisibility: false,
      $_selectedIdx: 0,
    };
  },

  computed: {
    $_numMenuContents() { return this.menuContents.length; },
    $_isSelected() {
      return this.menuContents.map((menuContent, menuContentIdx) => {
        if (menuContent.disabled) {
          return null;
        } else {
          return (this.$data.$_selectedIdx === menuContentIdx)? true : null;
        }
      });
    },
  },

  methods: {
    $_incrementSelectedIdx() {
      let currentSelectedIdx = this.$data.$_selectedIdx;
      let newSelectedIdx = currentSelectedIdx;
      do {
        ++newSelectedIdx;
        if (newSelectedIdx === this.$_numMenuContents) newSelectedIdx = 0;
        if (!this.menuContents[newSelectedIdx].disabled) break;
      }
      while (newSelectedIdx !== currentSelectedIdx);
      this.$data.$_selectedIdx = newSelectedIdx;
    },

    $_decrementSelectedIdx() {
      let currentSelectedIdx = this.$data.$_selectedIdx;
      let newSelectedIdx = currentSelectedIdx;
      do {
        --newSelectedIdx;
        if (newSelectedIdx < 0) newSelectedIdx = (this.$_numMenuContents - 1);
        if (!this.menuContents[newSelectedIdx].disabled) break;
      }
      while (newSelectedIdx !== currentSelectedIdx);
      this.$data.$_selectedIdx = newSelectedIdx;
    },

    async $_selectMenuItem() {
      await this.$_select(this.menuContents[this.$data.$_selectedIdx]);
    },

    async $_select(menuContent) {
      await menuContent.callback(menuContent.name);
      this.$_blurMenu();
    },

    $_blurMenu() {
      document.activeElement.blur();
    },

    $_closeMenu() {
      this.$data.$_menuVisibility = false;
    },

    $_openMenu(event) {
      this.$data.$_selectedIdx = 0;
      this.$data.$_menuPositionX = event.clientX;
      this.$data.$_menuPositionY = event.clientY;
      this.$data.$_menuVisibility = true;
    },
  }
};
</script>
<template>
  <div id="timeline-list-container">
    <div
      id="timeline-title-and-footer-container"
      class="border-common"
      v-bind:style="$_timelineTitleAndFooterContainerStyle"
    >
      <div
        id="timeline-title-container"
        v-bind:style="$_timelineTitleContainerStyle"
      >
        <TimelineTitle
          class="timeline-title"
          v-for="(visibleTimelineDataId, visibleTimelineDataIdx) of visibleTimelineDataIdxToId"
          v-bind:key="visibleTimelineDataIdx"
          v-bind:visible-timeline-data-id="visibleTimelineDataId"
          v-bind:visible-timeline-data-idx="visibleTimelineDataIdx"
          v-bind:timeline-title="visibleTimelineDataSet[visibleTimelineDataId].name"
          v-bind:timeline-height-px="$_timelineHeightPx"
          v-bind:timeline-readonly="visibleTimelineDataSet[visibleTimelineDataId].readonly"
          v-bind:timeline-locked="visibleTimelineDataSet[visibleTimelineDataId].locked"
          v-bind:is-realtime-segment-creation-target="$data.$_isRealtimeSegmentCreationTarget[visibleTimelineDataId]"
          v-bind:menu-contents="$_getMenuContentsTimelineTitle(visibleTimelineDataIdx)"
          v-on:select-all-visible-segments="$_selectAllVisibleSegments"
          v-on:toggle-set-or-unset-realtime-segment-creation-target="$_toggleSetOrUnsetRealtimeSegmentCreationTarget"
        />
        <GenerateNewTimelineDataButton
          class="timeline-title"
          v-bind:timeline-height-px="$_timelineHeightPx"
          v-bind:menu-contents="$_menuContentsGenerateNewTimelineData"
        />
      </div>
      <TimelineTitleFooter
        v-bind:is-mouse-mode="isMouseMode"
        v-bind:is-snap-enabled="isSnapEnabled"
        v-on:toggle-mouse-mode="$_toggleMouseMode"
        v-on:toggle-snap="$_toggleSnap"
      />
    </div>
    <div
      id="timeline-separator"
      ref="timelineSeparator"
      class="border-common"
    />
    <div id="timeline-container">
      <div
        id="timeline-canvas-container"
        ref="timelineCanvasContainer"
        v-bind:class="$data.$_timelineContainerClass"
      >
        <TimelineBackgroundCanvas
          id="timeline-background-canvas"
          ref="timelineBackgroundCanvas"
          v-bind:ruler-vertical-offset-px="$_rulerVerticalOffsetPx"
          v-bind:num-visible-timelines="$_numVisibleTimelines"
          v-bind:vertical-offset-px="$data.$_verticalOffsetPx"
          v-bind:timeline-height-px="$_timelineHeightPx"
          v-bind:time-resolution="$data.$_timeResolution"
          v-bind:waveform-digest="waveformDigest"
          v-bind:timeline-view-begin-msec="$data.$_timelineViewBeginMsec"
          v-bind:is-loop-enabled="isLoopEnabled"
          v-bind:loop-definition="loopDefinition"
        />
        <TimelineSegmentCanvas
          id="timeline-segment-canvas"
          ref="timelineSegmentCanvas"
          v-bind:ruler-vertical-offset-px="$_rulerVerticalOffsetPx"
          v-bind:duration-msec="durationMsec"
          v-bind:vertical-offset-px="$data.$_verticalOffsetPx"
          v-bind:timeline-height-px="$_timelineHeightPx"
          v-bind:time-resolution="$data.$_timeResolution"
          v-bind:timeline-view-begin-msec="$data.$_timelineViewBeginMsec"
          v-bind:timeline-segment-dom-rects="$_timelineSegmentDomRects"
          v-bind:selected-timeline-segment-ids="selectedTimelineSegmentIds"
          v-bind:visible-timeline-data-set="visibleTimelineDataSet"
          v-bind:visible-timeline-data-idx-to-id="visibleTimelineDataIdxToId"
        />
        <TimelineForegroundCanvas
          id="timeline-foreground-canvas"
          ref="timelineForegroundCanvas"
          v-bind:time-resolution="$data.$_timeResolution"
          v-bind:timeline-view-begin-msec="$data.$_timelineViewBeginMsec"
          v-bind:timeline-marker="timelineMarker"
        />
        <TimelinePlayTimeBarCanvas
          id="timeline-play-time-bar-canvas"
          ref="timelinePlayTimeBarCanvas"
          v-bind:ruler-vertical-offset-px="$_rulerVerticalOffsetPx"
          v-bind:play-time-msec="playTimeMsec"
          v-bind:is-play-time-in-view="$_isPlayTimeInView"
          v-bind:follow-play-bar-flag="$data.$_followPlayBarFlag"
          v-bind:time-resolution="$data.$_timeResolution"
          v-bind:timeline-view-begin-msec="$data.$_timelineViewBeginMsec"
        />
        <TimelineOverlayCanvas
          id="timeline-overlay-canvas"
          ref="timelineOverlayCanvas"
          v-bind:timeline-canvas-local-coords-at-mousedown="$_timelineCanvasLocalCoordsAtMousedownOrMarkerBegin"
          v-bind:timeline-canvas-local-coords-at-mousemove="$_timelineCanvasLocalCoordsAtMousemoveOrMarkerEnd"
          v-bind:is-mousedown-on-ruler-area="$data.$_isMousedownOnRulerArea"
          v-bind:timeline-segment-dom-rects-to-drag-with-info="$_timelineSegmentDomRectsToDragWithInfo"
          v-bind:segment-drag-offset-time-px="$_segmentDragOffsetTimePx"
          v-bind:num-visible-timelines="$_numVisibleTimelines"
          v-bind:view-mode="$data.$_currentViewMode"
          v-bind:vertical-offset-px="$data.$_verticalOffsetPx"
          v-bind:timeline-height-px="$_timelineHeightPx"
          v-bind:minimum-segment-duration-px="$_minimumSegmentDurationPx"
          v-bind:time-resolution="$data.$_timeResolution"
          v-bind:timeline-view-begin-msec="$data.$_timelineViewBeginMsec"
        />
        <TimeScrollBarCanvas
          id="time-scroll-bar-canvas"
          ref="timeScrollBarCanvas"
          v-bind:time-scroll-bar-canvas-height-px="$data.$_timelineScrollBarWidthPx"
          v-bind:time-scroll-bar-dom-rect="$_timeScrollBarDomRect"
        />
        <TimelineListMenu
          v-bind:menu-contents="$_menuContentsTimelineList"
          v-bind:height-px="$_timelineCanvasHeightPx"
          v-bind:width-px="$_timelineCanvasWidthPx"
        />
      </div>
      <TimelineInfoBar
        v-bind:duration-msec="durationMsec"
        v-bind:play-time-msec="playTimeMsec"
        v-bind:visible-timeline-data-set="visibleTimelineDataSet"
        v-bind:selected-timeline-segment-ids="selectedTimelineSegmentIds"
        v-bind:view-mode="$data.$_currentViewMode"
        v-bind:time-resolution="$data.$_timeResolution"
        v-bind:time-resolution-min="$data.$_timeResolutionMin"
        v-bind:time-resolution-max="$_timeResolutionMax"
        v-bind:timeline-position-resolution="$data.$_timelinePositionResolution"
        v-bind:timeline-position-resolution-min="$_timelinePositionResolutionMin"
        v-bind:timeline-position-resolution-max="$_timelinePositionResolutionMax"
      />
    </div>
  </div>
</template>

<style scoped>
#timeline-list-container {
  flex-grow: 1;
  display: flex;
  overflow: hidden;
  height: 100%;
}

#timeline-title-and-footer-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  position: relative;
  height: 100%;
}

#timeline-title-container {
  position: absolute;
  width: 100%;
}

#timeline-separator {
  border-right-width: 5px;
  border-right-style: solid;
  width: 5px;
  height: 100%;
  cursor: ew-resize;
  user-select: none;
}

.timeline-title {
  position: relative;
}

#timeline-container {
  display: flex;
  flex-direction: column;
  flex: auto;
  background-color: #f1f1f1;
  overflow-x: hidden;
}

#timeline-canvas-container {
  position: relative;
  flex: auto;
  background-color: #f1f1f1;
}

#timeline-background-canvas,
#timeline-segment-canvas,
#timeline-foreground-canvas,
#timeline-play-time-bar-canvas,
#timeline-overlay-canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

#time-scroll-bar-canvas {
  outline: none;
  position: absolute;
  width: 100%;
  height: 10px;
  bottom: 0;
  left: 0;
}

.timeline-canvas-view-mode-normal.mouse-cursor-on-timeline-segment,
.timeline-canvas-view-mode-select.mouse-cursor-on-timeline-segment,
.timeline-canvas-view-mode-divide.mouse-cursor-on-timeline-segment,
.timeline-canvas-view-mode-merge.mouse-cursor-on-timeline-segment,
.timeline-canvas-view-mode-position.mouse-cursor-on-timeline-segment {
  cursor: pointer;
}

.timeline-canvas-view-mode-select.mouse-cursor-on-timeline-selected-segment,
.timeline-canvas-view-mode-position.mouse-cursor-on-timeline-selected-segment {
  cursor: move;
}

.timeline-canvas-view-mode-select.mouse-cursor-on-timeline-selected-segment-edge,
.timeline-canvas-view-mode-position.mouse-cursor-on-timeline-selected-segment-edge {
  cursor: ew-resize;
}

.timeline-canvas-view-mode-create.mouse-cursor-on-timeline {
  cursor: crosshair;
}

.timeline-canvas-view-mode-divide.mouse-cursor-on-timeline-selected-segment,
.timeline-canvas-view-mode-divide.mouse-cursor-on-timeline-selected-segment-edge {
  cursor: text;
}

.mouse-cursor-on-scroll-bar-area,
.mouse-cursor-on-ruler-area {
  cursor: default !important;
}
</style>

<style src="../styles/common.css" scoped />

<script>
import TimelineInfoBar from './TimelineListView/TimelineInfoBar.vue';
import TimelineTitle from './TimelineListView/TimelineTitle.vue';
import TimelineTitleFooter from './TimelineListView/TimelineTitleFooter.vue';
import GenerateNewTimelineDataButton from './TimelineListView/GenerateNewTimelineDataButton.vue';
import TimelineListMenu from './TimelineListView/TimelineListMenu.vue';
import TimelineBackgroundCanvas from './TimelineListView/canvases/TimelineBackgroundCanvas.vue'
import TimelineSegmentCanvas from './TimelineListView/canvases/TimelineSegmentCanvas.vue'
import TimelineForegroundCanvas from './TimelineListView/canvases/TimelineForegroundCanvas.vue'
import TimelinePlayTimeBarCanvas from './TimelineListView/canvases/TimelinePlayTimeBarCanvas.vue'
import TimelineOverlayCanvas from './TimelineListView/canvases/TimelineOverlayCanvas.vue'
import TimelineSegmentDomRectsToDragWithInfo from './TimelineListView/canvases/modules/TimelineSegmentDomRectsToDragWithInfo.js'
import TimeScrollBarCanvas from './TimelineListView/canvases/TimeScrollBarCanvas.vue'
import { TimelineDataSet, TimelineSegmentId } from '../../external/modules/ScattData.js'
import viewMode from './TimelineListView/modules/viewMode.js'
import utils from '../modules/utils.js'
import logger from '../modules/logger.js'
import TimelineCanvasLocalCoords from './TimelineListView/canvases/modules/TimelineCanvasLocalCoords.js'
import TimeMsecAndTimelinePosition from './TimelineListView/canvases/modules/TimeMsecAndTimelinePosition.js'
import TimelineMarker from '../modules/TimelineMarker.js';
import LoopDefinition from '../modules/LoopDefinition.js';
import WaveformDigest from '../modules/WaveformDigest.js';

const materialDesignFont = new FontFace('material-design-icons', 'url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2)');

const timeStepPxOnScroll = 60;
const timelineViewPreBeginMarginPxMax = 20;
const timelineViewTimeOriginMsec = 0;
const timeResolutionMax = 4;
const timelinePositionResolutionMin = 0.5;
const timelinePositionResolutionMax = 3;
const verticalStepPxOnScroll = 30;
const timelineSegmentVerticalMarginPx = 2;
const unitTimelineHeightPx = 60;
const timeScrollBarDurationPxMin = 20;
const timelineTitleWidthPxMin = 100;
const timelineTitleWidthRightMarginPxMin = 100;
const doubleClickIntervalOnTimelineSegmentMsecMax = 300;

const rulerVerticalOffsetPx = 16;
const timelineViewEdgeWidthPx = 20;
const timelineSegmentEdgeWidth = 5;
const snapWidthPx = 10;

const seekMarginTimeMsecOnEndOfSegmentPositionChanged = 500;

class TimelineListViewMenuContent {
  constructor(name, callback, disabled) {
    this.name = name;
    this.callback = callback;
    this.disabled = disabled;
  }
}

class EdgeInfo {
  constructor(coords, timelineCanvasContainerWidthPx, timelineCanvasContainerHeightPx) {
    this.isOnLeftEdge = (coords.x < timelineViewEdgeWidthPx);
    this.isOnRightEdge = ((timelineCanvasContainerWidthPx - coords.x) < timelineViewEdgeWidthPx);
    this.isOnTopEdge = (coords.y < timelineViewEdgeWidthPx);
    this.isOnBottomEdge = ((timelineCanvasContainerHeightPx - coords.y) < timelineViewEdgeWidthPx);
  }
}

class TimelineSegmentIdWithInfo {
  constructor(timelineSegmentId, timelineSegmentDomRect, timelineCanvasLocalCoords) {
    this.timelineSegmentId = timelineSegmentId;
    this.timelineSegmentDomRect = timelineSegmentDomRect;
    let isOnLeftEdge = false;
    let isOnRightEdge = false;
    let offsetPxByLeftEdge = timelineCanvasLocalCoords.x - timelineSegmentDomRect.x;
    let offsetPxByRightEdge = timelineCanvasLocalCoords.x - (timelineSegmentDomRect.x + timelineSegmentDomRect.width);
    if (timelineSegmentDomRect.width >= (2 * timelineSegmentEdgeWidth)) {
      let leftEdgeDomRect = new DOMRect(
        timelineSegmentDomRect.x,
        timelineSegmentDomRect.y,
        timelineSegmentEdgeWidth,
        timelineSegmentDomRect.height,
      );
      isOnLeftEdge = utils.isInRect(leftEdgeDomRect, timelineCanvasLocalCoords.x, timelineCanvasLocalCoords.y);
      let rightEdgeDomRect = new DOMRect(
        timelineSegmentDomRect.x + timelineSegmentDomRect.width - timelineSegmentEdgeWidth,
        timelineSegmentDomRect.y,
        timelineSegmentEdgeWidth,
        timelineSegmentDomRect.height,
      );
      isOnRightEdge = utils.isInRect(rightEdgeDomRect, timelineCanvasLocalCoords.x, timelineCanvasLocalCoords.y);
    }
    this.isOnLeftEdge = isOnLeftEdge;
    this.isOnRightEdge = isOnRightEdge;
    this.offsetPxByLeftEdge = offsetPxByLeftEdge;
    this.offsetPxByRightEdge = offsetPxByRightEdge;
  }
}

export default {
  components: {
    TimelineInfoBar,
    TimelineTitle,
    TimelineTitleFooter,
    GenerateNewTimelineDataButton,
    TimelineBackgroundCanvas,
    TimelineForegroundCanvas,
    TimelineSegmentCanvas,
    TimelinePlayTimeBarCanvas,
    TimelineOverlayCanvas,
    TimeScrollBarCanvas,
    TimelineListMenu,
  },

  watch: {
    visibleTimelineDataSet: {
      handler(newVisibleTimelineDataSet) {
        let newIsRealtimeSegmentCreationTarget = new Object();
        let previousTimelineDataIds = Object.keys(this.$data.$_isRealtimeSegmentCreationTarget);
        for (let [ newTimelineDataId, timelineData ] of Object.entries(newVisibleTimelineDataSet)) {
          let isNewTimelineDataId = !previousTimelineDataIds.includes(newTimelineDataId);
          if (timelineData.locked || isNewTimelineDataId) {
            newIsRealtimeSegmentCreationTarget[newTimelineDataId] = false;
          } else {
            newIsRealtimeSegmentCreationTarget[newTimelineDataId] = this.$data.$_isRealtimeSegmentCreationTarget[newTimelineDataId];
          }
        }
        this.$data.$_isRealtimeSegmentCreationTarget = newIsRealtimeSegmentCreationTarget;
      },
      deep: true,
      immediate: true,
    },
    durationMsec() {
      this.$_initializeView();
    },
    playTimeMsec(newPlayTimeMsec) {
      if (!this.$_isPlayTimeInView) {
        if (this.$data.$_followPlayBarFlag) {
          this.$_setTimelineViewBeginMsec(this.$_clampTimelineViewBeginMsec(newPlayTimeMsec));
        }
      }
      if (this.$data.$_isRealtimeSegmentOpen) {
        if (this.$_isMarkerSet) {
          if (this.timelineMarker.beginTimeMsec < newPlayTimeMsec) {
            this.setMarker(this.timelineMarker.beginTimeMsec, newPlayTimeMsec);
          } else {
            this.setMarker(newPlayTimeMsec, newPlayTimeMsec);
          }
        } else {
          this.$data.$_isRealtimeSegmentOpen = false;
        }
      }
    },
    $_isDraggingSegments() {
      this.$_updateTimelineContainerClass();
    },
    '$data.$_currentViewMode'(newCurrentViewMode) {
      switch(newCurrentViewMode) {
      case viewMode.normal:
        this.clearSelectedTimelineSegments();
        break;
      case viewMode.position:
        this.invokeActionOnSelectedTimelineSegments('setLoop');
        this.invokeActionOnSelectedTimelineSegments('setMarker');
        break;
      }
      this.$_updateTimelineContainerClass();
    },
    '$data.$_isMouseCursorOnScrollBarArea'() {
      this.$_updateTimelineContainerClass();
    },
    '$data.$_isMouseCursorOnRulerArea'() {
      this.$_updateTimelineContainerClass();
    },
    '$data.$_isMouseCursorOnVisibleTimeline'() {
      this.$_updateTimelineContainerClass();
    },
    '$data.$_timelineSegmentIdWithInfoAtMouseCursor'() {
      this.$_updateTimelineContainerClass();
    },
    '$data.$_timeMsecAndTimelinePositionAtMousemove'() {
      this.$_updateTimelineContainerClass();
    },
    $_isAnyTimelineRealtimeSegmentCreationTarget(newIsAnyTimelineRealtimeSegmentCreationTarget) {
      if (!newIsAnyTimelineRealtimeSegmentCreationTarget) {
        this.$data.$_isRealtimeSegmentOpen = false;
      }
    },
    isVideoPlaying(newIsVideoPlaying) {
      if (!newIsVideoPlaying && this.$data.$_isRealtimeSegmentOpen) {
        this.$_recordRealtimeSegmentEnd();
      }
    },
    selectedTimelineSegmentIds() {
      switch(this.$data.$_currentViewMode) {
      case viewMode.position:
        this.invokeActionOnSelectedTimelineSegments('setLoop');
        this.invokeActionOnSelectedTimelineSegments('setMarker');
        break;
      }
    },
  },

  props: {
    durationMsec: { type: Number },
    playTimeMsec: { type: Number },
    isVideoPlaying: { type: Boolean },
    visibleTimelineDataSet: { type: TimelineDataSet },
    visibleTimelineDataIdxToId: { type: Array },
    sortedVisibleSegmentIdxToIdMaps: { type: Object },
    visibleSegmentIdToSortedIdxMaps: { type: Object },
    waveformDigest: { type: WaveformDigest },
    minimumSegmentDurationMsec: { type: Number },
    selectedTimelineSegmentIds: { type: Array },
    isAnySelectedTimelineSegmentImmutable: { type: Boolean },
    isLoopEnabled: { type: Boolean },
    isMouseMode: { type: Boolean },
    isSnapEnabled: { type: Boolean },
    loopDefinition: { type: LoopDefinition },
    timelineMarker: { type: TimelineMarker },
    segmentDataTypes: { type: Array },
    timelineTitleWidthPx: { type: Number }
  },

  data() {
    return {
      $_requestAnimationFrameCallbackId: null,

      $_timelinePositionResolution: timelinePositionResolutionMin,
      $_timelineViewBeginMsec: 0,
      $_timeResolution: 1,
      $_timeResolutionMin: 1,
      $_verticalOffsetPx: rulerVerticalOffsetPx,
      $_timelineCanvasContainerResizeObserver: null,
      $_timelineListContainerWidthPx: null,
      $_timelineCanvasContainerBoundingClientRect: null,
      $_timelineScrollBarWidthPx: null,
      $_clientXAtMousedownOnSeparator: null,
      $_timelineTitleWidthPxAtMousedownOnSeparator: null,
      $_timeMsecAndTimelinePositionAtMousedown: null,
      $_timeScrollBarOffsetPxAtMousedown: null,
      $_timelineViewCenterMsecAtMousedown: null,
      $_timeMsecAndTimelinePositionAtMousemove: null,
      $_timelineSegmentIdWithInfoAtMousedown: null,
      $_edgeInfoAtMousedown: null,
      $_isMousedownOnRulerArea: false,
      $_isSubMouseButtonPressedAtMousedown: false,

      $_segmentDragOffsetTimeMsec: 0,

      $_timelineSegmentIdAtOpeningContextMenu: null,
      $_isMousedownOnRulerAreaAtOpeningContextMenu: false,

      $_timelineSegmentIdWithInfoAtMouseCursor: null,
      $_isMouseCursorOnScrollBarArea: null,
      $_isMouseCursorOnRulerArea: null,
      $_isMouseCursorOnVisibleTimeline: null,

      $_timelineContainerClass: null,
      $_timelineSegmentNearestBaseTimeMsec: 0,
      $_followPlayBarFlag: true,
      $_currentViewMode: null,
      $_isSnapEnabled: true,

      $_timelineSegmentIdAtLastClick: null,
      $_doubleClickOnTimelineSegmentTimeout: null,

      $_scrollPending: false,
      $_autoScrollXInterval: null,
      $_autoScrollYInterval: null,

      $_isRealtimeSegmentOpen: false,
      $_isRealtimeSegmentCreationTarget: new Object(),
    }
  },

  computed: {
    $_timeResolutionMax() { return timeResolutionMax },
    $_timelinePositionResolutionMin() { return timelinePositionResolutionMin },
    $_timelinePositionResolutionMax() { return timelinePositionResolutionMax },
    $_rulerVerticalOffsetPx()    { return rulerVerticalOffsetPx; },
    $_minimumSegmentDurationPx() { return this.$_scaledTimeMsecToPx(this.minimumSegmentDurationMsec); },
    $_segmentDragOffsetTimePx()  { return this.$_scaledTimeMsecToPx(this.$data.$_segmentDragOffsetTimeMsec); },
    $_timelineTitleWidthPx: {
      get()                        { return this.timelineTitleWidthPx },
      set(newTimelineTitleWidthPx) { this.$emit('update-timeline-title-width-px', newTimelineTitleWidthPx) },
    },
    $_timelineTitleWidthMax()    { return this.$data.$_timelineListContainerWidthPx - timelineTitleWidthRightMarginPxMin; },
    $_timelineContainerLocalOffsetX() {
      if (this.$data.$_timelineCanvasContainerBoundingClientRect === null) return 0;
      return -this.$data.$_timelineCanvasContainerBoundingClientRect.left;
    },
    $_timelineContainerLocalOffsetY() {
      if (this.$data.$_timelineCanvasContainerBoundingClientRect === null) return 0;
      return -this.$data.$_timelineCanvasContainerBoundingClientRect.top;
    },
    $_timelineCanvasContainerHeightPx() {
      if (this.$data.$_timelineCanvasContainerBoundingClientRect === null) return 0;
      return this.$data.$_timelineCanvasContainerBoundingClientRect.height;
    },
    $_timelineCanvasWidthPx() {
      if (this.$data.$_timelineCanvasContainerBoundingClientRect === null) return 0;
      return this.$data.$_timelineCanvasContainerBoundingClientRect.width;
    },
    $_timelineCanvasHeightPx() {
      if (this.$data.$_timelineScrollBarWidthPx === null) return this.$_timelineCanvasContainerHeightPx;
      return this.$_timelineCanvasContainerHeightPx - this.$data.$_timelineScrollBarWidthPx;
    },
    $_timelineViewEndMsec()      { return this.$data.$_timelineViewBeginMsec + this.$_timelineViewDurationMsec; },
    $_timelineViewDurationMsec() { return this.$_pxToScaledTimeMsec(this.$_timelineCanvasWidthPx); },
    $_timelineHeightPx()         { return Math.round(unitTimelineHeightPx * this.$data.$_timelinePositionResolution); },
    $_isMarkerSet()              { return (this.timelineMarker !== null) },
    $_isPlayTimeInView() {
      if (this.playTimeMsec <= this.$data.$_timelineViewBeginMsec) {
        return false;
      }
      let timelineViewEndMsec = this.$data.$_timelineViewBeginMsec + this.$_timelineViewDurationMsec;
      if (this.playTimeMsec > timelineViewEndMsec) {
        return false;
      }
      return true;
    },

    $_isAnyTimelineRealtimeSegmentCreationTarget() {
      return Object.values(this.$data.$_isRealtimeSegmentCreationTarget).includes(true);
    },

    $_realtimeSegmentCreationTargetTimelineDataIds() {
      return Object.keys(this.$data.$_isRealtimeSegmentCreationTarget).filter(
        timelineDataId => this.$data.$_isRealtimeSegmentCreationTarget[timelineDataId]
      );
    },

    $_timelineTitleAndFooterContainerStyle() {
      return {
        minWidth: this.$_timelineTitleWidthPx + 'px',
        maxWidth: this.$_timelineTitleWidthPx + 'px',
      };
    },

    $_timelineTitleContainerStyle() {
      return {
        top: this.$data.$_verticalOffsetPx + 'px',
      };
    },

    $_isMouseEventStarted() {
      return (this.$data.$_timeScrollBarOffsetPxAtMousedown || this.$data.$_timeMsecAndTimelinePositionAtMousedown || this.$data.$_clientXAtMousedownOnSeparator);
    },

    $_verticalOffsetPxMin() {
      if (this.$_numVisibleTimelines === 0) {
        return rulerVerticalOffsetPx;
      } else {
        return -this.$_timelineHeightPx * (this.$_numVisibleTimelines - 1) + rulerVerticalOffsetPx;
      }
    },

    $_timelineViewBeginMsecMin() {
      let timelineViewDurationMsec = utils.resolve(this.$_timelineCanvasWidthPx, this.$data.$_timeResolution);
      return -timelineViewPreBeginMarginPxMax * timelineViewDurationMsec / this.$_timelineCanvasWidthPx;
    },

    $_timelineCanvasLocalCoordsAtMousedown() {
      if (!this.$data.$_timeMsecAndTimelinePositionAtMousedown) return null;
      let timelineCanvasLocalX = null;
      if (this.$_isOnSegmentAtMousedown) {
        timelineCanvasLocalX = this.$_timeMsecToTimelineContainerLocalX(this.$data.$_timeMsecAndTimelinePositionAtMousedown.timeMsec);
      } else {
        timelineCanvasLocalX = this.$_timeMsecToTimelineContainerLocalX(this.$data.$_timeMsecAndTimelinePositionAtMousedown.snappedTimeMsec);
      }
      let timelineCanvasLocalY = this.$_timelinePositionToTimelineContainerLocalY(this.$data.$_timeMsecAndTimelinePositionAtMousedown.timelinePosition);
      return new TimelineCanvasLocalCoords(timelineCanvasLocalX, timelineCanvasLocalY);
    },

    $_timelineCanvasLocalCoordsAtMousemove() {
      if (!this.$data.$_timeMsecAndTimelinePositionAtMousemove) return null;
      let timelineCanvasLocalX = null;
      if (this.$_isOnSegmentAtMousedown) {
        timelineCanvasLocalX = this.$_timeMsecToTimelineContainerLocalX(this.$data.$_timeMsecAndTimelinePositionAtMousemove.timeMsec);
      } else {
        timelineCanvasLocalX = this.$_timeMsecToTimelineContainerLocalX(this.$data.$_timeMsecAndTimelinePositionAtMousemove.snappedTimeMsec);
      }
      let timelineCanvasLocalY = this.$_timelinePositionToTimelineContainerLocalY(this.$data.$_timeMsecAndTimelinePositionAtMousemove.timelinePosition);
      return new TimelineCanvasLocalCoords(timelineCanvasLocalX, timelineCanvasLocalY);
    },

    $_timelineCanvasLocalCoordsAtMousedownOrMarkerBegin() {
      if (!this.$_timelineCanvasLocalCoordsAtMousedown) return null;
      let timelineCanvasLocalX = this.$_getTimelineCanvasLocalXOrMarkerBeginLocalX(this.$data.$_timeMsecAndTimelinePositionAtMousedown);
      return new TimelineCanvasLocalCoords(timelineCanvasLocalX, this.$_timelineCanvasLocalCoordsAtMousedown.y);
    },

    $_timelineCanvasLocalCoordsAtMousemoveOrMarkerEnd() {
      if (!this.$_timelineCanvasLocalCoordsAtMousemove) return null;
      let timelineCanvasLocalX = this.$_getTimelineCanvasLocalXOrMarkerEndLocalX(this.$data.$_timeMsecAndTimelinePositionAtMousemove);
      return new TimelineCanvasLocalCoords(timelineCanvasLocalX, this.$_timelineCanvasLocalCoordsAtMousemove.y);
    },

    $_isOnSegmentAtMousedown() {
      return (this.$data.$_timelineSegmentIdWithInfoAtMousedown !== null);
    },

    $_isDraggingSegments() {
      if (!this.$_isOnSegmentAtMousedown) return false;
      switch(this.$data.$_currentViewMode) {
      case viewMode.select:
      case viewMode.position:
        break;
      default:
        return false;
      }
      if (!this.$data.$_timeMsecAndTimelinePositionAtMousedown) return false;
      if (!this.$data.$_timeMsecAndTimelinePositionAtMousemove) return false;
      if (this.$data.$_isSubMouseButtonPressedAtMousedown) return false;
      return !this.$data.$_timeMsecAndTimelinePositionAtMousedown.isSame(this.$data.$_timeMsecAndTimelinePositionAtMousemove);
    },

    $_timelineSegmentDomRectsToDragWithInfo() {
      if (!this.$_isDraggingSegments) return null;
      let timelineSegmentIdAtMousedown = this.$data.$_timelineSegmentIdWithInfoAtMousedown.timelineSegmentId;
      if (this.$_isTimelineSegmentIdSelected(timelineSegmentIdAtMousedown)) {
        let selectedTimelineSegmentDomRects = this.selectedTimelineSegmentIds.map(timelineSegmentId => {
          let timelineDataId = timelineSegmentId.timelineDataId;
          let segmentId = timelineSegmentId.segmentId;
          return this.$_timelineSegmentDomRects[timelineDataId][segmentId];
        });
        let endTypes = this.selectedTimelineSegmentIds.map(timelineSegmentId => {
          let timelineDataId = timelineSegmentId.timelineDataId;
          let segmentId = timelineSegmentId.segmentId;
          let segment = this.visibleTimelineDataSet[timelineDataId].segments[segmentId];
          if (segment.begin === null) {
            if (segment.end === null) {
              return TimelineSegmentDomRectsToDragWithInfo.endType.bothEndInfinite;
            } else {
              return TimelineSegmentDomRectsToDragWithInfo.endType.leftEndInfinite;
            }
          } else {
            if (segment.end === null) {
              return TimelineSegmentDomRectsToDragWithInfo.endType.rightEndInfinite;
            } else {
              return TimelineSegmentDomRectsToDragWithInfo.endType.bothEndFinite;
            }
          }
        });
        let edgeType = null;
        if (this.$data.$_timelineSegmentIdWithInfoAtMousedown.isOnLeftEdge) {
          edgeType = TimelineSegmentDomRectsToDragWithInfo.edgeType.left;
        } else if (this.$data.$_timelineSegmentIdWithInfoAtMousedown.isOnRightEdge) {
          edgeType = TimelineSegmentDomRectsToDragWithInfo.edgeType.right;
        } else {
          edgeType = TimelineSegmentDomRectsToDragWithInfo.edgeType.none;
        }
        return new TimelineSegmentDomRectsToDragWithInfo(selectedTimelineSegmentDomRects, endTypes, edgeType);
      }
      return null;
    },

    $_menuContentsGenerateNewTimelineData() {
      return [
        ...this.segmentDataTypes.map(
          segmentDataType => {
            let menuContentName = 'Generate New ' + segmentDataType.name + ' Timeline';
            let defaultTimelineDataName = 'New ' + segmentDataType.name + ' Timeline';
            return new TimelineListViewMenuContent(
              menuContentName,
              async () => { await this.generateNewTimelineData(segmentDataType, defaultTimelineDataName, false) },
              false
            )
          },
        ),
        new TimelineListViewMenuContent(
          'Generate New Timeline From Existing Timelines',
          () => { this.generateNewTimelineDataFromExistingTimelines(false) },
          false
        ),
      ];
    },

    $_menuContentsTimelineList() {
      if (this.$data.$_timelineSegmentIdAtOpeningContextMenu) {
        if (this.$_isTimelineSegmentIdSelected(this.$data.$_timelineSegmentIdAtOpeningContextMenu)) {
          return [
            new TimelineListViewMenuContent(
              'Edit segment',
              async () => { await this.invokeActionOnSelectedTimelineSegments('editSegment') },
              (this.$_getOnlyOneSelectedTimelineSegmentId() === null),
            ),
            new TimelineListViewMenuContent(
              'Copy segment data',
              async () => { await this.invokeActionOnSelectedTimelineSegments('setCopySource') },
              (this.$_getOnlyOneSelectedTimelineSegmentId() === null),
            ),
            new TimelineListViewMenuContent(
              'Paste segment data',
              this.copySegmentsFromCopySource,
              false,
            ),
            new TimelineListViewMenuContent(
              'Lock segment',
              async () => { await this.invokeActionOnSelectedTimelineSegments('lock') },
              false,
            ),
            new TimelineListViewMenuContent(
              'Unlock segment',
              async () => { await this.invokeActionOnSelectedTimelineSegments('unlock') },
              false,
            ),
            new TimelineListViewMenuContent(
              'Set loop',
              async () => { await this.invokeActionOnSelectedTimelineSegments('setLoop') },
              false,
            ),
            new TimelineListViewMenuContent(
              'Set marker',
              async () => { await this.invokeActionOnSelectedTimelineSegments('setMarker') },
              false,
            ),
          ];
        }
      }
      if (this.$data.$_isMousedownOnRulerAreaAtOpeningContextMenu) {
        return [
          new TimelineListViewMenuContent(
            (this.isLoopEnabled)? 'Disable loop' : 'Enable loop',
            () => { (this.isLoopEnabled)? this.disableLoopFlag() : this.enableLoopFlag() },
            false,
          ),
          new TimelineListViewMenuContent(
            'Clear marker',
            this.clearMarker,
            !this.$_isMarkerSet,
          ),
          new TimelineListViewMenuContent(
            'Set loop by marker',
            this.setLoopByMarker,
            !this.$_isMarkerSet,
          ),
        ];
      }
      return Object.values(viewMode).map(viewModeTitle => new TimelineListViewMenuContent(viewModeTitle, this.$_setViewMode, false));
    },

    $_timeScrollBarTimeResolution() {
      let timelineScrollAreaWidthPxExcludingScrollBar = this.$_timelineCanvasWidthPx - this.$_timeScrollBarDurationPx;
      let timelineScrollAreaDurationMsecExcludingScrollBar = this.durationMsec - this.$_timelineViewBeginMsecMin - this.$_timelineViewDurationMsec;
      if (timelineScrollAreaDurationMsecExcludingScrollBar === 0) return null;
      return timelineScrollAreaWidthPxExcludingScrollBar / timelineScrollAreaDurationMsecExcludingScrollBar;
    },

    $_visibleTimelineDataIdAtMousedown() {
      if (!this.$_timelineCanvasLocalCoordsAtMousedown) return null;
      return this.$_getVisibleTimelineDataIdAtTimelineCanvasLocalY(this.$_timelineCanvasLocalCoordsAtMousedown.y);
    },

    $_numVisibleTimelines() {
      return Object.keys(this.visibleTimelineDataSet).length;
    },

    $_timelineSegmentDomRects() {
      let self = this;
      {
        let timelineSegmentDomRects = new Object();
        let timelineDataIdArray = this.visibleTimelineDataIdxToId;
        let timelineDataArray = timelineDataIdArray.map(timelineDataId => this.visibleTimelineDataSet[timelineDataId]);
        let numTimelineData = timelineDataIdArray.length;
        for(let timelineDataIdx = 0; timelineDataIdx < numTimelineData; timelineDataIdx++) {
          let timelineDataId = timelineDataIdArray[timelineDataIdx];
          let timelineData = timelineDataArray[timelineDataIdx];
          timelineSegmentDomRects[timelineDataId] = new Object();
          for (let [ segmentId, segment ] of Object.entries(timelineData.segments)) {
            let beginTimeMsec = (segment.begin === null)? this.$data.$_timelineViewBeginMsec : segment.begin;
            let endTimeMsec = (segment.end === null)? this.$_timelineViewEndMsec : segment.end;
            timelineSegmentDomRects[timelineDataId][segmentId] = generateSegmentDomRect(timelineDataIdx, beginTimeMsec, endTimeMsec);
          }
        }
        return timelineSegmentDomRects;
      }

      function generateSegmentDomRect(timelineDataIdx, beginTimeMsec, endTimeMsec) {
        let timelineTopOffsetPx = self.$_timelineHeightPx * timelineDataIdx + self.$data.$_verticalOffsetPx + timelineSegmentVerticalMarginPx;
        let beginTimelineViewOffsetMsec = beginTimeMsec - self.$data.$_timelineViewBeginMsec;
        let scaledBeginTimelineViewOffsetPx = self.$_scaledTimeMsecToPx(beginTimelineViewOffsetMsec);
        let durationMsec = endTimeMsec - beginTimeMsec;
        let scaledDurationPx = self.$_scaledTimeMsecToPx(durationMsec);
        let timelineSegmentHeightPx = self.$_timelineHeightPx - timelineSegmentVerticalMarginPx * 2;
        return new DOMRect(
          scaledBeginTimelineViewOffsetPx,
          timelineTopOffsetPx,
          scaledDurationPx,
          timelineSegmentHeightPx,
        );
      }
    },

    $_timelineCanvasContainerDomRect() {
      return new DOMRect(0, 0, this.$_timelineCanvasWidthPx, this.$_timelineCanvasContainerHeightPx);
    },

    $_timelineCanvasInnerDomRect() {
      return new DOMRect(0, rulerVerticalOffsetPx, this.$_timelineCanvasWidthPx, this.$_timelineCanvasHeightPx - rulerVerticalOffsetPx);
    },

    $_timelineViewDurationMsecMax() {
      return this.durationMsec - this.$_timelineViewBeginMsecMin;
    },

    $_timeScrollBarDurationPx() {
      let timeScrollBarDurationPx = this.$_timelineCanvasWidthPx * this.$_timelineViewDurationMsec / this.$_timelineViewDurationMsecMax;
      return (timeScrollBarDurationPx > timeScrollBarDurationPxMin)? timeScrollBarDurationPx : timeScrollBarDurationPxMin;
    },

    $_timeScrollBarDomRect() {
      let timelineViewBeginOffsetMsecFromMin = this.$data.$_timelineViewBeginMsec - this.$_timelineViewBeginMsecMin;
      let timeScrollBarOffsetPx = (this.$_timeScrollBarTimeResolution)? utils.unitize(timelineViewBeginOffsetMsecFromMin, this.$_timeScrollBarTimeResolution) : 0;
      return new DOMRect(
        timeScrollBarOffsetPx,
        0,
        this.$_timeScrollBarDurationPx,
        this.$data.$_timelineScrollBarWidthPx,
      );
    },
  },

  created() {
    document.fonts.add(materialDesignFont);
    this.$_setViewMode(viewMode.normal);
    this.$data.$_timelineCanvasContainerResizeObserver = new ResizeObserver(this.$_updateViewSize);
  },

  mounted() {
    this.$data.$_timelineCanvasContainerResizeObserver.observe(this.$refs.timelineCanvasContainer);
    this.$data.$_requestAnimationFrameCallbackId = window.requestAnimationFrame(this.$_draw);
    let intervalId = window.setInterval(
      () => {
        if (this.$el.clientWidth > 0) {
          this.$_initializeView();
          window.clearInterval(intervalId);
        }
      },
      100,
    )
  },

  destroyed() {
    this.$data.$_timelineCanvasContainerResizeObserver.disconnect();
    window.cancelAnimationFrame(this.$data.$_requestAnimationFrameCallbackId);
    document.fonts.delete(materialDesignFont);
  },

  inject: [
    'removeTimelineData',
    'renameTimelineData',
    'generateNewTimelineData',
    'generateNewTimelineDataFromExistingTimelines',
    'duplicateTimelineData',
    'hideTimelineData',
    'getTimelineDataImmutableReason',
    'incrementVisibleTimelineDataIdx',
    'seekInMsec',
    'setMarker',
    'clearMarker',
    'setLoopFullDuration',
    'setLoopByMarker',
    'enableLoopFlag',
    'disableLoopFlag',
    'createSegment',
    'divideSegment',
    'generateEmptySegment',
    'invokeActionOnSelectedTimelineSegments',
    'selectTimelineSegments',
    'clearSelectedTimelineSegments',
    'copySegmentsFromCopySource',
    'atomicChanges',
    'setNewSegment',
    'setSegment',
    'registerFooterMessage',
  ],

  provide() {
    return {
      updateTimeResolution: (timeResolution) => {
        this.$_updateTimelineViewBeginMsecAndTimeResolution(0, timeResolution);
      },
      updateTimelinePositionResolution: (timelinePositionResolution) => {
        this.$_updateVerticalOffsetPxAndTimelinePositionResolution(rulerVerticalOffsetPx, timelinePositionResolution);
      },
    };
  },

  methods: {
    /* public */
    selectTimelineSegment(timelineSegmentId) {
      if (this.$_isNotDisplayed()) return;
      if (this.$_isTimelineSegmentVisible(timelineSegmentId)) {
        this.$_selectTimelineSegmentCore(timelineSegmentId);
      }
    },

    onMousedown(event) {
      if (this.$_isNotDisplayed()) return;
      this.$_updateMouseCursorContext(event);
      if (event.buttons > 0) {
        let timelineContainerLocalX = event.clientX + this.$_timelineContainerLocalOffsetX;
        let timelineContainerLocalY = event.clientY + this.$_timelineContainerLocalOffsetY;
        if (this.$data.$_isMouseCursorOnScrollBarArea) {
          let scrollBarLocalX = timelineContainerLocalX;
          let scrollBarLocalY = timelineContainerLocalY - this.$_timelineCanvasHeightPx;
          if (utils.isInRect(this.$_timeScrollBarDomRect, scrollBarLocalX, scrollBarLocalY)) {
            this.$data.$_timelineViewCenterMsecAtMousedown = this.$data.$_timelineViewBeginMsec + (this.$_timelineViewDurationMsec / 2);
            this.$data.$_timeScrollBarOffsetPxAtMousedown = timelineContainerLocalX;
          } else {
            if (this.$_timeScrollBarTimeResolution !== null) {
              let nextTimelineViewCenterScrollBarOffsetPx = timelineContainerLocalX;
              let nextTimelineViewBeginScrollBarOffsetPx = nextTimelineViewCenterScrollBarOffsetPx - (this.$_timeScrollBarDurationPx / 2);
              let nextTimelineViewBeginMsec = this.$_timelineViewBeginMsecMin + utils.resolve(nextTimelineViewBeginScrollBarOffsetPx, this.$_timeScrollBarTimeResolution);
              this.$_setTimelineViewBeginMsec(this.$_clampTimelineViewBeginMsec(nextTimelineViewBeginMsec));
            }
            return;
          }
        } else if (utils.isInRect(this.$refs.timelineSeparator.getBoundingClientRect(), event.clientX, event.clientY)) {
          this.$data.$_clientXAtMousedownOnSeparator = event.clientX;
          this.$data.$_timelineTitleWidthPxAtMousedownOnSeparator = this.timelineTitleWidthPx;
        } else if (utils.isInRect(this.$_timelineCanvasContainerDomRect, timelineContainerLocalX, timelineContainerLocalY)) {
          let timelineContainerClampedLocalX = this.$_clampTimelineContainerLocalX(timelineContainerLocalX);
          let timelineContainerClampedLocalY = this.$_clampTimelineContainerLocalY(timelineContainerLocalY);
          let timelineCanvasLocalCoordsAtMousedown = new TimelineCanvasLocalCoords(timelineContainerClampedLocalX, timelineContainerClampedLocalY);
          let edgeInfoAtMousedown = new EdgeInfo(timelineCanvasLocalCoordsAtMousedown, this.$_timelineCanvasWidthPx, this.$_timelineCanvasContainerHeightPx);
          this.$data.$_isMousedownOnRulerArea = this.$data.$_isMouseCursorOnRulerArea;
          this.$data.$_edgeInfoAtMousedown = edgeInfoAtMousedown;
          this.$data.$_scrollPending = true;
          this.$data.$_timelineSegmentIdWithInfoAtMousedown = this.$data.$_timelineSegmentIdWithInfoAtMouseCursor;
          let timelinePosition = this.$_timelineContainerLocalYToTimelinePosition(timelineContainerClampedLocalY);
          let timeMsec = Math.round(this.$_timelineContainerLocalXToTimeMsec(timelineContainerClampedLocalX));
          let snappedTimelineContainerClampedLocalX = this.$_getSnappedTimelineContainerLocalX(timelineContainerClampedLocalX);
          let snappedTimeMsec = Math.round(this.$_timelineContainerLocalXToTimeMsec(snappedTimelineContainerClampedLocalX));
          this.$data.$_timeMsecAndTimelinePositionAtMousedown = new TimeMsecAndTimelinePosition(timeMsec, snappedTimeMsec, timelinePosition);
        }
        this.$data.$_isSubMouseButtonPressedAtMousedown = ((event.buttons & 2) || event.withCtrl);
      }
    },

    async onMousemove(event) {
      if (this.$_isNotDisplayed()) return;
      this.$refs.timelineOverlayCanvas.onMousemove(event);
      this.$_updateMouseCursorContext(event);
      if (this.$_isMouseEventStarted) {
        if (event.buttons === 0) {
          await this.onMouseup(event);
        } else {
          this.$_updateMouseEventContext(event);
        }
      }
    },

    async onMouseup(event) {
      if (this.$_isNotDisplayed()) return;
      if (this.$_isMouseEventStarted) {
        this.$_updateMouseCursorContext(event);
        this.$_updateMouseEventContext(event);
        if (this.$data.$_timeMsecAndTimelinePositionAtMousedown) {
          if (this.$data.$_isSubMouseButtonPressedAtMousedown) {
            this.$_onContextMenuEventFixed(event.shiftKey);
          } else {
            if (this.$data.$_timeMsecAndTimelinePositionAtMousemove.isSame(this.$data.$_timeMsecAndTimelinePositionAtMousedown)) {
              await this.$_onClickFixed(event.shiftKey);
            } else {
              await this.$_onDragFixed(this.$_timelineCanvasLocalCoordsAtMousemoveOrMarkerEnd, event.shiftKey);
            }
          }
        } else if (this.$data.$_clientXAtMousedownOnSeparator) {
          this.$_resetOnMouseEvent();
        } else if (this.$data.$_timeScrollBarOffsetPxAtMousedown) {
          this.$_resetOnMouseEvent();
        }
      }
    },

    onScroll(event) {
      if (this.$_isNotDisplayed()) return false;
      let currentFixedTimelineViewOffsetPx = event.clientX + this.$_timelineContainerLocalOffsetX;
      let currentFixedTimelineViewVerticalOffsetPx = event.clientY + this.$_timelineContainerLocalOffsetY;
      if (currentFixedTimelineViewVerticalOffsetPx < 0) return false;
      if (this.isMouseMode) {
        let inverted = ((event.deltaY + event.deltaX) > 0);
        let l2norm = Math.sqrt(Math.pow(event.deltaX, 2) + Math.pow(event.deltaY, 2));
        if (event.ctrlKey) {
          let amount = utils.clamp(((l2norm / 5) + 1), 1, 2);
          let scale = (inverted)? (1 / amount) : amount;
          if (event.shiftKey) {
            this.$_updateVerticalOffsetPxAndTimelinePositionResolution(
              currentFixedTimelineViewVerticalOffsetPx,
              this.$data.$_timelinePositionResolution * scale,
            );
          } else {
            if (currentFixedTimelineViewOffsetPx < 0) {
              currentFixedTimelineViewOffsetPx = 0;
            }
            this.$_updateTimelineViewBeginMsecAndTimeResolution(
              currentFixedTimelineViewOffsetPx,
              this.$data.$_timeResolution * scale,
            );
          }
        } else {
          let amount = utils.clamp(l2norm / 5, 0, 2);
          if (event.shiftKey) {
            this.$_stepTimeOffset(((inverted)? 1 : -1) * amount);
          } else {
            this.$_stepVerticalOffset(((inverted)? -1 : 1) * amount);
          }
        }
      } else {
        if (event.ctrlKey) {
          if (event.shiftKey) {
            let amountY = utils.clamp(((Math.abs(event.deltaY) / 20) + 1), 1, 2);
            let scaleY = (event.deltaY > 0)? (1 / amountY) : amountY;
            this.$_updateVerticalOffsetPxAndTimelinePositionResolution(
              currentFixedTimelineViewVerticalOffsetPx,
              this.$data.$_timelinePositionResolution * scaleY,
            );
          } else {
            let amountX = utils.clamp(((Math.abs(event.deltaX) / 10) + 1), 1, 2);
            let scaleX = (event.deltaX < 0)? (1 / amountX) : amountX;
            if (currentFixedTimelineViewOffsetPx < 0) {
              currentFixedTimelineViewOffsetPx = 0;
            }
            this.$_updateTimelineViewBeginMsecAndTimeResolution(
              currentFixedTimelineViewOffsetPx,
              this.$data.$_timeResolution * scaleX,
            );
          }
        } else {
          let amountX = utils.clamp(event.deltaX / 5, -2, 2);
          let amountY = utils.clamp(event.deltaY / 5, -2, 2);
          this.$_stepTimeOffset(amountX);
          this.$_stepVerticalOffset(-amountY);
        }
      }
      return true;
    },

    async onKeydown(event) {
      let self = this;
      {
        if (this.$_isNotDisplayed()) return false;
        let withShift = event.shiftKey;
        let withCtrl = event.ctrlKey;
        let isSelectMode = (this.$data.$_currentViewMode === viewMode.select);
        let isPositionEditMode = (this.$data.$_currentViewMode === viewMode.position);
        if (document.activeElement !== document.body) return false;
        switch (event.code) {
        case 'ArrowRight':
          if (withShift) {
            if (isPositionEditMode && this.$_isAnyTimelineSegmentIdSelected()) {
              offsetPositionForSelectedSegments(1, TimelineSegmentDomRectsToDragWithInfo.edgeType.left);
            } else {
              doubleTimeResolution(false);
            }
          } else {
            if (isPositionEditMode) {
              if (this.$_isAnyTimelineSegmentIdSelected()) {
                offsetPositionForSelectedSegments(1, TimelineSegmentDomRectsToDragWithInfo.edgeType.right);
              } else {
                this.selectNextSegment();
              }
            } else if (isSelectMode) {
              this.selectNextSegment();
            } else {
              this.$_stepTimeOffset(1);
            }
          }
          break;
        case 'ArrowLeft':
          if (withShift) {
            if (isPositionEditMode && this.$_isAnyTimelineSegmentIdSelected()) {
              offsetPositionForSelectedSegments(-1, TimelineSegmentDomRectsToDragWithInfo.edgeType.left);
            } else {
              doubleTimeResolution(true);
            }
          } else {
            if (isPositionEditMode) {
              if (this.$_isAnyTimelineSegmentIdSelected()) {
                offsetPositionForSelectedSegments(-1, TimelineSegmentDomRectsToDragWithInfo.edgeType.right);
              } else {
                this.selectPreviousSegment();
              }
            } else if (isSelectMode) {
              this.selectPreviousSegment();
            } else {
              this.$_stepTimeOffset(-1);
            }
          }
          break;
        case 'ArrowUp':
          if (withShift) {
            doublePositionResolution(false);
          } else {
            if (isSelectMode || this.$_isAnyTimelineSegmentIdSelected()) {
              selectNearestSegmentInNextTimeline(true);
            } else {
              this.$_stepVerticalOffset(1);
            }
          }
          break;
        case 'ArrowDown':
          if (withShift) {
            doublePositionResolution(true);
          } else {
            if (isSelectMode || this.$_isAnyTimelineSegmentIdSelected()) {
              selectNearestSegmentInNextTimeline(false);
            } else {
              this.$_stepVerticalOffset(-1);
            }
          }
          break;
        case 'Home':
          if (isSelectMode || this.$_isAnyTimelineSegmentIdSelected()) {
            selectFirstSegment(false);
          } else {
            this.$_setTimelineViewBeginMsec(this.$_timelineViewBeginMsecMin);
          }
          break;
        case 'End':
          if (isSelectMode || this.$_isAnyTimelineSegmentIdSelected()) {
            selectFirstSegment(true);
          } else {
            this.$_setTimelineViewBeginMsec(this.durationMsec - this.$_timelineViewDurationMsec);
          }
          break;
        case 'PageUp':
          this.$_stepPageTimeOffset(true);
          break;
        case 'PageDown':
          this.$_stepPageTimeOffset(false);
          break;
        case 'Enter':
          if (this.$_isAnyTimelineRealtimeSegmentCreationTarget) {
            if (this.isVideoPlaying) {
              if (this.$data.$_isRealtimeSegmentOpen) {
                this.$_recordRealtimeSegmentEnd();
              } else {
                this.$_recordRealtimeSegmentBegin();
              }
            }
          } else {
            if (this.$_isNoTimelineSegmentIdSelected()) return false;
            if (withShift) {
              await this.invokeActionOnSelectedTimelineSegments('general');
            } else {
              await this.invokeActionOnSelectedTimelineSegments('editSegment');
            }
          }
          break;
        case 'Delete':
        case 'Backspace':
          if (withShift) {
            await this.invokeActionOnSelectedTimelineSegments('deleteSegment');
          } else {
            await this.invokeActionOnSelectedTimelineSegments('clearSegment');
          }
          break;
        case 'Escape':
          {
            if (this.$_isAnyTimelineRealtimeSegmentCreationTarget) {
              if (this.$data.$_isRealtimeSegmentOpen) {
                this.clearMarker();
              } else {
                clearRealtimeSegmentCreationTarget();
              }
            } else {
              if (this.$_isMouseEventStarted) {
                this.$_resetOnMouseEvent();
                break;
              }
              if (this.$data.$_currentViewMode !== viewMode.normal) {
                this.$_setViewMode(viewMode.normal);
                break;
              }
              if (this.$_isMarkerSet) {
                this.clearMarker();
                break;
              }
              if (this.isLoopEnabled) {
                this.disableLoopFlag();
                break;
              }
            }
            return false;
          }
        case 'KeyC':
          if (withCtrl) {
            await this.invokeActionOnSelectedTimelineSegments('setCopySource');
          } else {
            if (!withShift) {
              this.$_setViewMode(viewMode.create);
            } else {
              return false;
            }
          }
          break;
        case 'KeyV':
          if (withCtrl & !withShift) {
            this.copySegmentsFromCopySource();
          } else {
            return false;
          }
          break;
        case 'KeyD':
          if (!withCtrl && !withShift) {
            this.$_setViewMode(viewMode.divide);
          } else {
            return false;
          }
          break;
        case 'KeyP':
          if (!withCtrl && !withShift) {
            this.$_setViewMode(viewMode.position);
          } else {
            return false;
          }
          break;
        case 'KeyL':
          if (withCtrl) {
            if (withShift) {
              await this.invokeActionOnSelectedTimelineSegments('unlock');
            } else {
              await this.invokeActionOnSelectedTimelineSegments('lock');
            }
          } else {
            if (withShift) {
              if (this.$_isAnyTimelineSegmentIdSelected()) {
                await this.invokeActionOnSelectedTimelineSegments('setLoop');
              } else if (this.$_isMarkerSet) {
                this.setLoopByMarker();
              } else {
                this.setLoopFullDuration();
              }
            } else {
              (this.isLoopEnabled)? this.disableLoopFlag() : this.enableLoopFlag();
            }
          }
          break;
        case 'KeyF':
          this.$data.$_followPlayBarFlag = !this.$data.$_followPlayBarFlag;
          break;
        case 'KeyM':
          if (withShift) {
            if (withCtrl) {
              await this.invokeActionOnSelectedTimelineSegments('setExclusiveMarker');
            } else {
              await this.invokeActionOnSelectedTimelineSegments('setMarker');
            }
          } else {
            if (!withCtrl) {
              this.$_setViewMode(viewMode.merge);
            } else {
              return false;
            }
          }
          break;
        case 'KeyS':
          if (!withCtrl) {
            this.$_setViewMode(viewMode.select);
          } else {
            return false;
          }
          break;
        case 'KeyN':
          await this.invokeActionOnSelectedTimelineSegments('addNote');
          break;
        default:
          return false;
        }
        return true;
      }

      function doubleTimeResolution(inverted) {
        let currentFixedTimelineViewOffsetPx = null;
        let lastSelectedTimelineSegmentId = self.$_peakLastSelectedTimelineSegmentId();
        if (lastSelectedTimelineSegmentId) {
          let lastSelectedTimelineDataId = lastSelectedTimelineSegmentId.timelineDataId;
          let lastSelectedSegmentId = lastSelectedTimelineSegmentId.segmentId;
          let lastSelectedSegment = self.visibleTimelineDataSet[lastSelectedTimelineDataId].segments[lastSelectedSegmentId];
          currentFixedTimelineViewOffsetPx = self.$_scaledTimeMsecToPx(lastSelectedSegment.begin - self.$data.$_timelineViewBeginMsec);
        } else {
          currentFixedTimelineViewOffsetPx = 0;
        }
        let amount = (inverted)? 0.5 : 2;
        self.$_updateTimelineViewBeginMsecAndTimeResolution(
          currentFixedTimelineViewOffsetPx,
          self.$data.$_timeResolution * amount,
        );
      }

      function doublePositionResolution(inverted) {
        let currentFixedTimelineViewVerticalOffsetPx = null;
        let lastSelectedTimelineSegmentId = self.$_peakLastSelectedTimelineSegmentId();
        if (lastSelectedTimelineSegmentId) {
          let lastSelectedTimelineDataId = lastSelectedTimelineSegmentId.timelineDataId;
          let lastSelectedSegmentId = lastSelectedTimelineSegmentId.segmentId;
          let lastSelectedTimelineSegmentDomRect = self.$_timelineSegmentDomRects[lastSelectedTimelineDataId][lastSelectedSegmentId];
          currentFixedTimelineViewVerticalOffsetPx = lastSelectedTimelineSegmentDomRect.y;
        } else {
          currentFixedTimelineViewVerticalOffsetPx = rulerVerticalOffsetPx;
        }
        let scale = (inverted)? (0.5) : 2;
        self.$_updateVerticalOffsetPxAndTimelinePositionResolution(
          currentFixedTimelineViewVerticalOffsetPx,
          self.$data.$_timelinePositionResolution * scale,
        );
      }

      function selectFirstSegment(inverted) {
        self.$data.$_followPlayBarFlag = false;
        let lastSelectedTimelineSegmentId = self.$_peakLastSelectedTimelineSegmentId();
        let nextTimelineSegmentId = null
        if (!lastSelectedTimelineSegmentId) {
          nextTimelineSegmentId = self.$_findFirstVisibleTimelineSegmentId();
        } else {
          let nextTimelineDataId = lastSelectedTimelineSegmentId.timelineDataId;
          let sortedVisibleSegmentIdxToIdMap = self.sortedVisibleSegmentIdxToIdMaps[nextTimelineDataId];
          let numSegments = sortedVisibleSegmentIdxToIdMap.length;
          let firstSegmentIdx = sortedVisibleSegmentIdxToIdMap[(inverted)? (numSegments - 1) : 0];
          nextTimelineSegmentId = new TimelineSegmentId(nextTimelineDataId, firstSegmentIdx);
        }
        self.$_selectTimelineSegmentCore(nextTimelineSegmentId);
      }

      function selectNearestSegmentInNextTimeline(inverted) {
        self.$data.$_followPlayBarFlag = false;
        let lastSelectedTimelineSegmentId = self.$_peakLastSelectedTimelineSegmentId();
        let nextTimelineSegmentId = null
        if (!lastSelectedTimelineSegmentId) {
          nextTimelineSegmentId = self.$_findFirstVisibleTimelineSegmentId();
        } else {
          let lastSelectedTimelineDataId = lastSelectedTimelineSegmentId.timelineDataId;
          let visibleTimelineDataIdxMax = self.visibleTimelineDataIdxToId.length - 1;
          let foundVisibleTimelineDataIdx = self.visibleTimelineDataIdxToId.findIndex((timelineDataId) => (lastSelectedTimelineDataId === timelineDataId));
          let visibleTimelineDataIdxTemp = foundVisibleTimelineDataIdx;
          while (nextTimelineSegmentId === null) {
            visibleTimelineDataIdxTemp += ((inverted)? -1 : 1);
            if ((visibleTimelineDataIdxTemp < 0) || (visibleTimelineDataIdxTemp > visibleTimelineDataIdxMax)) {
              nextTimelineSegmentId = lastSelectedTimelineSegmentId;
            } else {
              let visibleTimelineDataIdTemp = self.visibleTimelineDataIdxToId[visibleTimelineDataIdxTemp];
              let sortedVisibleSegmentIdxToIdMap = self.sortedVisibleSegmentIdxToIdMaps[visibleTimelineDataIdTemp];
              let numSegments = sortedVisibleSegmentIdxToIdMap.length;
              if (numSegments > 0) {
                let visibleSegmentIdTemp = sortedVisibleSegmentIdxToIdMap[0];
                for (let sortedSegmentIdx = 0; sortedSegmentIdx < numSegments; ++sortedSegmentIdx) {
                  let segmentId = sortedVisibleSegmentIdxToIdMap[sortedSegmentIdx];
                  let segment = self.visibleTimelineDataSet[visibleTimelineDataIdTemp].segments[segmentId];
                  if (segment.begin > self.$data.$_timelineSegmentNearestBaseTimeMsec) break;
                  visibleSegmentIdTemp = segmentId;
                }
                nextTimelineSegmentId = new TimelineSegmentId(visibleTimelineDataIdTemp, visibleSegmentIdTemp);
              }
            }
          }
        }
        if (nextTimelineSegmentId) {
          self.$_selectTimelineSegmentCore(nextTimelineSegmentId, { updatesNearestTimelineSegmentBase: false });
        }
      }

      function clearRealtimeSegmentCreationTarget() {
        for (let timelineDataId of Object.keys(self.$data.$_isRealtimeSegmentCreationTarget)) {
          self.$data.$_isRealtimeSegmentCreationTarget[timelineDataId] = false;
        }
      }

      function offsetPositionForSelectedSegments(offsetPx, edgeType) {
        self.$data.$_segmentDragOffsetTimeMsec = Math.floor(self.$_pxToScaledTimeMsec(offsetPx));
        self.$_offsetSegmentTimeByDrag(edgeType);
        let lastSelectedTimelineSegmentId = self.$_peakLastSelectedTimelineSegmentId();
        let lastSelectedTimelineSegment = self.visibleTimelineDataSet.getSegment(lastSelectedTimelineSegmentId);
        switch(edgeType) {
        case TimelineSegmentDomRectsToDragWithInfo.edgeType.left:
          self.$_forceTimeMsecBeCenterInTimelineView(lastSelectedTimelineSegment.begin);
          self.seekInMsec(lastSelectedTimelineSegment.begin);
          break;
        case TimelineSegmentDomRectsToDragWithInfo.edgeType.right:
          self.$_forceTimeMsecBeCenterInTimelineView(lastSelectedTimelineSegment.end);
          self.seekInMsec(utils.clamp(
            lastSelectedTimelineSegment.end - seekMarginTimeMsecOnEndOfSegmentPositionChanged,
            lastSelectedTimelineSegment.begin,
            lastSelectedTimelineSegment.end,
          ));
          break;
        }
      }
    },

    selectPreviousSegment() {
      this.$data.$_followPlayBarFlag = false;
      let firstSelectedTimelineSegmentId = this.$_findFirstTimelineSegmentId(this.selectedTimelineSegmentIds);
      if (firstSelectedTimelineSegmentId === null) {
        this.$_selectTimelineSegmentCore(this.$_findFirstVisibleTimelineSegmentId());
      } else {
        this.$_visuallyOffsetTimelineSegmentId(firstSelectedTimelineSegmentId, -1);
      }
    },

    selectNextSegment() {
      this.$data.$_followPlayBarFlag = false;
      let lastSelectedTimelineSegmentId = this.$_findLastTimelineSegmentId(this.selectedTimelineSegmentIds);
      if (lastSelectedTimelineSegmentId === null) {
        this.$_selectTimelineSegmentCore(this.$_findFirstVisibleTimelineSegmentId());
      } else {
        this.$_visuallyOffsetTimelineSegmentId(lastSelectedTimelineSegmentId, 1);
      }
    },

    /* private */
    $_draw() {
      this.$refs.timelineBackgroundCanvas.draw();
      this.$refs.timelineSegmentCanvas.draw();
      this.$refs.timelineForegroundCanvas.draw();
      this.$refs.timelinePlayTimeBarCanvas.draw();
      this.$refs.timelineOverlayCanvas.draw();
      this.$refs.timeScrollBarCanvas.draw();
      this.$data.$_requestAnimationFrameCallbackId = window.requestAnimationFrame(this.$_draw);
    },

    async $_onClickFixed(withShift) {
      let timelineContainerLocalX = this.$_timelineCanvasLocalCoordsAtMousedown.x;
      if (timelineContainerLocalX > 0) {
        let timelineContainerLocalY = this.$_timelineCanvasLocalCoordsAtMousedown.y;
        if (timelineContainerLocalY <= this.$_timelineCanvasHeightPx) {
          if (!this.$data.$_isMousedownOnRulerArea && this.$data.$_timelineSegmentIdWithInfoAtMousedown) {
            let snappedTimelineContainerLocalX = this.$_getSnappedTimelineContainerLocalX(timelineContainerLocalX);
            let snappedTimelineViewOffsetMsec = this.$_pxToScaledTimeMsec(snappedTimelineContainerLocalX);
            let snappedTimeMsec = Math.round(this.$data.$_timelineViewBeginMsec + snappedTimelineViewOffsetMsec);
            let timelineSegmentIdAtMousedown = this.$data.$_timelineSegmentIdWithInfoAtMousedown.timelineSegmentId;
            switch (this.$data.$_currentViewMode) {
            case viewMode.normal:
            case viewMode.position:
            case viewMode.select:
              {
                if (this.$_isAnyTimelineSegmentIdSelected()) {
                  if (withShift) {
                    if (this.$_isTimelineSegmentIdSelected(timelineSegmentIdAtMousedown)) {
                      await this.invokeActionOnSelectedTimelineSegments('general');
                    }
                  } else {
                    if (this.$data.$_timelineSegmentIdAtLastClick) {
                      if (this.$data.$_timelineSegmentIdAtLastClick.isSame(timelineSegmentIdAtMousedown)) {
                        let onlyOneSelectedTimelineSegmentId = this.$_getOnlyOneSelectedTimelineSegmentId();
                        if (onlyOneSelectedTimelineSegmentId && onlyOneSelectedTimelineSegmentId.isSame(timelineSegmentIdAtMousedown)) {
                          await this.invokeActionOnSelectedTimelineSegments('editSegment');
                        }
                      }
                    }
                    this.$data.$_timelineSegmentIdAtLastClick = timelineSegmentIdAtMousedown;
                    if (this.$data.$_doubleClickOnTimelineSegmentTimeout) {
                      window.clearTimeout(this.$data.$_doubleClickOnTimelineSegmentTimeout);
                    }
                    this.$data.$_doubleClickOnTimelineSegmentTimeout = window.setTimeout(
                      () => {
                        this.$data.$_timelineSegmentIdAtLastClick = null;
                        this.$data.$_doubleClickOnTimelineSegmentTimeout = null;
                      },
                      doubleClickIntervalOnTimelineSegmentMsecMax,
                    );
                  }
                }
                switch (this.$data.$_currentViewMode) {
                case viewMode.position:
                  this.$_selectTimelineSegmentCore(timelineSegmentIdAtMousedown, { retainsSelection: withShift, retainsViewMode: true });
                  break;
                default:
                  this.$_selectTimelineSegmentCore(timelineSegmentIdAtMousedown, { retainsSelection: withShift });
                  break;
                }
              }
              break;
            case viewMode.merge:
              if (this.$data.$_timelineSegmentIdWithInfoAtMousedown) {
                if (this.$_isTimelineSegmentIdSelected(timelineSegmentIdAtMousedown)) {
                  await this.invokeActionOnSelectedTimelineSegments('mergeSegment');
                } else {
                  this.$_selectTimelineSegmentCore(timelineSegmentIdAtMousedown, { retainsSelection: withShift });
                }
              }
              break;
            case viewMode.divide:
              if (this.$data.$_timelineSegmentIdWithInfoAtMousedown) {
                if (this.$_isTimelineSegmentIdSelected(timelineSegmentIdAtMousedown)) {
                  this.divideSegment(timelineSegmentIdAtMousedown, snappedTimeMsec);
                } else {
                  this.$_selectTimelineSegmentCore(timelineSegmentIdAtMousedown, { retainsSelection: withShift });
                }
              }
              break;
            }
          } else {
            let timelineViewOffsetMsec = this.$_pxToScaledTimeMsec(timelineContainerLocalX);
            let timeMsec = Math.round(this.$data.$_timelineViewBeginMsec + timelineViewOffsetMsec);
            if (this.$data.$_isMousedownOnRulerArea) {
              this.clearMarker();
            }
            if (!withShift) {
              switch (this.$data.$_currentViewMode) {
              case viewMode.select:
              case viewMode.position:
                this.$_setViewMode(viewMode.normal);
              }
              this.seekInMsec(timeMsec);
            }
          }
        }
      }
      this.$_resetOnMouseEvent();
    },

    $_onContextMenuEventFixed(withShift) {
      let timelineContainerLocalX = this.$_timelineCanvasLocalCoordsAtMousedown.x;
      if (timelineContainerLocalX > 0) {
        let timelineContainerLocalY = this.$_timelineCanvasLocalCoordsAtMousedown.y;
        if (timelineContainerLocalY <= this.$_timelineCanvasHeightPx) {
          if (this.$data.$_timelineSegmentIdWithInfoAtMouseCursor) {
            let timelineSegmentIdAtMouseup = this.$data.$_timelineSegmentIdWithInfoAtMouseCursor.timelineSegmentId;
            if (this.$_isAnyTimelineSegmentIdSelected()) {
              if (!this.$_isTimelineSegmentIdSelected(timelineSegmentIdAtMouseup)) {
                this.$_selectTimelineSegmentCore(timelineSegmentIdAtMouseup, { retainsSelection: withShift });
              }
            } else {
              this.$_selectTimelineSegmentCore(timelineSegmentIdAtMouseup);
            }
            this.$data.$_timelineSegmentIdAtOpeningContextMenu = timelineSegmentIdAtMouseup;
          } else {
            if (!withShift) {
              this.clearSelectedTimelineSegments();
            }
            this.$data.$_timelineSegmentIdAtOpeningContextMenu = null;
          }
          this.$data.$_isMousedownOnRulerAreaAtOpeningContextMenu = this.$data.$_isMousedownOnRulerArea;
        }
      }
      this.$_resetOnMouseEvent();
    },

    async $_onDragFixed(timelineCanvasLocalCoordsAtMouseup, withShift) {
      let self = this;
      {
        let mousedownTimelineViewOffsetPx = this.$_timelineCanvasLocalCoordsAtMousedownOrMarkerBegin.x;
        let mousedownTimelineViewVerticalOffsetPx = this.$_timelineCanvasLocalCoordsAtMousedownOrMarkerBegin.y;
        let mouseupTimelineViewOffsetPx = timelineCanvasLocalCoordsAtMouseup.x;
        let mouseupTimelineViewVerticalOffsetPx= timelineCanvasLocalCoordsAtMouseup.y;
        let [ x0, x1 ] = getX0AndX1(mousedownTimelineViewOffsetPx, mouseupTimelineViewOffsetPx);
        let [ y0, y1 ] =
          (mouseupTimelineViewVerticalOffsetPx >= mousedownTimelineViewVerticalOffsetPx)?
          [ mousedownTimelineViewVerticalOffsetPx, mouseupTimelineViewVerticalOffsetPx ] :
          [ mouseupTimelineViewVerticalOffsetPx, mousedownTimelineViewVerticalOffsetPx ];
        if (this.$data.$_isMousedownOnRulerArea) {
          let markerBeginTimeMsec = Math.round(this.$data.$_timelineViewBeginMsec + this.$_pxToScaledTimeMsec(x0));
          let markerEndTimeMsec = Math.round(this.$data.$_timelineViewBeginMsec + this.$_pxToScaledTimeMsec(x1));
          if (markerBeginTimeMsec === markerEndTimeMsec) {
            this.seekInMsec(markerBeginTimeMsec);
          } else {
            this.setMarker(markerBeginTimeMsec, markerEndTimeMsec);
          }
          this.$_resetOnMouseEvent();
        } else {
          switch (this.$data.$_currentViewMode) {
          default:
            if (this.$_timelineSegmentDomRectsToDragWithInfo) {
              this.$_offsetSegmentTimeByDrag(this.$_timelineSegmentDomRectsToDragWithInfo.edgeType);
            } else {
              if (!withShift) {
                this.clearSelectedTimelineSegments();
              }
              let dragRect = new DOMRect(x0, y0, x1 - x0, y1 - y0);
              let foundTimelineSegmentIds = findAllTimelineSegmentIdsInDomRect(dragRect);
              if (foundTimelineSegmentIds.length > 0) {
                let retainsViewMode = (this.$data.$_currentViewMode !== viewMode.normal);
                this.$_selectTimelineSegmentsCore(foundTimelineSegmentIds, { retainsViewMode: retainsViewMode, forcesToBeInTimelineView: false });
              } else {
                if (!withShift) {
                  if (this.$data.$_currentViewMode === viewMode.select) {
                    this.$_setViewMode(viewMode.normal);
                  }
                }
              }
            }
            this.$_resetOnMouseEvent();
            break;
          case viewMode.create:
            if (this.$_visibleTimelineDataIdAtMousedown !== null) {
              let timelineDataAtMousedown = this.visibleTimelineDataSet[this.$_visibleTimelineDataIdAtMousedown];
              if (timelineDataAtMousedown.locked) {
                this.$_resetOnMouseEvent();
              } else {
                let beginTimeMsec = null;
                let endTimeMsec = null;
                if (this.$_isTimeMsecInMarker(this.$data.$_timeMsecAndTimelinePositionAtMousedown.timeMsec)) {
                  beginTimeMsec = this.timelineMarker.beginTimeMsec;
                  endTimeMsec = this.timelineMarker.endTimeMsec;
                } else {
                  beginTimeMsec = Math.round(this.$data.$_timelineViewBeginMsec + this.$_pxToScaledTimeMsec(x0));
                  endTimeMsec = Math.round(this.$data.$_timelineViewBeginMsec + this.$_pxToScaledTimeMsec(x1));
                }
                if (withShift) {
                  let newTimelineSegmentId = this.generateEmptySegment(this.$_visibleTimelineDataIdAtMousedown, beginTimeMsec, endTimeMsec);
                  this.$nextTick(() => {
                    this.$_selectTimelineSegmentCore(newTimelineSegmentId, { retainsViewMode: true });
                  });
                } else {
                  let newTimelineSegmentId = await this.createSegment(this.$_visibleTimelineDataIdAtMousedown, beginTimeMsec, endTimeMsec);
                  if (newTimelineSegmentId !== null) {
                    this.$nextTick(() => {
                      this.$_selectTimelineSegmentCore(newTimelineSegmentId, { retainsViewMode: true });
                    });
                  }
                }
                this.$_resetOnMouseEvent();
              }
            } else {
              this.$_resetOnMouseEvent();
            }
            break;
          }
        }
      }

      function getX0AndX1(mousedownTimelineViewOffsetPx, mouseupTimelineViewOffsetPx) {
        let areMouseupAndMouseownSetByMarker = ((mousedownTimelineViewOffsetPx === null) || (mouseupTimelineViewOffsetPx === null));
        if (areMouseupAndMouseownSetByMarker) {
          return [ mousedownTimelineViewOffsetPx, mouseupTimelineViewOffsetPx ];
        } else {
          return (mouseupTimelineViewOffsetPx >= mousedownTimelineViewOffsetPx)?
            [ mousedownTimelineViewOffsetPx, mouseupTimelineViewOffsetPx ] :
            [ mouseupTimelineViewOffsetPx, mousedownTimelineViewOffsetPx ];
        }
      }

      function findAllTimelineSegmentIdsInDomRect(domRect) {
        let allTimelineSegmentIds = new Array();
        for (let [ timelineDataId, segmentDomRectsByTimelineDataId ] of Object.entries(self.$_timelineSegmentDomRects)) {
          for (let [ segmentId, timelineSegmentDomRect ] of Object.entries(segmentDomRectsByTimelineDataId)) {
            if (utils.areRectsOverwrapped(timelineSegmentDomRect, domRect)) {
              allTimelineSegmentIds.push(new TimelineSegmentId(timelineDataId, segmentId));
            }
          }
        }
        return allTimelineSegmentIds;
      }
    },

    $_offsetSegmentTimeByDrag(edgeType) {
      if (this.isAnySelectedTimelineSegmentImmutable) return;
      this.atomicChanges(
        'offsetting segments',
        'Offset segments.',
        () => {
          for (let timelineSegmentId of this.selectedTimelineSegmentIds) {
            let timelineData = this.visibleTimelineDataSet[timelineSegmentId.timelineDataId];
            let timelineSegment = this.visibleTimelineDataSet.getSegment(timelineSegmentId);
            let begin = timelineSegment.begin;
            let end = timelineSegment.end;
            switch (edgeType) {
            case TimelineSegmentDomRectsToDragWithInfo.edgeType.left:
              if (begin !== null) begin += this.$data.$_segmentDragOffsetTimeMsec;
              break;
            case TimelineSegmentDomRectsToDragWithInfo.edgeType.right:
              if (end !== null) end += this.$data.$_segmentDragOffsetTimeMsec;
              break;
            case TimelineSegmentDomRectsToDragWithInfo.edgeType.none:
              if (begin !== null) begin += this.$data.$_segmentDragOffsetTimeMsec;
              if (end !== null) end += this.$data.$_segmentDragOffsetTimeMsec;
              break;
            }
            let offsetedTimelineSegment = timelineSegment.clone(false);
            offsetedTimelineSegment.begin = begin;
            offsetedTimelineSegment.end = end;
            this.setSegment(
              timelineSegmentId,
              timelineData.segmentDataType,
              offsetedTimelineSegment,
            );
          }
        },
      );
    },

    $_setSegmentDragOffsetTimeMsec(segmentDragOffsetTimeMsec, edgeType) {
      let self = this;
      {
        if (this.isAnySelectedTimelineSegmentImmutable ||
          isEveryTimelineSegmentHasNoRange(this.selectedTimelineSegmentIds))
        {
          this.$data.$_segmentDragOffsetTimeMsec = 0;
          return;
        }
        let smallestBeginMsec = getSmallestBeginMsec(this.selectedTimelineSegmentIds);
        let largestEndMsec = getLargestEndMsec(this.selectedTimelineSegmentIds);
        let smallestDurationMsec = getSmallestDurationMsec(this.selectedTimelineSegmentIds);
        switch (edgeType) {
        case TimelineSegmentDomRectsToDragWithInfo.edgeType.left:
          segmentDragOffsetTimeMsec = clampTimeOffsetMsecOnLeftEdgeViaSmallestDurationMsec(segmentDragOffsetTimeMsec, smallestDurationMsec);
          segmentDragOffsetTimeMsec = clampTimeOffsetMsecViaSmallestBeginMsec(segmentDragOffsetTimeMsec, smallestBeginMsec);
          break;
        case TimelineSegmentDomRectsToDragWithInfo.edgeType.right:
          segmentDragOffsetTimeMsec = clampTimeOffsetMsecOnRightEdgeViaSmallestDurationMsec(segmentDragOffsetTimeMsec, smallestDurationMsec);
          segmentDragOffsetTimeMsec = clampTimeOffsetMsecViaLargestEndMsec(segmentDragOffsetTimeMsec, largestEndMsec);
          break;
        case TimelineSegmentDomRectsToDragWithInfo.edgeType.none:
          segmentDragOffsetTimeMsec = clampTimeOffsetMsecViaSmallestBeginMsec(segmentDragOffsetTimeMsec, smallestBeginMsec);
          segmentDragOffsetTimeMsec = clampTimeOffsetMsecViaLargestEndMsec(segmentDragOffsetTimeMsec, largestEndMsec);
          break;
        }
        this.$data.$_segmentDragOffsetTimeMsec = segmentDragOffsetTimeMsec;
      }

      function getSmallestDurationMsec(timelineSegmentIds) {
        let smallestDurationMsec = self.durationMsec;
        for (let timelineSegmentId of timelineSegmentIds) {
          let timelineSegment = self.visibleTimelineDataSet.getSegment(timelineSegmentId);
          if ((timelineSegment.begin !== null) && (timelineSegment.end !== null)) {
            let durationMsec = timelineSegment.end - timelineSegment.begin;
            if (durationMsec < smallestDurationMsec) {
              smallestDurationMsec = durationMsec;
            }
          }
        }
        return smallestDurationMsec;
      }

      function getSmallestBeginMsec(timelineSegmentIds) {
        let smallestBeginMsec = self.durationMsec;
        for (let timelineSegmentId of timelineSegmentIds) {
          let timelineSegment = self.visibleTimelineDataSet.getSegment(timelineSegmentId);
          if (timelineSegment.begin === null) continue;
          if (smallestBeginMsec > timelineSegment.begin) {
            smallestBeginMsec = timelineSegment.begin;
          }
        }
        return smallestBeginMsec;
      }

      function getLargestEndMsec(timelineSegmentIds) {
        let largestEndMsec = 0;
        for (let timelineSegmentId of timelineSegmentIds) {
          let timelineSegment = self.visibleTimelineDataSet.getSegment(timelineSegmentId);
          if (timelineSegment.end === null) continue;
          if (largestEndMsec < timelineSegment.end) {
            largestEndMsec = timelineSegment.end;
          }
        }
        return largestEndMsec;
      }

      function clampTimeOffsetMsecOnLeftEdgeViaSmallestDurationMsec(offsetTimeMsec, smallestDurationMsec) {
        let offsetTimeMsecMax = smallestDurationMsec - self.minimumSegmentDurationMsec;
        if (offsetTimeMsec > offsetTimeMsecMax) {
          offsetTimeMsec = offsetTimeMsecMax;
        }
        return offsetTimeMsec;
      }

      function clampTimeOffsetMsecOnRightEdgeViaSmallestDurationMsec(offsetTimeMsec, smallestDurationMsec) {
        let offsetTimeMsecMin = -(smallestDurationMsec - self.minimumSegmentDurationMsec);
        if (offsetTimeMsec < offsetTimeMsecMin) {
          offsetTimeMsec = offsetTimeMsecMin;
        }
        return offsetTimeMsec;
      }

      function clampTimeOffsetMsecViaSmallestBeginMsec(offsetTimeMsec, smallestBeginMsec) {
        let beginMsec = smallestBeginMsec + offsetTimeMsec;
        let beginMsecMin = 0;
        if (beginMsec < beginMsecMin) {
          offsetTimeMsec = beginMsecMin - smallestBeginMsec;
        }
        return offsetTimeMsec;
      }

      function clampTimeOffsetMsecViaLargestEndMsec(offsetTimeMsec, largestEndMsec) {
        let endMsec = largestEndMsec + offsetTimeMsec;
        let endMsecMax = self.durationMsec;
        if (endMsec > endMsecMax) {
          offsetTimeMsec = endMsecMax - largestEndMsec;
        }
        return offsetTimeMsec;
      }

      function isEveryTimelineSegmentHasNoRange(timelineSegmentIds) {
        for (let timelineSegmentId of timelineSegmentIds) {
          let timelineSegment = self.visibleTimelineDataSet.getSegment(timelineSegmentId);
          if (!timelineSegment.hasNoRange) return false;
        }
        return true;
      }
    },

    $_updateMouseCursorContext(event) {
      let self = this;
      {
        let timelineContainerLocalX = event.clientX + this.$_timelineContainerLocalOffsetX;
        let timelineContainerLocalY = event.clientY + this.$_timelineContainerLocalOffsetY;
        let timelineSegmentIdWithInfoAtMouseCursor = null;
        let timelineSegmentIdsAtMouseCursor = findTimelineSegmentIdsAtPoint(timelineContainerLocalX, timelineContainerLocalY);
        if (timelineSegmentIdsAtMouseCursor.length > 0) {
          let timelineSegmentIdAtMouseCursor = null;
          let alreadySelectedTimelineSegmentIds = findAlreadySelectedTimelineSegmentIds(timelineSegmentIdsAtMouseCursor);
          if (alreadySelectedTimelineSegmentIds.length > 0) {
            timelineSegmentIdAtMouseCursor = getRepresentativeTimelineSegmentIds(alreadySelectedTimelineSegmentIds);
          } else {
            timelineSegmentIdAtMouseCursor = getRepresentativeTimelineSegmentIds(timelineSegmentIdsAtMouseCursor);
          }
          let timelineDataIdAtMouseCursor = timelineSegmentIdAtMouseCursor.timelineDataId;
          let segmentIdAtMouseCursor = timelineSegmentIdAtMouseCursor.segmentId;
          let timelineSegmentDomRectAtMouseCursor = this.$_timelineSegmentDomRects[timelineDataIdAtMouseCursor][segmentIdAtMouseCursor];
          let timelineCanvasLocalCoords = new TimelineCanvasLocalCoords(timelineContainerLocalX, timelineContainerLocalY);
          timelineSegmentIdWithInfoAtMouseCursor = new TimelineSegmentIdWithInfo(
            timelineSegmentIdAtMouseCursor,
            timelineSegmentDomRectAtMouseCursor,
            timelineCanvasLocalCoords,
          );
        }
        this.$data.$_timelineSegmentIdWithInfoAtMouseCursor = timelineSegmentIdWithInfoAtMouseCursor;
        this.$data.$_isMouseCursorOnScrollBarArea =
          (timelineContainerLocalY >= this.$_timelineCanvasHeightPx) &&
          (timelineContainerLocalY < this.$_timelineCanvasContainerHeightPx) &&
          (timelineContainerLocalX > 0);
        this.$data.$_isMouseCursorOnRulerArea = (timelineContainerLocalY >= 0) && (timelineContainerLocalY < rulerVerticalOffsetPx);
        this.$data.$_isMouseCursorOnVisibleTimeline = this.$_getVisibleTimelineDataIdAtTimelineCanvasLocalY(timelineContainerLocalY);
      }

      function getRepresentativeTimelineSegmentIds(timelineSegmentIds) {
        return timelineSegmentIds[timelineSegmentIds.length - 1];
      }

      function findAlreadySelectedTimelineSegmentIds(timelineSegmentIds) {
        let alreadySelectedTimelineSegmentIds = new Array();
        for (let timelineSegmentId of timelineSegmentIds) {
          if (self.$_isTimelineSegmentIdSelected(timelineSegmentId)) {
            alreadySelectedTimelineSegmentIds.push(timelineSegmentId);
          }
        }
        return alreadySelectedTimelineSegmentIds;
      }

      function findTimelineSegmentIdsAtPoint(timelineContainerLocalX, timelineContainerLocalY) {
        let timelineSegmentIdsAtPoint = new Array();
        for (let [ timelineDataId, segmentDomRectsByTimelineDataId ] of Object.entries(self.$_timelineSegmentDomRects)) {
          for (let [ segmentId, timelineSegmentDomRect ] of Object.entries(segmentDomRectsByTimelineDataId)) {
            if (utils.isInRect(timelineSegmentDomRect, timelineContainerLocalX, timelineContainerLocalY)) {
              timelineSegmentIdsAtPoint.push(new TimelineSegmentId(timelineDataId, segmentId));
            }
          }
        }
        return timelineSegmentIdsAtPoint;
      }
    },

    $_updateMouseEventContext(event) {
      const stepValueScale = 0.5;
      const autoScrollMinimumInterval = 100;
      let self = this;
      {
        let timelineContainerLocalX = event.clientX + this.$_timelineContainerLocalOffsetX;
        let timelineContainerLocalY = event.clientY + this.$_timelineContainerLocalOffsetY;
        if (this.$data.$_timeScrollBarOffsetPxAtMousedown) {
          let mousemoveOffsetPx = timelineContainerLocalX - this.$data.$_timeScrollBarOffsetPxAtMousedown;
          if (this.$_timeScrollBarTimeResolution !== null) {
            let nextTimelineViewCenterMsec = this.$data.$_timelineViewCenterMsecAtMousedown + utils.resolve(mousemoveOffsetPx, this.$_timeScrollBarTimeResolution);
            let nextTimelineViewBeginMsec = nextTimelineViewCenterMsec - (this.$_timelineViewDurationMsec / 2);
            this.$_setTimelineViewBeginMsec(this.$_clampTimelineViewBeginMsec(nextTimelineViewBeginMsec));
          }
        } else if (this.$data.$_clientXAtMousedownOnSeparator) {
          let deltaX = event.clientX - this.$data.$_clientXAtMousedownOnSeparator;
          let timelineTitleWidthPx = this.$data.$_timelineTitleWidthPxAtMousedownOnSeparator + deltaX;
          timelineTitleWidthPx = utils.clamp(timelineTitleWidthPx, timelineTitleWidthPxMin, this.$_timelineTitleWidthMax);
          this.$_timelineTitleWidthPx = timelineTitleWidthPx;
        } else if (this.$data.$_timeMsecAndTimelinePositionAtMousedown) {
          let timelineContainerClampedLocalX = this.$_clampTimelineContainerLocalX(timelineContainerLocalX);
          let timelineContainerClampedLocalY = this.$_clampTimelineContainerLocalY(timelineContainerLocalY);
          let timelineCanvasLocalCoordsAtMousemove = new TimelineCanvasLocalCoords(timelineContainerClampedLocalX, timelineContainerClampedLocalY);
          let edgeInfoAtMousemove = new EdgeInfo(
            timelineCanvasLocalCoordsAtMousemove, this.$_timelineCanvasWidthPx, this.$_timelineCanvasContainerHeightPx);
          let scrollPending = getScrollPending(edgeInfoAtMousemove, timelineCanvasLocalCoordsAtMousemove);
          if (!scrollPending) {
            autoScrollX(edgeInfoAtMousemove);
            autoScrollY(edgeInfoAtMousemove);
          }
          this.$data.$_scrollPending = scrollPending;
          let timelinePosition = this.$_timelineContainerLocalYToTimelinePosition(timelineContainerClampedLocalY);
          let timeMsec = Math.round(this.$_timelineContainerLocalXToTimeMsec(timelineContainerClampedLocalX));
          let snappedTimelineContainerLocalX = this.$_getSnappedTimelineContainerLocalX(timelineContainerClampedLocalX);
          let snappedTimeMsec = Math.round(this.$_timelineContainerLocalXToTimeMsec(snappedTimelineContainerLocalX));
          if (!this.$data.$_isSubMouseButtonPressedAtMousedown) {
            this.$data.$_timeMsecAndTimelinePositionAtMousemove = new TimeMsecAndTimelinePosition(timeMsec, snappedTimeMsec, timelinePosition);
            if (this.$_timelineSegmentDomRectsToDragWithInfo) {
              let edgeType = this.$_timelineSegmentDomRectsToDragWithInfo.edgeType;
              let dragOffsetTimeMsec = snappedTimeMsec - this.$data.$_timeMsecAndTimelinePositionAtMousedown.timeMsec;
              this.$_setSegmentDragOffsetTimeMsec(dragOffsetTimeMsec, edgeType);
            }
          }
        }
      }

      function getScrollPending(edgeInfoAtMousemove, timelineCanvasLocalCoordsAtMousemove) {
        if (self.$data.$_scrollPending) {
          if (edgeInfoAtMousemove.isOnTopEdge) {
            if (self.$data.$_edgeInfoAtMousedown.isOnTopEdge) {
              if (timelineCanvasLocalCoordsAtMousemove.y < self.$_timelineCanvasLocalCoordsAtMousedown.y) {
                return false;
              }
            } else {
              return false;
            }
          } else if (edgeInfoAtMousemove.isOnBottomEdge) {
            if (self.$data.$_edgeInfoAtMousedown.isOnBottomEdge) {
              if (timelineCanvasLocalCoordsAtMousemove.y > self.$_timelineCanvasLocalCoordsAtMousedown.y) {
                return false;
              }
            } else {
              return false;
            }
          }
          if (edgeInfoAtMousemove.isOnRightEdge) {
            if (self.$data.$_edgeInfoAtMousedown.isOnRightEdge) {
              if (timelineCanvasLocalCoordsAtMousemove.x > self.$_timelineCanvasLocalCoordsAtMousedown.x) {
                return false;
              }
            } else {
              return false;
            }
          } else if (edgeInfoAtMousemove.isOnLeftEdge) {
            if (self.$data.$_edgeInfoAtMousedown.isOnLeftEdge) {
              if (timelineCanvasLocalCoordsAtMousemove.x < self.$_timelineCanvasLocalCoordsAtMousedown.x) {
                return false;
              }
            } else {
              return false;
            }
          }
        }
        return self.$data.$_scrollPending;
      }

      function autoScrollX(edgeInfoAtMousemove) {
        if (!self.$data.$_autoScrollXInterval) {
          if (edgeInfoAtMousemove.isOnRightEdge || edgeInfoAtMousemove.isOnLeftEdge) {
            self.$data.$_autoScrollXInterval = window.setInterval(
              () => {
                self.$_stepTimeOffset((edgeInfoAtMousemove.isOnRightEdge)? stepValueScale : -stepValueScale);
              },
              autoScrollMinimumInterval
            );
          }
        } else {
          if (!edgeInfoAtMousemove.isOnRightEdge && !edgeInfoAtMousemove.isOnLeftEdge) {
            window.clearInterval(self.$data.$_autoScrollXInterval);
            self.$data.$_autoScrollXInterval = null;
          }
        }
      }

      function autoScrollY(edgeInfoAtMousemove) {
        if (!self.$data.$_autoScrollYInterval) {
          if (edgeInfoAtMousemove.isOnTopEdge || edgeInfoAtMousemove.isOnBottomEdge) {
            self.$data.$_autoScrollYInterval = window.setInterval(
              () => {
                self.$_stepVerticalOffset((edgeInfoAtMousemove.isOnBottomEdge)? -stepValueScale : stepValueScale);
              },
              autoScrollMinimumInterval
            );
          }
        } else {
          if (!edgeInfoAtMousemove.isOnTopEdge && !edgeInfoAtMousemove.isOnBottomEdge) {
            window.clearInterval(self.$data.$_autoScrollYInterval);
            self.$data.$_autoScrollYInterval = null;
          }
        }
      }
    },

    $_isNotDisplayed() {
      return (this.$el.style.display === 'none');
    },

    $_updateTimelineContainerClass() {
      if (!this.$_isDraggingSegments) {
        let classNames = [];
        switch (this.$data.$_currentViewMode) {
        case viewMode.normal:
          classNames.push('timeline-canvas-view-mode-normal');
          break;
        case viewMode.select:
          classNames.push('timeline-canvas-view-mode-select');
          break;
        case viewMode.create:
          classNames.push('timeline-canvas-view-mode-create');
          break;
        case viewMode.divide:
          classNames.push('timeline-canvas-view-mode-divide');
          break;
        case viewMode.merge:
          classNames.push('timeline-canvas-view-mode-merge');
          break;
        case viewMode.position:
          classNames.push('timeline-canvas-view-mode-position');
          break;
        }

        if (this.$data.$_isMouseCursorOnScrollBarArea) {
          classNames.push('mouse-cursor-on-scroll-bar-area');
        } else if (this.$data.$_isMouseCursorOnRulerArea) {
          classNames.push('mouse-cursor-on-ruler-area');
        } else if (this.$data.$_isMouseCursorOnVisibleTimeline){
          classNames.push('mouse-cursor-on-timeline');
          if (this.$data.$_timelineSegmentIdWithInfoAtMouseCursor && !this.$data.$_timeMsecAndTimelinePositionAtMousemove) {
            let timelineSegmentIdAtMouseCursor = this.$data.$_timelineSegmentIdWithInfoAtMouseCursor.timelineSegmentId;
            if (this.$_isTimelineSegmentIdSelected(timelineSegmentIdAtMouseCursor)) {
              let isMouseCursorOnLeftEdge = this.$data.$_timelineSegmentIdWithInfoAtMouseCursor.isOnLeftEdge;
              let isMouseCursorOnRightEdge = this.$data.$_timelineSegmentIdWithInfoAtMouseCursor.isOnRightEdge;
              if (isMouseCursorOnLeftEdge || isMouseCursorOnRightEdge) {
                classNames.push('mouse-cursor-on-timeline-selected-segment-edge');
              } else {
                classNames.push('mouse-cursor-on-timeline-selected-segment');
              }
            } else {
              classNames.push('mouse-cursor-on-timeline-segment');
            }
          }
        }
        this.$data.$_timelineContainerClass = classNames;
      }
    },

    $_getSnappedTimelineContainerLocalX(timelineContainerLocalX) {
      let self = this;
      {
        if (!this.isSnapEnabled) return timelineContainerLocalX;
        let snapOffsetMinimum = null
        if (this.$_isPlayTimeInView) {
          let timelineContainerLocalXPlayTime = this.$_scaledTimeMsecToPx(this.playTimeMsec - this.$data.$_timelineViewBeginMsec);
          let snapOffsetToPlayTime = getSnapOffset(timelineContainerLocalXPlayTime, timelineContainerLocalX);
          snapOffsetMinimum = getUpdatedSnapOffsetMinimum(snapOffsetMinimum, snapOffsetToPlayTime);
        }
        if (this.$_isMarkerSet) {
          if (this.timelineMarker.beginTimeMsec !== null) {
            let timelineContainerLocalXMarkerBegin = this.$_scaledTimeMsecToPx(this.timelineMarker.beginTimeMsec - this.$data.$_timelineViewBeginMsec);
            let snapOffsetToMarkerBegin = getSnapOffset(timelineContainerLocalXMarkerBegin, timelineContainerLocalX);
            snapOffsetMinimum = getUpdatedSnapOffsetMinimum(snapOffsetMinimum, snapOffsetToMarkerBegin);
          }
          if (this.timelineMarker.endTimeMsec !== null) {
            let timelineContainerLocalXMarkerEnd = this.$_scaledTimeMsecToPx(this.timelineMarker.endTimeMsec - this.$data.$_timelineViewBeginMsec);
            let snapOffsetToMarkerEnd = getSnapOffset(timelineContainerLocalXMarkerEnd, timelineContainerLocalX);
            snapOffsetMinimum = getUpdatedSnapOffsetMinimum(snapOffsetMinimum, snapOffsetToMarkerEnd);
          }
        }
        for (let segmentDomRects of Object.values(this.$_timelineSegmentDomRects)) {
          for (let timelineSegmentDomRect of Object.values(segmentDomRects)) {
            let timelineSegmentBeginLocalX = timelineSegmentDomRect.x;
            if (timelineSegmentBeginLocalX >= 0) {
              let snapOffsetToTimelineSegmentBegin = getSnapOffset(timelineSegmentBeginLocalX, timelineContainerLocalX);
              snapOffsetMinimum = getUpdatedSnapOffsetMinimum(snapOffsetMinimum, snapOffsetToTimelineSegmentBegin);
            }
            let timelineSegmentEndLocalX = timelineSegmentDomRect.x + timelineSegmentDomRect.width;
            if (timelineSegmentEndLocalX <= this.$_timelineCanvasWidthPx) {
              let snapOffsetToTimelineSegmentEnd = getSnapOffset(timelineSegmentEndLocalX, timelineContainerLocalX);
              snapOffsetMinimum = getUpdatedSnapOffsetMinimum(snapOffsetMinimum, snapOffsetToTimelineSegmentEnd);
            }
          }
        }
        return timelineContainerLocalX + ((snapOffsetMinimum === null)? 0 : snapOffsetMinimum);
      }

      function getUpdatedSnapOffsetMinimum(currentSnapOffsetMinimum, snapOffset) {
        if (currentSnapOffsetMinimum === null) {
          return snapOffset;
        } else {
          if (snapOffset === null) {
            return currentSnapOffsetMinimum;
          } else {
            let currentSnapOffsetMinimumAmount = Math.abs(currentSnapOffsetMinimum);
            let snapOffsetAmount = Math.abs(snapOffset);
            return (snapOffsetAmount < currentSnapOffsetMinimumAmount)? snapOffset : currentSnapOffsetMinimum;
          }
        }
      }

      function getSnapOffset(snapTargetTimelineContainerLocalX, timelineContainerLocalX) {
        let snapBaseTimelineContainerLocalX = null;
        let offsetPxBySnapBase = null;
        if (self.$data.$_timelineSegmentIdWithInfoAtMousedown && self.$_isDraggingSegments) {
          if (self.$data.$_timelineSegmentIdWithInfoAtMousedown.isOnRightEdge) {
            offsetPxBySnapBase = self.$data.$_timelineSegmentIdWithInfoAtMousedown.offsetPxByRightEdge;
            snapBaseTimelineContainerLocalX = timelineContainerLocalX - offsetPxBySnapBase;
          } else if (self.$data.$_timelineSegmentIdWithInfoAtMousedown.isOnLeftEdge) {
            offsetPxBySnapBase = self.$data.$_timelineSegmentIdWithInfoAtMousedown.offsetPxByLeftEdge;
            snapBaseTimelineContainerLocalX = timelineContainerLocalX - offsetPxBySnapBase;
          } else {
            let offsetPxByLeftEdge = self.$data.$_timelineSegmentIdWithInfoAtMousedown.offsetPxByLeftEdge;
            let snapBaseCandidateTimelineContainerLocalXLeftEdge = timelineContainerLocalX - offsetPxByLeftEdge;
            let offsetPxByRightEdge = self.$data.$_timelineSegmentIdWithInfoAtMousedown.offsetPxByRightEdge;
            let snapBaseCandidateTimelineContainerLocalXRightEdge = timelineContainerLocalX - offsetPxByRightEdge;
            let distanceBetweenSnapBaseCandidateLeftEdgeAndSnapTarget = Math.abs(snapTargetTimelineContainerLocalX - snapBaseCandidateTimelineContainerLocalXLeftEdge);
            let distanceBetweenSnapBaseCandidateRightEdgeAndSnapTarget = Math.abs(snapTargetTimelineContainerLocalX - snapBaseCandidateTimelineContainerLocalXRightEdge);
            if (distanceBetweenSnapBaseCandidateLeftEdgeAndSnapTarget < distanceBetweenSnapBaseCandidateRightEdgeAndSnapTarget) {
              offsetPxBySnapBase = offsetPxByLeftEdge;
              snapBaseTimelineContainerLocalX = snapBaseCandidateTimelineContainerLocalXLeftEdge;
            } else {
              offsetPxBySnapBase = offsetPxByRightEdge;
              snapBaseTimelineContainerLocalX = snapBaseCandidateTimelineContainerLocalXRightEdge;
            }
          }
        } else {
          snapBaseTimelineContainerLocalX = timelineContainerLocalX;
          offsetPxBySnapBase = 0;
        }
        let distanceBetweenSnapBaseAndSnapTarget = Math.abs(snapTargetTimelineContainerLocalX - snapBaseTimelineContainerLocalX);
        if (distanceBetweenSnapBaseAndSnapTarget < snapWidthPx) {
          return snapTargetTimelineContainerLocalX + offsetPxBySnapBase - timelineContainerLocalX;
        } else {
          return null;
        }
      }
    },

    $_getTimelineCanvasLocalXOrMarkerBeginLocalX(timeMsecAndTimelinePosition) {
      let timelineCanvasLocalX = this.$_timeMsecToTimelineContainerLocalX(timeMsecAndTimelinePosition.snappedTimeMsec);
      if (this.$data.$_isMousedownOnRulerArea) return timelineCanvasLocalX;
      let isTimeMsecInMarker = this.$_isTimeMsecInMarker(timeMsecAndTimelinePosition.timeMsec);
      let isCurrentViewModeCreate = (this.$data.$_currentViewMode === viewMode.create);
      if (isCurrentViewModeCreate && isTimeMsecInMarker) {
        if (this.timelineMarker.beginTimeMsec === null) {
          return null;
        } else {
          return this.$_scaledTimeMsecToPx(this.timelineMarker.beginTimeMsec - this.$data.$_timelineViewBeginMsec);
        }
      } else {
        return timelineCanvasLocalX;
      }
    },

    $_getTimelineCanvasLocalXOrMarkerEndLocalX(timeMsecAndTimelinePosition) {
      let timelineCanvasLocalX = this.$_timeMsecToTimelineContainerLocalX(timeMsecAndTimelinePosition.snappedTimeMsec);
      if (!this.$data.$_timeMsecAndTimelinePositionAtMousedown) return timelineCanvasLocalX;
      if (this.$data.$_isMousedownOnRulerArea) return timelineCanvasLocalX;
      let timeMsecOnMousedown = this.$data.$_timeMsecAndTimelinePositionAtMousedown.timeMsec;
      let isTimeMsecOnMousedownInMarker = this.$_isTimeMsecInMarker(timeMsecOnMousedown);
      let isTimeMsecInMarker = this.$_isTimeMsecInMarker(timeMsecAndTimelinePosition.timeMsec);
      let isCurrentViewModeCreate = (this.$data.$_currentViewMode === viewMode.create);
      if (isCurrentViewModeCreate && isTimeMsecOnMousedownInMarker && isTimeMsecInMarker) {
        if (this.timelineMarker.endTimeMsec === null) {
          return null;
        } else {
          return this.$_scaledTimeMsecToPx(this.timelineMarker.endTimeMsec - this.$data.$_timelineViewBeginMsec);
        }
      } else {
        return timelineCanvasLocalX;
      }
    },

    $_isTimeMsecInMarker(timeMsec) {
      if (!this.$_isMarkerSet) return false;
      let isTimeMsecEqualsToOrMoreThanMarkerBegin = (this.timelineMarker.beginTimeMsec === null)? true : (timeMsec >= this.timelineMarker.beginTimeMsec);
      let isTimeMsecLessThanMarkerEnd = (this.timelineMarker.endTimeMsec === null)? true : (timeMsec <= this.timelineMarker.endTimeMsec);
      return isTimeMsecEqualsToOrMoreThanMarkerBegin && isTimeMsecLessThanMarkerEnd;
    },

    $_getVisibleTimelineDataIdAtTimelineCanvasLocalY(timelineCanvasLocalY) {
      let mousedownVisibleVerticalOffsetPx = timelineCanvasLocalY - this.$data.$_verticalOffsetPx;
      let mousedownVisibleTimelineDataIdx = Math.floor(mousedownVisibleVerticalOffsetPx / this.$_timelineHeightPx);
      if ((mousedownVisibleTimelineDataIdx < 0) || (mousedownVisibleTimelineDataIdx >= this.$_numVisibleTimelines)) return null;
      return this.visibleTimelineDataIdxToId[mousedownVisibleTimelineDataIdx];
    },

    $_resetOnMouseEvent() {
      this.$data.$_clientXAtMousedownOnSeparator = null,
      this.$data.$_timelineTitleWidthPxAtMousedownOnSeparator = null;
      this.$data.$_timeMsecAndTimelinePositionAtMousedown = null;
      this.$data.$_timeScrollBarOffsetPxAtMousedown = null;
      this.$data.$_timelineViewCenterMsecAtMousedown = null;
      this.$data.$_timeMsecAndTimelinePositionAtMousemove = null;
      this.$data.$_timelineSegmentIdWithInfoAtMousedown = null;
      this.$data.$_edgeInfoAtMousedown = null;
      this.$data.$_isMousedownOnRulerArea = false;
      this.$data.$_isSubMouseButtonPressedAtMousedown = false;
      this.$data.$_scrollPending = false;
      if (this.$data.$_autoScrollXInterval) {
        window.clearInterval(this.$data.$_autoScrollXInterval);
        this.$data.$_autoScrollXInterval = null;
      }
      if (this.$data.$_autoScrollYInterval) {
        window.clearInterval(this.$data.$_autoScrollYInterval);
        this.$data.$_autoScrollYInterval = null;
      }
      this.$data.$_segmentDragOffsetTimeMsec = 0;
    },

    $_setViewMode(currentViewMode) {
      this.$data.$_currentViewMode = currentViewMode;
    },

    $_toggleSnap() {
      this.$emit('update-is-snap-enabled', !this.isSnapEnabled);
    },

    $_toggleMouseMode() {
      this.$emit('update-is-mouse-mode', !this.isMouseMode);
    },

    $_isTimelineSegmentVisible(timelineSegmentId) {
      let timelineDataId = timelineSegmentId.timelineDataId;
      if (Object.keys(this.visibleTimelineDataSet).includes(timelineDataId)) {
        let visibleTimelineData = this.visibleTimelineDataSet[timelineSegmentId.timelineDataId];
        let segmentId = timelineSegmentId.segmentId;
        if (Object.keys(visibleTimelineData.segments).includes(segmentId)) {
          return true;
        }
      }
      return false;
    },

    $_findFirstVisibleTimelineSegmentId() {
      for (let [ timelineDataId, sortedVisibleSegmentIdxToIdMap ] of Object.entries(this.sortedVisibleSegmentIdxToIdMaps)) {
        let numSegments = sortedVisibleSegmentIdxToIdMap.length;
        for (let sortedSegmentIdx = 0; sortedSegmentIdx < numSegments; ++sortedSegmentIdx) {
          let segmentId = sortedVisibleSegmentIdxToIdMap[sortedSegmentIdx];
          return new TimelineSegmentId(timelineDataId, segmentId);
        }
      }
      return null;
    },

    $_forceTimelineSegmentDomRectBeInTimelineView(timelineSegmentId) {
      let offsetRect = this.$refs.timelineSegmentCanvas.getOffsetForRectTimelineSegmentToBeInCanvas(timelineSegmentId);
      if (offsetRect) {
        let timelineViewOffsetMsec = this.$_pxToScaledTimeMsec(offsetRect.x);
        let newTimelineViewBeginMsec = this.$_clampTimelineViewBeginMsec(this.$data.$_timelineViewBeginMsec + timelineViewOffsetMsec);
        this.$_setTimelineViewBeginMsec(newTimelineViewBeginMsec);
        let newVerticalOffsetPx = this.$_clampVerticalOffsetPx(this.$data.$_verticalOffsetPx + offsetRect.y);
        this.$_setVerticalOffsetPx(newVerticalOffsetPx);
      }
    },

    $_forceTimeMsecBeCenterInTimelineView(timeMsec) {
      let newTimelineViewBeginMsec = this.$_clampTimelineViewBeginMsec(timeMsec - (this.$_timelineViewDurationMsec / 2));
      this.$_setTimelineViewBeginMsec(newTimelineViewBeginMsec);
    },

    $_updateNearestTimelineSegmentBaseTimeMsec(timelineSegmentId) {
      let self = this;
      {
        let timelineDataId = timelineSegmentId.timelineDataId;
        let segmentId = timelineSegmentId.segmentId;
        let segment = this.visibleTimelineDataSet[timelineDataId].segments[segmentId];
        if (segment === null) {
          logger.warn(this.visibleTimelineDataSet[timelineDataId], timelineDataId, segmentId);
        }
        this.$data.$_timelineSegmentNearestBaseTimeMsec = getSegmentCenter(segment);
      }

      function getSegmentCenter(segment) {
        let begin = (segment.begin === null)? 0 : segment.begin;
        let end = (segment.end === null)? self.durationMsec : segment.end;
        return (begin + end) / 2;
      }
    },

    $_visuallyOffsetTimelineSegmentId(targetTimelineSegmentId, offsetAmount) {
      let lastSelectedTimelineDataId = targetTimelineSegmentId.timelineDataId;
      let lastSelectedSegmentId = targetTimelineSegmentId.segmentId;
      let visibleSegmentIdToSortedIdxMap = this.visibleSegmentIdToSortedIdxMaps[lastSelectedTimelineDataId];
      let numSegments = Object.keys(visibleSegmentIdToSortedIdxMap).length;
      let lastSelectedSegmentIdxInSortedMap = visibleSegmentIdToSortedIdxMap[lastSelectedSegmentId];
      let offsetSegmentIdx = utils.clamp(lastSelectedSegmentIdxInSortedMap + offsetAmount, 0, numSegments - 1);
      let offsetSegmentId = this.sortedVisibleSegmentIdxToIdMaps[lastSelectedTimelineDataId][offsetSegmentIdx];
      this.$_selectTimelineSegmentCore(new TimelineSegmentId(lastSelectedTimelineDataId, offsetSegmentId));
    },

    $_selectTimelineSegmentCore(
      timelineSegmentId,
      {
        retainsViewMode = false,
        retainsSelection = false,
        updatesNearestTimelineSegmentBase = true,
        forcesToBeInTimelineView = true,
      } = {}
    ) {
      this.$_selectTimelineSegmentsCore(
        [ timelineSegmentId ],
        {
          retainsViewMode,
          retainsSelection,
          updatesNearestTimelineSegmentBase,
          forcesToBeInTimelineView,
        },
      );
    },

    $_selectTimelineSegmentsCore(
      timelineSegmentIds,
      {
        retainsViewMode = false,
        retainsSelection = false,
        updatesNearestTimelineSegmentBase = true,
        forcesToBeInTimelineView = true,
      } = {}
    ) {
      if (!retainsViewMode) {
        this.$_setViewMode(viewMode.select);
      }
      if (!retainsSelection) {
        this.clearSelectedTimelineSegments();
      }
      this.selectTimelineSegments(...timelineSegmentIds);
      if (forcesToBeInTimelineView || forcesToBeInTimelineView) {
        let firstSelectedTimelineSegmentId = this.$_findLastTimelineSegmentId(timelineSegmentIds);

        if (forcesToBeInTimelineView) {
          this.$_forceTimelineSegmentDomRectBeInTimelineView(firstSelectedTimelineSegmentId);
        }

        if (updatesNearestTimelineSegmentBase) {
          this.$_updateNearestTimelineSegmentBaseTimeMsec(firstSelectedTimelineSegmentId);
        }
      }
    },

    $_isAnyTimelineSegmentIdSelected() {
      return (this.selectedTimelineSegmentIds.length > 0);
    },

    $_peakLastSelectedTimelineSegmentId() {
      if (!this.$_isAnyTimelineSegmentIdSelected()) return null;
      return this.selectedTimelineSegmentIds[this.selectedTimelineSegmentIds.length - 1];
    },

    $_isNoTimelineSegmentIdSelected() {
      return (this.selectedTimelineSegmentIds.length === 0);
    },

    $_getOnlyOneSelectedTimelineSegmentId() {
      if (this.selectedTimelineSegmentIds.length === 1) {
        return this.$_peakLastSelectedTimelineSegmentId();
      } else {
        return null;
      }
    },

    $_isTimelineSegmentIdSelected(timelineSegmentId) {
      for (let selectedTimelineSegmentId of this.selectedTimelineSegmentIds) {
        if (selectedTimelineSegmentId.isSame(timelineSegmentId)) {
          return true;
        }
      }
      return false;
    },

    $_findFirstTimelineSegmentId(targetTimelineSegmentIds) {
      for (let timelineDataId of this.visibleTimelineDataIdxToId) {
        for (let segmentId of this.sortedVisibleSegmentIdxToIdMaps[timelineDataId]) {
          for (let targetTimelineSegmentId of targetTimelineSegmentIds) {
            if (timelineDataId === targetTimelineSegmentId.timelineDataId) {
              if (segmentId === targetTimelineSegmentId.segmentId) {
                return targetTimelineSegmentId;
              }
            }
          }
        }
      }
      return null;
    },

    $_findLastTimelineSegmentId(targetTimelineSegmentIds) {
      for (let timelineDataId of this.visibleTimelineDataIdxToId) {
        let reversedSortedVisibleSegmentIdxToId = Array.from(this.sortedVisibleSegmentIdxToIdMaps[timelineDataId]).reverse();
        for (let segmentId of reversedSortedVisibleSegmentIdxToId) {
          for (let targetTimelineSegmentId of targetTimelineSegmentIds) {
            if (timelineDataId === targetTimelineSegmentId.timelineDataId) {
              if (segmentId === targetTimelineSegmentId.segmentId) {
                return targetTimelineSegmentId;
              }
            }
          }
        }
      }
      return null;
    },

    $_pxToScaledTimeMsec(px) {
      return utils.resolve(px, this.$data.$_timeResolution);
    },

    $_scaledTimeMsecToPx(timeMsec) {
      return utils.unitize(timeMsec, this.$data.$_timeResolution);
    },

    $_timelineContainerLocalXToTimeMsec(timelineContainerLocalX) {
      return this.$data.$_timelineViewBeginMsec + utils.resolve(timelineContainerLocalX, this.$data.$_timeResolution);
    },

    $_timeMsecToTimelineContainerLocalX(timeMsec) {
      return utils.unitize(timeMsec - this.$data.$_timelineViewBeginMsec, this.$data.$_timeResolution);
    },

    $_timelineContainerLocalYToTimelinePosition(timelineContainerLocalY) {
      return utils.resolve(timelineContainerLocalY - this.$data.$_verticalOffsetPx, this.$data.$_timelinePositionResolution);
    },

    $_timelinePositionToTimelineContainerLocalY(timelinePosition) {
      return utils.unitize(timelinePosition, this.$data.$_timelinePositionResolution) + this.$data.$_verticalOffsetPx;
    },

    $_stepPageTimeOffset(inverted) {
      let timeStep = this.$_timelineCanvasWidthPx * ((inverted)? -1 : 1);
      let steppedTimelineViewBeginMsec = this.$_calculateSteppedTimelineViewBeginMsec(timeStep);
      this.$_setTimelineViewBeginMsec(this.$_clampTimelineViewBeginMsec(steppedTimelineViewBeginMsec));
    },

    $_stepTimeOffset(scale) {
      let timeStep = timeStepPxOnScroll * scale;
      let steppedTimelineViewBeginMsec = this.$_calculateSteppedTimelineViewBeginMsec(timeStep);
      this.$_setTimelineViewBeginMsec(this.$_clampTimelineViewBeginMsec(steppedTimelineViewBeginMsec));
    },

    $_stepVerticalOffset(scale) {
      let verticalStepPx = verticalStepPxOnScroll * scale;
      let steppedVerticalOffsetPx = this.$data.$_verticalOffsetPx + verticalStepPx;
      this.$_setVerticalOffsetPx(this.$_clampVerticalOffsetPx(steppedVerticalOffsetPx));
    },

    $_setTimelineViewBeginMsec(newTimelineViewBeginMsec) {
      this.$data.$_timelineViewBeginMsec = newTimelineViewBeginMsec;
    },

    $_setVerticalOffsetPx(newVerticalOffsetPx) {
      newVerticalOffsetPx = Math.round(newVerticalOffsetPx);
      this.$data.$_verticalOffsetPx = newVerticalOffsetPx;
    },

    $_initializeView() {
      this.$_updateViewSize();
      this.$data.$_timeResolution = this.$data.$_timeResolutionMin;
      this.$data.$_timelineViewBeginMsec = this.$_timelineViewBeginMsecMin;
    },

    $_updateViewSize() {
      this.$data.$_timelineCanvasContainerBoundingClientRect = this.$refs.timelineCanvasContainer.getBoundingClientRect();
      this.$data.$_timelineScrollBarWidthPx = this.$refs.timeScrollBarCanvas.$el.getBoundingClientRect().height;
      this.$data.$_timelineListContainerWidthPx = this.$el.clientWidth;
      let timelineViewBeginMsecMin = -(timelineViewPreBeginMarginPxMax * this.durationMsec) / (this.$_timelineCanvasWidthPx - timelineViewPreBeginMarginPxMax);
      let timeResolutionMin = this.$_timelineCanvasWidthPx / (this.durationMsec - timelineViewBeginMsecMin);
      let currentTimeResolution = this.$data.$_timeResolution;
      let newTimeResolution = (currentTimeResolution < timeResolutionMin)? timeResolutionMin : currentTimeResolution;
      this.$data.$_timeResolutionMin = timeResolutionMin;
      this.$data.$_timeResolution = newTimeResolution;
      this.$_timelineTitleWidthPx = utils.clamp(this.$_timelineTitleWidthPx, timelineTitleWidthPxMin, this.$_timelineTitleWidthMax);
      this.$_setTimelineViewBeginMsec(this.$_clampTimelineViewBeginMsec(this.$data.$_timelineViewBeginMsec));
    },

    $_getMenuContentsTimelineTitle(visibleTimelineDataIdx) {
      let self = this;
      {
        let timelineDataId = this.visibleTimelineDataIdxToId[visibleTimelineDataIdx];
        let isTimelineDataLocked = this.visibleTimelineDataSet[timelineDataId].locked;
        let isTimelineDataReadonly = this.visibleTimelineDataSet[timelineDataId].readonly;
        let isTimelineDataImmutable = isTimelineDataReadonly || isTimelineDataLocked;
        let isFirstVisibleTimeline = (visibleTimelineDataIdx === 0);
        let isLastVisibleTimeline = (visibleTimelineDataIdx === (this.visibleTimelineDataIdxToId.length - 1));
        let isNotMarkerSet = !this.$_isMarkerSet;
        return [
          new TimelineListViewMenuContent(
            'Select all segments',
            this.$_selectAllVisibleSegments,
            false
          ),
          new TimelineListViewMenuContent(
            'Create new segment on marker',
            createNewSegmentOnMarker,
            isNotMarkerSet || isTimelineDataImmutable
          ),
          new TimelineListViewMenuContent(
            'Remove timeline',
            removeTimeline,
            isTimelineDataImmutable
          ),
          new TimelineListViewMenuContent(
            'Hide timeline',
            hideTimeline,
            false
          ),
          new TimelineListViewMenuContent(
            'Rename timeline',
            renameTimelineId,
            isTimelineDataImmutable
          ),
          new TimelineListViewMenuContent(
            'Duplicate timeline',
            duplicateTimeline,
            false
          ),
          new TimelineListViewMenuContent(
            'Move up',
            moveUp,
            isFirstVisibleTimeline
          ),
          new TimelineListViewMenuContent(
            'Move down',
            moveDown,
            isLastVisibleTimeline
          ),
        ];
      }

      async function createNewSegmentOnMarker(visibleTimelineDataIdx) {
        let timelineDataId = self.visibleTimelineDataIdxToId[visibleTimelineDataIdx];
        let newTimelineSegmentId = await self.createSegment(timelineDataId, Math.round(self.timelineMarker.beginTimeMsec), Math.round(self.timelineMarker.endTimeMsec));
        if (newTimelineSegmentId !== null) {
          self.$_selectTimelineSegmentCore(newTimelineSegmentId, { retainsViewMode: true });
        }
      }

      function removeTimeline(visibleTimelineDataIdx) {
        let timelineDataId = self.visibleTimelineDataIdxToId[visibleTimelineDataIdx];
        self.removeTimelineData(timelineDataId);
      }

      function hideTimeline(visibleTimelineDataIdx) {
        let timelineDataId = self.visibleTimelineDataIdxToId[visibleTimelineDataIdx];
        self.hideTimelineData(timelineDataId);
      }

      function duplicateTimeline(visibleTimelineDataIdx) {
        let timelineDataId = self.visibleTimelineDataIdxToId[visibleTimelineDataIdx];
        self.duplicateTimelineData(timelineDataId);
      }

      async function renameTimelineId(visibleTimelineDataIdx) {
        let timelineDataId = self.visibleTimelineDataIdxToId[visibleTimelineDataIdx];
        await self.renameTimelineData(timelineDataId);
      }

      function moveUp(visibleTimelineDataIdx) {
        self.incrementVisibleTimelineDataIdx(visibleTimelineDataIdx, true);
      }

      function moveDown(visibleTimelineDataIdx) {
        self.incrementVisibleTimelineDataIdx(visibleTimelineDataIdx, false);
      }
    },

    $_selectAllVisibleSegments(timelineDataIdx) {
      let visibleTimelineDataId = this.visibleTimelineDataIdxToId[timelineDataIdx];
      let visibleTimelineData = this.visibleTimelineDataSet[visibleTimelineDataId];
      let visibleSegmentIds = Object.keys(visibleTimelineData.segments);
      let visibleTimelineSegmentIds = visibleSegmentIds.map(visibleSegmentId => new TimelineSegmentId(visibleTimelineDataId, visibleSegmentId));
      let lastVisibleTimelineSegmentId = visibleTimelineSegmentIds[visibleTimelineSegmentIds.length - 1];
      if (lastVisibleTimelineSegmentId) {
        this.$_selectTimelineSegmentsCore(visibleTimelineSegmentIds);
      }
    },

    $_clampTimelineViewBeginMsec(timelineViewBeginMsec) {
      let timelineViewEndMsec = timelineViewBeginMsec + this.$_timelineViewDurationMsec;
      if (timelineViewEndMsec > this.durationMsec) {
        let overrunDurationMsec = timelineViewEndMsec - this.durationMsec;
        timelineViewBeginMsec -= overrunDurationMsec;
      }
      if (timelineViewBeginMsec < this.$_timelineViewBeginMsecMin) {
        timelineViewBeginMsec = this.$_timelineViewBeginMsecMin;
      }
      return timelineViewBeginMsec;
    },

    $_clampVerticalOffsetPx(verticalOffsetPx) {
      if (verticalOffsetPx > rulerVerticalOffsetPx) {
        verticalOffsetPx = rulerVerticalOffsetPx;
      }
      if (verticalOffsetPx < this.$_verticalOffsetPxMin) {
        verticalOffsetPx = this.$_verticalOffsetPxMin;
      }
      return verticalOffsetPx;
    },

    $_clampTimelinePositionResolution(timelinePositionResolution) {
      if (timelinePositionResolution < timelinePositionResolutionMin) {
        timelinePositionResolution = timelinePositionResolutionMin;
      }
      if (timelinePositionResolution > timelinePositionResolutionMax) {
        timelinePositionResolution = timelinePositionResolutionMax;
      }
      return timelinePositionResolution;
    },

    $_clampTimeResolution(timeResolution) {
      if (timeResolution < this.$data.$_timeResolutionMin) {
        timeResolution = this.$data.$_timeResolutionMin;
      }
      if (timeResolution > timeResolutionMax) {
        timeResolution = timeResolutionMax;
      }
      return timeResolution;
    },

    $_clampTimelineContainerLocalX(timelineContainerLocalX) {
      let minimumTimelineContainerLocalX = null;
      if (this.$data.$_timelineViewBeginMsec < timelineViewTimeOriginMsec) {
        minimumTimelineContainerLocalX = this.$_scaledTimeMsecToPx(timelineViewTimeOriginMsec - this.$data.$_timelineViewBeginMsec);
      } else {
        minimumTimelineContainerLocalX = 0;
      }
      return utils.clamp(timelineContainerLocalX, minimumTimelineContainerLocalX, this.$_timelineCanvasWidthPx);
    },

    $_clampTimelineContainerLocalY(timelineContainerLocalY) {
      return utils.clamp(timelineContainerLocalY, rulerVerticalOffsetPx, this.$_timelineCanvasHeightPx);
    },

    $_calculateTimelineViewBeginMsecWithFixedOffsetMsec(
      currentFixedTimelineViewOffsetMsec, nextFixedTimelineViewOffsetMsec)
    {
      /*************************************************
       * Ex) Current-scaled Duration[msec]: 12
       *     Double-scaled Duration[msec]:  6
       *
       *     Begin     Fixed
       *     |=====12====|-------
       *     ------|==6==|-------
       * 
       *     |==x==| x(compensating) = 12(current) - 6(doubled)
       *************************************************/
      let timelineViewBeginCompensatingOffsetMsec = currentFixedTimelineViewOffsetMsec - nextFixedTimelineViewOffsetMsec;
      return this.$data.$_timelineViewBeginMsec + timelineViewBeginCompensatingOffsetMsec;
    },

    $_calculateVerticalOffsetPxWithFixedOffsetPx(
      currentFixedTimelineViewVerticalOffsetPx, nextFixedVerticalOffsetPx)
    {
      /*************************************************
       * Ex) Current-scaled Offset[px]: 10
       *     Double-scaled Offset[px]:  5
       *
       *     VerticalOffset
       *     |             Origin
       *     |             |         Current Timeline View Vertical Offset
       *     |======14=====|====10===|
       *     ------------|=====12====|
       * 
       *     ------------|x| x(compensating) = 10 - 12
       *************************************************/
      return currentFixedTimelineViewVerticalOffsetPx - nextFixedVerticalOffsetPx;
    },

    $_calculateSteppedTimelineViewBeginMsec(timeStepPx) {
      let scaledTimeStepMsec = this.$_pxToScaledTimeMsec(timeStepPx);
      return this.$data.$_timelineViewBeginMsec + scaledTimeStepMsec;
    },

    $_updateTimelineViewBeginMsecAndTimeResolution(currentFixedTimelineViewOffsetPx, nextTimeResolution) {
      let currentTimeResolution = this.$data.$_timeResolution;
      nextTimeResolution = this.$_clampTimeResolution(nextTimeResolution);
      let currentFixedTimelineViewOffsetMsec = utils.resolve(currentFixedTimelineViewOffsetPx, currentTimeResolution);
      let nextTimelineViewBeginMsec = null;
      if ((this.$data.$_timelineViewBeginMsec + currentFixedTimelineViewOffsetMsec) < timelineViewTimeOriginMsec) {
        let timelineViewPreBeginPxAtCurrentTimeResolution = utils.unitize(this.$data.$_timelineViewBeginMsec, currentTimeResolution);
        let timelineViewPreBeginMsecAtNextTimeResolution = utils.resolve(timelineViewPreBeginPxAtCurrentTimeResolution, nextTimeResolution);
        nextTimelineViewBeginMsec = timelineViewPreBeginMsecAtNextTimeResolution;
      } else {
        let nextFixedTimelineViewOffsetMsec = utils.resolve(currentFixedTimelineViewOffsetPx, nextTimeResolution);
        nextTimelineViewBeginMsec = this.$_calculateTimelineViewBeginMsecWithFixedOffsetMsec(
          currentFixedTimelineViewOffsetMsec, nextFixedTimelineViewOffsetMsec);
      }
      this.$data.$_timeResolution = nextTimeResolution;
      this.$_setTimelineViewBeginMsec(this.$_clampTimelineViewBeginMsec(nextTimelineViewBeginMsec));
    },

    $_updateVerticalOffsetPxAndTimelinePositionResolution(currentFixedTimelineViewVerticalOffsetPx, nextTimelinePositionResolution) {
      let currentTimelinePositionResolution = this.$data.$_timelinePositionResolution;
      nextTimelinePositionResolution = this.$_clampTimelinePositionResolution(nextTimelinePositionResolution);
      let nextVerticalRatio = nextTimelinePositionResolution / currentTimelinePositionResolution;
      let currentFixedVerticalOffsetPx = currentFixedTimelineViewVerticalOffsetPx - this.$data.$_verticalOffsetPx;
      let nextFixedVerticalOffsetPx = currentFixedVerticalOffsetPx * nextVerticalRatio;
      let nextVerticalOffsetPx = this.$_calculateVerticalOffsetPxWithFixedOffsetPx(currentFixedTimelineViewVerticalOffsetPx, nextFixedVerticalOffsetPx);
      this.$data.$_timelinePositionResolution = nextTimelinePositionResolution;
      this.$_setVerticalOffsetPx(this.$_clampVerticalOffsetPx(nextVerticalOffsetPx));
    },

    $_toggleSetOrUnsetRealtimeSegmentCreationTarget(timelineDataId) {
      if (this.$data.$_isRealtimeSegmentCreationTarget[timelineDataId]) {
        this.$data.$_isRealtimeSegmentCreationTarget[timelineDataId] = false;
      } else {
        let timelineData = this.visibleTimelineDataSet[timelineDataId];
        let immutableReason = this.getTimelineDataImmutableReason(timelineData, false);
        if (immutableReason !== null) {
          this.registerFooterMessage(immutableReason);
        } else {
          this.$data.$_isRealtimeSegmentCreationTarget[timelineDataId] = true;
        }
      }
    },

    $_recordRealtimeSegmentBegin() {
      this.setMarker(this.playTimeMsec, this.playTimeMsec);
      this.$data.$_isRealtimeSegmentOpen = true;
    },

    $_recordRealtimeSegmentEnd() {
      if (!this.$data.$_isRealtimeSegmentOpen) return;
      if (this.$_isAnyTimelineRealtimeSegmentCreationTarget) {
        if (this.timelineMarker.durationMsec < this.minimumSegmentDurationMsec) {
          this.registerFooterMessage('Segment is too short.');
        } else {
          this.atomicChanges(
            'setting segment',
            'Segment set.',
            () => {
              for (let timelineDataId of this.$_realtimeSegmentCreationTargetTimelineDataIds) {
                let timelineData = this.visibleTimelineDataSet[timelineDataId];
                let newSegment = timelineData.segmentDataType.generateEmpty(this.timelineMarker.beginTimeMsec, this.timelineMarker.endTimeMsec);
                this.setNewSegment(
                  timelineDataId,
                  timelineData.segmentDataType,
                  newSegment,
                );
              }
            },
          );
        }
      }
      this.$data.$_isRealtimeSegmentOpen = false;
    },
  }
};
</script>
function alwaysAvailable(timelineDataSet) {
  return ((timelineDataSet !== null) && (timelineDataSet !== undefined));
}

class EditorDefinition {
  constructor(componentName, title, isAvailable = alwaysAvailable) {
    this.componentName = componentName;
    this.title = title;
    this.isAvailable = isAvailable;
  }
}

export default EditorDefinition;

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "flex-grow-1" },
            [
              _c("label-sequence-input-field", {
                attrs: {
                  delimiter: _vm.$data.$_labelSequenceDelimiter,
                  "label-options": _vm.labelOptions,
                  "selected-audio-segment-idcs":
                    _vm.$data.$_selectedAudioSegmentIdcs,
                },
                model: {
                  value: _vm.$data.$_labelSequence,
                  callback: function ($$v) {
                    _vm.$set(_vm.$data, "$_labelSequence", $$v)
                  },
                  expression: "$data.$_labelSequence",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "flex-grow-0" },
            [
              _c("label-sequence-delimiter-input-field", {
                model: {
                  value: _vm.$data.$_labelSequenceDelimiter,
                  callback: function ($$v) {
                    _vm.$set(_vm.$data, "$_labelSequenceDelimiter", $$v)
                  },
                  expression: "$data.$_labelSequenceDelimiter",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("time-unit-selector", {
                staticClass: "mb-3",
                model: {
                  value: _vm.$data.$_timeUnit,
                  callback: function ($$v) {
                    _vm.$set(_vm.$data, "$_timeUnit", $$v)
                  },
                  expression: "$data.$_timeUnit",
                },
              }),
              _vm.$data.$_audioSegmentSequence.samplingRate !== null
                ? _c("audio-segment-list", {
                    attrs: {
                      id: "audio-segment-list",
                      "selected-audio-segment-idcs":
                        _vm.$data.$_selectedAudioSegmentIdcs,
                      "label-sequence": _vm.$data.$_labelSequence,
                      "label-options": _vm.labelOptions,
                      "time-unit": _vm.$data.$_timeUnit,
                    },
                    on: { "update-label-sequence": _vm.$_updateLabelSequence },
                    model: {
                      value: _vm.$data.$_audioSegmentSequence,
                      callback: function ($$v) {
                        _vm.$set(_vm.$data, "$_audioSegmentSequence", $$v)
                      },
                      expression: "$data.$_audioSegmentSequence",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _vm.$_isAudioLoaded
                ? _c("audio-player", {
                    attrs: {
                      "audio-context": _vm.$data.$_audioContext,
                      "audio-buffer": _vm.$data.$_audioBuffer,
                      "loop-definition":
                        _vm.$data.$_audioPlaybackLoopDefinition,
                      "is-loop-enabled": _vm.$data.$_isLoopPlaybackEnabled,
                    },
                    on: {
                      "register-component-instance":
                        _vm.$_registerAudioPlayerComponentInstance,
                      "unregister-component-instance":
                        _vm.$_unregisterAudioPlayerComponentInstance,
                      "update-play-time": _vm.$_onUpdatePlayTime,
                    },
                  })
                : _vm._e(),
              _vm.$_isAudioLoaded
                ? _c("waveform-view", {
                    attrs: {
                      "label-sequence": _vm.$data.$_labelSequence,
                      "label-options": _vm.labelOptions,
                      "audio-buffer": _vm.$data.$_audioBuffer,
                      "waveform-digest": _vm.$data.$_waveformDigest,
                      "time-unit": _vm.$data.$_timeUnit,
                      "selected-audio-segment-idcs":
                        _vm.$data.$_selectedAudioSegmentIdcs,
                      "play-time-sec": _vm.$data.$_playTimeSec,
                      "is-undo-disabled": _vm.$data.$_isUndoDisabled,
                      "is-redo-disabled": _vm.$data.$_isRedoDisabled,
                    },
                    on: {
                      "register-component-instance":
                        _vm.$_registerWaveformViewComponentInstance,
                      "unregister-component-instance":
                        _vm.$_unregisterWaveformViewComponentInstance,
                      "update-label-sequence": _vm.$_updateLabelSequence,
                    },
                    model: {
                      value: _vm.$data.$_audioSegmentSequence,
                      callback: function ($$v) {
                        _vm.$set(_vm.$data, "$_audioSegmentSequence", $$v)
                      },
                      expression: "$data.$_audioSegmentSequence",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
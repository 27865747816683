<template>
    <v-container pa-10 style="max-width: 1000px;">
        <v-card class="my-10">
            <v-system-bar window dark color="indigo">
                <h3>Your task</h3>
            </v-system-bar>
            <div class="pa-4 text-body-1 font-weight-medium">
                Compare two musics and give an answer which sounds more "bright".
            </div>
        </v-card>
        <v-card class="mx-auto my-6 pa-6">
            <v-row align="center" class="pt-10">
                <v-col cols="5">
                    <t-audio
                        :src="nano.props.audioPath1"
                        :display-flags="audioDisplayFlags"
                    />
                </v-col>

                <v-col cols="2" align="center" justify="center"><v-icon x-large>mdi-arrow-left-right-bold</v-icon></v-col>

                <v-col cols="5">
                    <t-audio
                        :src="nano.props.audioPath2"
                        :display-flags="audioDisplayFlags"
                    />
                </v-col>
            </v-row>
            <div class="d-flex justify-center">
                <v-btn class="ma-10" x-large dark color="blue darken-1" @click="submitWithChoice('A')"><h3>A</h3></v-btn>
                <v-btn class="ma-10" x-large @click="submitWithChoice('cant_tell')">Can't tell</v-btn>
                <v-btn class="ma-10" x-large dark color="pink darken-1"   @click="submitWithChoice('B')"><h3>B</h3></v-btn>
            </div>
        </v-card>
    </v-container>
</template>

<script>
import nanoMixIn from "@/mixins/nano";
import TAudio from '@/components/template-ui/TAudio'

const audioDisplayFlags = {
    fastForward: false,
    rewind: false, 
    skipForward: false, 
    skipBackward: false, 
    volumeIcon: false,
    playbackSpeedIcon: false,
    downloadIcon: false,
};

export default {
    mixins: [nanoMixIn],
    components:{ TAudio },
    data: () => ({
        audioDisplayFlags,
        defaultNanoProps: {
            audioPath1: 'https://storage.googleapis.com/iflab-public/tutti.works/audios/pops-music-01.mp3',
            audioPath2: 'https://storage.googleapis.com/iflab-public/tutti.works/audios/edm-music-01.mp3',
        },
        defaultNanoAnswers: {
            choice: null,
        }
    }),
    methods: {
        submitWithChoice(choice) {
            this.nano.ans.choice = choice;
            this.submit();            
        }
    }
};
</script>

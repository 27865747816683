var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._g(
      _vm._b(
        { staticClass: "canvas-container", on: { mousemove: _vm.$_onResize } },
        "div",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm._t("default", null, {
        widthPx: _vm.canvasWidthPx,
        heightPx: _vm.canvasHeightPx,
        beginSec: _vm.beginSec,
        endSec: _vm.endSec,
        durationSec: _vm.durationSec,
        durationSample: _vm.durationSample,
        sampleResolution: _vm.sampleResolution,
        canvasLocalRect: _vm.canvasLocalRect,
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
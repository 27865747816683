<template>   
    <div v-show="deviceId" id="wrapper">
        <t-camera
            :device-id="deviceId"
            :always-show-camera="false"
            :mime-type="mimeType"
            :quality="quality"
            :flip-camera="flip"
            :flip-preview="flip"
            max-width="500px"
            @device-enumerate="onDeviceEnumerate"
            @set-device-id="onSetDeviceId"
            @stand-by="onStandBy"
            @capture-photo="onCapturePhoto"
        >
            <template v-slot:camera-preview>
                <v-img v-if="media" :src="media.url"></v-img>
            </template>
            <template v-slot:overlay="{ videoShown, capturePhoto, clear, isStandby }">
                <v-overlay v-model="devicesMenuShown" absolute :dark="false">
                    <v-card>
                        <v-list dense max-height="200" class="overflow-y-auto">
                            <v-list-item
                                v-for="item, i in deviceItems"
                                :key="`device-item-${i}`"
                                @click="deviceId = item.value; devicesMenuShown = false;"
                            >
                                <v-list-item-title>{{ item.text }}</v-list-item-title>
                                <v-list-item-icon>
                                    <v-icon small v-if="item.value === deviceId">mdi-check</v-icon>
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-overlay>
                <div v-if="isStandby" class="camera-overlay">
                    <div class="flex-grow-1"></div>
                    <div class="camera-action-buttons">
                        <div>
                            <v-btn
                                v-if="!submitting"
                                fab
                                outlined
                                dark
                                small
                                color="white"
                                @click="() => {
                                    if(videoShown) {
                                        devicesMenuShown = true;
                                    } else {
                                        clear();
                                        media = null;
                                    }
                                }"
                            >
                                <v-icon>
                                    {{ videoShown ? 'mdi-camera-flip-outline' : 'mdi-arrow-u-left-top' }}
                                </v-icon>
                            </v-btn>
                        </div>
                        <div>
                            <v-btn
                                fab
                                dark
                                color="blue"
                                :loading="submitting"
                                @click="() => {
                                    if(videoShown) {
                                        capturePhoto();
                                    } else {
                                        uploadAndSubmit();
                                    }
                                }"
                            >
                                <v-icon v-if="!videoShown">mdi-check</v-icon>
                            </v-btn>
                        </div>
                        <div>
                            <v-btn
                                v-if="(videoShown && !submitting)"
                                fab
                                outlined
                                dark
                                small
                                color="white"
                                @click="flip = !flip"
                            >
                                <v-icon>mdi-flip-horizontal</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </template>
        </t-camera>
    </div>    
</template>

<script>
import nanoMixIn from "@/mixins/nano";
import TCamera from '@/components/template-ui/TCamera.vue'
    
export default {
    mixins: [nanoMixIn],
    components: { TCamera },
    data: () => ({
        defaultNanoProps: {
        },
        defaultNanoAnswers: {
            media: null,
        },
        media: null,
        deviceId: null,
        devices: [],
        mimeType: 'image/jpeg',
        quality: 1,
        flip: true,
        submitting: false,
        devicesMenuShown: false,
    }),
    computed: {
        deviceItems() {
            return this.devices.map((dev) => ({
                text: dev.label ? dev.label : dev.deviceId,
                value: dev.deviceId,
            }));
        }
    },
    methods: {
        async startRecording() {
            let constraint = { video: { deviceId: { exact: this.deviceId } } };
            if (this.preview.srcObject) {
                this.preview.srcObject.getTracks().forEach( (track) => {
                    if (track.readyState == 'live') {
                        track.stop();
                    }
                });
            }
            this.preview.srcObject = await navigator.mediaDevices.getUserMedia(constraint);
            this.preview.onloadedmetadata = () => {
                this.preview.play();
                this.canvas.width = this.preview.clientWidth;
                this.canvas.height = this.preview.clientHeight;
            };
        },
        async uploadAndSubmit() {
            let suffix = "";
            if (!this.media.input.filename.includes(".")) {
                let match = this.media.metadata.mimeType.match(/(.*)\/([^;]*)[;]?(.*)/);
                if (match && match.length > 1)
                    suffix = '.'+match[2];
            }
            await this.addBufferToFileUploadQueue(this.media.buf, this.media.input.dir, this.media.input.filename+suffix);
            this.nano.ans.metadata = this.media.metadata;
            this.nano.ans.input = this.media.input;
            this.submitting = true;
            this.submit();
        },

        onDeviceEnumerate(devices) {
            this.devices = devices;
            if(this.devices.length > 0) {
                this.$nextTick(() => {
                    this.deviceId = this.deviceItems[0].value;
                });
            } else {
                alert('No camera device found. Please connect a camera device and refresh the page.');
            }
        },
        onSetDeviceId(deviceId) {
            console.log('onSetDeviceId', deviceId);
        },
        onStandBy(deviceId) {
            console.log('standby', deviceId);
        },
        onCapturePhoto(media) {
            this.media = media;
        }
    },
    async mounted() {
        this.preview = this.$refs.preview;
        this.canvas = this.$refs.canvas;
        this.addFileUploadStatusChangeHandler(() => {
            if(this.allFileUploadComplete) this.submitting = false;
        });
    },
};
</script>

<style scoped>   
#wrapper {
    height: 100%;
    background-color: black;
}
div.camera-overlay {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
div.camera-action-buttons {
    display: flex;
    flex-direction: row;
    padding: 40px 0;
    align-items: center;
}
div.camera-action-buttons > div {
    flex: 1;
    text-align: center;
}
</style>
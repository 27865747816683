<template>
  <div
    id="seek-bar-container"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div
      id="seek-bar-base"
      ref="seekBarBase"
    >
      <div
        id="seek-bar-played"
        ref="seekBarPlayed"
      >
        <div
          id="seek-bar-handle"
          ref="seekBarHandle"
          v-show="$data.$_isCursorOnClickableArea"
        >
        </div>
      </div>

      <div
        id="seek-bar-marker"
        class="marker"
        ref="seekBarMarker"
      >
      </div>

      <div
        id="seek-bar-loop"
        class="loop"
        ref="seekBarLoop"
        v-show="isLoopEnabled"
      >
      </div>

      <div
        id="seek-bar-clickable-area"
        ref="seekBarClickableArea"
        v-on:mouseover="$_onCursorOnClickableArea(true)"
        v-on:mouseout="$_onCursorOnClickableArea(false)"
        v-on:mousedown.stop="$_seekStart"
      >
      </div>
    </div>
  </div>
</template>

<style scoped>
div#seek-bar-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

div#seek-bar-base {
  position: relative;
  flex-grow: 1;
  height: 3px;
  background-color: #444444;
}

div#seek-bar-played {
  height: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

div#seek-bar-handle {
  flex-shrink: 0;
  background-color: #ffffff;
  height: 15px;
  width: 15px;
  margin-right: -8px;
  border-radius: 50%;
}

div#seek-bar-marker,
div#seek-bar-loop {
  position: absolute;
  top: 0;
  height: 100%;
  opacity: 0.5;
}

div#seek-bar-clickable-area {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: -10px;
  padding: 10px 0;
}
</style>

<style src="./video_player.css" scoped />

<script>
import TimelineMarker from '../../modules/TimelineMarker.js';
import LoopDefinition from '../../modules/LoopDefinition.js'
import utils from '../../modules/utils.js';

export default {
  watch: {
    '$data.$_seekBarPlayedElement'() { this.$_updateSeekBarPosition(this.currentTimeMsec) },

    currentTimeMsec(newCurrentTimeMsec) { this.$_updateSeekBarPosition(newCurrentTimeMsec) },

    '$data.$_seekBarBaseElement'(newSeekBarBaseElement) {
      let seekBarBaseWidthPx = newSeekBarBaseElement.getBoundingClientRect().width;
      this.$_updateSeekBarScaleAndPosition(seekBarBaseWidthPx, this.durationMsec);
    },

    durationMsec(newDurationMsec) {
      let seekBarBaseWidthPx = this.$data.$_seekBarBaseElement.getBoundingClientRect().width;
      this.$_updateSeekBarScaleAndPosition(seekBarBaseWidthPx, newDurationMsec);
    },

    '$data.$_seekBarMarkerElement'() {
      if (this.timelineMarker === null) return;
      this.$_updateMarkerRange(this.timelineMarker)
    },

    timelineMarker: {
      handler(newTimelineMarker) {
        if (newTimelineMarker === null) return;
        this.$_updateMarkerRange(newTimelineMarker);
      },
      deep: true,
    },

    '$data.$_seekBarLoopElement'() { this.$_updateLoopRange(this.loopDefinition) },

    loopDefinition: {
      handler(newLoopDefinition) { this.$_updateLoopRange(newLoopDefinition) },
      deep: true,
    },
  },

  mounted() {
    this.$data.$_seekBarBaseElement = this.$refs.seekBarBase;
    this.$data.$_seekBarPlayedElement = this.$refs.seekBarPlayed;
    this.$data.$_seekBarMarkerElement = this.$refs.seekBarMarker;
    this.$data.$_seekBarLoopElement = this.$refs.seekBarLoop;
    this.$data.$_seekBarClickableAreaElement = this.$refs.seekBarClickableArea;
    this.$data.$_seekBarBaseResizeObserver = new ResizeObserver(
      resizeObserverEntries => {
        let resizeObserverEntry = resizeObserverEntries[0];
        this.$_updateSeekBarScaleAndPosition(resizeObserverEntry.contentRect.width, this.durationMsec);
      },
    );
    this.$data.$_seekBarBaseResizeObserver.observe(this.$data.$_seekBarBaseElement);
    window.addEventListener('mousemove', this.$_seek);
    window.addEventListener('mouseup', this.$_seekEnd);
  },

  destroyed() {
    this.$data.$_seekBarBaseResizeObserver.disconnect();
  },

  props: {
    currentTimeMsec: { type: Number },
    durationMsec: { type: Number },
    isLoopEnabled: { type: Boolean },
    loopDefinition: { type: LoopDefinition },
    timelineMarker: { type: TimelineMarker },
  },

  data() {
    return {
      $_seekBarBaseElement: null,
      $_seekBarPlayedElement: null,
      $_seekBarMarkerElement: null,
      $_seekBarLoopElement: null,
      $_seekBarClickableAreaElement: null,
      $_seekBarBaseResizeObserver: null,
      $_seekBarScale: null,
      $_isSeeking: false,
      $_isCursorOnClickableArea: false,
    };
  },

  methods: {
    $_updateSeekBarPosition(currentTimeMsec) {
      this.$data.$_seekBarPlayedElement.style.width = String(currentTimeMsec / this.$_seekBarScale) + 'px';
    }, 

    $_updateSeekBarScaleAndPosition(seekBarBaseWidthPx, durationMsec) {
      this.$_seekBarScale = durationMsec / seekBarBaseWidthPx;
      this.$_updateSeekBarPosition(this.currentTimeMsec);
    },

    $_updateMarkerRange(timelineMarker) {
      let markerApparentBeginTimeMsec = (timelineMarker.beginTimeMsec === null)? 0 : timelineMarker.beginTimeMsec;
      let markerApparentEndTimeMsec = (timelineMarker.endTimeMsec === null)? this.durationMsec : timelineMarker.endTimeMsec;
      let markerApparentDurationTimeMsec = markerApparentEndTimeMsec - markerApparentBeginTimeMsec;
      this.$data.$_seekBarMarkerElement.style.left = String(markerApparentBeginTimeMsec / this.$_seekBarScale) + 'px';
      this.$data.$_seekBarMarkerElement.style.width = String(markerApparentDurationTimeMsec / this.$_seekBarScale) + 'px';
    },

    $_updateLoopRange(loopDefinition) {
      let loopBeginTimeMsec = loopDefinition.beginTimeMsec;
      this.$data.$_seekBarLoopElement.style.left = String(loopBeginTimeMsec / this.$_seekBarScale) + 'px';
      this.$data.$_seekBarLoopElement.style.width = String(loopDefinition.durationMsec / this.$_seekBarScale) + 'px';
    },

    $_getSeekTimeMsecByMouseEvent(mouseEvent) {
      let seekBarBaseClientRect = this.$data.$_seekBarBaseElement.getBoundingClientRect();
      let seekBarOffsetPx = mouseEvent.clientX - seekBarBaseClientRect.x;
      return seekBarOffsetPx * this.$_seekBarScale;
    },

    $_seek(event) {
      if (this.$data.$_isSeeking) {
        let seekBarBaseClientRect = this.$data.$_seekBarBaseElement.getBoundingClientRect();
        let seekBarOffsetPx = event.clientX - seekBarBaseClientRect.x;
        let seekTimeMsec = seekBarOffsetPx * this.$_seekBarScale;
        utils.clamp(seekTimeMsec, 0, this.durationMsec);
        this.$emit('seek', seekTimeMsec);
      }
    },

    $_seekStart(event) {
      this.$data.$_isSeeking = true;
      this.$emit('seek-start');
      this.$_setIsCursorOnClickableArea(true);
      this.$_seek(event);
    },

    $_seekEnd(event) {
      this.$data.$_isSeeking = false;
      this.$_seek(event);
      this.$_updateIsCursorOnClickableArea(event);
      this.$emit('seek-end');
    },

    $_updateIsCursorOnClickableArea(event) {
      let seekBarClickableAreaBoundingClientRect = this.$data.$_seekBarClickableAreaElement.getBoundingClientRect();
      this.$_setIsCursorOnClickableArea(utils.isInRect(seekBarClickableAreaBoundingClientRect, event.clientX, event.clientY));
    },

    $_setIsCursorOnClickableArea(displaysHandle) {
      this.$data.$_isCursorOnClickableArea = displaysHandle;
    },

    $_onCursorOnClickableArea(displaysHandle) {
      if (!this.$data.$_isSeeking) {
        this.$_setIsCursorOnClickableArea(displaysHandle);
      }
    },
  },
}
</script>
<template>
  <div class="d-flex flex-column">
    <label class="caption">Delimiter</label>
    <editable-text v-model="$_delimiter"></editable-text>
  </div>
</template>

<script>
import EditableText from './EditableText.vue';

export default {
  components: {
    EditableText,
  },

  model: {
    prop: 'delimiter',
    event: 'update',
  },

  props: {
    delimiter: { type: String },
  },

  computed: {
    $_delimiter: {
      get()          { return this.delimiter },
      set(delimiter) { this.$emit('update', delimiter) },
    }
  },
}
</script>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pa-6" },
    [
      _c("t-audio-segmentation", {
        attrs: { "audio-url": _vm.nano.props.audioUrl },
        model: {
          value: _vm.nano.ans.audioSegmentationInput,
          callback: function ($$v) {
            _vm.$set(_vm.nano.ans, "audioSegmentationInput", $$v)
          },
          expression: "nano.ans.audioSegmentationInput",
        },
      }),
      _c(
        "div",
        { staticClass: "text-end" },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submit()
                },
              },
            },
            [_vm._v("Submit")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
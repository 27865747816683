var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("dialog-base", {
    attrs: {
      shows: _vm.shows,
      "initialize-callback": _vm.$_onInitialize,
      "ok-callback": _vm.$_onOkClicked,
      "finalize-callback": _vm.finalizeCallback,
      "ok-disabled": _vm.$_isNoTimelineDataSelected,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("v-card-title", [_vm._v("Select Timeline Data")]),
            _c(
              "v-card-text",
              [
                _vm._l(
                  _vm.$data.$_timelineDataSelectionWithInfoSet,
                  function (timelineDataSelectionWithInfo, timelineDataId) {
                    return [
                      _c("v-checkbox", {
                        key: timelineDataId,
                        attrs: {
                          dense: "",
                          disabled: _vm.$_isCheckBoxDisabled[timelineDataId],
                          label:
                            timelineDataSelectionWithInfo.selectionItemLabel,
                        },
                        model: {
                          value: timelineDataSelectionWithInfo.selected,
                          callback: function ($$v) {
                            _vm.$set(
                              timelineDataSelectionWithInfo,
                              "selected",
                              $$v
                            )
                          },
                          expression: "timelineDataSelectionWithInfo.selected",
                        },
                      }),
                    ]
                  }
                ),
              ],
              2
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
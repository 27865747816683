<template>
  <div>
    <v-textarea
      dense
      v-model="$data.$_note"
      v-bind:rows="$_numRowsOfNote"
      v-bind:messages="$data.$_message"
      v-on:blur="$_saveNote"
    />
    <div class="timeline-segment-editor-note-button-container">
      <v-spacer />
      <v-btn
        outlined text x-small
        v-on:click="$_deleteNote"
      >
        <span>Delete Note</span>
      </v-btn>
    </div>
  </div>
</template>

<style scoped>
div.timeline-segment-editor-note-button-container {
  display: flex;
  align-items: center;
}
</style>

<script>
import { TimelineSegmentId } from '../../../../external/modules/ScattData.js'
import utils from '../../../modules/utils.js'

const displayMessageDurationMsec = 2000;

export default {
  watch: {
    note: {
      handler() { this.$_loadNote(); },
      immediate: true,
    },
  },

  props: {
    noteTimelineSegmentId: { type: TimelineSegmentId },
    note: { type: String },
  },

  data() {
    return {
      $_note: null,
      $_message: null,
    };
  },

  computed: {
    $_numRowsOfNote() { return utils.countNumLines(this.$data.$_note); },
  },

  inject: [
    'deleteSegments',
  ],

  methods: {
    $_loadNote() {
      this.$data.$_note = String(this.note);
    },

    $_saveNote() {
      this.$emit('save-note', this.noteTimelineSegmentId, this.$data.$_note);
      this.$data.$_message = 'autosaved';
      window.setTimeout(
        () => { this.$data.$_message = null; },
        displayMessageDurationMsec,
      );
    },

    $_deleteNote() {
      this.deleteSegments([ this.noteTimelineSegmentId ]);
    },
  },
}
</script>
<template>
  <canvas></canvas>
</template>

<script>
import utils from '../../../modules/utils.js'
import canvasUtils from './modules/canvasUtils.js'
import WaveformDigest from '../../../modules/WaveformDigest.js'
import timelineCanvas from './mixins/timelineCanvas.js'
import LoopDefinition from '../../../modules/LoopDefinition.js'

const timelineGridIntervalPxMin = 120;

const timelineGridIntervalMsecByLevel = [
  10, // 10 milliseconds
  25, // 25 milliseconds
  50, // 50 milliseconds
  100, // 100 milliseconds
  250, // 250 milliseconds
  500, // 500 milliseconds
  1000, // 1 second
  2500, // 2.5 seconds
  5000, // 5 seconds
  10000, // 10 seconds
  30000, // 30 seconds
  60000, // 1 minute
  300000, // 5 minute
  600000, // 10 minutes
  1800000, // 30 minutes
  3600000, // 1 hour
];

const loopIndicatorHeightPx = 3;

class TimelineGridBarTimeMsecAndOffsetPx {
  constructor(timeMsec, offsetPx) {
    this.timeMsec = timeMsec;
    this.offsetPx = offsetPx;
  }
}

export default {
  mixins: [
    timelineCanvas,
  ],

  watch: {
    numVisibleTimelines() { this.$_setDirty(true); },
    verticalOffsetPx() { this.$_setDirty(true); },
    timelineHeightPx() { this.$_setDirty(true); },
    timelineViewBeginMsec() { this.$_setDirty(true); },
    timeResolution() { this.$_setDirty(true); },
    isLoopEnabled() { this.$_setDirty(true); },
    waveformDigest() { this.$_setDirty(true); },
    loopDefinition: {
      handler() { this.$_setDirty(true); },
      deep: true,
    },
  },

  props: {
    rulerVerticalOffsetPx: { type: Number },
    numVisibleTimelines: { type: Number },
    verticalOffsetPx: { type: Number },
    timelineHeightPx: { type: Number },
    timeResolution: { type: Number },
    waveformDigest: { type: WaveformDigest },
    timelineViewBeginMsec: { type: Number },
    isLoopEnabled: { type: Boolean },
    loopDefinition: { type: LoopDefinition },
  },

  computed: {
    $_timelineViewEndMsec()        { return this.timelineViewBeginMsec + this.$_timelineViewDurationMsec; },
    $_timelineViewDurationMsec()   { return utils.resolve(this.$data.$_timelineCanvasWidthPx, this.timeResolution); },

    $_currentWaveformDigest() {
      if (this.waveformDigest === null) return;
      let samplingRateDotByDot = this.$data.$_timelineCanvasWidthPx / (this.$_timelineViewDurationMsec / 1000);
      return this.waveformDigest.getDigest(samplingRateDotByDot);
    },

    $_timelineGridBarTimeMsecAndOffsetPxArray() {
      let firstGridOffsetMsec = utils.minimumMultipleGreaterThan(this.$_timelineGridIntervalMsec, this.timelineViewBeginMsec);
      let firstGridTimelineViewOffsetMsec = firstGridOffsetMsec - this.timelineViewBeginMsec;
      let timelineGridBarTimeMsecAndOffsetPxArray = [];
      for (
        let currentGridTimelineViewOffsetMsec = firstGridTimelineViewOffsetMsec;
        currentGridTimelineViewOffsetMsec < this.$_timelineViewDurationMsec;
        currentGridTimelineViewOffsetMsec += this.$_timelineGridIntervalMsec
      ) {
        let currentGridTimeMsec = Math.round(this.timelineViewBeginMsec + currentGridTimelineViewOffsetMsec);
        let currentGridTimelineViewOffsetpx = utils.unitize(currentGridTimelineViewOffsetMsec, this.timeResolution);
        timelineGridBarTimeMsecAndOffsetPxArray.push(new TimelineGridBarTimeMsecAndOffsetPx(currentGridTimeMsec, currentGridTimelineViewOffsetpx));
      }
      return timelineGridBarTimeMsecAndOffsetPxArray;
    },

    $_timelineGridIntervalMsec() {
      let intervalMsec;
      for (let currentIntervalMsec of timelineGridIntervalMsecByLevel) {
        intervalMsec = currentIntervalMsec;
        let currentIntervalPx = utils.unitize(currentIntervalMsec, this.timeResolution);
        if (currentIntervalPx > timelineGridIntervalPxMin) {
          break;
        }
      }
      return intervalMsec;
    },

    $_loopIndicatorDomRect() {
      if (this.timelineViewBeginMsec > this.loopDefinition.endTimeMsec) return null;
      if (this.$_timelineViewEndMsec < this.loopDefinition.beginTimeMsec) return null;
      let loopBeginTimeMsec = (this.timelineViewBeginMsec > this.loopDefinition.beginTimeMsec)? this.timelineViewBeginMsec : this.loopDefinition.beginTimeMsec;
      let loopEndTimeMsec = (this.$_timelineViewEndMsec < this.loopDefinition.endTimeMsec)? this.$_timelineViewEndMsec : this.loopDefinition.endTimeMsec;
      let loopBeginTimelineViewOffsetMsec = loopBeginTimeMsec - this.timelineViewBeginMsec;
      let loopBeginTimelineViewOffsetPx = utils.unitize(loopBeginTimelineViewOffsetMsec, this.timeResolution);
      let loopDurationMsec = loopEndTimeMsec - loopBeginTimeMsec;
      let loopDurationPx = utils.unitize(loopDurationMsec, this.timeResolution);
      return new DOMRect(
        loopBeginTimelineViewOffsetPx,
        this.rulerVerticalOffsetPx - loopIndicatorHeightPx,
        loopDurationPx,
        loopIndicatorHeightPx,
      );
    },
  },

  methods: {
    /* public */
    draw() {
      let self = this;
      let timelineBackgroundCanvas = this.$data.$_timelineCanvasContext;
      this.$_draw(() => {
        if (this.waveformDigest) {
          drawWaveform();
        }
        drawTimelineTrack()
        drawRulerBackground();
        drawTimelineGridBars();
        drawTimelineGridBarTime();
        if (this.isLoopEnabled) {
          drawLoopArea();
        }
      });

      function drawBar(timeOffsetPx, color) {
        timelineBackgroundCanvas.beginPath();
        timelineBackgroundCanvas.setLineDash([]);
        timelineBackgroundCanvas.lineWidth = 1;
        timelineBackgroundCanvas.strokeStyle = color;
        timelineBackgroundCanvas.moveTo(...canvasUtils.dotByDotOffsetCoordArgs(timeOffsetPx, 0));
        timelineBackgroundCanvas.lineTo(...canvasUtils.dotByDotOffsetCoordArgs(timeOffsetPx, self.$data.$_timelineCanvasHeightPx));
        timelineBackgroundCanvas.stroke();
      }

      function drawWaveform() {
        {
          let numChannels = self.waveformDigest.numChannels;
          let waveformHeightAll = self.$data.$_timelineCanvasHeightPx - self.rulerVerticalOffsetPx;
          let eachWaveformHeight = waveformHeightAll / numChannels;
          timelineBackgroundCanvas.setLineDash([]);
          timelineBackgroundCanvas.lineWidth = 1;
          timelineBackgroundCanvas.strokeStyle = 'rgb(220, 220, 220)';
          for (let channelIdx = 0; channelIdx < numChannels; ++channelIdx) {
            let waveformVerticalOffsetPx = getWaveformVerticalOffsetPx(eachWaveformHeight, channelIdx);
            timelineBackgroundCanvas.beginPath();
            timelineBackgroundCanvas.moveTo(...canvasUtils.dotByDotOffsetCoordArgs(0, waveformVerticalOffsetPx));
            timelineBackgroundCanvas.lineTo(...canvasUtils.dotByDotOffsetCoordArgs(self.$data.$_timelineCanvasWidthPx, waveformVerticalOffsetPx));
            timelineBackgroundCanvas.stroke();
          }
          let eachWaveformAmplitude = eachWaveformHeight / 2;
          let timelineViewBeginSec = self.timelineViewBeginMsec / 1000;
          let timelineViewEndSec = self.$_timelineViewEndMsec / 1000;
          let samplingRate = (self.$_currentWaveformDigest)? self.$_currentWaveformDigest[0].samplingRate : self.waveformDigest.originalSamplingRate;
          let timelineViewBeginSampleOffset = timelineViewBeginSec * samplingRate;
          let sampleInViewportInitialOffset = Math.floor(timelineViewBeginSampleOffset);
          if (sampleInViewportInitialOffset < 0) sampleInViewportInitialOffset = 0;
          let sampleInViewportFinalOffset = Math.ceil(timelineViewEndSec * samplingRate);
          let sampleFinalOffsetMax = (self.$_currentWaveformDigest)? (self.$_currentWaveformDigest[0].max.length - 1) : (self.waveformDigest.originalWaveform[0].length - 1);
          if (sampleInViewportFinalOffset > sampleFinalOffsetMax) sampleInViewportFinalOffset = sampleFinalOffsetMax;
          let initialSampleTimelineViewOffsetPx = getTimelineViewOffsetPx(sampleInViewportInitialOffset, timelineViewBeginSampleOffset, samplingRate);
          for (let channelIdx = 0; channelIdx < numChannels; ++channelIdx) {
            let waveformVerticalOffsetPx = getWaveformVerticalOffsetPx(eachWaveformHeight, channelIdx);
            timelineBackgroundCanvas.beginPath();
            timelineBackgroundCanvas.moveTo(...canvasUtils.dotByDotOffsetCoordArgs(initialSampleTimelineViewOffsetPx, waveformVerticalOffsetPx));
            if (self.$_currentWaveformDigest) {
              let currentLpcmDigestByChannel = self.$_currentWaveformDigest[channelIdx];
              timelineBackgroundCanvas.fillStyle = 'rgb(220, 220, 220)';
              for (let currentSampleOffset = sampleInViewportInitialOffset; currentSampleOffset <= sampleInViewportFinalOffset; ++currentSampleOffset) {
                let currentSampleTimelineViewOffsetPx = getTimelineViewOffsetPx(currentSampleOffset, timelineViewBeginSampleOffset, samplingRate);
                let currentSampleVerticalOffsetPx = waveformVerticalOffsetPx - currentLpcmDigestByChannel.max[currentSampleOffset] * eachWaveformAmplitude;
                timelineBackgroundCanvas.lineTo(...canvasUtils.dotByDotOffsetCoordArgs(currentSampleTimelineViewOffsetPx, currentSampleVerticalOffsetPx));
              }
              for (let currentSampleOffset = sampleInViewportFinalOffset; currentSampleOffset >= sampleInViewportInitialOffset; --currentSampleOffset) {
                let currentSampleTimelineViewOffsetPx = getTimelineViewOffsetPx(currentSampleOffset, timelineViewBeginSampleOffset, samplingRate);
                let currentSampleVerticalOffsetPx = waveformVerticalOffsetPx - currentLpcmDigestByChannel.min[currentSampleOffset] * eachWaveformAmplitude;
                timelineBackgroundCanvas.lineTo(...canvasUtils.dotByDotOffsetCoordArgs(currentSampleTimelineViewOffsetPx, currentSampleVerticalOffsetPx));
              }
              timelineBackgroundCanvas.fill();
            } else {
              let lpcmData = self.waveformDigest.originalWaveform;
              timelineBackgroundCanvas.strokeStyle = 'rgba(0, 0, 0, 0.2)';
              timelineBackgroundCanvas.setLineDash([]);
              timelineBackgroundCanvas.lineWidth = 1;
              for (let currentSampleOffset = sampleInViewportInitialOffset; currentSampleOffset <= sampleInViewportFinalOffset; ++currentSampleOffset) {
                let currentSampleTimelineViewOffsetPx = getTimelineViewOffsetPx(currentSampleOffset, timelineViewBeginSampleOffset, samplingRate);
                let currentSampleVerticalOffsetPx = waveformVerticalOffsetPx - lpcmData[channelIdx][currentSampleOffset] * eachWaveformAmplitude;
                timelineBackgroundCanvas.lineTo(...canvasUtils.dotByDotOffsetCoordArgs(currentSampleTimelineViewOffsetPx, currentSampleVerticalOffsetPx));
              }
              timelineBackgroundCanvas.stroke();
            }
          }
        }

        function getWaveformVerticalOffsetPx(eachWaveformHeight, channelIdx) {
          return self.rulerVerticalOffsetPx + (eachWaveformHeight * (channelIdx + 0.5));
        }

        function getTimelineViewOffsetPx(sampleOffset, timelineViewBeginSampleOffset, samplingRate) {
          let currentLocalSampleOffset = sampleOffset - timelineViewBeginSampleOffset;
          let currentSampleTimelineViewOffsetSec = currentLocalSampleOffset / samplingRate;
          return Math.round(utils.unitize(currentSampleTimelineViewOffsetSec * 1000, self.timeResolution));
        }
      }

      function drawTimelineGridBars() {
        for (let timelineGridBarTimeMsecAndOffsetPx of self.$_timelineGridBarTimeMsecAndOffsetPxArray) {
          drawBar(timelineGridBarTimeMsecAndOffsetPx.offsetPx, 'rgb(210, 210, 210)');
        }
      }

      function drawTimelineTrack() {
        for (let timelineDataIdx = 0; timelineDataIdx < self.numVisibleTimelines; ++timelineDataIdx) {
          let trackBeginVerticalOffsetPx = self.verticalOffsetPx + self.timelineHeightPx * timelineDataIdx;
          let trackEndVerticalOffsetPx = self.verticalOffsetPx + self.timelineHeightPx * (timelineDataIdx + 1);
          if (timelineDataIdx % 2 === 1) {
            timelineBackgroundCanvas.beginPath();
            timelineBackgroundCanvas.rect(...canvasUtils.dotByDotOffsetRectArgs(
              0,
              trackBeginVerticalOffsetPx,
              self.$data.$_timelineCanvasWidthPx,
              self.timelineHeightPx,
            ));
            timelineBackgroundCanvas.fillStyle = 'rgba(0, 0, 0, 0.05)';
            timelineBackgroundCanvas.fill();
          }
          timelineBackgroundCanvas.beginPath();
          timelineBackgroundCanvas.setLineDash([]);
          timelineBackgroundCanvas.lineWidth = 1;
          timelineBackgroundCanvas.strokeStyle = 'rgb(210, 210, 210)';
          timelineBackgroundCanvas.moveTo(...canvasUtils.dotByDotOffsetCoordArgs(0, trackEndVerticalOffsetPx));
          timelineBackgroundCanvas.lineTo(...canvasUtils.dotByDotOffsetCoordArgs(self.$data.$_timelineCanvasWidthPx, trackEndVerticalOffsetPx));
          timelineBackgroundCanvas.stroke();
        }
      }

      function drawRulerBackground() {
        timelineBackgroundCanvas.beginPath();
        timelineBackgroundCanvas.rect(...canvasUtils.dotByDotOffsetRectArgs(
          0,
          0,
          self.$data.$_timelineCanvasWidthPx,
          self.rulerVerticalOffsetPx,
        ));
        timelineBackgroundCanvas.fillStyle = 'rgb(255, 255, 255)';
        timelineBackgroundCanvas.fill();
      }

      function drawTimelineGridBarTime() {
        {
          for (let timelineGridBarTimeMsecAndOffsetPx of self.$_timelineGridBarTimeMsecAndOffsetPxArray) {
            let remainingTimeMsec = timelineGridBarTimeMsecAndOffsetPx.timeMsec;
            let timeHour = utils.msecToHour(remainingTimeMsec);
            remainingTimeMsec -= utils.hourToMsec(timeHour);
            let timeMinutes = utils.msecToMin(remainingTimeMsec);
            remainingTimeMsec -= utils.minToMsec(timeMinutes);
            let timeSec = utils.msecToSec(remainingTimeMsec);
            remainingTimeMsec -= utils.secToMsec(timeSec);
            let timeMsec = remainingTimeMsec;
            let offsetPx = timelineGridBarTimeMsecAndOffsetPx.offsetPx;
            timelineBackgroundCanvas.font = 'normal 9px sans-serif';
            timelineBackgroundCanvas.fillStyle = 'rgb(210, 210, 210)';
            timelineBackgroundCanvas.textAlign = 'left';
            timelineBackgroundCanvas.textBaseline = 'top';
            timelineBackgroundCanvas.fillText(utils.formatTime(timeHour, timeMinutes, timeSec, timeMsec), offsetPx + 2, 2);
          }
        }
      }

      function drawLoopArea() {
        if (!self.$_loopIndicatorDomRect) return;
        timelineBackgroundCanvas.beginPath();
        timelineBackgroundCanvas.rect(...canvasUtils.dotByDotOffsetRectArgs(
          self.$_loopIndicatorDomRect.x,
          self.$_loopIndicatorDomRect.y,
          self.$_loopIndicatorDomRect.width,
          self.$_loopIndicatorDomRect.height,
        ));
        timelineBackgroundCanvas.fillStyle = 'rgb(34, 200, 200)';
        timelineBackgroundCanvas.fill();
      }
    },
  }
};
</script>

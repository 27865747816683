var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    _vm._g(
      _vm._b(
        {
          attrs: {
            width: "unset",
            scrollable: "",
            value: this.shows,
            "retain-focus": _vm.$data.$_retainsFocus,
          },
          on: {
            input: _vm.$_onShowsChange,
            keydown: [
              function ($event) {
                $event.stopPropagation()
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.$_onEnterKeyDown.apply(null, arguments)
              },
            ],
          },
        },
        "v-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "v-card",
        { attrs: { id: "dialog-window" } },
        [
          _vm._t("body"),
          _c(
            "v-card-actions",
            [
              _vm._t(
                "buttons",
                function () {
                  return [
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      _vm._b(
                        {
                          attrs: { color: "secondary" },
                          on: { click: _vm.$_onCancelClicked },
                        },
                        "v-btn",
                        _vm.$_buttonAttributes,
                        false
                      ),
                      [_vm._v(" Cancel ")]
                    ),
                    _c(
                      "v-btn",
                      _vm._b(
                        {
                          attrs: { color: "primary", disabled: _vm.okDisabled },
                          on: { click: _vm.$_onOkClicked },
                        },
                        "v-btn",
                        _vm.$_buttonAttributes,
                        false
                      ),
                      [_vm._v(" OK ")]
                    ),
                  ]
                },
                { on: _vm.$_buttonEventHandlers, attrs: _vm.$_buttonAttributes }
              ),
            ],
            2
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import RationalNumber from './RationalNumber.js';

class AudioSegment {
  constructor(begin, end) {
    this.begin = begin;
    this.end = end;
    if (this.invalidReasons) {
      throw new this.constructor.TypeError(this.invalidReasons.join('/'));
    }
  }

  isEqualTo(that) {
    if (!this.begin.isEqualTo(that.begin)) return false;
    if (!this.end.isEqualTo(that.end)) return false;
    return true;
  }

  clone() {
    return new AudioSegment(
      (this.begin === null)? null : this.begin.clone(),
      (this.end === null)? null : this.end.clone(),
    );
  }

  get invalidReasons() {
    let invalidReasons = new Array();
    let isBeginNull = (this.begin === null);
    let isBeginARationalNumber = (this.begin instanceof RationalNumber);
    let isEndNull = (this.end === null);
    let isEndARationalNumber = (this.end instanceof RationalNumber);
    if (!isBeginNull && !isBeginARationalNumber) invalidReasons.push('begin is not a rational number.');
    if (!isEndNull && !isEndARationalNumber) invalidReasons.push('end is not a rational number.');
    if (isBeginARationalNumber && isEndARationalNumber) {
      if (this.begin.isLessThan(0)) invalidReasons.push('begin is less than zero.');
      if (this.end.isLessThan(0)) invalidReasons.push('end is less than zero.');
      if (this.begin.isGreaterThan(this.end)) invalidReasons.push('begin must be less than or equal to end.');
    }
    if (invalidReasons.length > 0) {
      return invalidReasons;
    } else {
      return null;
    }
  }
}

Object.defineProperty(
  AudioSegment,
  'TypeError',
  {
    value: class AudioSegmentTypeError extends Error {
      constructor(...args) {
        super(...args);
      }
    },
    writable: false,
  }
);

export default AudioSegment;
import EditorDefinition from '../../modules/EditorDefinition.js'
import NoteSegmentData from '../../modules/segmentDataTypes/NoteSegmentData.js'

export default new EditorDefinition(
  'ScattNoteEditor',
  'Note',
  timelineDataSet => {
    for (let timelineData of Object.values(timelineDataSet)) {
      if (timelineData.segmentDataType === NoteSegmentData) return true;
    }
    return false;
  },
);

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "editor-view" } },
    [
      _vm.$data.$_renderComponent
        ? _c(
            "v-slide-group",
            {
              attrs: {
                id: "editor-selector",
                "show-arrows": "",
                mandatory: "",
                height: "24",
                value: _vm.$data.$_selectedEditorComponentName,
              },
              on: { change: _vm.$_selectEditor },
            },
            [
              _vm._l(_vm.editorDefinitions, function (editorDefinition) {
                return [
                  editorDefinition.isAvailable(_vm.timelineDataSet)
                    ? _c("v-slide-item", {
                        key: editorDefinition.componentName,
                        attrs: { value: editorDefinition.componentName },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ active, toggle }) {
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        tile: "",
                                        text: "",
                                        small: "",
                                        "input-value": active,
                                      },
                                      on: { click: toggle, space: toggle },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(editorDefinition.title) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : _vm._e(),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _c(
        "div",
        { attrs: { id: "editor-container" } },
        [
          _c(_vm.$data.$_selectedEditorComponentName, {
            tag: "component",
            attrs: {
              "annotator-id": _vm.annotatorId,
              "annotator-name": _vm.annotatorName,
              "timeline-data-set": _vm.timelineDataSet,
              "selected-timeline-segment-ids": _vm.selectedTimelineSegmentIds,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-container>
    <v-card v-if="$_inspectionTargetSegments.length > 0">
      <v-card-text>
        <dl class="insepctor-definition-list">
          <dt>Timeline Segment IDs</dt>
          <dd>
            <span
              class="enumeration"
              v-for="(timelineSegmentId, timelineSegmentIdIdx) of selectedTimelineSegmentIds"
              v-bind:key="timelineSegmentIdIdx"
            >
              {{ timelineSegmentId.mergedId }}
            </span>
          </dd>
        </dl>
        <dl class="insepctor-definition-list">
          <dt>Segment Type</dt>
          <dd>{{ $_inspectionTargetSegmentsSingleDataType }}</dd>
        </dl>
        <div v-if="$_singleInspectionTargetTimelineSegmentId">
          <dl class="insepctor-definition-list">
            <dt>Data</dt>
            <dd>
              <dl>
                <dt>begin</dt>
                <dd>{{ $_segmentDataTemp.begin }}</dd>
              </dl>
              <dl>
                <dt>end</dt>
                <dd>{{ $_segmentDataTemp.end }}</dd>
              </dl>
              <InspectorDefinition
                title="data"
                v-bind:definition="$_segmentDataTemp.data"
              />
            </dd>
          </dl>
          <dl class="insepctor-definition-list">
            <dt>References</dt>
            <dd class="as-block">
              <v-container
                fluid pt-0
                v-if="$_inspectionTargetSegments.length === 1"
              >
                <v-row no-gutters>
                  <v-col
                    col="6"
                    v-for="(timelineSegmentIdsReferToOrFrom, referToOrFrom) in $_inspectionTargetTimelineSegmentIdsReferToOrFrom"
                    v-bind:key="referToOrFrom"
                  >
                    <v-card flat>
                      <v-subheader>
                        <span>{{ $_getTitleByReferToOrFrom(referToOrFrom) }}</span>
                        <v-spacer />
                        <v-btn
                          outlined text x-small fab
                          v-bind:disabled="Object.keys(timelineSegmentIdsReferToOrFrom).length === 0"
                          v-on:click="$_emitUnregisterAllReferences(referToOrFrom)"
                          v-on:keydown.stop
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <v-btn
                          outlined text x-small fab
                          v-on:click="$_toggleSelectSegmentsCallbackRegistoration(referToOrFrom, $event)"
                          v-on:keydown.stop
                        >
                          <v-icon>mdi-playlist-plus</v-icon>
                        </v-btn>
                      </v-subheader>
                      <v-btn
                        text small tile
                        v-for="(timelineSegmentId, referenceId) in timelineSegmentIdsReferToOrFrom"
                        v-bind:key="referenceId"
                        v-on:click="selectTimelineSegmentAndSeek(timelineSegmentId)"
                        v-on:keydown.stop
                      >
                        {{ timelineSegmentId.mergedId }}
                      </v-btn>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </dd>
          </dl>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<style scoped src="./Inspector/inspector.css" />

<style scoped>
.attached-ref-class {
  background-color: #f03e1e !important;
}

.enumeration:not(:last-child):after {
  content: ', ';
}
</style>

<script>
import InspectorDefinition from './Inspector/InspectorDefinition.vue'
import referencePool from '../../../external/modules/referencePool.js'
import { Reference } from '../../../external/modules/referencePool.js'

function generateEmptyTimelineSegmentIdsReferToOrFrom() {
  return {
    referTo: new Object(),
    referFrom: new Object(),
  };
}

const segmentDataTypeStringMixed = 'mixed';

export default {
  components: {
    InspectorDefinition,
  },

  props: {
    selectedTimelineSegmentIds: { type: Array },
    timelineDataSet: { type: Object },
  },

  data() {
    return {
      $_registerSelectSegmentsCallbackButtonElement: null,
      $_lockedInspectionTargetTimelineSegmentId: null,
      $_timelineSegmentIdsReferToOrFrom: new Object(),
      $_onReferencePoolRegisterCallbackId: null,
      $_onReferencePoolUnregisterCallbackId: null,
    };
  },

  created() {
    let allReferences = referencePool.getAllReferences();
    this.$_createTimelineSegmentIdCache(Object.keys(allReferences), Object.values(allReferences));
    this.$data.$_onReferencePoolRegisterCallbackId = referencePool.registerOnRegisterCallback(this.$_createTimelineSegmentIdCache);
    this.$data.$_onReferencePoolUnregisterCallbackId = referencePool.registerOnUnregisterCallback(this.$_deleteTimelineSegmentIdCache);
  },

  destroyed() {
    if (this.$_isSelectSegmentsCallbackRegistered) {
      this.$_unregisterSelectSegmentsCallback();
    }
    referencePool.unregisterOnRegisterCallback(this.$data.$_onReferencePoolRegisterCallbackId);
    referencePool.unregisterOnUnregisterCallback(this.$data.$_onReferencePoolUnregisterCallbackId);
  },

  computed: {
    $_segmentDataTemp() {
      if (this.$_singleInspectionTargetTimelineSegmentId) {
        return this.$_inspectionTargetSegments[0];
      } else {
        if (this.$_inspectionTargetSegmentsSingleDataType === segmentDataTypeStringMixed) {
          return null;
        } else {
          let representativeTimelineSegmentId = this.$_inspectionTargetTimelineSegmentIds[0];
          let representativeTimelineDataId = representativeTimelineSegmentId.timelineDataId;
          let singleSegmentDataType = this.timelineDataSet[representativeTimelineDataId].segmentDataType;
          let emptySegmentData = singleSegmentDataType.generateEmpty(null, null);
          return emptySegmentData;
        }
      }
    },

    $_singleInspectionTargetTimelineSegmentId() {
      if (this.$data.$_lockedInspectionTargetTimelineSegmentId) {
        return this.$data.$_lockedInspectionTargetTimelineSegmentId;
      } else {
        if (this.selectedTimelineSegmentIds.length !== 1) return null;
        return this.selectedTimelineSegmentIds[0];
      }
    },

    $_inspectionTargetTimelineSegmentIds() {
      if (this.$data.$_lockedInspectionTargetTimelineSegmentId) {
        return [ this.$data.$_lockedInspectionTargetTimelineSegmentId ];
      } else {
        return this.selectedTimelineSegmentIds;
      }
    },

    $_inspectionTargetSegments() {
      let targetTimelineSegmentIds = this.$_inspectionTargetTimelineSegmentIds;
      return targetTimelineSegmentIds.map(timelineSegmentId => {
        let timelineDataId = timelineSegmentId.timelineDataId;
        let segmentId = timelineSegmentId.segmentId;
        return this.timelineDataSet[timelineDataId].segments[segmentId];
      });
    },

    $_inspectionTargetSegmentsSingleDataType() {
      let initialTimelineSegmentId = this.$_inspectionTargetTimelineSegmentIds[0];
      if (initialTimelineSegmentId.length === 0) return null;
      let initialTimelineDataId = initialTimelineSegmentId.timelineDataId;
      let singleSegmentDataType = this.timelineDataSet[initialTimelineDataId].segmentDataType;
      for (let timelineSegmentId of this.$_inspectionTargetTimelineSegmentIds) {
        let timelineDataId = timelineSegmentId.timelineDataId;
        let currentSegmentDataType = this.timelineDataSet[timelineDataId].segmentDataType;
        if (singleSegmentDataType !== currentSegmentDataType) return segmentDataTypeStringMixed;
      }
      return String(singleSegmentDataType);
    },

    $_isSelectSegmentsCallbackRegistered() { return (this.$data.$_registerSelectSegmentsCallbackButtonElement !== null); },

    $_inspectionTargetTimelineSegmentIdsReferToOrFrom() {
      if (!this.$_singleInspectionTargetTimelineSegmentId) return null;
      let singleInspectionTargetTimelineSegmentMergedId = this.$_singleInspectionTargetTimelineSegmentId.mergedId;
      if (!Object.keys(this.$data.$_timelineSegmentIdsReferToOrFrom).includes(singleInspectionTargetTimelineSegmentMergedId)) {
        this.$set(
          this.$data.$_timelineSegmentIdsReferToOrFrom,
          singleInspectionTargetTimelineSegmentMergedId,
          generateEmptyTimelineSegmentIdsReferToOrFrom(),
        );
      }
      return this.$data.$_timelineSegmentIdsReferToOrFrom[singleInspectionTargetTimelineSegmentMergedId];
    },
  },

  inject: [
    'registerSelectSegmentsCallback',
    'unregisterSelectSegmentsCallback',
    'registerReferences',
    'unregisterReferences',
    'selectTimelineSegmentAndSeek',
  ],

  methods: {
    $_emitUnregisterAllReferences(referToOrFrom) {
      if (!this.$_singleInspectionTargetTimelineSegmentId) return;
      let singleInspectionTargetTimelineSegmentMergedId = this.$_singleInspectionTargetTimelineSegmentId.mergedId;
      let timelineSegmentIdsReferToOrFrom = this.$data.$_timelineSegmentIdsReferToOrFrom[singleInspectionTargetTimelineSegmentMergedId][referToOrFrom];
      let referenceIds = Object.keys(timelineSegmentIdsReferToOrFrom);
      this.unregisterReferences(...referenceIds);
    },

    $_toggleSelectSegmentsCallbackRegistoration(referToOrFrom, event) {
      let registerSelectSegmentsCallbackButtonElement = event.currentTarget;
      if (this.$data.$_registerSelectSegmentsCallbackButtonElement === registerSelectSegmentsCallbackButtonElement) {
        this.$_unregisterSelectSegmentsCallback();
      } else {
        if (this.$_isSelectSegmentsCallbackRegistered) {
          this.$_unregisterSelectSegmentsCallback();
        }
        this.$_registerSelectSegmentsCallback(referToOrFrom, registerSelectSegmentsCallbackButtonElement);
      }
    },

    $_unregisterSelectSegmentsCallback() {
      if (this.$data.$_registerSelectSegmentsCallbackButtonElement) {
        this.$data.$_registerSelectSegmentsCallbackButtonElement.classList.remove('attached-ref-class');
        this.$data.$_registerSelectSegmentsCallbackButtonElement = null;
      }
      if (this.$data.$_lockedInspectionTargetTimelineSegmentId) {
        this.selectTimelineSegmentAndSeek(this.$data.$_lockedInspectionTargetTimelineSegmentId);
        this.$data.$_lockedInspectionTargetTimelineSegmentId = null;
      }
      this.unregisterSelectSegmentsCallback();
    },

    $_registerSelectSegmentsCallback(referToOrFrom, registerSelectSegmentsCallbackButtonElement) {
      let self = this;
      {
        if (!this.$_singleInspectionTargetTimelineSegmentId) return;
        this.$data.$_registerSelectSegmentsCallbackButtonElement = registerSelectSegmentsCallbackButtonElement;
        this.$data.$_registerSelectSegmentsCallbackButtonElement.classList.add('attached-ref-class');
        this.$data.$_lockedInspectionTargetTimelineSegmentId = this.$_singleInspectionTargetTimelineSegmentId;
        this.registerSelectSegmentsCallback((selectedTimelineSegmentIds) => {
          let allReferences = referencePool.getAllReferences();
          let unregisteringReferenceIds = new Array();
          let registeringReferences = new Array();
          for (let selectedTimelineSegmentId of selectedTimelineSegmentIds) {
            let foundReferenceId = null;
            for (let [ referenceId, reference ] of Object.entries(allReferences)) {
              let timelineSegmentIdReferToOrFrom = null;
              switch (referToOrFrom) {
              case 'referTo':
                timelineSegmentIdReferToOrFrom = reference.referTo;
                break;
              case 'referFrom':
                timelineSegmentIdReferToOrFrom = reference.referFrom;
                break;
              }
              if (timelineSegmentIdReferToOrFrom.isSame(selectedTimelineSegmentId)) {
                foundReferenceId = referenceId;
                break;
              }
            }
            if (foundReferenceId) {
              unregisteringReferenceIds.push(foundReferenceId);
            } else {
              let referFrom = null;
              let referTo = null;
              switch (referToOrFrom) {
              case 'referTo':
                referFrom = this.$_singleInspectionTargetTimelineSegmentId;
                referTo = selectedTimelineSegmentId;
                break;
              case 'referFrom':
                referFrom = selectedTimelineSegmentId;
                referTo = this.$_singleInspectionTargetTimelineSegmentId;
                break;
              }
              let refClass = getRefClass(referFrom);
              registeringReferences.push(new Reference(refClass, referFrom, referTo));
            }
          }
          if (unregisteringReferenceIds.length > 0) {
            this.unregisterReferences(...unregisteringReferenceIds);
          }
          if (registeringReferences.length > 0) {
            this.registerReferences(...registeringReferences);
          }
        });
      }

      function getRefClass(timelineSegmentIdReferFrom) {
        let timelineDataId = timelineSegmentIdReferFrom.timelineDataId;
        return String(self.timelineDataSet[timelineDataId].segmentDataType);
      }
    },

    $_createTimelineSegmentIdCache(registeredReferenceIds, registeredReferences) {
      let numRegisteredReferences = registeredReferences.length;
      for (let registeredReferenceIdx = 0; registeredReferenceIdx < numRegisteredReferences; ++registeredReferenceIdx) {
        let registeredReferenceId = registeredReferenceIds[registeredReferenceIdx];
        let registeredReference = registeredReferences[registeredReferenceIdx];
        let timelineSegmentMergedIdReferFrom = registeredReference.referFrom.mergedId;
        let timelineSegmentMergedIdReferTo = registeredReference.referTo.mergedId;
        for (let timelineSegmentMergedId of [ timelineSegmentMergedIdReferFrom, timelineSegmentMergedIdReferTo ]) {
          if (!Object.keys(this.$data.$_timelineSegmentIdsReferToOrFrom).includes(timelineSegmentMergedId)) {
            this.$set(
              this.$data.$_timelineSegmentIdsReferToOrFrom,
              timelineSegmentMergedId,
              generateEmptyTimelineSegmentIdsReferToOrFrom(),
            );
          }
        }
        let timelineSegmentIdsReferFrom = this.$data.$_timelineSegmentIdsReferToOrFrom[timelineSegmentMergedIdReferFrom];
        this.$set(timelineSegmentIdsReferFrom.referTo, registeredReferenceId, registeredReference.referTo);
        let timelineSegmentIdsReferTo = this.$data.$_timelineSegmentIdsReferToOrFrom[timelineSegmentMergedIdReferTo];
        this.$set(timelineSegmentIdsReferTo.referFrom, registeredReferenceId, registeredReference.referFrom);
      }
    },

    $_deleteTimelineSegmentIdCache(unregisteredReferenceIds) {
      let numUnregisteredReferences = unregisteredReferenceIds.length;
      for (let unregisteredReferenceIdx = 0; unregisteredReferenceIdx < numUnregisteredReferences; ++unregisteredReferenceIdx) {
        let unregisteredReferenceId = unregisteredReferenceIds[unregisteredReferenceIdx];
        for (let timelineSegmentIds of Object.values(this.$data.$_timelineSegmentIdsReferToOrFrom)) {
          if (Object.keys(timelineSegmentIds.referFrom).includes(unregisteredReferenceId)) {
            this.$delete(timelineSegmentIds.referFrom, unregisteredReferenceId);
          }
          if (Object.keys(timelineSegmentIds.referTo).includes(unregisteredReferenceId)) {
            this.$delete(timelineSegmentIds.referTo, unregisteredReferenceId);
          }
        }
      }
    },

    $_getTitleByReferToOrFrom(referToOrFrom) {
      switch (referToOrFrom) {
      case 'referTo':
        return 'Refer To';
      case 'referFrom':
        return 'Refer From';
      }
    }
  },
};
</script>


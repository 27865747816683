<template>
  <div
    class="canvas-container"
    v-bind="$attrs"
    v-on="$listeners"
    v-on:mousemove="$_onResize"
  >
    <slot
      name="default"
      v-bind:width-px="canvasWidthPx"
      v-bind:height-px="canvasHeightPx"
      v-bind:begin-sec="beginSec"
      v-bind:end-sec="endSec"
      v-bind:duration-sec="durationSec"
      v-bind:duration-sample="durationSample"
      v-bind:sample-resolution="sampleResolution"
      v-bind:canvas-local-rect="canvasLocalRect"
    >
    </slot>
  </div>
</template>

<style scoped>
div.canvas-container {
  flex-grow: 1;
  position: relative;
  width: 100%;
  height: 100%;
  user-select: none;
}

div.canvas-container > canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
</style>

<script>
import AudioSegmentSequence from '../../modules/AudioSegmentSequence.js';
import RationalNumber from '../../modules/RationalNumber.js';

function convertToSecond(rationalSampleOffset, samplingRate) {
  if (rationalSampleOffset === null) return null;
  if (samplingRate === null) return null;
  let rationalSecond = AudioSegmentSequence.convertTime(
    RationalNumber.generateFrom(Math.floor(rationalSampleOffset)),
    AudioSegmentSequence.TimeUnit.sample,
    AudioSegmentSequence.TimeUnit.second,
    { samplingRate },
  );
  return rationalSecond.toNumber();
}

export default {
  props: {
    beginSampleOffset: { type: Number, default: null },
    endSampleOffset: { type: Number, default: null },
    samplingRate: { type: RationalNumber, default: null },
  },

  data() {
    return {
      $_boundingClientRect: null,
      $_resizeObserver: new ResizeObserver(this.$_onResize),
    };
  },

  computed: {
    canvasWidthPx()    { return (this.$data.$_boundingClientRect)? this.$data.$_boundingClientRect.width : 1 },
    canvasHeightPx()   { return (this.$data.$_boundingClientRect)? this.$data.$_boundingClientRect.height : 1 },
    canvasLocalRect()  { return new DOMRect(0, 0, this.canvasWidthPx, this.canvasHeightPx) },
    durationSample()   { return this.endSampleOffset - this.beginSampleOffset },
    durationSec()      { return this.endSec - this.beginSec },
    beginSec()         { return convertToSecond(this.beginSampleOffset, this.samplingRate) },
    endSec()           { return convertToSecond(this.endSampleOffset, this.samplingRate) },
    sampleResolution() { return this.durationSample / this.canvasWidthPx },
  },

  mounted() {
    this.$data.$_resizeObserver.observe(this.$el);
    this.$_onResize();
  },

  beforeDestroy() {
    this.$data.$_resizeObserver.disconnect();
  },

  methods: {
    $_onResize() {
      let boundingClientRect = this.$el.getBoundingClientRect();
      boundingClientRect.width = Math.floor(boundingClientRect.width);
      boundingClientRect.height = Math.floor(boundingClientRect.height);
      this.$data.$_boundingClientRect = boundingClientRect;
      this.$emit('resize', { boundingClientRect, canvasLocalRect: this.canvasLocalRect });
    },
  },
}
</script>


<template>
  <dialog-base
    v-bind:shows="shows"
    v-bind:initialize-callback="$_onInitialize"
    v-bind:ok-callback="$_okCallback"
    v-bind:finalize-callback="finalizeCallback"
  >
    <template v-slot:body>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <v-text-field
          dense autofocus
          v-model="$data.$_textTmp"
        />
      </v-card-text>
    </template>
  </dialog-base>
</template>

<script>
import DialogBase from './DialogBase.vue';

export default {
  components: {
    DialogBase,
  },

  props: {
    shows: { type: Boolean, default: false },
    okCallback: { type: Function, default: null },
    finalizeCallback: { type: Function, default: null },
    title: { type: String, default: null },
    text: { type: String, default: null },
  },

  data() {
    return {
      $_textTmp: null,
    };
  },

  watch: {
    text() { this.$_onInitialize() },
  },

  methods: {
    $_onInitialize() {
      this.$data.$_textTmp = this.text;
    },

    $_okCallback() {
      this.okCallback(this.$data.$_textTmp);
    },
  },
}
</script>
<template>
  <v-card
    tile text outlined
    id="timeline-title-footer"
  >
    <v-btn
      icon small
      v-on:click="filterTimelineData"
    >
      <v-icon>mdi-format-list-checkbox</v-icon>
    </v-btn>
    <v-btn
      icon small
      v-on:click="$_toggleMouseMode"
    >
      <v-icon v-if="isMouseMode">mdi-mouse</v-icon>
      <v-icon v-else>mdi-trackpad</v-icon>
    </v-btn>
    <v-btn
      icon small
      v-on:click="$_emitToggleSnap"
    >
      <v-icon v-bind:disabled="!isSnapEnabled">mdi-format-horizontal-align-center</v-icon>
    </v-btn>
  </v-card>
</template>

<style scoped>
div#timeline-title-footer {
  width: 100%;
  height: 30px;
  border-width: 1px 0 0;
}
</style>

<script>
export default {
  props: {
    isMouseMode: { type: Boolean },
    isSnapEnabled: { type: Boolean },
  },

  inject: [
    'filterTimelineData',
  ],

  methods: {
    $_emitToggleSnap() {
      this.$emit('toggle-snap')
    },

    $_toggleMouseMode() {
      this.$emit('toggle-mouse-mode');
    },
  },
};
</script>
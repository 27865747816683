var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("v-select", {
        attrs: {
          multiple: "",
          "persistent-hint": "",
          label: "Timeline Data ID",
          items: _vm.$_timelineLabelDataIdAndNames,
        },
        on: {
          keydown: function ($event) {
            $event.stopPropagation()
          },
        },
        model: {
          value: _vm.$data.$_currentTimelineDataIds,
          callback: function ($$v) {
            _vm.$set(_vm.$data, "$_currentTimelineDataIds", $$v)
          },
          expression: "$data.$_currentTimelineDataIds",
        },
      }),
      _vm.$data.$_currentTimelineDataIds
        ? _c("v-data-table", {
            attrs: {
              dense: "",
              "hide-default-footer": "",
              headers: _vm.$_timelineSegmentHeaders,
              items: _vm.$_currentTimelineSegments,
              "items-per-page": _vm.$_currentTimelineSegments.length,
            },
            on: { "click:row": _vm.$_onClickRow },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.actions",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "div",
                        { staticClass: "table-actions" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "flex-nowrap",
                              attrs: { icon: "", small: "" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.$_openDialog(item)
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("mdi-dock-window"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              3381078077
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
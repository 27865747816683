<template>
  <dialog-base
    v-bind:shows="shows"
    v-bind:initialize-callback="$_onInitialize"
    v-bind:ok-callback="$_onOkClicked"
    v-bind:finalize-callback="finalizeCallback"
  >
    <template v-slot:body>
      <v-card-title>Filter Timeline Data</v-card-title>
      <v-card-text>
        <v-checkbox
          dense
          v-for="(timelineDataAppearenceWithInfo, timelineDataId) in $data.$_timelineDataAppearenceWithInfoSet"
          v-model="timelineDataAppearenceWithInfo.show"
          v-bind:label="timelineDataAppearenceWithInfo.timelineDataName"
          v-bind:key="timelineDataId"
        >
        </v-checkbox>
      </v-card-text>
    </template>

    <template v-slot:buttons="{ on, attrs }">
      <v-btn
        color="primary"
        v-bind="attrs"
        v-on:click="$_onToggleCheckAllClicked"
      >
        Toggle Check All
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        v-bind="attrs"
        v-on:click="on.cancelClicked"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        v-bind="attrs"
        v-on:click="on.okClicked"
      >
        OK
      </v-btn>
    </template>
  </dialog-base>
</template>

<script>
import { TimelineDataSet } from '../../../external/modules/ScattData.js';
import DialogBase from './DialogBase.vue';

class TimelineDataAppearenceWithInfo {
  constructor(show, timelineDataName) {
    this.show = Boolean(show);
    this.timelineDataName = String(timelineDataName);
  }
}

export default {
  components: {
    DialogBase,
  },

  props: {
    shows: { type: Boolean, default: false },
    okCallback: { type: Function, default: null },
    finalizeCallback: { type: Function, default: null },
    timelineDataSet: { type: TimelineDataSet, default: null },
  },

  data() {
    return {
      $_timelineDataAppearenceWithInfoSet: null,
    };
  },

  watch: {
    timelineDataSet: {
      handler() { this.$_onInitialize() },
      deep: true,
    },
  },

  methods: {
    /* private */
    $_onInitialize() {
      let timelineDataAppearenceWithInfoSet = new Object();
      for (let [ timelineDataId, timelineData ] of Object.entries(this.timelineDataSet)) {
        timelineDataAppearenceWithInfoSet[timelineDataId] = new TimelineDataAppearenceWithInfo(!timelineData.hidden, timelineData.name);
      }
      this.$data.$_timelineDataAppearenceWithInfoSet = timelineDataAppearenceWithInfoSet;
    },

    $_onToggleCheckAllClicked() {
      let allTimelineDataApperenceShowChecked = true;
      for (let timelineDataAppearenceWithInfo of Object.values(this.$data.$_timelineDataAppearenceWithInfoSet)) {
        if (!timelineDataAppearenceWithInfo.show) {
          allTimelineDataApperenceShowChecked = false;
          break;
        }
      }
      let showCheckValue = null;
      if (allTimelineDataApperenceShowChecked) {
        showCheckValue = false;
      } else {
        showCheckValue = true;
      }
      for (let timelineDataAppearenceWithInfo of Object.values(this.$data.$_timelineDataAppearenceWithInfoSet)) {
        timelineDataAppearenceWithInfo.show = showCheckValue;
      }
    },

    $_onOkClicked() {
      let isTimelineDataHiddenSet = new Object();
      for (let [ timelineDataId, timelineDataAppearenceWithInfo ] of Object.entries(this.$data.$_timelineDataAppearenceWithInfoSet)) {
        isTimelineDataHiddenSet[timelineDataId] = !timelineDataAppearenceWithInfo.show;
      }
      this.okCallback(isTimelineDataHiddenSet);
    },
  },
}
</script>

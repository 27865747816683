export default [
  'registerReferences',
  'unregisterReferences',
  'registerSelectSegmentsCallback',
  'unregisterSelectSegmentsCallback',

  'seekInMsec',

  'setMarker',
  'clearMarker',

  'enableLoopFlag',
  'disableLoopFlag',
  'setLoopFullDuration',
  'setLoopByMarker',

  'setSegment',
  'setNewSegment',
  'generateEmptySegment',
  'generateEmptyNoteReferTo',
  'generateAndAttachEmptyNote',
  'createSegment',
  'deleteSegments',
  'divideSegment',
  'copySegmentsFromCopySource',

  'removeTimelineData',
  'renameTimelineData',
  'lockTimeline',
  'unlockTimeline',
  'hideTimelineData',
  'unhideTimelineData',
  'duplicateTimelineData',
  'generateNewTimelineData',
  'generateNewHiddenTimelineData',
  'getTimelineDataImmutableReason',

  'invokeActionOnSelectedTimelineSegments',
  'selectTimelineSegments',
  'selectTimelineSegmentAndSeek',
  'clearSelectedTimelineSegments',

  'atomicChanges',
  'registerChange',
  'registerFooterMessage',
]
import EditorDefinition from '../../modules/EditorDefinition.js'

export default new EditorDefinition(
  'ScattInspector',
  'Inspector',
  (timelineDataSet) => {
    for (let timelineData of Object.values(timelineDataSet)) {
      if (Object.keys(timelineData.segments).length > 0) return true;
    }
    return false;
  },
);

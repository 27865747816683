<template>
  <v-navigation-drawer
    absolute right temporary
    v-model="$data.$_enabled"
    v-bind:permanent="$data.$_enabled"
  >
    <v-card flat v-on:keydown.stop>
      <v-subheader>
        <v-checkbox
          dense label="Author Name"
          v-model="$data.$_enableAuthorName"
        />
      </v-subheader>
      <v-card-text v-if="$data.$_enableAuthorName">
        <v-text-field
          dense
          v-model="$data.$_authorName"
        />
      </v-card-text>

      <v-subheader>
        <v-checkbox
          dense label="Author ID"
          v-model="$data.$_enableAuthorId"
        />
      </v-subheader>
      <v-card-text v-if="$data.$_enableAuthorId">
        <v-text-field
          dense
          v-model="$data.$_authorId"
        />
      </v-card-text>

      <v-subheader>
        <v-checkbox
          dense label="Since"
          v-model="$data.$_enableDateSince"
        />
      </v-subheader>
      <v-card-text v-show="$data.$_enableDateSince">
        <NoteEditorFilterDateSelector
          ref="noteEditorFilterDateSinceSelector"
          v-on:update-error="$_onUpdateErrorDateSince"
        />
      </v-card-text>

      <v-subheader>
        <v-checkbox
          dense label="Until"
          v-model="$data.$_enableDateUntil"
        />
      </v-subheader>
      <v-card-text v-show="$data.$_enableDateUntil">
        <NoteEditorFilterDateSelector
          ref="noteEditorFilterDateUntilSelector"
          v-on:update-error="$_onUpdateErrorDateUntil"
        />
      </v-card-text>

      <v-subheader>
        <v-checkbox
          dense label="Refer to"
          v-model="$data.$_enableReferences"
        />
        <v-spacer />
        <v-btn
          outlined text x-small fab
          v-if="$data.$_enableReferences"
          v-bind:disabled="!$_isTimelineSegmentIdFilterRegistered"
          v-on:click="$_clearTimelineSegmentIdFilters()"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn
          outlined text x-small fab
          v-if="$data.$_enableReferences"
          v-on:click="$_toggleSelectSegmentsCallbackRegistoration($event)"
        >
          <v-icon>mdi-playlist-plus</v-icon>
        </v-btn>
      </v-subheader>
      <v-card-text v-if="$data.$_enableReferences">
        <v-btn
          text small tile
          v-for="(timelineSegmentId, timelineSegmentIdFilterIdx) in $data.$_timelineSegmentIdFilters"
          v-bind:key="timelineSegmentIdFilterIdx"
          v-on:click="$_onClickReference(timelineSegmentId)"
        >
          {{ timelineSegmentId.mergedId }}
        </v-btn>
      </v-card-text>

      <v-subheader>
        <v-checkbox
          dense label="Text"
          v-model="$data.$_enableText"
        />
      </v-subheader>
      <v-card-text v-if="$data.$_enableText">
        <v-text-field
          dense
          v-model="$data.$_text"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary" text
          v-on:click="$_disable"
        >
          Close
        </v-btn>
        <v-btn
          color="primary" text
          v-bind:disabled="$_hasError"
          v-on:click="$_applyFilter"
        >
          Apply
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-navigation-drawer>
</template>

<style scoped>
.attached-reference {
  background-color: #f03e1e !important;
}
</style>

<script>
import NoteEditorFilterDateSelector from './NoteEditorFilterDateSelector.vue'

class NoteFilter {
  constructor(authorName, authorId, timeSince, timeUntil, timelineSegmentIdsReferringTo, text) {
    this.authorName = authorName;
    this.authorId = authorId;
    this.timeSince = timeSince;
    this.timeUntil = timeUntil;
    this.timelineSegmentIdsReferringTo = timelineSegmentIdsReferringTo;
    this.text = text;
  }

  static generateEmpty() {
    return new NoteFilter(null, null, null, null, [], null);
  }

  static emptyInstance = this.generateEmpty();

  isEmpty() {
    if (this.authorName !== NoteFilter.emptyInstance.authorName) return false;
    if (this.authorId !== NoteFilter.emptyInstance.authorId) return false;
    if (this.timeSince !== NoteFilter.emptyInstance.timeSince) return false;
    if (this.timeUntil !== NoteFilter.emptyInstance.timeUntil) return false;
    if (this.timelineSegmentIdsReferringTo !== NoteFilter.emptyInstance.timelineSegmentIdsReferringTo) return false;
    if (this.text !== NoteFilter.emptyInstance.text) return false;
    return true;
  }
}

export { NoteFilter };

export default {
  components: {
    NoteEditorFilterDateSelector,
  },

  data() {
    return {
      $_enabled: false,
      $_authorName: '',
      $_authorId: '',
      $_timelineSegmentIdFilters: new Object(),
      $_text: '',
      $_enableAuthorName: false,
      $_enableAuthorId: false,
      $_enableDateSince: false,
      $_enableDateUntil: false,
      $_enableReferences: false,
      $_enableText: false,
      $_hasErrorOnDateSince: null,
      $_hasErrorOnDateUntil: null,
      $_registerSelectSegmentsCallbackButtonElement: null,
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.noteEditorFilterDateSinceSelector.setDate(new Date(0));
      this.$refs.noteEditorFilterDateUntilSelector.setDate(new Date());
    });
  },

  beforeDestroy() {
    if (this.$_isSelectSegmentsCallbackRegistered) {
      this.$_unregisterSelectSegmentsCallback();
    }
  },

  computed: {
    $_hasError() {
      if (this.$data.$_hasErrorOnDateSince) return true;
      if (this.$data.$_hasErrorOnDateUntil) return true;
      return false;
    },

    $_isSelectSegmentsCallbackRegistered() { return (this.$data.$_registerSelectSegmentsCallbackButtonElement !== null); },
    $_isTimelineSegmentIdFilterRegistered() { return (Object.keys(this.$data.$_timelineSegmentIdFilters).length > 0); },
  },

  inject: [
    'registerSelectSegmentsCallback',
    'unregisterSelectSegmentsCallback',
    'selectTimelineSegmentAndSeek',
  ],

  methods: {
    /* public */
    enable() {
      this.$data.$_enabled = true;
    },

    /* private */
    $_disable() {
      this.$data.$_enabled = false;
      this.$_unregisterSelectSegmentsCallback();
    },

    $_onClickReference(reference)  { this.selectTimelineSegmentAndSeek(reference); },

    $_clearTimelineSegmentIdFilters() {
      this.$data.$_timelineSegmentIdFilters = new Object();
    },

    $_toggleSelectSegmentsCallbackRegistoration(event) {
      let referenceButtonElement = event.currentTarget;
      if (this.$data.$_registerSelectSegmentsCallbackButtonElement === referenceButtonElement) {
        this.$_unregisterSelectSegmentsCallback();
      } else {
        if (this.$_isSelectSegmentsCallbackRegistered) {
          this.$_unregisterSelectSegmentsCallback();
        }
        this.$_registerSelectSegmentsCallback(referenceButtonElement);
      }
    },

    $_applyFilter() {
      let empty = NoteFilter.emptyInstance;
      let authorName = (this.$data.$_enableAuthorName)? this.$data.$_authorName : empty.authorName;
      let authorId = (this.$data.$_enableAuthorId)? this.$data.$_authorId : empty.authorId;
      let text = (this.$data.$_enableText)? this.$data.$_text : empty.text;
      let timelineSegmentIdsReferringTo = (this.$data.$_enableReferences)? this.$data.$_timelineSegmentIdFilters : empty.timelineSegmentIdsReferringTo;
      let timeSince = (this.$data.$_enableDateSince)? this.$refs.noteEditorFilterDateSinceSelector.getDate() : empty.timeSince;
      let timeUntil = (this.$data.$_enableDateUntil)? this.$refs.noteEditorFilterDateUntilSelector.getDate() : empty.timeUntil;
      this.$emit('apply-filter', new NoteFilter(authorName, authorId, timeSince, timeUntil, timelineSegmentIdsReferringTo, text));
      this.$_disable();
    },

    $_onUpdateErrorDateSince(hasError) {
      this.$data.$_hasErrorOnDateSince = hasError;
    },

    $_onUpdateErrorDateUntil(hasError) {
      this.$data.$_hasErrorOnDateUntil = hasError;
    },

    $_unregisterSelectSegmentsCallback() {
      if (this.$data.$_registerSelectSegmentsCallbackButtonElement) {
        this.$data.$_registerSelectSegmentsCallbackButtonElement.classList.remove('attached-reference');
        this.$data.$_registerSelectSegmentsCallbackButtonElement = null;
      }
      this.unregisterSelectSegmentsCallback();
    },

    $_registerSelectSegmentsCallback(referenceButtonElement) {
      this.$data.$_registerSelectSegmentsCallbackButtonElement = referenceButtonElement;
      this.$data.$_registerSelectSegmentsCallbackButtonElement.classList.add('attached-reference');
      this.registerSelectSegmentsCallback((selectedTimelineSegmentIds) => {
        for (let selectedTimelineSegmentId of selectedTimelineSegmentIds) {
          let selectedTimelineSegmentMergedId = selectedTimelineSegmentId.mergedId;
          let foundTimelineSegmentId = Object.values(this.$data.$_timelineSegmentIdFilters).find((timelineSegmentId) => {
            return timelineSegmentId.isSame(selectedTimelineSegmentId);
          });
          if (foundTimelineSegmentId) {
            this.$delete(this.$data.$_timelineSegmentIdFilters, selectedTimelineSegmentMergedId);
          } else {
            this.$set(this.$data.$_timelineSegmentIdFilters, selectedTimelineSegmentMergedId, selectedTimelineSegmentId);
          }
        }
      });
    },
  },
}
</script>
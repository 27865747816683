var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("page-toolbar", {
        ref: "toolbar",
        attrs: {
          id: "toolbar",
          title: _vm.$t("console.templates.create.toolbar.title"),
          "previous-page-label": _vm.$t(
            "console.templates.create.toolbar.prevPageLabel"
          ),
        },
        scopedSlots: _vm._u([
          {
            key: "contents",
            fn: function () {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      text: "",
                      color: "indigo",
                      disabled: !_vm.presetGroupName || !_vm.presetName,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$refs.dialogCreateTemplate.show()
                      },
                    },
                  },
                  [
                    _c("v-icon", { attrs: { left: "" } }, [
                      _vm._v("mdi-check"),
                    ]),
                    _vm._v(
                      _vm._s(
                        _vm.$t("console.templates.create.toolbar.confirmButton")
                      ) + " "
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c(
            "v-list",
            {
              staticClass: "pt-0",
              style: _vm.styles.presetsList,
              attrs: { id: "presets-list", dense: "" },
            },
            [
              _c(
                "v-list-item-group",
                { attrs: { color: "indigo" } },
                [
                  _vm._l(_vm.presetCategoryItems, function (categoryItem, i) {
                    return [
                      _c(
                        "v-subheader",
                        {
                          key: `preset-category-subheader-${i}`,
                          staticClass: "font-weight-bold grey lighten-3",
                        },
                        [
                          _c("v-icon", {
                            attrs: { small: "", left: "" },
                            domProps: {
                              textContent: _vm._s(categoryItem.icon),
                            },
                          }),
                          _vm._v(" " + _vm._s(categoryItem.name) + " "),
                        ],
                        1
                      ),
                      _vm._l(categoryItem.presets, function (presetItem, j) {
                        return _c(
                          "v-list-item",
                          {
                            key: `preset-${i}-${j}`,
                            attrs: { link: "" },
                            on: {
                              click: function ($event) {
                                return _vm.setPresetSelection(presetItem)
                              },
                            },
                          },
                          [
                            _c("v-list-item-content", [
                              presetItem.component
                                ? _c("span", [_vm._v(_vm._s(presetItem.name))])
                                : _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-decoration-line-through",
                                    },
                                    [_vm._v(_vm._s(presetItem.name))]
                                  ),
                            ]),
                          ],
                          1
                        )
                      }),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "v-card",
            {
              style: _vm.styles.navCollapseBtn,
              attrs: { tile: "", id: "nav-collapse-button" },
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { large: "", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.navCollapsed = !_vm.navCollapsed
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { large: "" } }, [
                    _vm._v(_vm._s(this.navCollapseBtnIcon)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { style: _vm.styles.component, attrs: { id: "component" } },
            [
              _c(_vm.component, {
                tag: "component",
                on: {
                  submit: _vm.showSubmitResults,
                  "set-nano-props": _vm.updateNanoProps,
                  "response-update": _vm.updateNanoAnswers,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("response-navigation-drawer", {
        attrs: { "nano-props": _vm.nanoProps, "nano-answers": _vm.nanoAnswers },
      }),
      _c("dialog-create-template", {
        ref: "dialogCreateTemplate",
        attrs: {
          client: _vm.client,
          "project-name": _vm.projectName,
          "preset-group-name": _vm.presetGroupName,
          "preset-name": _vm.presetName,
        },
        on: { complete: _vm.goBackToTemplatesPage },
      }),
      _c("dialog-submit-response", {
        ref: "dialogSubmitResponse",
        attrs: { "nano-answers": _vm.nanoAnswers },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tutti-dialog", {
    ref: "dialog",
    attrs: {
      "max-width": "400",
      buttons: [
        {
          label: _vm.$t("console.templates.create.dialog.buttons.cancel"),
          attrs: {
            color: "grey darken-1",
            text: true,
          },
          on: {
            click() {
              _vm.$refs.dialog.hide()
            },
          },
        },
        {
          label: _vm.$t("console.templates.create.dialog.buttons.create"),
          attrs: {
            color: "indigo darken-1",
            text: true,
          },
          on: {
            click: _vm.createTemplate,
          },
        },
      ],
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [
            _vm._v(
              " " +
                _vm._s(_vm.$t("console.templates.create.dialog.title")) +
                " "
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "v-card-text",
              [
                _c(
                  "v-form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                    model: {
                      value: _vm.valid,
                      callback: function ($$v) {
                        _vm.valid = $$v
                      },
                      expression: "valid",
                    },
                  },
                  [
                    _c("v-text-field", {
                      attrs: {
                        filled: "",
                        autofocus: "",
                        "prepend-icon": "mdi-pencil",
                        label: _vm.$t("console.templates.create.dialog.label"),
                        rules: [
                          (val) =>
                            _vm.rules.reg(
                              /^[a-zA-Z0-9-_]+$/,
                              "Only numbers, alphabets, _, and - are allowed",
                              val
                            ),
                        ],
                        "error-messages": _vm.errorMessage,
                      },
                      model: {
                        value: _vm.newTemplateName,
                        callback: function ($$v) {
                          _vm.newTemplateName = $$v
                        },
                        expression: "newTemplateName",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
class TimelineMarker {
  constructor(beginTimeMsec, endTimeMsec) {
    this.beginTimeMsec = beginTimeMsec;
    this.endTimeMsec = endTimeMsec;
  }

  get durationMsec() {
    return this.endTimeMsec - this.beginTimeMsec;
  }
}

export default TimelineMarker;

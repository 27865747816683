var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("dialog-base", {
    attrs: { shows: _vm.shows, "finalize-callback": _vm.finalizeCallback },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _vm.title
              ? _c("v-card-title", [_vm._v(_vm._s(_vm.title))])
              : _vm._e(),
            _vm.messages
              ? _c(
                  "v-card-text",
                  _vm._l(_vm.messages, function (message, messageIdx) {
                    return _c("div", { key: messageIdx }, [
                      _vm._v(" " + _vm._s(message) + " "),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "buttons",
        fn: function ({ on, attrs }) {
          return [
            _c("v-spacer"),
            _c(
              "v-btn",
              _vm._b(
                { attrs: { color: "primary" }, on: { click: on.okClicked } },
                "v-btn",
                attrs,
                false
              ),
              [_vm._v(" OK ")]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
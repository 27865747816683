/* eslint-disable no-console */

export default {
  normal: 'Normal Mode',
  select: 'Select Mode',
  create: 'Create Segment Mode',
  divide: 'Divide Segment Mode',
  merge: 'Merge Segment Mode',
  position: 'Position Edit Mode',
};

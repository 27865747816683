import { TimelineSegmentDataBase, TimelineSegmentContentDataBase } from '../ScattData.js';

class PlaybackHistoryData extends TimelineSegmentContentDataBase {
  constructor(date) {
    super();
    this.date = date;
  }

  generateRawObj() {
    let playbackHistoryDataRawObj = new Object();
    playbackHistoryDataRawObj.date = this.date.toISOString();
    return playbackHistoryDataRawObj;
  }

  isSame(that) {
    if (this.date.getTime() !== that.date.getTime()) return false;
    return true;
  }
}

class PlaybackHistorySegmentData extends TimelineSegmentDataBase {
  static contentData = PlaybackHistoryData;

  constructor(begin, end, playbackHistoryData, isLocked) {
    super(begin, end, playbackHistoryData, isLocked);
  }

  static generateFromRawObj(begin, end, rawObj) {
    return new PlaybackHistorySegmentData(begin, end, new PlaybackHistoryData(new Date(rawObj.date)), false);
  }

  static generateEmpty(begin, end) {
    return new PlaybackHistorySegmentData(begin, end, new PlaybackHistoryData(new Date()), false);
  }

  static toString() {
    return 'PlaybackHistorySegmentData';
  }

  static get name() {
    return 'Playback History Data';
  }

  generateRawObj() {
    let playbackHistorySegmentDataRawObj = new Object();
    playbackHistorySegmentDataRawObj.begin = this.begin;
    playbackHistorySegmentDataRawObj.end = this.end;
    playbackHistorySegmentDataRawObj.data = this.data.generateRawObj();
    playbackHistorySegmentDataRawObj.locked = this.locked;
    return playbackHistorySegmentDataRawObj;
  }

  isSame(that) {
    return super.isSame(that) && this.data.isSame(that.data);
  }
}

export default PlaybackHistorySegmentData;
